import { call, put, takeEvery } from 'redux-saga/effects'
import instance from "../../../../api/api_instance";

async function getApi(data) {
  try {
    const result = instance({
      url: "v1/add-profile-picture",
      method: "POST",
      data: data,
    }).then((response) => {
      return response;
    })
    return await result;
  } catch (error) {
    throw error;
  }
}
function* fetchProfile(action) {
  try {
    const res = yield call(getApi, action.payload);
    const toastrData = {
      content: "Profile Picture updated successfully",
      type: "success"
    }
    yield put({ type: "ADD_PROFILE_PICTURE_SUCCESS", profileDetails: res.data.data });
    yield put({ type: "GET_PROFILE_DETAILS_REQUESTED", payload: {} });
    yield put({ type: "SET_TOASTR_SUCCESS", data: toastrData });

  } catch (e) {
    if (e.response.status === 400 || e.response.status === 404 || e.response.status === 409) {
      yield put({ type: "ADD_PROFILE_PICTURE_FAILED", message: e.response.data.message });
    } else {
      yield put({ type: "ADD_PROFILE_PICTURE_FAILED", message: "Some error occurred!" });
    }

    if (e.response.status === 500) {
      const toastrFailedData = {
          content: e.response.data.errors ? e.response.data.errors : "Something went wrong!",
          type: "failed"
      };
      yield put({ type: "SET_TOASTER_SUCCESS", data: toastrFailedData });
    }
  }
}

function* addProfilePictureSaga() {
  yield takeEvery("ADD_PROFILE_PICTURE_REQUESTED", fetchProfile);
}

export default addProfilePictureSaga;
