import { call, put, takeEvery } from 'redux-saga/effects'
import instance from "../../../../api/api_instance";

async function getApi(data) {
  try {
    const result = instance({
      url: "v1/forgot-password-otp-verify",
      method: "POST",
      data: data,
    }).then((response) => {
      return response;
    })
    return await result;
  } catch (error) {
    throw error;
  }
}
function* fetchAuth(action) {
  try {
    const res = yield call(getApi, action.payload);
    const data = {
      otp: action.payload.otp,
      data: res.data.data,
    }
    const toastrData = {
      content: "OTP verified successfully",
      type: "success"
    }
    yield put({ type: "VERIFY_FORGOT_PASSWORD_EMAIL_OTP_SUCCESS", data: data });
    yield put({ type: "SET_TOASTR_SUCCESS", data: toastrData });
  } catch (e) {
    if (e.response.status === 400 || e.response.status === 404 || e.response.status === 409) {
      yield put({ type: "VERIFY_FORGOT_PASSWORD_EMAIL_OTP_FAILED", message: e.response.data.message });
    } else {
      yield put({ type: "VERIFY_FORGOT_PASSWORD_EMAIL_OTP_FAILED", message: "Some error occurred!" });
    }
  }
}

function* verifyForgotPasswordEmailOtpSaga() {
  yield takeEvery("VERIFY_FORGOT_PASSWORD_EMAIL_OTP_REQUESTED", fetchAuth);
}

export default verifyForgotPasswordEmailOtpSaga;
