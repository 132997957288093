import React, { useState } from "react";
import PageTitle from "../../../components/global/common/pageTitle";
import Layout from "../../../layout";
import { linkedInAuth } from "../../../utils/socialLogin";
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Row,
} from "reactstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  experimentalEmailSelector,
  resetLoginErrorMessages,
} from "../../../redux/reduxes/global/auth/actions";
import { useEffect } from "react";
import EmailSelector from "../../../components/customer/account-settings/emailSelector";
import EmailSelectorOTP from "../../../components/customer/account-settings/emailOTP";

const params = new URLSearchParams(window.location.search);
const emailOne = params.get("emailOne");

// const updateEmailStep=0
const EmailSelection = () => {
  const updateEmailStep = useSelector((state) => state.profile.updateEmailStep);

  return (
    <>
      <PageTitle pageTitle="Select Email" />

      <Layout>
        {/* Register Section */}
        <section
          className="us-section us-section__auth"
          style={{
            background:
              "url('/assets/images/auth/auth-bg.png') no-repeat 80% 10%",
          }}
        >
          <div className="us-section__inwrap pt-md-20">
            <Container>
              <Row>
                <Col md="6" xl="6" className="my-auto">
                  <div className="us-auth__left d-none d-md-block">
                    <div className="us-auth__left-imgwrap">
                      <img
                        src="/assets/images/auth/auth-banner.png"
                        alt="auth-banner"
                        className="img-fluid"
                      />
                    </div>
                  </div>
                </Col>
                {updateEmailStep==0? (
                  // Registration Form
                  <EmailSelector />
                  
                ) : (
                  // Verify Email and Mobile OTP
                  <EmailSelectorOTP />
                )}
              </Row>
            </Container>
          </div>
        </section>
      </Layout>
    </>
  );
};
export default EmailSelection;
