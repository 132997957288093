import React, { useEffect,  useState } from "react";
import { Button, Form, FormGroup, Input, Label, Row, Col } from "reactstrap";
import Icon from "react-eva-icons";
import { useDispatch, useSelector } from "react-redux";
import { createExpertTabOne, getExpertDetailsStepOne, updateExpertTabOne } from "../../../../redux/reduxes/customer/expertRegistration/action";
import {
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import AddPortfolioUrl from "./addPortfolioUrl";

// Personal Info
const infos = [
  { id: "individual", title: "Individual", checked: true, disabled:false, tag:""  },
  { id: "organization", title: "Organization", checked: false, disabled:true, tag:"This feature is coming soon"},
];

// User types
const lists = [
  {
    id: "user_researcher",
    title: "UX Design & Research Expert",
    icon: "ux-design-research-expert.svg",
    description:
      "An expert in studying user behavior and optimizing the user experience and product usability.",
  },
  {
    id: "ux_expert",
    title: "UI Design Expert",
    icon: "ui-design-expert.svg",
    description:
      "An expert specialized in analyzing the layout, visuals, and interactive elements of a digital product.",
  },
  {
    id: "marketing_expert",
    title: "Marketing Expert",
    icon: "marketing-expert.svg",
    description:
      "An expert in market research, brand positioning, and audience segmentation.",
  },
  {
    id: "business_consultant",
    title: "Business Consultant",
    icon: "business-consultant.svg",
    description: `An expert in understanding the intricacies of business operations and performance.`,
  },
];

const PersonalInfoTab = ({ toggleExpertRegistrationTabs }) => {
  const dispatch = useDispatch();
  const { realExpertType } = useParams();
  const search = useLocation().search;
  const expert = new URLSearchParams(search).get("expert");

  //get Data
  const createExpertStepOneLoading = useSelector((state) => state.expertRegistration.createExpertStepOneLoading);
  const expertRegistrationActiveTab = useSelector((state) => state.expertRegistration.expertActiveStep);
  const stepOneData = useSelector((state) => state.expertRegistration.stepOneData);
  console.log(stepOneData, "stepOneData in the");

    // Get page one data
  useEffect(() => {
    if (expertRegistrationActiveTab===0) {
      dispatch(getExpertDetailsStepOne());
    }
    }, [expertRegistrationActiveTab]);

  //States
  const [state, setState] = useState({
    expert_title: [],
    marketFit: false,
    portfolio: [],

    errors: {
      expert_title: "",
      market_fit: "",
      portfolio: "",
    },
  });

  // Sync to state on update
  useEffect(() => {
    stepOneData && stepOneData.iAm && setState((prevState) => ({
      ...prevState,
      expert_title: stepOneData && stepOneData.iAm && stepOneData.iAm,
      marketFit: stepOneData && stepOneData.marketFit && stepOneData.marketFit,
    }));
    stepOneData && stepOneData.iAm && setAllPortfolio(stepOneData && stepOneData.portfolioUrl && stepOneData.portfolioUrl);
    }, [stepOneData]);

  // Add Multiple Prototype URLs
  const [allPortfolio, setAllPortfolio] = useState([
    {
      url: "",
      urlError: "",
    },
    ...state.portfolio,
  ]);

  const handleAddProtfolio = () => {
    const newProtfolio = { url: "" };
    setAllPortfolio([...allPortfolio, newProtfolio]);
  };

  // onChange Prototype URL
  const handlePortfolioUrlChange = (value, indexNumber) => {
    const validUrlRegex = new RegExp(
      "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?"
    );
    const updatedPortfolio = allPortfolio&&allPortfolio.map((items, index) => {
      if (indexNumber === index) {
        return {
          ...items,
          url: value,
          urlError:
            value === ""
              ? "Enter Prototype URL"
              : !validUrlRegex.test(value)
              ? "Invalid Url"
              : "",
        };
      }
      return items;
    });
    setAllPortfolio(updatedPortfolio);

    // Update the state's portfolio field with the URLs
    setState((prevState) => ({
      ...prevState,
      portfolio: updatedPortfolio&&updatedPortfolio.map((item) => item.url),
    }));
  };

  const handleRemovePortfolio = (index) => {
    // const filteredPrototypes = allPortfolio.filter((_, i) => i !== index);
    let arr = allPortfolio.splice(index, 1); // Remove 1 element from locationList at index i

    // Get the elements that were not removed by creating a copy of locationList
    let nonRemovedElements = allPortfolio.slice();
    setAllPortfolio(nonRemovedElements);
  };

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const onCheckboxExpertTitle = (event) => {
    const { value, checked } = event.target;
    // if (state.expert_title.length > 1) {

    // }else
    let expertTitle = state.expert_title;

    if (checked) {
      expertTitle = [...expertTitle, value];
    } else {
      expertTitle = expertTitle.filter((item) => item !== value);
    }

    setState((prevState) => ({
      ...prevState,
      expert_title: expertTitle,
      errors: { ...prevState.errors, expert_title: "" },
    }));
  };

  const onCheckboxMarketFit = (event) => {
    console.log(event.target,"event.target")
    const { checked } = event.target;
    setState((prevState) => ({
      ...prevState,
      marketFit: checked,
      errors: { ...prevState.errors, market_fit: "" },
    }));
  };

  //Validate Form
  const validateForm = () => {
    const errors = {
      expert_title:
        state.expert_title.length === 0 ? "Select at least one option" : "",
      // market_fit: state.market_fit === "" ? "Select an option" : "",
      portfolio: allPortfolio.some((item) => item.urlError)
        ? "Enter Prototype URL"
        : "",
    };

    setState((prevState) => ({
      ...prevState,
      errors: errors,
    }));
console.log(errors);
    return Object.values(errors).every((error) => error === "");
  };

  const onSubmitHandler = (event) => {
    event.preventDefault();
    // console.log(stepOneData._id, "gbvchbshbsubusjbn OKOKOKOK");
    if (validateForm()) {
      const portfolioUrl = allPortfolio&&allPortfolio.map((item) => ({ url: item.url }));
      const data = {
        iAm: state.expert_title,
        marketFit: state.marketFit,
        portfolioUrl: portfolioUrl,
      };
      // console.log("coming inside huhuhuh");
      if (stepOneData&& stepOneData._id) {
        dispatch(updateExpertTabOne(data));
      } else {
        dispatch(createExpertTabOne(data));
      }
    }
  };
  const isAnyUrlEmpty = () => {
    return allPortfolio.some((portfolio) => portfolio.url === "");
  };
  const doNothing = () => {}
  return (
    <>
      <div>
        <Form onSubmit={(e) => onSubmitHandler(e)}>
          {/* User Types Input */}
          <div className="">
            <FormGroup className="us-form__wrap">
              <Label
                htmlFor="user_type"
                className="us-form__label mb-4 font-bold"
              >
                I am a &#40;Choose up to 2 options&#41;
                <span style={{ color: "red" }}>*</span>
              </Label>
              {/* {console.log(lists, state, "listslistslistslists")} */}
              <Row className="g-4">
                {lists &&
                  lists.map((list, index) => {
                    const { id, title, icon, description } = list;
                    return (
                      <Col xs={12} sm={6} lg={3} key={id}>
                        <div
                          className={`us-form__wrap us-form__custom-checkboxcard bg-white border border-1 rounded-3 w-100 ${
                            state.expert_title.length == 2 &&
                            !state.expert_title.includes(title)
                              ? "opacity-03"
                              : ""
                          }`}
                          // key={index}
                        >
                          <Input
                            type="checkbox"
                            id={id}
                            name="expert_phase"
                            className="us-form__custom-checkbox"
                            value={list.title}
                            onChange={(e) => onCheckboxExpertTitle(e)}
                            checked={state.expert_title.includes(title)}
                            disabled={
                              state.expert_title.length == 2 &&
                              !state.expert_title.includes(title)
                            }
                          />
                          <Label
                            htmlFor={id}
                            className={`us-form__custom-label  us-form__custom-label--radioTopRight ${
                              state.expert_title.length == 2 &&
                              !state.expert_title.includes(title)
                                ? "cursor-reset"
                                : ""
                            }`}
                          >
                            <img
                              src={`/assets/icons/expert-registration/${icon}`}
                              alt={title}
                              className="my-5"
                            />
                            <div className="us-form__custom-label--title head-font font-bold text-sm">
                              {title}
                            </div>

                            <p className="us-para text-sm">{description}</p>
                          </Label>
                        </div>
                      </Col>
                    );
                  })}
              </Row>

              <div className="us-form__error">{state.errors.expert_title}</div>
            </FormGroup>
          </div>
          <div className="us-form__custom-checkboxcard bg-white rounded-3 w-100 mb-3">
            <Input
              type="checkbox"
              id="evaluateProductMarketFit"
              name="market_fit"
              className="us-form__custom-checkbox"
              value="evaluateProductMarketFit"
              onChange={onCheckboxMarketFit} // No need to pass (e) here
              checked={state.marketFit}
            />
            <Label
              htmlFor="evaluateProductMarketFit"
              className="us-form__custom-label us-form__custom-label--center text-start"
            >
              <h4 className="text-md text-lg-xl font-bold">
                Evaluate product market fit
              </h4>
              <p className="text-sm color-gray">
                <Icon name="question-mark-circle" size="small" fill="#7F7F7F" />{" "}
                Validate your product/service for market fit
              </p>
            </Label>
          </div>
          <div className="us-form__wrap-card">
            <FormGroup className="us-form__wrap mb-12">
              <div className="w-100 d-flex align-items-start flex-wrap gap-2 gap-md-3">
                {infos &&
                  infos.map((app, index) => {
                    const { id, title, checked, disabled, tag } = app;
                    return (
                      <>
                        <div
                          className={`custom-radio-v1 me-4  ${
                            title === "Organization"
                              ? "opacity-03 cursor-reset"
                              : ""
                          }`}
                          key={id}
                        >
                          <input
                            type="radio"
                            id={id}
                            name="radio-group"
                            checked={checked}
                            disabled={disabled}
                            onChange={(e) => doNothing(e)}
                          ></input>
                          <label
                            for={id}
                            className={`${
                              title === "Organization" ? "cursor-reset" : ""
                            }`}
                          >
                            {title}
                          </label>
                          <span className="text-xs d-block ms-9">{tag}</span>
                        </div>
                      </>
                    );
                  })}
              </div>
            </FormGroup>

            <FormGroup className="us-form__wrap">
              <Label
                htmlFor="portfolio_url"
                className="us-form__label text-md text-lg-xl font-bold"
              >
                App/website/Portfolio URL
                <span style={{ color: "red" }}>*</span>
              </Label>

              <div
                className={`${
                  allPortfolio &&
                  allPortfolio.length > 3 &&
                  "us-form__input-lists"
                }`}
              >
                {allPortfolio &&
                  allPortfolio.length > 0 &&
                  allPortfolio.map((portfolio, index) => {
                    return (
                      <AddPortfolioUrl
                        allPortfolio={allPortfolio}
                        key={index}
                        indexNumber={index}
                        value={portfolio}
                        handlePortfolioUrlChange={handlePortfolioUrlChange}
                        handleRemovePortfolio={handleRemovePortfolio}
                        urlError={portfolio.urlError}
                      />
                    );
                  })}
              </div>

              {/* <div className='us-form__error'>{state.errors.portfolio}</div> */}

              <div className="mt-8 text-end">
                <Button
                  onClick={handleAddProtfolio}
                  type="button"
                  className="us-button__link text-primary font-medium border-0 bg-transparent"
                  disabled={isAnyUrlEmpty()}
                >
                  Add another URL{" "}
                  <Icon
                    name="plus"
                    size="large" // small, medium, large, xlarge
                    fill="#7F7F7F"
                  />
                </Button>
              </div>
            </FormGroup>
          </div>

          <div className="mt-8 mt-md-14 text-end">
            {/* <Button
              onClick={() => dispatch(0)}
              type="button"
              color="primary"
              outline
              className="us-button us-button__primary-outline text-uppercase px-10 me-6"
            >
              Cancel
            </Button> */}
            <Button
              // onClick={() => toggleExpertRegistrationTabs("2")}
              type="submit"
              color="primary"
              disabled={createExpertStepOneLoading}
              className={`us-button us-button__primary text-uppercase px-10 ${
                createExpertStepOneLoading
                  ? "us-button__disabled"
                  : "us-button__primary"
              }`}
            >
              {createExpertStepOneLoading ? "Saving..." : "Continue"}
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
};
export default PersonalInfoTab;
