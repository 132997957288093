import { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, FormGroup, Label, Col } from "reactstrap";
import { updateEmailOTPVerification,resendUpdateEmailOTP } from "../../../redux/reduxes/customer/profile/actions";
import UpdateEmailModal from "../../global/auth/updateEmailModal";

const VerifyOTP = () => {
  const dispatch = useDispatch();

  // Fetch Data
  const updateEmailOTPVerificationError = useSelector(state => state.profile.updateEmailOTPVerificationError);

  const email = useSelector((state) => state.profile.email); 
  const emailOtpToken = useSelector((state) => state.profile.emailOtpToken);
  const updateEmailOTPVerificationLoading = useSelector(state => state.profile.updateEmailOTPVerificationLoading);
  const profileDetails = useSelector(state => state.profile.profileDetails);


  // const mobileOtpToken = useSelector((state) => state.auth.mobileOtpToken);
 
  const verifyEmailMobileOTPError = useSelector(
    (state) => state.auth.verifyEmailMobileOTPError
  );
  const setShowRegisterUpdateEmailModal = useSelector(
    (state) => state.auth.setShowRegisterUpdateEmailModal
  );



  // const theToken = localStorage.getItem('token');

  // Dispatch Email & Mobile Confirmation Tokens
  // useEffect(() => {
  //   console.log("coming inside this", theToken);

  //   theToken&&theToken!=""&& dispatch(confirmEmailMobileOTP());
  // }, [theToken]);

  // States
  const [state, setState] = useState({
    email: "",
    email_otp: "",
    // email_otp: "",
    // mobile_otp: "",

    errors: {
      email_otp: "",
      mobile_otp: "",
    },
  });

  useEffect(() => {
    profileDetails && email && setState((prevProps) => ({
      ...prevProps,
      email: email,
    }))
  }, [profileDetails]);
  
  // On Email Change Handler
  const onEmailChangeHandler = (event) => {
    let errors = state.errors;
    errors.email_otp = "";
    setState((prevProps) => ({
      ...prevProps,
      errors,
      email_otp: event,
    }));
  };


  

  // OTP Expiration Time ====== EMAIL
  const [emailTimeRemaining, setEmailTimeRemaining] = useState(120); // Time remaining in seconds
  const [isEmailRunning, setIsEmailRunning] = useState(true); // Whether the countdown is running or paused

  // Decrement Time remaining by every 1 second  ====== EMAIL
  useEffect(() => {
    let interval;
    if (isEmailRunning && emailTimeRemaining > 0) {
      interval = setInterval(() => {
        setEmailTimeRemaining((prevTime) => prevTime - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isEmailRunning, emailTimeRemaining]);

  // Reset the Timer to 2 Minutes ====== EMAIL
  const handleEmailResetTime = () => {
    setIsEmailRunning(true);
    setEmailTimeRemaining(120);
  };

  // Format the Time remaining as MM:SS ====== EMAIL
  const minutesEmail = Math.floor(emailTimeRemaining / 60)
    .toString()
    .padStart(2, "0");
  const secondsEmail = (emailTimeRemaining % 60).toString().padStart(2, "0");
  const timeStringEmail = `${minutesEmail}:${secondsEmail}`;

  // Resend Email OTP Confirmation
  const confirmResendEmailOTP = (event) => {
    event.preventDefault();
    const data = {
      email: email,
    };
    dispatch(resendUpdateEmailOTP(data));
    handleEmailResetTime();
  };

  // OTP Expiration Time ====== MOBILE NUMBER
  const [mobileTimeRemaining, setMobileTimeRemaining] = useState(120); // Time remaining in seconds
  const [isMobileRunning, setIsMobileRunning] = useState(true); // Whether the countdown is running or paused

  // Decrement Time remaining by every 1 second  ====== MOBILE NUMBER
  useEffect(() => {
    let interval;
    if (isMobileRunning && mobileTimeRemaining > 0) {
      interval = setInterval(() => {
        setMobileTimeRemaining((prevTime) => prevTime - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isMobileRunning, mobileTimeRemaining]);
  console.log(updateEmailOTPVerificationError,"updateEmailError")
  // useEffect(() => {
  //   let errors = { ...state.errors };
  //   if (updateEmailError && updateEmailError.length > 0) {
  //     updateEmailError.forEach(error => {
  //       switch (error.context.key) {
  //         case "email":
  //           errors.email = error.message;
  //           break;
  //         default:
  //           break;
  //       }
  //     });
  //   } else {
  //     errors.email = "";
  //   }
  //   setState((prevProps) => ({
  //     ...prevProps,
  //     errors,
  //   }));
  // }, [updateEmailError]);
  

  // onSubmit Handler
  const onSubmitHandler = async (event) => {
    event.preventDefault();
    // if (validateForm()) {
    //   const data = {
    //     email: email,
    //     mobileNumber: mobileNumber,
    //     emailOtpToken: emailOtpToken,
    //     mobileOtpToken: mobileOtpToken,
    //     emailOtp: state.email_otp,
    //     mobileOtp: state.mobile_otp,
    //   };
    const data = {

      email: state.email,

      emailOtp: state.email_otp,

      emailOtpToken: emailOtpToken,

    }
    console.log(state.email,"state.email")
      dispatch(updateEmailOTPVerification(data));
    // } else {
    //   let errors = state.errors;
    //   if (state.email_otp == "") {
    //     errors.email_otp = "Enter Email OTP";
    //   }
    //   if (state.mobile_otp == "") {
    //     errors.mobile_otp = "Enter Mobile Number OTP";
    //   }
    // }
  };

  return (
    <>
      <Col md="6" xl="5">
        <div className="us-auth__right px-6 py-8 px-lg-10 py-lg-10">
          <Form onSubmit={(e) => onSubmitHandler(e)}>
            <h1 className="us-section__title mb-10 text-center">
              Verify your Identity
            </h1>

            <div className="mb-12">
              {/* Email OTP Input */}
              <FormGroup className="us-form__wrap">
                <Label
                  htmlFor="verify_email_top"
                  className="us-form__label mb-0"
                >
                  Enter OTP received on Email
                </Label>
                <div className="us-para d-flex align-items-center mb-3"> </div>
                <OtpInput
                  id="verify_email_otp"
                  name="email_otp"
                  numInputs={6}
                  isInputNum={true}
                  className="me-2"
                  inputStyle="us-form__input-otp"
                  value={state.email_otp}
                  onChange={(e) => onEmailChangeHandler(e)}
                  shouldAutoFocus={true}
                />
                {/* <div className="us-form__error">{state.errors.email_otp}</div>
                {verifyEmailMobileOTPError &&
                  verifyEmailMobileOTPError.error &&
                  verifyEmailMobileOTPError.error.emailOtpValid && (
                    <div className="us-form__error">
                      {verifyEmailMobileOTPError.message}
                    </div>
                  )} */}
                {updateEmailOTPVerificationError && <div className="us-form__error">{updateEmailOTPVerificationError}</div>}

              </FormGroup>

              <div className="flex items-center mt-3">
                {(timeStringEmail == "00:00" ||
                  state.errors.email_otp == "OTP expired") && (
                  <Button
                    type="button"
                    color="link"
                    onClick={(e) => confirmResendEmailOTP(e)}
                    className="us-button__link text-primary text-sm font-semibold p-0"
                  >
                    Resend OTP
                  </Button>
                )}
                {timeStringEmail != "00:00" && (
                  <div className="color-gray3 text-md font-medium">
                    <div>{timeStringEmail}</div>
                  </div>
                )}
              </div>
            </div>

            {/* <Button
              type="submit"
              color="primary"
              disabled={verifyEmailMobileOTPLoading}
              className={`us-button us-button__primary text-uppercase w-100 ${
                verifyEmailMobileOTPLoading
                  ? "us-button__disabled"
                  : "us-button__primary"
              }`}
            >
              {verifyEmailMobileOTPLoading ? "Loading..." : "Verify Account"}
            </Button> */}
            <Button
                  type="submit"
                  color="primary"
                  disabled={updateEmailOTPVerificationLoading}
                  className={`us-button us-button__primary py-3 px-8 ${updateEmailOTPVerificationLoading ? "us-button__disabled" : "us-button__primary"}`}
                >
                  {updateEmailOTPVerificationLoading ? "Verifying..." : "Verify"}
                </Button>
          </Form>

          {/* Update Email Modal */}
          {setShowRegisterUpdateEmailModal && (
            <UpdateEmailModal
              setShowRegisterUpdateEmailModal={setShowRegisterUpdateEmailModal}
            />
          )}
        </div>
      </Col>
    </>
  );
};
export default VerifyOTP;
