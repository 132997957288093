import * as type from "./types";

const initialState = {
  // General
  showManageEvelutionMaterial: false,
  openedEvelutionMaterialId: null,
  showManageEvelutionMaterialIsUpdate: false,
  evaluationMaterialActiveStep: 0,

  // Get All Evaluation Materials Details
  allEvaluationDetails: {},
  allEvaluationDetailsLoading: false,
  allEvaluationDetailsError: null,

  // Create Evaluation Material - Step One
  createEvaluationStepOneLoading: false,
  createEvaluationStepOneError: null,

  // Update Evaluation Material - Step one
  updateEvaluationStepOneLoading: false,
  updateEvaluationStepOneError: null,

  // Update Evaluation Material - Step Two
  updateEvaluationStepTwoLoading: false,
  updateEvaluationStepTwoError: null,

  // Update Evaluation Material - Step Three
  updateEvaluationStepThreeLoading: false,
  updateEvaluationStepThreeError: null,

  // Delete Project Persona
  deleteEvalutionMaterialLoading: false,
  deleteEvalutionMaterialError: null,

  // Delete Evaluation Materials
  deleteEvaluationMaterialsLoading: false,
  deleteEvaluationMaterialsError: null,

  // Get Evaluation Details - Step One
  evaluationStepOneDetails: {},
  evaluationStepOneDetailsLoading: false,
  evaluationStepOneDetailsError: null,

  // Get Evaluation Details - Step Two
  evaluationStepTwoDetails: {},
  evaluationStepTwoDetailsLoading: false,
  evaluationMaterialMedias:[],
  evaluationStepTwoDetailsError: null,

  // Get Evaluation Details - Step Three
  evaluationStepThreeDetails: {},
  evaluationStepThreeDetailsLoading: false,
  evaluationStepThreeDetailsError: null,
};

export default function evelutionMaterials(state = initialState, action) {
  switch (action.type) {
    // SET EVELUTION MATERIAL POPUP
    case type.SET_EVELUTION_MATERIAL_POPUP:
      return {
        ...state,
        showManageEvelutionMaterial: action.payload.show,
        openedEvelutionMaterialId: action.payload.id,
        showManageEvelutionMaterialIsUpdate: action.payload.isUpdate,
      };

    // GET ALL EVALUATION MATERIALS DETAILS
    case type.GET_ALL_EVALUATION_MATERIALS_DETAILS_REQUESTED:
      return {
        ...state,
        allEvaluationDetailsLoading: true,
      };
    case type.GET_ALL_EVALUATION_MATERIALS_DETAILS_SUCCESS: 
      return {
        ...state,
        allEvaluationDetailsLoading: false,
        allEvaluationDetails: action.allEvaluationDetails.evaluationMaterial,
        evaluationMaterialMedias:action.allEvaluationDetails.medias
      };
    case type.GET_ALL_EVALUATION_MATERIALS_DETAILS_FAILED:
      return {
        ...state,
        allEvaluationDetailsLoading: false,
        allEvaluationDetailsError: action.message,
      };

    // EVALUATION MATERIAL STEP ONE
    case type.CREATE_EVALUATION_STEP_ONE_REQUESTED:
      return {
        ...state,
        createEvaluationStepOneLoading: true,
      };
    case type.CREATE_EVALUATION_STEP_ONE_SUCCESS:
      return {
        ...state,
        createEvaluationStepOneLoading: false,
        evaluationMaterialData:
          action.evaluationMaterialData.evaluationMaterial,
        evaluationMaterialActiveStep: 1,
        evaluationId: action.evaluationMaterialData.evaluationMaterial._id,
        openedEvelutionMaterialId: action.evaluationMaterialData.evaluationMaterial._id,
      };
    case type.CREATE_EVALUATION_STEP_ONE_FAILED:
      return {
        ...state,
        createEvaluationStepOneLoading: false,
        createEvaluationStepOneError: action.message,
      };

    // UPDATE EVALUATION MATERIAL STEP ONE
    case type.UPDATE_EVALUATION_MATERIALS_STEP_ONE_REQUESTED:
      return {
        ...state,
        updateEvaluationStepOneLoading: true,
      };
    case type.UPDATE_EVALUATION_MATERIALS_STEP_ONE_SUCCESS:
      return {
        ...state,
        updateEvaluationStepOneLoading: false,
        evaluationMaterialActiveStep: 1,
      };
    case type.UPDATE_EVALUATION_MATERIALS_STEP_ONE_FAILED:
      return {
        ...state,
        updateEvaluationStepOneLoading: false,
        updateEvaluationStepOneError: action.message,
      };

    // UPDATE EVALUATION MATERIAL STEP TWO
    case type.UPDATE_EVALUATION_MATERIALS_STEP_TWO_REQUESTED:
      return {
        ...state,
        updateEvaluationStepTwoLoading: true,
      };
    case type.UPDATE_EVALUATION_MATERIALS_STEP_TWO_SUCCESS:
      return {
        ...state,
        updateEvaluationStepTwoLoading: false,
        evaluationMaterialActiveStep: 2,
      };
    case type.UPDATE_EVALUATION_MATERIALS_STEP_TWO_FAILED:
      return {
        ...state,
        updateEvaluationStepTwoLoading: false,
        updateEvaluationStepTwoError: action.message,
      };

    // UPDATE EVALUATION MATERIAL STEP THREE
    case type.UPDATE_EVALUATION_MATERIALS_STEP_THREE_REQUESTED:
      return {
        ...state,
        updateEvaluationStepThreeLoading: true,
      };
    case type.UPDATE_EVALUATION_MATERIALS_STEP_THREE_SUCCESS:
      return {
        ...state,
        updateEvaluationStepThreeLoading: false,
        showManageEvelutionMaterial: false,
        openedEvelutionMaterialId: null,
        showManageEvelutionMaterialIsUpdate: false,
        evaluationMaterialActiveStep: 0,
      };
    case type.UPDATE_EVALUATION_MATERIALS_STEP_THREE_FAILED:
      return {
        ...state,
        updateEvaluationStepThreeLoading: false,
        updateEvaluationStepThreeError: action.message,
      };

    // DELETE EVALUATION MATERIAL FILES
    case type.DELETE_EVALUATION_MATERIALS_FILES_REQUESTED:
      return {
        ...state,
        deleteEvalutionMaterialLoading: true,
      };
    case type.DELETE_EVALUATION_MATERIALS_FILES_SUCCESS:
      return {
        ...state,
        deleteEvalutionMaterialLoading: false,
      };
    case type.DELETE_EVALUATION_MATERIALS_FILES_FAILED:
      return {
        ...state,
        deleteEvalutionMaterialLoading: false,
        deleteEvalutionMaterialError: action.message,
      };

    // DELETE EVALUATION MATERIALS
    case type.DELETE_EVALUATION_MATERIALS_REQUESTED:
      return {
        ...state,
        deleteEvaluationMaterialsLoading: true,
      };
    case type.DELETE_EVALUATION_MATERIALS_SUCCESS:
      return {
        ...state,
        deleteEvaluationMaterialsLoading: false,
      };
    case type.DELETE_EVALUATION_MATERIALS_FAILED:
      return {
        ...state,
        deleteEvaluationMaterialsLoading: false,
        deleteEvaluationMaterialsError: action.message,
      };

        // GET EVALUATION DETAILS - STEP ONE
        case type.GET_EVALUATION_STEP_ONE_DETAILS_REQUESTED:
          return {
            ...state,
            evaluationStepOneDetailsLoading: true,
          };
        case type.GET_EVALUATION_STEP_ONE_DETAILS_SUCCESS:
          return {
            ...state,
            evaluationStepOneDetailsLoading: false,
            evaluationStepOneDetails: action.evaluationStepOneDetails.evaluationMaterial,
          };
        case type.GET_EVALUATION_STEP_ONE_DETAILS_FAILED:
          return {
            ...state,
            evaluationStepOneDetailsLoading: false,
            evaluationStepOneDetailsError: action.message,
          };

    // EVALUATION MATERIAL STEP TWO DETAILS
    case type.GET_EVALUATION_STEP_TWO_DETAILS_REQUESTED:
      return {
        ...state,
        evaluationStepTwoDetailsLoading: true,
      };
    case type.GET_EVALUATION_STEP_TWO_DETAILS_SUCCESS:
      return {
        ...state,
        evaluationStepTwoDetailsLoading: false,
        evaluationStepTwoDetails: action.evaluationStepTwoDetails,
      };
    case type.GET_EVALUATION_STEP_TWO_DETAILS_FAILED:
      return {
        ...state,
        evaluationStepTwoDetailsLoading: false,
        evaluationStepTwoDetailsError: action.message,
      };

    // GET EVALUATION DETAILS - STEP THREE
    case type.GET_EVALUATION_STEP_THREE_DETAILS_REQUESTED:
      return {
        ...state,
        evaluationStepThreeDetailsLoading: true,
      };
    case type.GET_EVALUATION_STEP_THREE_DETAILS_SUCCESS:
      return {
        ...state,
        evaluationStepThreeDetailsLoading: false,
        evaluationStepThreeDetails: action.evaluationStepThreeDetails,
      };
    case type.GET_EVALUATION_STEP_THREE_DETAILS_FAILED:
      return {
        ...state,
        evaluationStepThreeDetailsLoading: false,
        evaluationStepThreeDetailsError: action.message,
      };


    // DELETE EVALUATION MATERIAL PROTOTYPE
    case type.DELETE_EVALUATION_MATERIAL_PROTOTYPE_REQUESTED:
      return {
        ...state,
        evaluationStepThreeDetailsLoading: true,
      };
    case type.DELETE_EVALUATION_MATERIAL_PROTOTYPE_SUCCESS:
      return {
        ...state,
        evaluationStepThreeDetailsLoading: false,
        evaluationStepThreeDetails: action.evaluationStepThreeDetails,
      };
    case type.DELETE_EVALUATION_MATERIAL_PROTOTYPE_FAILED:
      return {
        ...state,
        evaluationStepThreeDetailsLoading: false,
        evaluationStepThreeDetailsError: action.message,
      };

    // SET EVALUATION MATERIAL ACTIVE STEP
    case type.SET_EVALUATION_MATERIAL_ACTIVE_STEP_REQUESTED:
      return {
        ...state,
        evaluationMaterialActiveStep: action.payload,
      };

    default:
      return state;
  }
}
