import { call, put, takeEvery } from 'redux-saga/effects'
import instance from "../../../../api/api_instance";

async function getApi(data) {
  let age;
  switch(data){
    case '5 - 15 years old':
    age = 15
    break;
    case '15 - 30 years old':
    age = 30
    break;
    case '30 - 45 years old':
    age = 45
    break;
    case '45+ Above':
    age = 100
    break;
  }
  try {
    const result = instance({
      url:`v1/educations`,
      // url: `v1/educations?age=${age}`,
      method: "GET",
      data: data,
    }).then((response) => {
      return response;
    })
    return await result;
  } catch (error) {
    throw error;
  }
}
function* fetchProjects(action) {
  try {
    const res = yield call(getApi, action.payload);
    yield put({ type: "GET_ALL_EDUCATION_LIST_SELECTION_SUCCESS", allEducationList: res.data.data });
  } catch (e) {
    if (e.response.status === 400 || e.response.status === 403 || e.response.status === 404 || e.response.status === 409) {
      yield put({ type: "GET_ALL_EDUCATION_LIST_SELECTION_FAILED", message: e.response.data.message });
    } else {
      yield put({ type: "GET_ALL_EDUCATION_LIST_SELECTION_FAILED", message: "Some error occurred!" });
    }

    if (e.response.status === 500) {
      const toastrFailedData = {
          content: e.response.data.errors ? e.response.data.errors : "Something went wrong!",
          type: "failed"
      };
      yield put({ type: "SET_TOASTER_SUCCESS", data: toastrFailedData });
    }
  }
}

function* getAllEducationListSaga() {
  yield takeEvery("GET_ALL_EDUCATION_LIST_SELECTION_REQUESTED", fetchProjects);
}

export default getAllEducationListSaga;
