import * as type from './types';

// ADD TOASTR
export function addToastr(data) {
  return {
    type: type.ADD_LOCAL_TOASTR_SUCCESS,
    payload: data,
  }
}

// STOP TOASTR
export function stopToastr(data) {
  return {
    type: type.SET_TOASTR_CLOSED,
    payload: data,
  }
}