import { call, put, takeEvery } from 'redux-saga/effects'
import instance from "../../../../api/api_instance";

async function getApi(data) {
  try {
    const result = instance({
      url: "v1/resend-email-otp",
      method: "POST",
      data: data,
    }).then((response) => {
      return response;
    })
    return await result;
  } catch (error) {
    throw error;
  }
}
function* fetchProfile(action) {
  try {
    const res = yield call(getApi, action.payload);
    if (res.status == 200) {
      yield put({ type: "RESEND_UPDATE_EMAIL_OTP_SUCCESS", data: res.data.data });
    } else {
      yield put({ type: "RESEND_UPDATE_EMAIL_OTP_FAILED", message: "" });
    }
  } catch (e) {
    if (e.response.status === 400 || e.response.status === 404 || e.response.status === 409) {
      yield put({ type: "RESEND_UPDATE_EMAIL_OTP_FAILED", message: e.response.data.message });
    } else {
      yield put({ type: "RESEND_UPDATE_EMAIL_OTP_FAILED", message: "Some error occurred!" });
    }
  }
}

function* resendUpdateEmailOTPSaga() {
  yield takeEvery("RESEND_UPDATE_EMAIL_OTP_REQUESTED", fetchProfile);
}

export default resendUpdateEmailOTPSaga;
