import { useState, useEffect } from "react";
import OtpInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, FormGroup, Label } from "reactstrap";
import { resetVerifyEmailOTPErrors, setForgotPasswordActiveStep, triggerForgotPasswordEmailOTP, verifyForgotPasswordEmailOTP } from "../../../redux/reduxes/global/resetPassword/actions";

const VerifyEmailOTP = () => {
  const dispatch = useDispatch();

  // Fetch Data
  const email = useSelector(state => state.resetPassword.email);
  const emailOtpToken = useSelector(state => state.resetPassword.emailOtpToken);
  const verifyEmailOtpLoading = useSelector(state => state.resetPassword.verifyEmailOtpLoading);
  const verifyEmailOtpError = useSelector(state => state.resetPassword.verifyEmailOtpError);


  // States
  const [state, setState] = useState({
    email_otp: "",

    errors: {
      email_otp: "",
    }
  });

  // Set Errors
  useEffect(() => {
    dispatch(resetVerifyEmailOTPErrors());
  }, [])

  // On Change Handler
  const onChangeHandler = (event) => {
    let errors = state.errors;
    errors.email_otp = "";
    setState((prevProps) => ({
      ...prevProps,
      errors,
      email_otp: event
    }));
    dispatch(resetVerifyEmailOTPErrors());
  }

  // Validate Form
  const validateVerifyOTPForm = () => {
    let valid = true;
    let errors = state.errors;
    if (state.email_otp.length < 6) {
      errors.email_otp = "Invalid OTP";
      valid = false;
    }
    setState((prevProps) => ({
      ...prevProps,
      errors: errors,
    }));
    return valid;
  }


  // OTP Expiration Time ====== EMAIL
  const [timeRemaining, setTimeRemaining] = useState(120); // Time remaining in seconds
  const [isRunning, setIsRunning] = useState(true) // Whether the countdown is running or paused

  // Decrement Time remaining by every 1 second  ====== EMAIL
  useEffect(() => {
    let interval;
    if (isRunning && timeRemaining > 0) {
      interval = setInterval(() => {
        setTimeRemaining(prevTime => prevTime - 1)
      }, 1000)
    }
    return () => clearInterval(interval);
  }, [isRunning, timeRemaining]);

  // Reset the Timer to 2 Minutes ====== EMAIL
  const handleEmailResetTime = () => {
    setIsRunning(true);
    setTimeRemaining(120);
  }

  // Format the Time remaining as MM:SS ====== EMAIL
  const minutes = Math.floor(timeRemaining / 60).toString().padStart(2, '0');
  const seconds = (timeRemaining % 60).toString().padStart(2, '0');
  const timeString = `${minutes}:${seconds}`;

  // Resend Email OTP Confirmation
  const confirmResendEmailOTP = (event) => {
    event.preventDefault();
    const data = {
      email: email,
    }
    dispatch(triggerForgotPasswordEmailOTP(data));
    handleEmailResetTime();
  }

  //  on Verify OTP Submit Handler
  const onSubmitHandler = async (event) => {
    event.preventDefault();
    if (validateVerifyOTPForm()) {
      const data = {
        email: state.email,
        otp: state.email_otp,
        emailOtpToken: emailOtpToken,
      }
      dispatch(verifyForgotPasswordEmailOTP(data))
    }
  }

  return (
    <>
      <div>
        <h1 className="us-section__title text-center mb-10">Forgot Password</h1>

        <Form onSubmit={(e) => onSubmitHandler(e)}>
          <FormGroup className="us-form__wrap">
            <Label htmlFor="email_otp" className="us-form__label mb-1">Enter OTP received on Email ID <span className="text-danger">*</span></Label>
            <div className="d-flex align-items-center mb-3">
              <div className="text-sm me-3">{email}</div>
              <Button onClick={() => dispatch(setForgotPasswordActiveStep(0))} type="button" color="link" className="us-button__link text-sm text-primary font-medium p-0">Edit</Button>
            </div>

            <OtpInput
              id="verify_mobile_otp"
              name="mobile_otp"
              numInputs={6}
              isInputNum={true}
              className="me-2"
              inputStyle="us-form__input-otp"
              value={state.email_otp}
              onChange={(e) => onChangeHandler(e)}
            />
            <div className="us-form__error">{state.errors.email_otp}</div>
            {verifyEmailOtpError && <div className="us-form__error">{verifyEmailOtpError}</div>}
          </FormGroup>
          <div className='flex items-center'>
            {(timeString == "00:00" || state.errors.email_otp == "OTP expired") &&
              <Button type="button" color="link" onClick={(e) => confirmResendEmailOTP(e)} className="us-button__link text-primary text-sm font-semibold p-0">Resend OTP</Button>
            }
            {timeString != "00:00" &&
              <div className="color-gray3 text-md font-medium">
                <div>{timeString}</div>
              </div>
            }
          </div>

          <Button
            type="submit"
            color="primary"
            disabled={verifyEmailOtpLoading}
            className={`us-button us-button__primary w-100 text-uppercase mt-10 ${verifyEmailOtpLoading ? "us-button__disabled" : "us-button__primary"}`}
          >
            {verifyEmailOtpLoading ? "Verifying..." : "Verify OTP"}
          </Button>
        </Form>
      </div>
    </>
  )
}
export default VerifyEmailOTP;