import { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, FormGroup, Label } from "reactstrap";
import { confirmEmailMobileOTP, resendEmailOTPConfirmation, resendMobileOTPConfirmation, resetVerifyEmailMobileOTPErrors, setRegisterUpdateEmailModal, setRegisterUpdateMobileNumber, verifyEmailMobileOTP } from "../../../redux/reduxes/global/auth/actions";
import UpdateEmailModal from "./updateEmailModal";
import UpdateMobileNumberModal from "./updateMobileNumberModal";
import { useParams } from 'react-router-dom';



const VerifyOTP = () => {
  const dispatch = useDispatch();
  const { projectURL, mode, urlType } = useParams();

  // Fetch Data
  const email = useSelector(state => state.auth.email);
  const mobileNumber = useSelector(state => state.auth.mobileNumber);
  const emailOtpToken = useSelector(state => state.auth.emailOtpToken);
  const mobileOtpToken = useSelector(state => state.auth.mobileOtpToken);
  const verifyEmailMobileOTPLoading = useSelector(state => state.auth.verifyEmailMobileOTPLoading);
  const verifyEmailMobileOTPError = useSelector(state => state.auth.verifyEmailMobileOTPError);
  const setShowRegisterUpdateEmailModal = useSelector(state => state.auth.setShowRegisterUpdateEmailModal);
  const setShowRegisterUpdateMobileNumberModal = useSelector(state => state.auth.setShowRegisterUpdateMobileNumberModal);
  const appUrl = useSelector(state => state.auth.appUrl);
  // const registerStep = useSelector(state => state.auth.registerStep);
  // console.log(appUrl, "THe app URL coming ");
  // console.log(auth,  "the authentication  hdbhdu");

  // console.log(emailOtpToken, "emailOtpToken message", mobileOtpToken);

  // const theToken = localStorage.getItem('token');

  // Dispatch Email & Mobile Confirmation Tokens
  // useEffect(() => {
  //   console.log("coming inside this", theToken);

  //   theToken&&theToken!=""&& dispatch(confirmEmailMobileOTP());
  // }, [theToken]);

  // States
  const [state, setState] = useState({
    email_otp: "",
    mobile_otp:"",

    errors: {
      email_otp: "",
      mobile_otp:"",
    }
  });

  // Set Verify Email & OTP Errors
  useEffect(() => {
    dispatch(resetVerifyEmailMobileOTPErrors());
  }, []);

  // On Email Change Handler
  const onEmailChangeHandler = (event) => {
    let errors = state.errors;
    errors.email_otp = "";
    setState((prevProps) => ({
      ...prevProps,
      errors,
      email_otp: event
    }));
  }

  // On Mobile Change Handler
  // const onMobileChangeHandler = (event) => {
  //   let errors = state.errors;
  //   errors.mobile_otp = "";
  //   setState((prevProps) => ({
  //     ...prevProps,
  //     errors,
  //     mobile_otp: event
  //   }))
  // }

  // Validate Form
  const validateForm = () => {
    let valid = true;
    let errors = state.errors;
    if (state.email_otp.length < 6) {
      errors.email_otp = "Invalid OTP";
      valid = false;
    }
    // if (state.mobile_otp.length < 6) {
    //   errors.mobile_otp = "Invalid OTP";
    //   valid = false;
    // }
    setState((prevProps) => ({
      ...prevProps,
      errors: errors,
    }));
    return valid;
  }



  // OTP Expiration Time ====== EMAIL
  const [emailTimeRemaining, setEmailTimeRemaining] = useState(120); // Time remaining in seconds
  const [isEmailRunning, setIsEmailRunning] = useState(true) // Whether the countdown is running or paused

  // Decrement Time remaining by every 1 second  ====== EMAIL
  useEffect(() => {
    let interval;
    if (isEmailRunning && emailTimeRemaining > 0) {
      interval = setInterval(() => {
        setEmailTimeRemaining(prevTime => prevTime - 1)
      }, 1000)
    }
    return () => clearInterval(interval);
  }, [isEmailRunning, emailTimeRemaining]);

  // Reset the Timer to 2 Minutes ====== EMAIL
  const handleEmailResetTime = () => {
    setIsEmailRunning(true);
    setEmailTimeRemaining(120);
  }

  // Format the Time remaining as MM:SS ====== EMAIL
  const minutesEmail = Math.floor(emailTimeRemaining / 60).toString().padStart(2, '0');
  const secondsEmail = (emailTimeRemaining % 60).toString().padStart(2, '0');
  const timeStringEmail = `${minutesEmail}:${secondsEmail}`;

   // Resend Email OTP Confirmation
   const confirmResendEmailOTP = (event) => {
    event.preventDefault();
    const data = {
      email: email,
     }
     let errors = state.errors;
     errors.email_otp = "";
     setState((prevProps) => ({
       ...prevProps,
       errors,
       email_otp: ""
     }));
    dispatch(resendEmailOTPConfirmation(data));
    handleEmailResetTime();
  }


  // OTP Expiration Time ====== MOBILE NUMBER
  // const [mobileTimeRemaining, setMobileTimeRemaining] = useState(120); // Time remaining in seconds
  // const [isMobileRunning, setIsMobileRunning] = useState(true) // Whether the countdown is running or paused

  // // Decrement Time remaining by every 1 second  ====== MOBILE NUMBER
  // useEffect(() => {
  //   let interval;
  //   if (isMobileRunning && mobileTimeRemaining > 0) {
  //     interval = setInterval(() => {
  //       setMobileTimeRemaining(prevTime => prevTime - 1)
  //     }, 1000)
  //   }
  //   return () => clearInterval(interval);
  // }, [isMobileRunning, mobileTimeRemaining]);

  // // Reset the Timer to 2 Minutes ====== MOBILE NUMBER
  // const handleMobileResetTime = () => {
  //   setIsMobileRunning(true);
  //   setMobileTimeRemaining(120);
  // }

  // // Format the Time remaining as MM:SS ====== EMAIL
  // const minutesMobile = Math.floor(mobileTimeRemaining / 60).toString().padStart(2, '0');
  // const secondsMobile = (mobileTimeRemaining % 60).toString().padStart(2, '0');
  // const timeStringMobile = `${minutesMobile}:${secondsMobile}`;


  // // Resend Mobile OTP Confirmation
  // const confirmResendMobileOTP = (event) => {
  //   event.preventDefault();
  //   const data = {
  //     mobileNumber: mobileNumber
  //   }
  //   let errors = state.errors;
  //    errors.mobile_otp = "";
  //    setState((prevProps) => ({
  //      ...prevProps,
  //      errors,
  //      mobile_otp: ""
  //    }));
  //   dispatch(resendMobileOTPConfirmation(data));
  //   handleMobileResetTime();
  // }


  // UPDATE EMAIL MODAL
  const confirmUpdateEmailModal = (stat) => {
    dispatch(setRegisterUpdateEmailModal(stat))
  }

  // // UPDATE MOBILE NUMBER MODAL
  // const confirmUpdateMobileNumberModal = (stat) => {
  //   dispatch(setRegisterUpdateMobileNumber(stat));
  // }

  // onSubmit Handler
  const onSubmitHandler = async (event) => {
    event.preventDefault();
    if (validateForm()) {
      const data = {
        email: email,
        mobileNumber: mobileNumber,
        emailOtpToken: emailOtpToken,
        // mobileOtpToken: mobileOtpToken,
        emailOtp: state.email_otp,
        // mobileOtp: state.mobile_otp,
        appUrl: appUrl,
        ...(projectURL && { projectURL: projectURL }),
        ...(urlType && { urlType: urlType })
       ,
      }
      dispatch(verifyEmailMobileOTP(data));
    } else {
      let errors = state.errors;
      if (state.email_otp === "") {
        errors.email_otp = "Enter Email OTP"
      }
      // if (state.mobile_otp === "") {
      //   errors.mobile_otp = "Enter Mobile Number OTP"
      // }
    }
  }

  return (
    <>
      <Form onSubmit={(e) => onSubmitHandler(e)}>
        <h1 className="us-section__title mb-10 text-center">Verify your Identity</h1>

        <div className="mb-12">
          {/* Email OTP Input */}
          <FormGroup className="us-form__wrap">
            <Label htmlFor="verify_email_top" className="us-form__label mb-0">Enter OTP received on Email</Label>
            <div className="us-para d-flex align-items-center mb-3">
              <span>{email}</span>
              <Button onClick={() => confirmUpdateEmailModal(true)} color="link" type="button" className="us-button__link text-primary  text-sm p-0 ms-3">Edit</Button>
            </div>
            <OtpInput
              id="verify_email_otp"
              name="email_otp"
              numInputs={6}
              isInputNum={true}
              className="me-2"
              inputStyle="us-form__input-otp"
              value={state.email_otp}
              onChange={(e) => onEmailChangeHandler(e)}
              shouldAutoFocus={true}
            />
            <div className="us-form__error">{state.errors.email_otp}</div>
            {verifyEmailMobileOTPError && verifyEmailMobileOTPError.error && verifyEmailMobileOTPError.error.emailError && <div className="us-form__error">{verifyEmailMobileOTPError.error.emailError}</div>}
          </FormGroup>

          <div className='flex items-center mt-3'>
            {(timeStringEmail === "00:00" || state.errors.email_otp === "OTP expired") &&
              <Button type="button" color="link" onClick={(e) => confirmResendEmailOTP(e)} className="us-button__link text-primary text-sm font-semibold p-0">Resend OTP</Button>
            }
            {timeStringEmail !== "00:00" &&
              <div className="color-gray3 text-md font-medium">
                <div>{timeStringEmail}</div>
              </div>
            }
          </div>
        </div>

        {/* Mobile OTP Input */}
        {/* <div className="mb-10">
          <FormGroup className="us-form__wrap">
            <Label htmlFor="verify_mobile_otp" className="us-form__label mb-0">Enter OTP received on Mobile</Label>
            <div className="us-para d-flex align-items-center mb-3">
              <span>+{mobileNumber}</span>
            <Button onClick={() => confirmUpdateMobileNumberModal(true)} color="link" type="button" className="us-button__link text-primary text-sm p-0 ms-3">Edit</Button>
            </div>
            <OtpInput
              id="verify_mobile_otp"
              name="mobile_otp"
              numInputs={6}
              isInputNum={true}
              className="me-2"
              inputStyle="us-form__input-otp"
              value={state.mobile_otp}
              onChange={(e) => onMobileChangeHandler(e)}
            />
            <div className="us-form__error">{state.errors.mobile_otp}</div>
            {verifyEmailMobileOTPError && verifyEmailMobileOTPError.error && verifyEmailMobileOTPError.error.mobileOtpError && <div className="us-form__error">{verifyEmailMobileOTPError.error.mobileOtpError}</div>}
          </FormGroup>
          <div className='flex items-center mt-3'>
            {(timeStringMobile === "00:00" || state.errors.mobile_otp === "OTP expired") &&
              <Button type="button" color="link" onClick={(e) => confirmResendMobileOTP(e)} className="us-button__link text-primary text-sm font-semibold p-0">Resend OTP</Button>
            }
            {timeStringMobile !== "00:00" &&
              <div className="color-gray3 text-md font-medium">
                <div>{timeStringMobile}</div>
              </div>
            }
          </div>
        </div> */}

        <Button
          type="submit"
          color="primary"
          disabled={verifyEmailMobileOTPLoading}
          className={`us-button us-button__primary text-uppercase w-100 ${verifyEmailMobileOTPLoading ? "us-button__disabled" : "us-button__primary"}`}
        >
          {verifyEmailMobileOTPLoading ? "Loading..." : "Verify Account"}
        </Button>

      </Form>

      {/* Update Email Modal */}
      {setShowRegisterUpdateEmailModal &&
        <UpdateEmailModal
          setShowRegisterUpdateEmailModal={setShowRegisterUpdateEmailModal}
        />
      }

      {/* Update Mobile Number Modal
      {setShowRegisterUpdateMobileNumberModal &&
        <UpdateMobileNumberModal
          setShowRegisterUpdateMobileNumberModal={setShowRegisterUpdateMobileNumberModal}
        />
      } */}
    </>
  )
}
export default VerifyOTP;