import { call, put, takeEvery } from 'redux-saga/effects'
import instance from "../../../../api/api_instance";
import TokenService from '../../../services/tokenServices';

async function getApi(data) {
  try {
    const result = instance({
      url: "v1/socialTokenLogin",
      method: "POST",
      data: data
    }).then((response) => {
      return response;
    })
    return await result;
  } catch (error) {
    throw error;
  }
}

function* fetchLogin(action) {
  try {
    const res = yield call(getApi, action.payload);
    yield put({ type: "LINKEDIN_LOGIN_TOKEN_SUCCESS", linkedInLoginTokenData: res.data.data });
    console.log('linkedInLoginTokenData', res);
    if (res && res.data && res.data.data && res.data.data.registrationCompleted && res.data.data.registrationCompleted) {
      TokenService.updateLocalAccessToken(res.data.data.token);
      window.localStorage.setItem('isLogin', true);
      window.location.href = "/";
    } else {
      TokenService.updateLocalAccessToken(res.data.data.token);
      window.location.href = "/register";
    }
  } catch (e) {
    if (e.response.status === 406 || e.response.status === 404 || e.response.status === 403 || e.response.status === 400) {
      console.log('linkedInLoginTokenDataFailed', e.response);
      yield put({ type: "LINKEDIN_LOGIN_TOKEN_FAILED", message: e.response.data.error.message });
    }
  }
}

function* linkedInLoginTokeSaga() {
  yield takeEvery("LINKEDIN_LOGIN_TOKEN_REQUESTED", fetchLogin);
}

export default linkedInLoginTokeSaga;
