import { Col, Container, Row } from "reactstrap";
import PageTitle from "../../components/global/common/pageTitle";
import Layout from "../../layout";
import VerifyEmail from "../../components/global/forgotPassword/verifyEmail";
import VerifyEmailOTP from "../../components/global/forgotPassword/verifyEmailOTP";
import ChangePassword from "../../components/global/forgotPassword/changePassword";
import { useSelector } from "react-redux";



const ResetPassword = () => {

  // Fetch Data
  const forgotPasswordStep = useSelector(state => state.resetPassword.forgotPasswordStep);

  return (
    <>
      <PageTitle pageTitle="Reset Password" />

      <Layout>
        {/* Reset Password Steps */}
        <section className='us-section us-section__auth-reset--password d-flex flex-column justify-content-center' style={{ background: "url('/assets/images/auth/auth-bg.png') no-repeat 80% 10%",height:"80vh"}}>
          <div className="us-section__inwrap mt-20">
            <Container>
              <Row>
                <Col lg="6" xl="6" className='my-auto'>
                  <div className="us-auth__left d-none d-lg-block">
                    <div className="us-auth__left-imgwrap">
                      <img src="/assets/images/auth/auth-banner.png" alt="auth-banner" className='img-fluid' />
                    </div>
                  </div>
                </Col>

                <Col md={{ size:10, offset:1 }} lg={{size:6, offset:0}} xl="5" className="my-auto">
                  <div className="us-auth__right px-6 py-8 px-lg-10 py-lg-10">

                    {forgotPasswordStep == 0 ?
                      <VerifyEmail />
                    :
                      forgotPasswordStep == 1 ?
                      <VerifyEmailOTP />
                    :
                      forgotPasswordStep == 2 ?
                        <ChangePassword />
                    :
                      null
                    }
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </section>
      </Layout>
    </>
  )
}
export default ResetPassword;