import {call,put,takeEvery} from 'redux-saga/effects'
import instance from "../../../../api/api_instance";
import * as type from "./types"

async function getApi(data) {

    try{
        const result = instance({
            url:`v1/expert-phase-one`,
            method:"PATCH",
            data: data,
        }).then((response)=>{
            return response;
        })
        return await result;
    }catch(error){
        throw error;
    }
}
function* fetchExpert(action){
    try{
        const res = yield call(getApi, action.payload);
        const toastrData = {
            content: "Expert Registraion updated Successfully",
            type: "success"
        }
        yield put({type: "UPDATE_EXPERT_TAB_ONE_SUCCESS", data: res.data.data});
        yield put({type: "SET_TOASTER_SUCCESS", data: toastrData});
    }catch(e){
        if(e.response.status === 400 || e.response.status === 404 || e.response.status === 409){
            yield put({type: "UPDATE_EXPERT_TAB_ONE_FAILED", message: e.response.data.message});
        }else{
            yield put({type: "UPDATE_EXPERT_TAB_ONE_FAILED", message: "Some error occurred!"});
        }

        if(e.response.status === 500){
            const toastrFailedData = {
                content: e.response.data.errors ? e.response.data.errors: "Something went wrong!",
                type: "failed"
            };
            yield put({type: "SET_TOASTER_SUCCESS", data: toastrFailedData});
        }
    }
}

function* updateExpertStepOneSaga(){
    yield takeEvery(type.UPDATE_EXPERT_TAB_ONE_REQUESTED, fetchExpert);
}

export default updateExpertStepOneSaga;