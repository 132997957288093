
// GET ALL COUNTRIES LIST
export const GET_ALL_COUNTRIES_LIST_REQUESTED = "GET_ALL_COUNTRIES_LIST_REQUESTED";
export const GET_ALL_COUNTRIES_LIST_SUCCESS = "GET_ALL_COUNTRIES_LIST_SUCCESS";
export const GET_ALL_COUNTRIES_LIST_FAILED = "GET_ALL_COUNTRIES_LIST_FAILED";

// GET ALL STATES LIST
export const GET_ALL_STATES_LIST_REQUESTED = "GET_ALL_STATES_LIST_REQUESTED";
export const GET_ALL_STATES_LIST_SUCCESS = "GET_ALL_STATES_LIST_SUCCESS";
export const GET_ALL_STATES_LIST_FAILED = "GET_ALL_STATES_LIST_FAILED";

// GET ALL CITIES LIST
export const GET_ALL_CITIES_LIST_REQUESTED = "GET_ALL_CITIES_LIST_REQUESTED";
export const GET_ALL_CITIES_LIST_SUCCESS = "GET_ALL_CITIES_LIST_SUCCESS";
export const GET_ALL_CITIES_LIST_FAILED = "GET_ALL_CITIES_LIST_FAILED";

// GET ALL EDUCATION LIST SELECTION - TAB THREE
export const GET_ALL_EDUCATION_LIST_SELECTION_REQUESTED = "GET_ALL_EDUCATION_LIST_SELECTION_REQUESTED";
export const GET_ALL_EDUCATION_LIST_SELECTION_SUCCESS = "GET_ALL_EDUCATION_LIST_SELECTION_SUCCESS";
export const GET_ALL_EDUCATION_LIST_SELECTION_FAILED = "GET_ALL_EDUCATION_LIST_SELECTION_FAILED";

// GET ALL PROJECT LANGUAGES
export const GET_ALL_PROJECT_LANGUAGES_REQUESTED = "GET_ALL_PROJECT_LANGUAGES_REQUESTED";
export const GET_ALL_PROJECT_LANGUAGES_SUCCESS = "GET_ALL_PROJECT_LANGUAGES_SUCCESS";
export const GET_ALL_PROJECT_LANGUAGES_FAILED = "GET_ALL_PROJECT_LANGUAGES_FAILED";

// GET MULTILINGUAL LIST - EVALUATION MATERIAL STEP TWO
export const GET_ALL_PROJECT_MULTILINGUAL_REQUESTED = "GET_ALL_PROJECT_MULTILINGUAL_REQUESTED";
export const GET_ALL_PROJECT_MULTILINGUAL_SUCCESS = "GET_ALL_PROJECT_MULTILINGUAL_SUCCESS";
export const GET_ALL_PROJECT_MULTILINGUAL_FAILED = "GET_ALL_PROJECT_MULTILINGUAL_FAILED";

// GET CATEGORY LIST - EVALUATION MATERIAL STEP ONE
export const GET_ALL_PROJECT_CATEGORIES_REQUESTED = "GET_ALL_PROJECT_CATEGORIES_REQUESTED";
export const GET_ALL_PROJECT_CATEGORIES_SUCCESS = "GET_ALL_PROJECT_CATEGORIES_SUCCESS";
export const GET_ALL_PROJECT_CATEGORIES_FAILED = "GET_ALL_PROJECT_CATEGORIES_FAILED";

// CLEAR ALL STATES AND CITIES
export const GET_ALL_STATES_AND_CITIES_LIST = "GET_ALL_STATES_AND_CITIES_LIST";