//  SET EVELUTION MATERIAL POPUP
export const SET_EVELUTION_MATERIAL_POPUP = "SET_EVELUTION_MATERIAL_POPUP";

// GET EVALUATION DETAILS - STEP ONE, TWO, THREE (INCLUDES ALL STEPS)
export const GET_ALL_EVALUATION_MATERIALS_DETAILS_REQUESTED = "GET_ALL_EVALUATION_MATERIALS_DETAILS_REQUESTED";
export const GET_ALL_EVALUATION_MATERIALS_DETAILS_SUCCESS = "GET_ALL_EVALUATION_MATERIALS_DETAILS_SUCCESS";
export const GET_ALL_EVALUATION_MATERIALS_DETAILS_FAILED = "GET_ALL_EVALUATION_MATERIALS_DETAILS_FAILED";

// CREATE PROJECT - EVALUATION METERIAL - STEP ONE
export const CREATE_EVALUATION_STEP_ONE_REQUESTED = "CREATE_EVALUATION_STEP_ONE_REQUESTED";
export const CREATE_EVALUATION_STEP_ONE_SUCCESS = "CREATE_EVALUATION_STEP_ONE_SUCCESS";
export const CREATE_EVALUATION_STEP_ONE_FAILED = "CREATE_EVALUATION_STEP_ONE_FAILED";

// UPDATE EVALUATION MATERIALS STEP ONE
export const UPDATE_EVALUATION_MATERIALS_STEP_ONE_REQUESTED = "UPDATE_EVALUATION_MATERIALS_STEP_ONE_REQUESTED";
export const UPDATE_EVALUATION_MATERIALS_STEP_ONE_SUCCESS = "UPDATE_EVALUATION_MATERIALS_STEP_ONE_SUCCESS";
export const UPDATE_EVALUATION_MATERIALS_STEP_ONE_FAILED = "UPDATE_EVALUATION_MATERIALS_STEP_ONE_FAILED";

// UPDATE EVALUATION MATERIALS STEP TWO
export const UPDATE_EVALUATION_MATERIALS_STEP_TWO_REQUESTED = "UPDATE_EVALUATION_MATERIALS_STEP_TWO_REQUESTED";
export const UPDATE_EVALUATION_MATERIALS_STEP_TWO_SUCCESS = "UPDATE_EVALUATION_MATERIALS_STEP_TWO_SUCCESS";
export const UPDATE_EVALUATION_MATERIALS_STEP_TWO_FAILED = "UPDATE_EVALUATION_MATERIALS_STEP_TWO_FAILED";

// UPDATE EVALUATION MATERIALS STEP THREE
export const UPDATE_EVALUATION_MATERIALS_STEP_THREE_REQUESTED = "UPDATE_EVALUATION_MATERIALS_STEP_THREE_REQUESTED";
export const UPDATE_EVALUATION_MATERIALS_STEP_THREE_SUCCESS = "UPDATE_EVALUATION_MATERIALS_STEP_THREE_SUCCESS";
export const UPDATE_EVALUATION_MATERIALS_STEP_THREE_FAILED = "UPDATE_EVALUATION_MATERIALS_STEP_THREE_FAILED";

// DELETE EVALUATION MATERIALS
export const DELETE_EVALUATION_MATERIALS_REQUESTED = "DELETE_EVALUATION_MATERIALS_REQUESTED";
export const DELETE_EVALUATION_MATERIALS_SUCCESS = "DELETE_EVALUATION_MATERIALS_SUCCESS";
export const DELETE_EVALUATION_MATERIALS_FAILED = "DELETE_EVALUATION_MATERIALS_FAILED";


// DELETE EVALUATION MATERIALS FILES
export const DELETE_EVALUATION_MATERIALS_FILES_REQUESTED = "DELETE_EVALUATION_MATERIALS_FILES_REQUESTED";
export const DELETE_EVALUATION_MATERIALS_FILES_SUCCESS = "DELETE_EVALUATION_MATERIALS_FILES_SUCCESS";
export const DELETE_EVALUATION_MATERIALS_FILES_FAILED = "DELETE_EVALUATION_MATERIALS_FILES_FAILED";

// GET EVALUATION MATERIAL DETAILS - STEP ONE
export const GET_EVALUATION_STEP_ONE_DETAILS_REQUESTED = "GET_EVALUATION_STEP_ONE_DETAILS_REQUESTED";
export const GET_EVALUATION_STEP_ONE_DETAILS_SUCCESS = "GET_EVALUATION_STEP_ONE_DETAILS_SUCCESS";
export const GET_EVALUATION_STEP_ONE_DETAILS_FAILED = "GET_EVALUATION_STEP_ONE_DETAILS_FAILED";

// GET EVALUATION MATERAL DETAILS - STEP TWO
export const GET_EVALUATION_STEP_TWO_DETAILS_REQUESTED = "GET_EVALUATION_STEP_TWO_DETAILS_REQUESTED";
export const GET_EVALUATION_STEP_TWO_DETAILS_SUCCESS = "GET_EVALUATION_STEP_TWO_DETAILS_SUCCESS";
export const GET_EVALUATION_STEP_TWO_DETAILS_FAILED = "GET_EVALUATION_STEP_TWO_DETAILS_FAILED";

// GET EVALUATION DETAILS - STEP THREE
export const GET_EVALUATION_STEP_THREE_DETAILS_REQUESTED = "GET_EVALUATION_STEP_THREE_DETAILS_REQUESTED";
export const GET_EVALUATION_STEP_THREE_DETAILS_SUCCESS = "GET_EVALUATION_STEP_THREE_DETAILS_SUCCESS";
export const GET_EVALUATION_STEP_THREE_DETAILS_FAILED = "GET_EVALUATION_STEP_THREE_DETAILS_FAILED";

// EVALUATION MATERIAL ACTIVE STEP
export const SET_EVALUATION_MATERIAL_ACTIVE_STEP_REQUESTED = "SET_EVALUATION_MATERIAL_ACTIVE_STEP_REQUESTED";

// DELETE EVALUTION MATERIAL PROTOTYPE
export const DELETE_EVALUATION_MATERIAL_PROTOTYPE_REQUESTED = "DELETE_EVALUATION_MATERIAL_PROTOTYPE_REQUESTED";
export const DELETE_EVALUATION_MATERIAL_PROTOTYPE_SUCCESS = "DELETE_EVALUATION_MATERIAL_PROTOTYPE_SUCCESS";
export const DELETE_EVALUATION_MATERIAL_PROTOTYPE_FAILED = "DELETE_EVALUATION_MATERIAL_PROTOTYPE_FAILED";
