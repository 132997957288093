import { call, put, takeEvery } from 'redux-saga/effects'
import instance from "../../../../api/api_instance";
import TokenService from '../../../services/tokenServices';

async function getApi(data) {
  try {
    const result = instance({
      url: "v1/email-otp-verify",
      method: "POST",
      data: data,
    }).then((response) => {
      return response;
    })
    return await result;
  } catch (error) {
    throw error;
  }
}
function* fetchProfile(action) {
  try {
    const res = yield call(getApi, action.payload);
    if (res.status == 200) {
      yield put({ type: "UPDATE_EMAIL_OTP_VERIFICATION_SUCCESS", data: res.data.data });
      yield put({ type: "GET_PROFILE_DETAILS_REQUESTED", profileDetails: res.data.data });
      window.location.href = '/customer/account-settings';
    } else {
      yield put({ type: "UPDATE_EMAIL_OTP_VERIFICATION_FAILED", message: "" });
    }
  } catch (e) {
    if (e.response.status === 400 || e.response.status === 404 || e.response.status === 409) {
      yield put({ type: "UPDATE_EMAIL_OTP_VERIFICATION_FAILED", message: e.response.data.message });
    } else {
      yield put({ type: "UPDATE_EMAIL_OTP_VERIFICATION_FAILED", message: "Some error occurred!" });
    }
  }
}

function* updateEmailOTPVerificationSaga() {
  yield takeEvery("UPDATE_EMAIL_OTP_VERIFICATION_REQUESTED", fetchProfile);
}

export default updateEmailOTPVerificationSaga;
