import React, { useState, useEffect } from "react";
import { Button, Row, Col, Form, FormGroup, Input, Label } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  updateExpertTabThree,
  getExpertDetailsStepThree,
  getExpertDetailsStepOne
} from "../../../../redux/reduxes/customer/expertRegistration/action";
// import {  } from "../../../../redux/reduxes/customer/expertRegistration/action";
import SelectLocation from "./locationSelect";
import EvaluationMaterialModal from "./successMessagePopUp";
import Icon from "react-eva-icons";
import SkeletonLoader from "../../SkeletonLoader";
const { v4: uuidv4 } = require("uuid");

//Media info
const infos = [
  {
    id: "Websites",
    title: "Websites",
  },
  {
    id: "Web application",
    title: "Web application",
  },
  {
    id: "Mobile",
    title: "Mobile",
  },
  { id: "Wearables", title: "Wearables" },
  {
    id: "Desktop applications",
    title: "Desktop applications",
  },
  { id: "IoT", title: "IoT" },
  { id: "Smart home applications", title: "Smart home applications" },
  {
    id: "Virtual Reality(VR)",
    title: "Virtual Reality(VR)",
  },
  {
    id: "Augmented Reality(AR)",
    title: "Augmented Reality(AR)",
  },
  {
    id: "Dashboard and Analytics",
    title: "Dashboard and Analytics",
  },
  {
    id: "Point of Sale (POS)",
    title: "Point of Sale (POS)",
  },
];

//Fluent
const fluents = [
  {
    id: "Cognitive Walkthrough",
    title: "Cognitive Walkthrough",
  },
  {
    id: "Accessibility Evaluation",
    title: "Accessibility Evaluation",
  },
  {
    id: "Surveys and Questionnaires",
    title: "Surveys and Questionnaires",
  },
  { id: "User Interviews", title: "User Interviews" },
  {
    id: "Task Analysis",
    title: "Task Analysis",
  },
  { id: "A/B Testing", title: "A/B Testing" },
  { id: "Eye Tracking", title: "Eye Tracking" },
  {
    id: "Card Sorting",
    title: "Card Sorting",
  },
  {
    id: "First Click Testing",
    title: "First Click Testing",
  },
  {
    id: "Remote Testing",
    title: "Remote Testing",
  },
  {
    id: "Emotional Analysis",
    title: "Emotional Analysis",
  },
  {
    id: "Usability Benchmarking",
    title: "Usability Benchmarking",
  },
  {
    id: "Persona Development",
    title: "Persona Development",
  },
  {
    id: "Prototype Testing",
    title: "Prototype Testing",
  },
  {
    id: "Tree Testing",
    title: "Tree Testing",
  },
  {
    id: "Desirability Studies",
    title: "Desirability Studies",
  },
  {
    id: "Think-Aloud Protocol",
    title: "Think-Aloud Protocol",
  },
  {
    id: "Formative Evaluation",
    title: "Formative Evaluation",
  },
  {
    id: "Ethnographic Field Studies",
    title: "Ethnographic Field Studies",
  },
  {
    id: "Usability Metrics Analysis",
    title: "Usability Metrics Analysis",
  },
];

//Expertise
const expertises = [
  {
    id: "Accounting",
    title: "Accounting",
  },
  {
    id: "Airlines/Aviation",
    title: "Airlines/Aviation",
  },
  {
    id: "Apparel & Fashion",
    title: "Apparel & Fashion",
  },
  { id: "Automative", title: "Automative" },
  {
    id: "Banking",
    title: "Banking",
  },
  { id: "Biotechnology", title: "Biotechnology" },
  { id: "Computer Software", title: "Computer Software" },
  {
    id: "Construction",
    title: "Construction",
  },
  {
    id: "Consumer Electronics",
    title: "Consumer Electronics",
  },
  {
    id: "Consumer Goods",
    title: "Consumer Goods",
  },
  {
    id: "Education Management",
    title: "Education Management",
  },
  {
    id: "Financial Services",
    title: "Financial Services",
  },
  {
    id: "Healthcase",
    title: "Healthcase",
  },
  {
    id: "Hospitality",
    title: "Hospitality",
  },
  {
    id: "Information Technology & Services",
    title: "Information Technology & Services",
  },
  {
    id: "Internet",
    title: "Internet",
  },
  {
    id: "Management Consulting",
    title: "Management Consulting",
  },
  {
    id: "Marketing & Advertising",
    title: "Marketing & Advertising",
  },
  {
    id: "Media Production",
    title: "Media Production",
  },
  {
    id: "Pharmaceuticals",
    title: "Pharmaceuticals",
  },
  {
    id: "Real Estate",
    title: "Real Estate",
  },
  {
    id: "Retail",
    title: "Retail",
  },
  {
    id: "Telecommunications",
    title: "Telecommunications",
  },
  {
    id: "Transportation/Trucking/Railroad",
    title: "Transportation/Trucking/Railroad",
  },
  {
    id: "Utilities",
    title: "Utilities",
  },
];

// HASHMAP FOR FLUENT TAB
const duplicateFluentFilter = new Map();
fluents.forEach((v, i) => {
  duplicateFluentFilter.set(
    v.id.toLowerCase().replace(/\s/g, ""),
    i.toString()
  );
});

//HASHMAP FOR EXPERTISE TAB
const duplicateExpertiseFilter = new Map();
fluents.forEach((v, i) => {
  duplicateFluentFilter.set(
    v.id.toLowerCase().replace(/\s/g, ""),
    i.toString()
  );
});

const DomainExpertise = ({ toggleExpertRegistrationTabs ,duplicateMediaFilter,exp}) => {
  const dispatch = useDispatch();
  const showManageEvelutionMaterial = useSelector(
    (state) => state.evelutionMaterials.showManageEvelutionMaterial
  );

  const expertRegistrationActiveTab = useSelector(
    (state) => state.expertRegistration.expertActiveStep
  );
  const stepOneData = useSelector(
    (state) => state.expertRegistration.stepOneData
  );
  const updatedSuccess =
    useSelector((state) => state.expertRegistration.updatedSuccess) || false;

  const stepThreeData = useSelector(
    (state) => state.expertRegistration.stepThreeData
  );

  const [citiesData, setCitiesData] = useState([]);
  const handleLocationData = (cities) => {
    setGeographyMessage(null)
    setCitiesData(cities);
  };

  // Active Tab Element
  const [currentDomainExpertiseTab, setCurrentDomainExpertiseTab] =
    useState("1");

  //MEDIA STATES
  const [media, setMedia] = useState([...infos]);
  const [checkedMedia, setCheckedMedia] = useState([]);
  const [inputMedia, setInputMedia] = useState("");
  const [mediaWarningMessageDuplicates, setMediaWarningMessageDuplicates] =
    useState("");

  //FLUENTS STATES
  const [fluent, setFluent] = useState([...fluents]);
  const [checkedFluent, setCheckedFluent] = useState([]);
  const [inputFluent, setInputFluent] = useState("");
  const [fluentWarningMessageDuplicates, setFluentWarningMessageDuplicates] =
    useState("");

  // Get page one data
  useEffect(() => {
    if (expertRegistrationActiveTab === 2) {
      dispatch(getExpertDetailsStepOne());
    }
  }, [expertRegistrationActiveTab]);

  //YEAR OF EXPERTISE STATES
  const [domains, setDomains] = useState([]);
  const [domainExperience, setDomainExperience] = useState([]);

  const onChangeDomainExperience = (event, index) => {
    setyearsOfExpertiseMessage(null)
    const { value, name } = event.target;
    const upDomains = [];
    domains.forEach((domain) => {
      if (domain.name == name) {
        upDomains.push({ name: name, value: value });
      } else {
        upDomains.push({ name: domain.name, value: domain.value });
      }
    });
    setDomains(upDomains);
  };

  //INDUSTRY EXPERTIES STATES
  const [expertise, setExpertise] = useState([...expertises]);
  const [checkedExpertise, setCheckedExpertise] = useState([]);
  const [inputExpertise, setInputExpertise] = useState("");

  // EXPERIMENTAL
  const [mediaMessage, setMediaMessage] = useState(null);
  const [message, setMessage] = useState(null);
  const [evalMessage, setEvalMessage] = useState(null);
  const [industryMessage, setIndustryMessage] = useState(null);
  const [yearsOfExpertiseMessage, setyearsOfExpertiseMessage] = useState(null);
  const [geographyMessage, setGeographyMessage] = useState(null);

  const toggleProfessionalInfoTabs = (tab) => {
    setMessage(null);
    if (currentDomainExpertiseTab !== tab) {
      setCurrentDomainExpertiseTab(tab);
    }
  };

  // Get page two data
  useEffect(() => {
    if (expertRegistrationActiveTab === 2) {
      dispatch(getExpertDetailsStepThree());
    }
  }, [expertRegistrationActiveTab]);

  // Sync to state on update
  useEffect(() => {
    stepThreeData &&
      stepThreeData.mediaExperties &&
      setCheckedMedia(stepThreeData.mediaExperties);
    stepThreeData &&
      stepThreeData.expertEvaluation &&
      setCheckedFluent(stepThreeData.expertEvaluation);
    stepThreeData &&
      stepThreeData.yearOfExpertise &&
      setDomainExperience(stepThreeData && stepThreeData.yearOfExpertise);
    // stepThreeData &&
    //   stepThreeData.specializedGeography &&
    // setCitiesData(stepThreeData.specializedGeography);
    stepThreeData &&
      stepThreeData.industryExperties &&
      setCheckedExpertise(stepThreeData.industryExperties);
  }, [stepThreeData]);

  useEffect(() => {
    // if ((stepOneData && stepOneData.iAm) && (stepThreeData && stepThreeData.mediaExperties)) {
    // stepThreeData && stepThreeData.mediaExperties.forEach(data => {
    // }
    // )
    // domains
    // }

    stepThreeData &&
      stepThreeData.yearOfExpertise &&
      stepThreeData.yearOfExpertise.forEach((data) => {
        // if(data)
        let tempDomain = [];
        for (const key in data) {
          if (data.hasOwnProperty(key) && key != "_id") {
            const value = data[key];
            tempDomain.push({
              name:
                key == "uxDesignAndResearcher"
                  ? "UX Design & Research Expert"
                  : key == "uiDesignExpert"
                  ? "UI Design Expert"
                  : key == "marketingExpert"
                  ? "Marketing Expert"
                  : key == "businessConsultant"
                  ? "Business Consultant"
                  : "",
              value: value,
            });
          }
        }
        setDomains(tempDomain);
      });
  }, [stepThreeData, stepOneData]);

  useEffect(() => {
    if (domains.length==0 && stepOneData && stepOneData.iAm) {
      const newData = [];
      stepOneData.iAm.forEach((data) => {
        newData.push({ name: data, value: 0 });
      });
      setDomains(newData);
    }
  }, [stepOneData]);

  const [existingLocations, setExistingLocations] = useState([]);

  //EXPERIMENTAL
  useEffect(() => {
    const defaultMediainfos = infos.map((item) => item.id);
    const defaultFluentsinfos = fluents.map((item) => item.id);
    const defaultExpertiseinfos = expertise.map((item) => item.id);

    //MEDIA
    if (stepThreeData) {
      const inMediainfo = stepThreeData.mediaExperties;
      const inFluentinfo = stepThreeData.expertEvaluation;
      const inYearExpertiseinfo = stepThreeData.expertEvaluation;
      const inGeographyinfo = stepThreeData.expertEvaluation;
      const inExpertiseinfo = stepThreeData.expertEvaluation;

      //MEDIA
      if (inMediainfo) {
        const uniqueMediaElements = inMediainfo.filter(
          (item) => !defaultMediainfos.includes(item)
        );

        uniqueMediaElements.forEach((i) => {
          if (!duplicateMediaFilter.has(i.toLowerCase().replace(/\s/g, ""))) {
            media.push({ id: i, title: i });
            duplicateMediaFilter.set(
              i.toLowerCase().replace(/\s/g, ""),
              uuidv4()
            );
          }
        });
      }

      //FLUENT
      if (inFluentinfo) {
        const uniqueFluentElements = inFluentinfo.filter(
          (item) => !defaultFluentsinfos.includes(item)
        );

        uniqueFluentElements.forEach((i) => {
          if (!duplicateMediaFilter.has(i.toLowerCase().replace(/\s/g, ""))) {
            fluent.push({ id: i, title: i });
            duplicateMediaFilter.set(
              i.toLowerCase().replace(/\s/g, ""),
              uuidv4()
            );
          }
        });
      }
      //YEAR OF EXPERTISE
      // if (inYearExpertiseinfo) {
      //   const uniqueYearExpertiseElements = inYearExpertiseinfo.filter(
      //     (item) => !defaultFluentsinfos.includes(item)
      //   );

      //   uniqueYearExpertiseElements.forEach((i) => {
      //     if (!duplicateFluentFilter.has(i.toLowerCase().replace(/\s/g, ""))) {
      //       fluent.push({ id: i, title: i });
      //       duplicateFluentFilter.set(
      //         i.toLowerCase().replace(/\s/g, ""),
      //         uuidv4()
      //       );
      //     }
      //   });
      // }

      //SPECIALIZED GEOGRAPHY
      // if (inGeographyinfo) {
      //   const uniqueGeographyElements = inGeographyinfo.filter(
      //     (item) => !defaultFluentsinfos.includes(item)
      //   );

      //   uniqueGeographyElements.forEach((i) => {
      //     if (!duplicateFluentFilter.has(i.toLowerCase().replace(/\s/g, ""))) {
      //       fluent.push({ id: i, title: i });
      //       duplicateFluentFilter.set(
      //         i.toLowerCase().replace(/\s/g, ""),
      //         uuidv4()
      //       );
      //     }
      //   });
      // }
      stepThreeData.specializedGeography &&
        setExistingLocations(stepThreeData.specializedGeography);

      //EXPERTISE
      if (inExpertiseinfo) {
        const uniqueExpertiseElements = inExpertiseinfo.filter(
          (item) => !defaultExpertiseinfos.includes(item)
        );

        uniqueExpertiseElements.forEach((i) => {
          if (
            !duplicateExpertiseFilter.has(i.toLowerCase().replace(/\s/g, ""))
          ) {
            expertise.push({ id: i, title: i });
            duplicateExpertiseFilter.set(
              i.toLowerCase().replace(/\s/g, ""),
              uuidv4()
            );
          }
        });
      }
    }
  }, [stepThreeData]);

  // HANDLE MEDIA CHANGE!!
  const handleMediaCheckboxChange = (event) => {
    setMediaMessage(null)
    const value = event.target.id;
    const isChecked = event.target.checked;
    setCheckedMedia((prevCheckedItems) => {
      if (isChecked) {
        return [...prevCheckedItems, value];
      } else {
        return prevCheckedItems.filter((item) => item !== value);
      }
    });
  };

  // HANDLE FLUENT CHANGE!!
  const handleFluentCheckboxChange = (event) => {
    setEvalMessage(null)
    const value = event.target.id;
    const isChecked = event.target.checked;
    setCheckedFluent((prevCheckedItems) => {
      if (isChecked) {
        return [...prevCheckedItems, value];
      } else {
        return prevCheckedItems.filter((item) => item !== value);
      }
    });
  };

  // HANDLE EXPERTISE CHANGE!!
  const handleExpertiseCheckboxChange = (event) => {
    setMessage(null);
    const value = event.target.id;
    const isChecked = event.target.checked;
    setCheckedExpertise((prevCheckedItems) => {
      if (isChecked) {
        return [...prevCheckedItems, value];
      } else {
        return prevCheckedItems.filter((item) => item !== value);
      }
    });
  };

  // MEDIA CHANGE HANDLER
  const handleMediaChange = (event) => {
    setInputMedia(event.target.value);
    setMediaWarningMessageDuplicates("");
  };

  // FLUENT
  const handleFluentChange = (event) => {
    setMessage(null);
    setInputFluent(event.target.value);
    setFluentWarningMessageDuplicates("");
  };

  // MEDIA EXP SUBMIT FUNCTION
  const handleMediaSubmit = (event) => {
    event.preventDefault();
    // VALIDATION OF MEDIA INPUT FIELD
    if (
      inputMedia.trim().length > 2 &&
      inputMedia.trim().length < 100 &&
      !duplicateMediaFilter.has(inputMedia.toLowerCase().replace(/\s/g, ""))
    ) {
      setMedia([...media, { id: inputMedia, title: inputMedia.trim() }]);
      duplicateMediaFilter.set(
        inputMedia.toLowerCase().replace(/\s/g, ""),
        uuidv4()
      );
    }else{
    if (inputMedia.trim().length < 3){
      setMediaWarningMessageDuplicates("Should be more than 2 characters.")
    }
    else if (inputMedia.trim().length > 99){
      setMediaWarningMessageDuplicates("Shouldn't be more than 100 characters.")
    }
    else if (duplicateMediaFilter.has(inputMedia.toLowerCase().replace(/\s/g, ""))){
      setMediaWarningMessageDuplicates("This item is already added.")}
    };
    setInputMedia("");
  };

  // FLUENTS
  const handleFluentSubmit = (event) => {
    event.preventDefault();
    // VALIDATION OF FLUENT INPUT FIELD
    if (
      inputFluent.trim().length > 2 &&
      inputFluent.trim().length < 100 &&
      !duplicateMediaFilter.has(inputFluent.toLowerCase().replace(/\s/g, ""))
    ) {
      setFluent([...fluent, { id: inputFluent, title: inputFluent.trim() }]);
      duplicateMediaFilter.set(
        inputFluent.toLowerCase().replace(/\s/g, ""),
        uuidv4()
      );
    } else {
      if (inputFluent.trim().length < 3) {
        setFluentWarningMessageDuplicates("Should be more than 2 characters.");
      } else if (inputFluent.trim().length > 99) {
        setFluentWarningMessageDuplicates(
          "Should'nt be more than 100 characters."
        );
      } else if (
        duplicateMediaFilter.has(inputFluent.toLowerCase().replace(/\s/g, ""))
      ) {
        setFluentWarningMessageDuplicates("This item is already added.");
      }
    }
    setInputFluent("");
  };

  const tests = () => {
    return false;
  };
  // setDomains

  // useEffect(() => {
  //   if (stepOneData && stepOneData.iAm) {
  //     // setDomains(stepOneData.iAm);
  //     // setDomainExperience(stepOneData &&stepOneData.iAm.length!=0&& stepOneData.iAm.map((str) => ({ [str]: "" })));
  //     // const x = stepOneData && stepOneData.iAm.length != 0 && stepOneData.iAm.map((str) => ({ [str]: "" }));
  //     let b = [];
  //     if (stepOneData &&stepOneData.iAm&&stepOneData.iAm.length!=0) {
  //       for (const key in stepOneData.iAm) {
  //         if (stepOneData.iAm.hasOwnProperty(key)) {
  //             b.push({
  //                 value: 0,
  //                 name: stepOneData.iAm[key]
  //             });
  //         }
  //     }
  //     }
  //     setDomains(b);
  //   }
  // }, [stepOneData]);

  const handleformSubmition = (event) => {
    event.preventDefault();

    if (
      checkedMedia.length === 0 &&
      checkedFluent.length === 0 &&
      domains.length === 0 &&
      citiesData.length === 0 &&
      checkedExpertise.length === 0
    ) {
      setMessage(
        "MEDIA and FLUENT and YEAREXPERTISE and GEOGRAPHY and EXPERTISE have to be selected."
      );
    } else if (checkedMedia.length === 0) {
      setMediaMessage("MEDIA should be selected.");
    } else if (checkedFluent.length === 0) {
      setEvalMessage("FLUENT should be selected.");
    } else if (domains.length === 0) {
      setyearsOfExpertiseMessage("YEARS OF EXPERTISE should be selected.");
    } else if ((citiesData.length+existingLocations.length) === 0) {
      setGeographyMessage("GEOGRAPHY should be selected.");
    } else if (checkedExpertise.length === 0) {
      setMessage("EXPERTISE should be selected.");
    } else {
      setMessage(null);

      // Convert the specializedGeography data to an array of strings
      console.log(existingLocations, "existingLocations okokokok", citiesData);
      let specializedGeography = [];
      let states = [];
      citiesData.forEach((city) => {
        if (city.stateAsCity == true) {
          states.push(city.cityId)
        } else {
          specializedGeography.push(city.cityId)
        }
      });
      existingLocations.forEach((city) => {
        if (city.city) {
          specializedGeography.push(city.city_id);
        } else {
          states.push(city.state_id);
        }
      });
      // ...existingLocations.map((city) => city.city_id),

      let mappedYearOfExpertise = {};

      domains.forEach((exp) => {
        if (exp.name === "UX Design & Research Expert") {
          Object.assign(mappedYearOfExpertise, {
            uxDesignAndResearcher: parseInt(exp.value, 10),
          });
        }
        if (exp.name === "UI Design Expert") {
          Object.assign(mappedYearOfExpertise, {
            uiDesignExpert: parseInt(exp.value, 10),
          });
        }
        if (exp.name === "Marketing Expert") {
          Object.assign(mappedYearOfExpertise, {
            marketingExpert: parseInt(exp.value, 10),
          });
        }
        if (exp.name === "Business Consultant") {
          Object.assign(mappedYearOfExpertise, {
            businessConsultant: parseInt(exp.value, 10),
          });
        }
      });

      const data = {
        mediaExperties: checkedMedia,
        expertEvaluation: checkedFluent,
        yearOfExpertise: [mappedYearOfExpertise],
        specializedGeography,
        states: states,
        industryExperties: checkedExpertise,
      };

      dispatch(updateExpertTabThree(data));
    }
  };
  const removeLocationFromList = (id) => {
    setExistingLocations(existingLocations.filter((obj) => obj.city_id !== id));
  };
  const updateExpertStepThreeLoading = useSelector((state) => state.expertRegistration.updateExpertStepThreeLoading)

  return (
    <>
      <div>
        <Form onSubmit={(e) => handleformSubmition(e)}>
          {/* Media Expertise : Start */}
          <div className="us-form__wrap-card">
            <h4 className="text-md text-lg-xl font-bold">Media Expertise</h4>
            <p className="text-sm color-gray">
              Which of these product types do you specialize in?
            </p>

            <FormGroup className="us-form__wrap mb-12 mt-5">
              <div className="w-100 d-flex align-items-start flex-wrap gap-2 gap-md-3">
                {updateExpertStepThreeLoading && <>{Array(8).fill(<SkeletonLoader />)}</>}
                {media?
                  media.map((app, index) => {
                    const { id, title } = app;
                    return (
                      <div className=" bg-white" key={index}>
                        <Input
                          type="checkbox"
                          id={title}
                          name="project_phase"
                          className="us-form__custom-checkbox"
                          onChange={handleMediaCheckboxChange}
                          checked={checkedMedia.includes(title)}
                        />
                        <Label
                          htmlFor={title}
                          className="us-form__custom-label  text-start radius-35 py-5 px-4"
                        >
                          <p className="text-md text-nowrap pe-14">{title}</p>
                        </Label>
                      </div>
                    );
                  }): <>{Array(8).fill(<SkeletonLoader />)}</>}
              </div>
            </FormGroup>
            <FormGroup className="us-form__wrap">
              <Input
                type="text"
                id="portfolio_url"
                name="portfolio_url"
                className="us-form__input"
                placeholder="Additional expertise"
                value={inputMedia}
                onChange={handleMediaChange}
              />

            <div className="mt-2 text-end">
              <Button
                onClick={handleMediaSubmit}
                type="button"
                className="us-button__link text-primary font-medium border-0 bg-transparent"
              >
                Add more{" "}
                <Icon
                  name="plus"
                  size="large" // small, medium, large, xlarge
                  fill="#7F7F7F"
                />
              </Button>
            </div>
            <p style={{ color: "red" }}>{mediaMessage}</p>
            <p style={{ color: "red" }}>{mediaWarningMessageDuplicates}</p>
          </FormGroup>
        </div>
        {/* Media Expertise : ENd */}
        {/* Evoluation Method : Start */}
        <div className="us-form__wrap-card">
          <h4 className="text-md text-lg-xl font-bold">
            Evaluation methods you are fluent with
          </h4>
          <p className="text-sm color-gray">
            Which of these product types do you specialize in?
          </p>

          <FormGroup className="us-form__wrap mb-12 mt-5">
            <div className="w-100 d-flex align-items-start flex-wrap gap-2 gap-md-3">
            {updateExpertStepThreeLoading && <>{Array(8).fill(<SkeletonLoader />)}</>}
              {fluent?
                fluent.map((app, index) => {
                  const { id, title } = app;
                  return (
                    <div className=" bg-white" key={index}>
                      <Input
                        type="checkbox"
                        id={title}
                        name="project_phase"
                        className="us-form__custom-checkbox"
                        onChange={handleFluentCheckboxChange}
                        checked={checkedFluent.includes(title)}
                      />
                      <Label
                        htmlFor={title}
                        className="us-form__custom-label  text-start radius-35 py-5 px-4"
                      >
                        <p className="text-md text-nowrap pe-14">{title}</p>
                      </Label>
                    </div>
                  );
                }):<>{Array(8).fill(<SkeletonLoader />)}</>}
            </div>
          </FormGroup>
          <FormGroup className="us-form__wrap">
            <Input
              type="text"
              id="portfolio_url"
              name="portfolio_url"
              className="us-form__input"
              placeholder="Additional expertise"
              value={inputFluent}
              onChange={handleFluentChange}
            />

            <div className="mt-2 text-end">
              <Button
                type="button"
                className="us-button__link text-primary font-medium border-0 bg-transparent"
                onClick={handleFluentSubmit}
              >
                Add more
                <Icon
                  name="plus"
                  size="large" // small, medium, large, xlarge
                  fill="#7F7F7F"
                />
              </Button>
            </div>
            <p style={{ color: "red" }}>{evalMessage}</p>
            <p style={{ color: "red" }}>{fluentWarningMessageDuplicates}</p>
          </FormGroup>
        </div>
        {/* Evoluation Method : End */}

          {/* Years & Location  : Start*/}
          <div className="us-form__wrap-card">
            <FormGroup className="us-form__wrap">
              <Label
                htmlFor="expertise_years"
                className="us-form__label text-md text-lg-xl font-bold"
              >
                Years of Expertise
              </Label>
              <Row className="g-4">
                {domains.map((domain, index) => {
                  return (
                    <Col md={6} key={index}>
                      <p className="text-sm color-gray mb-3">{domain.name}</p>
                      <Input
                        type="number"
                        id="expertise_years"
                        name={domain.name}
                        value={domain.value}
                        className="us-form__input"
                        placeholder={domain.name}
                        onChange={(e) => onChangeDomainExperience(e, index)}
                        min="2"
                      />
                    </Col>
                  );
                })}
              </Row>
              <p className="text-danger mb-2">{yearsOfExpertiseMessage}</p>
            </FormGroup>

          <FormGroup className="row us-form__wrap mt-8">
            <Label className="us-form__label text-md text-lg-xl font-bold">
              Specialized Geography
            </Label>
            <Row className="">
              <SelectLocation
                handleLocationData={handleLocationData}
                existingLocations={existingLocations}
                removeLocationFromList={removeLocationFromList}
              />
            </Row>
          </FormGroup>
          <p style={{ color: "red" }}>{geographyMessage}</p>
        </div>

          {/* Years & Location  : End*/}

          {/* Industry Expertise : Start */}
          <div className="us-form__wrap-card">
            <h4 className="text-md text-lg-xl font-bold">Industry Expertise</h4>
            <p className="text-sm color-gray">
              Which of these product types do you specialize in?
            </p>
            <FormGroup className="us-form__wrap mb-12 mt-5">
              <div className="w-100 d-flex align-items-start flex-wrap gap-2 gap-md-3">
              {updateExpertStepThreeLoading && <>{Array(8).fill(<SkeletonLoader />)}</>}
                {expertises?
                  expertises.map((app, index) => {
                    const { id, title } = app;
                    return (
                      <div className=" bg-white" key={index}>
                        <Input
                          type="checkbox"
                          id={title}
                          name="project_phase"
                          className="us-form__custom-checkbox"
                          onChange={handleExpertiseCheckboxChange}
                          checked={checkedExpertise.includes(title)}
                        />
                        <Label
                          htmlFor={title}
                          className="us-form__custom-label  text-start radius-35 py-5 px-4"
                        >
                          <p className="text-md text-nowrap pe-14">{title}</p>
                        </Label>
                      </div>
                    );
                  }):<>{Array(8).fill(<SkeletonLoader />)}</>}
              </div>

            </FormGroup>
            <p style={{ color: "red" }}>{message}</p>
          </div>
          {/* Industry Expertise : End */}
          <div className="text-end mt-8 mt-md-14">
            <Button
              onClick={(e) => toggleExpertRegistrationTabs(1)}
              type="button"
              color="primary"
              outline
              className="us-button us-button__primary-outline text-uppercase px-10 me-6"
            >
              Back
            </Button>
            <Button
              type="submit"
              color="primary"
              className={`us-button us-button__primary text-uppercase px-10 ${
                tests() ? "us-button__disabled" : "us-button__primary"
              }`}
            >
              {tests() ? "Saving..." : "Next"}
            </Button>
          </div>
        </Form>
      </div>
      {/* Evaluation Material Modal */}
      {updatedSuccess && (
        <EvaluationMaterialModal
          setShowEvaluationMaterialModal={updatedSuccess}
        />
      )}
    </>
  );
};
export default DomainExpertise;
