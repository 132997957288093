import * as type from './types';

// GET ALL COUNTRIES LIST
export function getAllCountriesList(data) {
  return {
    type: type.GET_ALL_COUNTRIES_LIST_REQUESTED,
    payload: data,
  }
}

// GET ALL STATES LIST
export function getAllStatesList(data) {
  return {
    type: type.GET_ALL_STATES_LIST_REQUESTED,
    payload: data,
  }
}

// GET ALL CITIES LIST
export function getAllCitiesList(data) {
  return {
    type: type.GET_ALL_CITIES_LIST_REQUESTED,
    payload: data,
  }
}

// GET ALL EDUCATION LIST SELECTION
export function getAllEducationListSelection(data) {
  return {
    type: type.GET_ALL_EDUCATION_LIST_SELECTION_REQUESTED,
    payload: data,
  }
}

// GET ALL PROJECT LANGUAGES
export function getAllProjectLanguages(data) {
  return {
    type: type.GET_ALL_PROJECT_LANGUAGES_REQUESTED,
    payload: data,
  }
}

// GET ALL CATEGORIES - EVALUATION MATERIAL STEP ONE
export function getAllProjectCategories(data) {
  return {
    type: type.GET_ALL_PROJECT_CATEGORIES_REQUESTED,
    payload: data,
  }
}

// GET ALL MULTLINGUAL - EVALUATION MATERIAL STEP ONE
export function getAllProjectMultilingual(data) {
  return {
    type: type.GET_ALL_PROJECT_MULTILINGUAL_REQUESTED,
    payload: data,
  }
}

// CLEAR ALL STATES AND CITIES
export function clearAllStateCities(data) {
  return {
    type: type.GET_ALL_STATES_AND_CITIES_LIST,
    payload: data,
  }
}