import { Button, FormGroup, Input, Label } from "reactstrap";


const AddTestScenerio = ({allTestScenarios, key, indexNumber, value, handleTestScenarioChange, handleRemoveTestScenario, titleError}) => {
  return (
    <>
      <div key={key} className="d-flex align-items-center mb-4">
        <div className="w-100">
          <Input
            type="text"
            id="testScenarios"
            name="testScenarios"
            placeholder="Specify your scenario here..."
            className="us-form__input w-100"
            value={value.title}
            onChange={(e) => handleTestScenarioChange(e.target.value, indexNumber)}
          />
          <div className="us-form__error">{titleError}</div>
        </div>

        {allTestScenarios && allTestScenarios.length > 1 &&
          <Button onClick={() => handleRemoveTestScenario(indexNumber)} type="button" color="link" className="us-button__link py-0">
            <img src="/assets/icons/icon-delete-red.svg" alt="icon-delete" />
          </Button>
        }
      </div>
    </>
  )
}
export default AddTestScenerio;