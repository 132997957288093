import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";


const PaginatedItems = ({ itemsPerPage, handlePageClick, pageCount, currentPage, totalPages }) => {
  // console.log(itemsPerPage, handlePageClick, pageCount, currentPage, totalPages, "itemsPerPage, handlePageClick, pageCount, currentPage, totalPages");
  const [itemOffset, setItemOffset] = useState(0);
  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
  }, [])
  // console.log(totalPages,"PAGES")
  return (
    <>
      {totalPages &&
        <div className='d-flex align-items-center justify-end'>
          <ReactPaginate
            className='us-pagination d-flex align-items-center'
            pageClassName='us-pagination__page'
            activeLinkClassName='us-pagination__active'
            previousClassName="us-pagination__previous text-sm me-2"
            nextClassName="us-pagination__next text-sm ms-2"
            disabledClassName="us-pagination__disabled"
            breakLabel="..."
            nextLabel="Next"
            onPageChange={handlePageClick}
            pageRangeDisplayed={2}
            pageCount={Number(pageCount)}
            previousLabel="Previous"
            renderOnZeroPageCount={false}
            forcePage={currentPage ? currentPage - 1 : null}
          />
        </div>
      }
    </>
  )
}
export default PaginatedItems;