import { act } from '@testing-library/react';
import * as type from './types';

const initialState = {
  // REGISTER USER
  email: "",
  mobileNumber: "",

  registerUser: null,
  registerUserLoading: false,
  registerUserError: [],
  registerStep: 0,
  linkedInUser: false,
  accessToken: "",

  // CONFIRM EMAIL & MOBILE OTP
  emailOtpToken: "",
  mobileOtpToken: "",

  confirmEmailMobileOTPDetails:{},
  confirmEmailMobileOTPLoading: false,
  confirmEmailMobileOTPError: '',

  // VERIFY EMAIL & MOBILE OTP
  verifyEmailMobileOTPLoading: false,
  verifyEmailMobileOTPError: '',

  // LOGIN USER
  loginUser: null,
  loginUserLoading: false,
  loginUserError: null,

  // LOGOUT USER
  logoutUserLoading: false,
  logoutUserError: null,

  // UPDATE REGISTER EMAIL
  updateRegisterEmailLoading: false,
  updateRegisterEmailError: [],

  // SET UPDATE EMAIL MODAL
  setShowRegisterUpdateEmailModal: false,

  // UPDATE REGISTER MOBILE NUMBER
  updateRegisterMobileNumberLoading: false,
  updateRegisterMobileNumberError: [],

  // SET UPDATE MOBILE NUMBER
  setShowRegisterUpdateMobileNumberModal: false,

  // RESEND EMAIL OTP CONFIRMATION
  resendEmailOTPConfirmationLoading: false,
  resendEmailOTPConfirmationError: null,

  // RESEND EMAIL OTP VERIFICATION
  resendEmailOTPVerificationLoading: false,
  resendEmailOTPVerificationError: null,

  // RESEND MOBILE OTP CONFIRMATION
  resendMobileOTPConfirmationLoading: false,
  resendMobileOTPConfirmationError: null,

  // LINKEDIN LOGIN TOKEN
  linkedInLoginTokenData: "",
  linkedInLoginTokenLoading: false,
  linkedInLoginTokenError: null,

  // SCREENSHOT LOADER
  screenShotLoading:false,
  screenShotError:"",
  profileDetails: null,
  appUrl: null,
};

export default function auth(state = initialState, action) {
  switch (action.type) {
    // REGISTER USER
    case type.REGISTER_USER_REQUESTED:
      return {
        ...state,
        registerUserLoading: true,
      }
    case type.REGISTER_USER_SUCCESS:
      return {
        ...state,
        registerUserLoading: false,
        registerUser: action.registerUser,
        registerStep: action.registerUser.registrationCompleted ? 0 : 1,
        accessToken: action.registerUser.token,
        email: action.registerUser.email,
        mobileNumber: action.registerUser.mobileNumber,
      }
    case type.REGISTER_USER_FAILED:
      return {
        ...state,
        registerUserLoading: false,
        registerUserError: action.message,
      }

    // REGISTER STEP
    case type.REGISTER_STEP_REQUESTED:
      return {
        ...state,
        registerStep: action.payload,
      }


    // REGISTER RESET ERROR MESSAGES
    case type.REGISTER_RESET_ERROR_MESSAGES_REQUESTED:
      return {
        ...state,
        registerUserError: [],
      }



    // LOGIN USER
    case type.LOGIN_USER_REQUESTED:
      return {
        ...state,
        loginUserLoading: true,
      }
    case type.LOGIN_USER_SUCCESS:
      return {
        ...state,
        loginUserLoading: false,
        loginUser: action.loginUser,
        registerStep: action.loginUser.registrationCompleted == true ? 0 : 1,
        email: action.loginUser.email,
        mobileNumber: action.loginUser.mobileNumber,
        accessToken:action.loginUser.token,
      }
    case type.LOGIN_USER_FAILED:
      return {
        ...state,
        loginUserLoading: false,
        loginUserError: action.message,
      }

    // LOGIN ERROR MESSAGE RESET
    case type.LOGIN_RESET_ERROR_MESSAGES_REQUESTED:
      return {
        ...state,
        loginUserError: "",
      }

    // LOGOUT USER
    case type.LOGOUT_USER_REQUESTED:
      return {
        ...state,
        logoutUserLoading: true,
      }
    case type.LOGOUT_USER_SUCCESS:
      return {
        ...state,
        logoutUserLoading: false,
      }
    case type.LOGOUT_USER_FAILED:
      return {
        ...state,
        logoutUserLoading: false,
        logoutUserError: action.message,
      }

    // LINKEDIN SIGNUP
    case type.LINKED_IN_USER:
      return {
        ...state,
        linkedInUser: action.payload
      }

    // LINKEDIN LOGIN TOKEN
    case type.LINKEDIN_LOGIN_TOKEN_REQUESTED:
      return {
        ...state,
        linkedInLoginTokenLoading: true,
      }
    case type.LINKEDIN_LOGIN_TOKEN_SUCCESS:
      return {
        ...state,
        linkedInLoginTokenLoading: false,
        linkedInLoginTokenData: action.linkedInLoginTokenData,
      }
    case type.LINKEDIN_LOGIN_TOKEN_FAILED:
      return {
        ...state,
        linkedInLoginTokenLoading: false,
        linkedInLoginTokenError: action.message,
      }



    // EMAIL & MOBILE OTP CONFIRMATION
    case type.CONFIRM_EMAIL_MOBILE_OTP_REQUESTED:
      return {
        ...state,
        confirmEmailMobileOTPLoading: true,
      }
    case type.CONFIRM_EMAIL_MOBILE_OTP_SUCCESS:
      return {
        ...state,
        confirmEmailMobileOTPLoading: false,
        confirmEmailMobileOTPDetails: action.confirmEmailMobileOTPDetails,
        emailOtpToken: action.confirmEmailMobileOTPDetails.emailOtpToken,
        mobileOtpToken: action.confirmEmailMobileOTPDetails.mobileOtpToken,
      }
    case type.CONFIRM_EMAIL_MOBILE_OTP_FAILED:
      return {
        ...state,
        confirmEmailMobileOTPError: action.message,
      }

    // EMAIL & MOBILE OTP VERIFICATION
    case type.VERIFY_EMAIL_MOBILE_OTP_REQUESTED:
      return {
        ...state,
        verifyEmailMobileOTPLoading: true,
      }
    case type.VERIFY_EMAIL_MOBILE_OTP_SUCCESS:
      return {
        ...state,
        verifyEmailMobileOTPLoading: false,
        confirmEmailMobileOTPDetails: {},
        emailOtpToken: "",
        mobileOtpToken: "",
        registerStep: 0,
      }
    case type.VERIFY_EMAIL_MOBILE_OTP_FAILED:
      return {
        ...state,
        verifyEmailMobileOTPLoading: false,
        verifyEmailMobileOTPError: action.message,
      }

    // SET VERIFY EMAIL & MOBILE OTP ERRORS
    case type.RESET_VERIFY_EMAIL_MOBILE_OTP_ERRORS:
      return {
        ...state,
        verifyEmailMobileOTPError: "",
      }

    // UPDATE REGISTER EMAIL
    case type.UPDATE_REGISTER_EMAIL_REQUESTED:
      return {
        ...state,
        updateRegisterEmailLoading: true,
      }
    case type.UPDATE_REGISTER_EMAIL_SUCCESS:
      return {
        ...state,
        updateRegisterEmailLoading: false,
        setShowRegisterUpdateEmailModal: false,
        email: action.registerUser.email,
        emailOtpToken: action.registerUser.emailOtpToken,
      }
    case type.UPDATE_REGISTER_EMAIL_FAILED:
      return {
        ...state,
        updateRegisterEmailLoading: false,
        updateRegisterEmailError: action.message,
      }

    // RESET UPDATE EMAIL ERRORS
    case type.RESET_UPDATE_EMAIL_ERRORS_REQUESTED:
      return {
        ...state,
        updateRegisterEmailError: [],
      }

    // SET UPDATE EMAIL MODAL
    case type.SET_REGISTER_UPDATE_EMAIL_MODAL_REQUESTED:
      return {
        ...state,
        setShowRegisterUpdateEmailModal: action.payload,
      }

    // UPDATE REGISTER MOBILE NUMBER
    case type.UPDATE_REGISTER_MOBILE_NUMBER_REQUESTED:
      return {
        ...state,
        updateRegisterMobileNumberLoading: true,
      }
    case type.UPDATE_REGISTER_MOBILE_NUMBER_SUCCESS:
      return {
        ...state,
        updateRegisterMobileNumberLoading: false,
        setShowRegisterUpdateMobileNumberModal: false,
        mobileNumber: action.registerUser.mobileNumber,
        emailOtpToken: action.registerUser.emailOtpToken,
      }
    case type.UPDATE_REGISTER_MOBILE_NUMBER_FAILED:
      return {
        ...state,
        updateRegisterMobileNumberLoading: false,
        updateRegisterMobileNumberError: action.message,
      }

    // RESET UPDATE MOBILE NUMBER ERRORS
    // case type.RESET_UPDATE_MOBILE_NUMBER_ERRORS_REQUESTED:
    //   return {
    //     ...state,
    //     updateRegisterMobileNumberError: [],
    //     updateMobileNumberError:[],
    //   }

    // SET UPDATE MOBILE NUMBER MODAL
    case type.SET_REGISTER_UPDATE_MOBILE_NUMBER_MODAL_REQUESTED:
      return {
        ...state,
        setShowRegisterUpdateMobileNumberModal: action.payload,
      }

    // RESEND EMAIL OTP CONFIRMATION
    case type.RESEND_EMAIL_OTP_CONFIRMATION_REQUESTED:
      return {
        ...state,
        resendEmailOTPConfirmationLoading: true,
      }
    case type.RESEND_EMAIL_OTP_CONFIRMATION_SUCCESS:
      return {
        ...state,
        resendEmailOTPConfirmationLoading: false,
        emailOtpToken: action.data.emailOtpToken,
      }
    case type.RESEND_EMAIL_OTP_CONFIRMATION_FAILED:
      return {
        ...state,
        resendEmailOTPConfirmationLoading: false,
        resendEmailOTPConfirmationError: action.message,
      }

    // RESEND MOBILE OTP CONFIRMATION
    case type.RESEND_MOBILE_OTP_CONFIRMATION_REQUESTED:
      return {
        ...state,
        resendMobileOTPConfirmationLoading: true,
      }
    case type.RESEND_MOBILE_OTP_CONFIRMATION_SUCCESS:
      return {
        ...state,
        resendMobileOTPConfirmationLoading: false,
        mobileOtpToken: action.data.mobileOtpToken,
      }
    case type.RESEND_MOBILE_OTP_CONFIRMATION_FAILED:
      return {
        ...state,
        resendEmailOTPConfirmationLoading: false,
        resendMobileOTPConfirmationError: action.message,
      }

    // reset mobile number
    case type.RESET_MOBILE_NUMBER_REQUESTED:
      return {
        ...state,
        mobileNumber: action.payload
      }

    // reset email
    case type.RESET_EMAIL_REQUESTED:
      return {
        ...state,
        email: action.payload,
      }

    case type.CREATE_PROJECT_AFTER_OTP_REQUEST:
      return {
        ...state
      }
      case type.CREATE_PROJECT_AFTER_OTP_SUCCESS:
        return {
          ...state
        }
        case type.CREATE_PROJECT_AFTER_OTP_FAILED:
          return {
            ...state
          }


    case type.GET_SCREENSHOT_REQUESTED:
      return {
        ...state,
        screenShotLoading: true,
        screenShotError:"",
      }

    case type.GET_SCREENSHOT_SUCCESS:
      console.log(action.profileDetails, "action.profileDetails");
      return {
        ...state,
        screenShotLoading:false,
        profileDetails: action.profileDetails.screenshot,
        appUrl: action.profileDetails.fullUrl,
        screenShotError: "",
      }

    case type.GET_SCREENSHOT_FAILED:
      return {
        ...state,
        screenShotLoading:false,
        // screenShotFailedLoading:false,
        screenShotError:action.message,
        profileDetails: null,
        appUrl: null


      }

    default:
      return state
  }
}