import { call, put, takeEvery } from 'redux-saga/effects'
import instance from "../../../../api/api_instance";

async function getApi(data) {
  try {
    const result = instance({
      url: "v1/profile-update-mobile-otp",
      method: "POST",
      data: data,
    }).then((response) => {
      return response;
    })
    return await result;
  } catch (error) {
    throw error;
  }
}
function* fetchAuth(action) {
  try {
    const res = yield call(getApi, action.payload);
    yield put({ type: "UPDATE_REGISTER_MOBILE_NUMBER_SUCCESS", registerUser: res.data.data });
  } catch (e) {
    if (e.response.status === 400 || e.response.status === 404 || e.response.status === 409 || e.response.status === 422) {
      yield put({ type: "UPDATE_REGISTER_MOBILE_NUMBER_FAILED", message: e.response.data.errors });
    } else {
      yield put({ type: "UPDATE_REGISTER_MOBILE_NUMBER_FAILED", message: "Some error occurred!" });
    }
  }
}

function* updateRegisterMobileNumberSaga() {
  yield takeEvery("UPDATE_REGISTER_MOBILE_NUMBER_REQUESTED", fetchAuth);
}

export default updateRegisterMobileNumberSaga;
