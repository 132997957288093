import { call, put, takeEvery } from 'redux-saga/effects'
import instance from "../../../../api/api_instance";
import TokenService from '../../../services/tokenServices';

async function getApi(data) {
  const loginData = {
    email: data.email,
    password: data.password
  }

  try {
    const result = instance({
      url: "v1/login",
      method: "POST",
      data: loginData
    }).then((response) => {
      return response;
    })
    return await result;
  } catch (error) {
    throw error;
  }
}

function* fetchLogin(action) {
  try {
    const res = yield call(getApi, action.payload);
    yield put({ type: "LOGIN_USER_SUCCESS", loginUser: res.data.data });
    if (res && res.data && res.data.data && res.data.data.registrationCompleted) {
      TokenService.updateLocalAccessToken(res.data.data.token);
      window.localStorage.setItem('isLogin', true);
      window.location.href = "/";
    } else {
      TokenService.updateLocalAccessToken(res.data.data.token);
      // window.location.href = "/register";
      yield put({ type: "CONFIRM_EMAIL_MOBILE_OTP_REQUESTED", payload: {redirectAfter:true} });
    }
  } catch (e) {
    if (e.response.status === 406 || e.response.status === 404 || e.response.status === 403 || e.response.status === 400) {
      yield put({ type: "LOGIN_USER_FAILED", message: e.response.data.error.message });
    }
  }
}

function* loginAuthSaga() {
  yield takeEvery("LOGIN_USER_REQUESTED", fetchLogin);
}

export default loginAuthSaga;
