import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Button } from "reactstrap";
import UpdateFullNameModal from "./updateFullNameModal";
import UpdateProfilePictureModal from "./addProfilePictureModal";
import UpdateEmailModal from "./updateEmailModal";
import UpdateMobileNumberModal from "./updateMobileNumberModal";
import { getProfileDetails, setUpdateFullNameModal, setUpdateEmailModal, setUpdateMobileNumberModal, setChangePasswordModal, setUpdateProfilePictureModal, removeProfilePicture, unlinkLinkedInAction, linkLinkedInAction, unlinkLinkedInPassword, unlinkPasswordModalShow} from "../../../redux/reduxes/customer/profile/actions";
import { useEffect } from "react";
import UpdatePasswordModal from "./updatePasswordModal";
import UnlinkLinkedIn from "./unlinkLinkedIn";
import Skeleton from "react-loading-skeleton";
import DeleteModal from "../../global/common/deleteModal";
import { linkLinkedInRedirect } from "../../../utils/socialLogin";


const ProfileTab = () => {
  const dispatch = useDispatch();

  // Fetch Data
  const profileDetailsLoading = useSelector(state => state.profile.profileDetailsLoading);
  const profileDetails = useSelector(state => state.profile.profileDetails);
  const setShowProfilePictureModal = useSelector(state => state.profile.setShowProfilePictureModal);
  const removeProfilePictureLoading = useSelector(state => state.profile.removeProfilePictureLoading);
  const setShowUpdateFullNameModal = useSelector(state => state.profile.setShowUpdateFullNameModal);
  const setShowUpdateEmailModal = useSelector(state => state.profile.setShowUpdateEmailModal);
  const setShowUpdateMobileNumberModal = useSelector(state => state.profile.setShowUpdateMobileNumberModal);
  const setShowChangePasswordModal = useSelector(state => state.profile.setShowChangePasswordModal);
  const setShowUnlinkLinkedIn = useSelector(state => state.profile.setShowUnlinkLinkedIn);
  const linkedinChangePasswordModal = useSelector(state => state.profile.linkedinChangePasswordModal)


  // Profile Details Dispatch
  useEffect(() => {
    dispatch(getProfileDetails());
  }, [dispatch])

  // Update Profile Picture
  const confirmUpdateProfilePicture = (stat) => {
    dispatch(setUpdateProfilePictureModal(stat));
  }

  // Remove Profile Picture
  const [deleteProfilePictureModal, setDeleteProfilePictureModal] = useState(false);
  // const[ShowUnlinkLinkedIn,setShowUnlinkLinkedIn]=useState(false)

  const confirmRemoveProfilePicture = (stat) => {
    setDeleteProfilePictureModal(stat);
  }

  // Update Full Name
  const confirmUpdateFullName = (stat) => {
    dispatch(setUpdateFullNameModal(stat));
  }

  // Update Email ID
  const confirmUpdateEmail = (stat) => {
    dispatch(setUpdateEmailModal(stat));
  }

  // Update Mobile Number
  const confirmUpdateMobileNumber = (stat) => {
    dispatch(setUpdateMobileNumberModal(stat))
  }

  // Change Password
  const confirmChangePassword = (stat) => {
    dispatch(setChangePasswordModal(stat));
  }

  // Test
  const trueTest=(stat)=>{
    // unlinkLinkedIn()
    dispatch(unlinkLinkedInPassword(stat))
  }


  // end

  const unlinkLinkedIn = () => {
    // trueTest(true)
    if (profileDetails && profileDetails.passwordLoggedIn) {
      dispatch(unlinkLinkedInAction());
    } else {
      dispatch(unlinkPasswordModalShow(true));
    }
  }

  const linkLinkedIn = () => {
    // dispatch(linkLinkedInAction());
    linkLinkedInRedirect()
  }

  return (
    <>
      <Row>
        {profileDetails.linkedInLinked ?
          <Col lg={{ size: 10, offset: 1 }} className="mb-12">
            <div className="us-profile__linkedin d-flex align-items-start w-100">
              <div className="d-flex align-items-center">
                <div>
                  <img src="/assets/icons/icon-linkedin.svg" alt="icon-linkedin" />
                </div>
                <div className="ms-3">
                  <div className="us-profile__info-title mb-0 head-font font-bold">LinkedIn</div>
                  <div className="text-xs us-profile__info-description">Connected</div>
                </div>
              </div>
              <Button color="link" type="button" onClick={unlinkLinkedIn} className="us-button__link text-primary text-sm p-0 ms-auto">Unlink</Button>
            </div>
          </Col>
          :
          <Col lg={{ size: 10, offset: 1 }} className="mb-12">
            <div className="us-profile__linkedin d-flex align-items-start w-100">
              <div className="d-flex align-items-center">
                <div>
                  <img src="/assets/icons/icon-linkedin.svg" alt="icon-linkedin" />
                </div>
                <div className="ms-3">
                  <div className="us-profile__info-title mb-0 head-font font-bold">LinkedIn</div>
                  <div className="text-xs us-profile__info-description">Not Connected</div>
                  <div className="text-md">Connect your LinkedIn account to get started</div>
                </div>
              </div>
              <Button type="button" onClick={linkLinkedIn} className="us-button__primary-outline ms-auto text-xs text-uppercase pb-2 pt-3 px-5 font-semibold">Connect Now</Button>
            </div>
          </Col>
          }
          {/* <Button type="button" onClick={()=>trueTest(true)}  className="us-button__primary-outline ms-auto text-xs text-uppercase pb-2 pt-3 px-5 font-semibold">Test here!</Button> */}

        <Col lg={{ size: 8, offset: 2 }}>
          <div>
            <h2 className="us-section__title-mini mb-6">Personal Information</h2>
            <div className="overflow-hidden">
              {/* Profile Picture */}
              <div className="d-flex align-items-start w-100 mb-7">
                {profileDetailsLoading ?
                  <Skeleton
                    count={2}
                    width="1000px"
                    height="22px"
                    baseColor="#E2E2E2"
                    highlightColor="#E9EAF0"
                    borderRadius={0}
                    className="mb-1"
                    enableAnimation={true}
                  />
                :
                  <>
                    <div className="us-profile__info">
                      <div className="us-profile__info-title">Profile Picture</div>
                      <div className="us-profile__info-picture d-flex align-items-center justify-content-center">
                        <img src={profileDetails && profileDetails.profilePic} alt="profile-pic" />
                      </div>
                    </div>
                    <div className="ms-auto">
                      <Button onClick={() => confirmUpdateProfilePicture(true)} color="link" type="button" className="us-button__link text-sm text-primary p-0">Edit</Button>
                      {(profileDetails && profileDetails.profilePicAttached) &&
                        <Button onClick={() => confirmRemoveProfilePicture(true)} color="link" type="button" className="us-button__link text-sm text-dark opacity-75 font-medium p-0 ms-3">Remove</Button>
                      }
                      </div>
                  </>
                }
              </div>

              {/* Name */}
              <div className="d-flex align-items-start mb-7">
                {profileDetailsLoading ?
                  <Skeleton
                    count={2}
                    width="1000px"
                    height="22px"
                    baseColor="#E2E2E2"
                    highlightColor="#E9EAF0"
                    borderRadius={0}
                    className="mb-1"
                    enableAnimation={true}
                  />
                  :
                  <>
                    <div className="us-profile__info">
                      <div className="us-profile__info-title">Full Name</div>
                      <div className="us-profile__info-description">{profileDetails && profileDetails.firstName} {profileDetails && profileDetails.lastName}</div>
                    </div>
                    <div className="ms-auto">
                      <Button onClick={() => confirmUpdateFullName(true)} color="link" type="button" className="us-button__link text-sm text-primary p-0">Edit</Button>
                    </div>
                  </>
                }
              </div>

              {/* Email */}
              <div className="d-flex align-items-start mb-7">
                {profileDetailsLoading ?
                  <Skeleton
                    count={2}
                    width="1000px"
                    height="22px"
                    baseColor="#E2E2E2"
                    highlightColor="#E9EAF0"
                    borderRadius={0}
                    className="mb-1"
                    enableAnimation={true}
                  />
                  :
                  <>
                    <div className="us-profile__info">
                      <div className="us-profile__info-title">Email</div>
                      <div className="us-profile__info-description">{profileDetails && profileDetails.email}</div>
                    </div>
                    <div className="ms-auto">
                      <Button onClick={() => confirmUpdateEmail(true)} color="link" type="button" className="us-button__link text-sm text-primary p-0">Edit</Button>
                    </div>
                  </>
                }
              </div>

              {/* Mobile Number */}
              <div className="d-flex align-items-start mb-7">
                {profileDetailsLoading ?
                  <Skeleton
                    count={2}
                    width="1000px"
                    height="22px"
                    baseColor="#E2E2E2"
                    highlightColor="#E9EAF0"
                    borderRadius={0}
                    className="mb-1"
                    enableAnimation={true}
                  />
                  :
                  <>
                    <div className="us-profile__info">
                      <div className="us-profile__info-title">Mobile Number</div>
                      <div className="us-profile__info-description">+{profileDetails && profileDetails.mobileNumber}</div>
                    </div>
                    <div className="ms-auto">
                      <Button onClick={() => confirmUpdateMobileNumber(true)} color="link" type="button" className="us-button__link text-sm text-primary p-0">Edit</Button>
                    </div>
                  </>
                }
              </div>

              {/* Manage Password */}
              {profileDetails && profileDetails.passwordLoggedIn &&
                <div className="d-flex align-items-start mb-7">
                  {profileDetailsLoading ?
                    <Skeleton
                      count={2}
                      width="1000px"
                      height="22px"
                      baseColor="#E2E2E2"
                      highlightColor="#E9EAF0"
                      borderRadius={0}
                      className="mb-1"
                      enableAnimation={true}
                    />
                    :
                    <>
                      <div className="us-profile__info">
                        <div className="us-profile__info-title">Password</div>
                        {/* <div className="us-profile__info-description">Changed 46 days ago</div> */}
                      </div>
                      <div className="ms-auto">
                        <Button onClick={() => confirmChangePassword(true)} color="link" type="button" className="us-button__link text-sm text-primary p-0">Change Password</Button>
                      </div>
                    </>
                  }
                </div>
              }
            </div>
          </div>
        </Col>
      </Row>

      {/* Add Profile Picture Modal */}
      {setShowProfilePictureModal &&
        <UpdateProfilePictureModal
          setShowProfilePictureModal={setShowProfilePictureModal}
        />
      }

      {/* Remove Profile Picture Modal  */}
      {deleteProfilePictureModal &&
        <DeleteModal
          headText="Remove Profile Picture"
          bodyText="Are you sure, you want to remove Profile Picture?"
          modalValue={deleteProfilePictureModal}
          modalAction={setDeleteProfilePictureModal}
          deleteAction={removeProfilePicture}
          deleteLoading={removeProfilePictureLoading}
        />
      }

      {/* Update Full Name Modal */}
      {setShowUpdateFullNameModal &&
        <UpdateFullNameModal
          setShowUpdateFullNameModal={setShowUpdateFullNameModal}
        />
      }

      {/* Update Email Modal */}
      {setShowUpdateEmailModal &&
        <UpdateEmailModal
          setShowUpdateEmailModal={setShowUpdateEmailModal}
        />
      }

      {/* Update Mobile Number */}
      {setShowUpdateMobileNumberModal &&
        <UpdateMobileNumberModal
          setShowUpdateMobileNumberModal={setShowUpdateMobileNumberModal}
        />
      }

      {/* Change Password Modal */}
      {setShowChangePasswordModal &&
        <UpdatePasswordModal
          setShowChangePasswordModal={setShowChangePasswordModal}
        />
      }
      {linkedinChangePasswordModal &&
      // {true&&
        <UnlinkLinkedIn
        setShowUnlinkLinkedIn={linkedinChangePasswordModal}
        // setShowUnlinkLinkedIn={true}
        />
      }


    </>
  )
}
export default ProfileTab;