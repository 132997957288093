import { call, put, takeEvery } from 'redux-saga/effects'
import instance from "../../../../api/api_instance";

async function getApi(data) {
  try {
    const result = instance({
      url: "v1/email-mobile-otp-verify",
      method: "POST",
      data: data,
    }).then((response) => {
      return response;

    })
    return await result;
  } catch (error) {
    throw error;
  }
}
function* fetchAuth(action) {
  try {
    const res = yield call(getApi, action.payload);
    if (res.status === 200) {
      yield put({ type: "VERIFY_EMAIL_MOBILE_OTP_SUCCESS", authData: res.data.data });
      // TokenService.updateLocalAccessToken(res.data.data.token);
      // IM AM HERE
      if (action.payload.projectURL) {
        console.log(action.payload.appUrl, "appUrl");
        var urlString = action.payload.appUrl;
        function getPlaystoreUrl() {
          var url = new URL(urlString);
          var queryParams = url.searchParams;
          var id = queryParams.get("id");
          console.log("param1:", id);
          return id.split('.')[id.split('.').length - 1]
        };
        function getApptoreUrl() {
          // Create a URL object
          var urlObject = new URL(urlString);
          // Get the pathname from the URL
          var pathname = urlObject.pathname;
          // Extract the desired text from the pathname
          var parts = pathname.split('/');
          return parts[parts.length - 1];
        };
        function getWebsiteName() {
          // Create a URL object
          var urlObject = new URL(urlString);
          // Get the hostname from the URL
          return urlObject.hostname;
        };
        const urlType = action.payload.urlType;
        const name = urlType === "playstore" ? getPlaystoreUrl() : urlType === "appstore" ? getApptoreUrl() : urlType === "website" ? getWebsiteName() : "New Project";



        const data =
        {
          "evaluationData": {
            "prototypes": [
              {
                "title": name,
                "url": action.payload.projectURL
              }
            ]
          },
          "projectData": {
            "title": name
          }
        }
        console.log(data, "the data is showing here");
        // {
        //   projectURL: action.payload.projectURL,
        //   tyep: action.payload.urlType
        // }
        yield put({ type: 'CREATE_PROJECT_AFTER_OTP_REQUEST', payload:data});
      }
      window.localStorage.setItem('isLogin', true);
      window.location.href="/"
    } else {
      yield put({ type: "VERIFY_EMAIL_MOBILE_OTP_FAILED", message: "" });
    }
  } catch (e) {
    if (e.response.status === 400 || e.response.status === 404 || e.response.status === 409) {
      yield put({ type: "VERIFY_EMAIL_MOBILE_OTP_FAILED", message: e.response.data });
    } else {
      yield put({ type: "VERIFY_EMAIL_MOBILE_OTP_FAILED", message: "Some error occurred!" });
    }
  }
}

function* verifyEmailMobileOTPSaga() {
  yield takeEvery("VERIFY_EMAIL_MOBILE_OTP_REQUESTED", fetchAuth);
}

export default verifyEmailMobileOTPSaga;
