import { call, put, takeEvery } from 'redux-saga/effects'
import instance from "../../../../api/api_instance";

async function getApi(data) {
  let formData = {
    name:'',
    projectAwareness:[],
    age:'',
    gender:'',
    language:'',
    educationLevel:[]
  }
  formData.name = data.name
  formData.age = data.age
  formData.gender = data.gender
  formData.language = data.language

  data.projectAwareness.forEach((item)=>{
    formData.projectAwareness.push(item)
  })

  data.educationLevel.forEach((item)=>{
    formData.educationLevel.push(item)
  })
  try {
    const result = instance({
      url: `v1/project/${data.projectId}/persona`,
      method: "POST",
      data: formData,
    }).then((response) => {
      return response;
    })
    return await result;
  } catch (error) {
  }
}

function* fetchProjects(action) {
  try {
    const res = yield call(getApi, action.payload);
    console.log(res)
    const toastrData = {
      content: "Project Persona added successfully",
      type: "success"
    }
    yield put({ type: "CREATE_PROJECT_PERSONA_SUCCESS", createProjectPersona: res.data.data });
    const data = {
      projectId: action.payload.projectId
    }
    yield put({ type: "GET_ALL_PROJECT_PERSONAS_REQUESTED", payload: data });
    yield put({ type: "SET_TOASTR_SUCCESS", data: toastrData });
  } catch (e) {
    console.log(e)
    if (e.response.status === 400 || e.response.status === 403 || e.response.status === 404 || e.response.status === 409) {
      yield put({ type: "CREATE_PROJECT_PERSONA_FAILED", message: e.response.data.message });
    } else {
      yield put({ type: "CREATE_PROJECT_PERSONA_FAILED", message: "Some error occurred!" });
    }

    if (e.response.status === 500) {
      const toastrFailedData = {
          content: e.response.data.errors ? e.response.data.errors : "Something went wrong!",
          type: "failed"
      };
      yield put({ type: "SET_TOASTER_SUCCESS", data: toastrFailedData });
    }
  }
}

function* createProjectPersonaSaga() {
  yield takeEvery("CREATE_PROJECT_PERSONA_REQUESTED", fetchProjects);
}

export default createProjectPersonaSaga;
