import { call, put, takeEvery } from 'redux-saga/effects'
import instance from "../../../../api/api_instance";

async function getApi(data) {
  try {
    const result = instance({
      url: "v1/profile-change-password",
      method: "PATCH",
      data: data,
    }).then((response) => {
      return response;
    })
    return await result;
  } catch (error) {
    throw error;
  }
}

function* fetchProfile(action) {

  try {
    const res = yield call(getApi, action.payload);
    const toastrData = {
      content: "Password updated successfully",
      type: "success"
    }
    yield put({ type: "CHANGE_PASSWORD_SUCCESS", data: res.data.data });
    yield put({ type: "GET_PROFILE_DETAILS_REQUESTED", payload: {} });
    yield put({ type: "SET_TOASTR_SUCCESS", data: toastrData });
  } catch (e) {
    if (e.response.status === 406 || e.response.status === 404 || e.response.status === 403 || e.response.status === 400 || e.response.status === 422) {
      yield put({ type: "CHANGE_PASSWORD_FAILED", message: e.response.data.errors });
    }
  }
}

function* changePasswordSaga() {
  yield takeEvery("CHANGE_PASSWORD_REQUESTED", fetchProfile);
}

export default changePasswordSaga;
