// SET MANAGE PROJECT
export const SET_MANAGE_PROJECT = "SET_MANAGE_PROJECT";

// SET PROJECT ACTIVE TAB
export const SET_PROJECT_ACTIVE_TAB_REQUESTED = "SET_PROJECT_ACTIVE_TAB_REQUESTED";

// GET ALL PROJECTS
export const GET_ALL_PROJECTS_REQUESTED = "GET_ALL_PROJECTS_REQUESTED";
export const GET_ALL_PROJECTS_SUCCESS = "GET_ALL_PROJECTS_SUCCESS";
export const GET_ALL_PROJECTS_FAILED = "GET_ALL_PROJECTS_FAILED";

// GET PROJECT DETAILS
export const GET_PROJECT_DETAILS_REQUESTED = "GET_PROJECT_DETAILS_REQUESTED";
export const GET_PROJECT_DETAILS_SUCCESS = "GET_PROJECT_DETAILS_SUCCESS";
export const GET_PROJECT_DETAILS_FAILED = "GET_PROJECT_DETAILS_FAILED";

// CREATE PROJECT - PROJECT PHASE TAB
export const CREATE_PROJECT_TAB_ONE_REQUESTED = "CREATE_PROJECT_TAB_ONE_REQUESTED";
export const CREATE_PROJECT_TAB_ONE_SUCCESS = "CREATE_PROJECT_TAB_ONE_SUCCESS";
export const CREATE_PROJECT_TAB_ONE_FAILED = "CREATE_PROJECT_TAB_ONE_FAILED";

// UPDATE PROJECT - PROJECT PHASE TAB - IN TAB TWO
export const UPDATE_PROJECT_TAB_ONE_REQUESTED = "UPDATE_PROJECT_TAB_ONE_REQUESTED";
export const UPDATE_PROJECT_TAB_ONE_SUCCESS = "UPDATE_PROJECT_TAB_ONE_SUCCESS";
export const UPDATE_PROJECT_TAB_ONE_FAILED = "UPDATE_PROJECT_TAB_ONE_FAILED";

// UPDATE PROJECT EVALUATION - TAB TWO
export const UPDATE_EVALUATION_REASONS_REQUESTED = "UPDATE_EVALUATION_REASONS_REQUESTED";
export const UPDATE_EVALUATION_REASONS_SUCCESS = "UPDATE_EVALUATION_REASONS_SUCCESS";
export const UPDATE_EVALUATION_REASONS_FAILED = "UPDATE_EVALUATION_REASONS_FAILED";

// UPDATE PROJECT - LOCATION & DEMOGRAPHICS TAB
export const UPDATE_PROJECT_TAB_THREE_REQUESTED = "UPDATE_PROJECT_TAB_THREE_REQUESTED";
export const UPDATE_PROJECT_TAB_THREE_SUCCESS = "UPDATE_PROJECT_TAB_THREE_SUCCESS";
export const UPDATE_PROJECT_TAB_THREE_FAILED = "UPDATE_PROJECT_TAB_THREE_FAILED";

// GET ALL EVALUATION MATERIAL
export const GET_ALL_EVALUATION_MATERIALS_REQUESTED = "GET_ALL_EVALUATION_MATERIALS_REQUESTED";
export const GET_ALL_EVALUATION_MATERIALS_SUCCESS = "GET_ALL_EVALUATION_MATERIALS_SUCCESS";
export const GET_ALL_EVALUATION_MATERIALS_FAILED = "GET_ALL_EVALUATION_MATERIALS_FAILED";


// =========================== PERSONA - REAL USER REPORT =================================== //

// SET PROJECT PERSONA MODAL
export const SET_PROJECT_PERSONA_MODAL_REQUESTED = "SET_PROJECT_PERSONA_MODAL_REQUESTED";

// CREATE PROJECT PERSONAs
export const CREATE_PROJECT_PERSONA_REQUESTED = "CREATE_PROJECT_PERSONA_REQUESTED";
export const CREATE_PROJECT_PERSONA_SUCCESS = "CREATE_PROJECT_PERSONA_SUCCESS";
export const CREATE_PROJECT_PERSONA_FAILED = "CREATE_PROJECT_PERSONA_FAILED";

// GET ALL PROJECT PERSONAs
export const GET_ALL_PROJECT_PERSONAS_REQUESTED = "GET_ALL_PROJECT_PERSONAS_REQUESTED";
export const GET_ALL_PROJECT_PERSONAS_SUCCESS = "GET_ALL_PROJECT_PERSONAS_SUCCESS";
export const GET_ALL_PROJECT_PERSONAS_FAILED = "GET_ALL_PROJECT_PERSONAS_FAILED";

// GET PROJECT PERSONA DETAILS
export const GET_PROJECT_PERSONA_DETAILS_REQUESTED = "GET_PROJECT_PERSONA_DETAILS_REQUESTED";
export const GET_PROJECT_PERSONA_DETAILS_SUCCESS = "GET_PROJECT_PERSONA_DETAILS_SUCCESS";
export const GET_PROJECT_PERSONA_DETAILS_FAILED = "GET_PROJECT_PERSONA_DETAILS_FAILED";

// UPDATE PROJECT PERSONA
export const UPDATE_PROJECT_PERSONA_REQUESTED = "UPDATE_PROJECT_PERSONA_REQUESTED";
export const UPDATE_PROJECT_PERSONA_SUCCESS = "UPDATE_PROJECT_PERSONA_SUCCESS";
export const UPDATE_PROJECT_PERSONA_FAILED = "UPDATE_PROJECT_PERSONA_FAILED";

// DELETE PROJECT PERSONA
export const DELETE_PROJECT_PERSONA_REQUESTED = "DELETE_PROJECT_PERSONA_REQUESTED";
export const DELETE_PROJECT_PERSONA_SUCCESS = "DELETE_PROJECT_PERSONA_SUCCESS";
export const DELETE_PROJECT_PERSONA_FAILED = "DELETE_PROJECT_PERSONA_FAILED";

// DELETE PROJECT DRAFT
export const DELETE_DRAFT_PROJECT_REQUESTED = "DELETE_DRAFT_PROJECT_REQUESTED";
export const DELETE_DRAFT_PROJECT_SUCCESS = "DELETE_DRAFT_PROJECT_SUCCESS";
export const DELETE_DRAFT_PROJECT_FAILED = "DELETE_DRAFT_PROJECT_FAILED";

