import * as type from './types';

// FORGOT PASSWORD ACTIVE STEP
export function setForgotPasswordActiveStep(data) {
  return {
    type: type.SET_FORGOT_PASSWORD_ACTIVE_STEP_REQUESTED,
    payload: data,
  }
}

// TRIGGER EMAIL OTP - FORGOT PASSWORD
export function triggerForgotPasswordEmailOTP(data) {
  return {
    type: type.TRIGGER_FORGOT_PASSWORD_EMAIL_OTP_REQUEESTED,
    payload: data,
  }
}

// SET TRIGGER EMAIL OTP RESET ERRORS
export function resetTriggerEmailOTPErrors(data) {
  return {
    type: type.SET_TRIGGER_EMAIL_OTP_ERRORS_REQUESTED,
    payload: data,
  }
}


// VERIFY EMAIL OTP - FORGOT PASSWORD
export function verifyForgotPasswordEmailOTP(data) {
  return {
    type: type.VERIFY_FORGOT_PASSWORD_EMAIL_OTP_REQUESTED,
    payload: data,
  }
}

// SET VERIFY EMAIL OTP - ERROR MESSAGES
export function resetVerifyEmailOTPErrors(data) {
  return {
    type: type.SET_VERIFY_EMAIL_OTP_ERRORS_REQUESTED,
    payload: data,
  }
}


// RESET PASSWORD - FORGOT PASSWORD
export function resetForgotPassword(data) {
  return {
    type: type.RESET_FORGOT_PASSWORD_REQUESTED,
    payload: data,
  }
}

// SET RESET PASSWORD - ERROR MESSAGES
export function resetPasswordErrors(data) {
  return {
    type: type.SET_RESET_PASSWORD_ERRORS_REQUESTED,
    payload: data,
  }
}