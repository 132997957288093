// SET ACTIVE STEP
export const SET_ACTIVE_STEP_REQUESTED = "SET_ACTIVE_STEP_REQUESTED";

// CREATE EXPERT - EXPERT PHASE TAB
export const CREATE_EXPERT_TAB_ONE_REQUESTED = "CREATE_EXPERT_TAB_ONE_REQUESTED";
export const CREATE_EXPERT_TAB_ONE_SUCCESS = "CREATE_EXPERT_TAB_ONE_SUCCESS";
export const CREATE_EXPERT_TAB_ONE_FAILED = "CREATE_EXPERT_TAB_ONE_FAILED";

// UPDATE EXPERT - EXPERT PHASE TAB
export const UPDATE_EXPERT_TAB_ONE_REQUESTED = "UPDATE_EXPERT_TAB_ONE_REQUESTED";
export const UPDATE_EXPERT_TAB_ONE_SUCCESS = "UPDATE_EXPERT_TAB_ONE_SUCCESS";
export const UPDATE_EXPERT_TAB_ONE_FAILED = "UPDATE_EXPERT_TAB_ONE_FAILED";

// UPDATE EXPERT - TAB TWO
export const UPDATE_EXPERT_TAB_TWO_REQUESTED = "UPDATE_EXPERT_TAB_TWO_REQUESTED";
export const UPDATE_EXPERT_TAB_TWO_SUCCESS = "UPDATE_EXPERT_TAB_TWO_SUCCESS";
export const UPDATE_EXPERT_TAB_TWO_FAILED = "UPDATE_EXPERT_TAB_TWO_FAILED";

// UPDATE EXPERT - EXPERT PHASE TAB
export const UPDATE_EXPERT_TAB_THREE_REQUESTED = "UPDATE_EXPERT_TAB_THREE_REQUESTED";
export const UPDATE_EXPERT_TAB_THREE_SUCCESS = "UPDATE_EXPERT_TAB_THREE_SUCCESS";
export const UPDATE_EXPERT_TAB_THREE_FAILED = "UPDATE_EXPERT_TAB_THREE_FAILED";

// GET DETAILS EXPERT REG TAB ONE
export const GET_DETAILS_EXPERT_TAB_ONE_REQUESTED = "GET_DETAILS_EXPERT_TAB_ONE_REQUESTED";
export const GET_DETAILS_EXPERT_TAB_ONE_SUCCESS = "GET_DETAILS_EXPERT_TAB_ONE_SUCCESS";
export const GET_DETAILS_EXPERT_TAB_ONE_FAILED = "GET_DETAILS_EXPERT_TAB_ONE_FAILED";

// GET DETAILS EXPERT REG TAB TWO
export const GET_DETAILS_EXPERT_TAB_TWO_REQUESTED = "GET_DETAILS_EXPERT_TAB_TWO_REQUESTED";
export const GET_DETAILS_EXPERT_TAB_TWO_SUCCESS = "GET_DETAILS_EXPERT_TAB_TWO_SUCCESS";
export const GET_DETAILS_EXPERT_TAB_TWO_FAILED = "GET_DETAILS_EXPERT_TAB_TWO_FAILED";

// GET DETAILS EXPERT REG TAB THREE
export const GET_DETAILS_EXPERT_TAB_THREE_REQUESTED = "GET_DETAILS_EXPERT_TAB_THREE_REQUESTED";
export const GET_DETAILS_EXPERT_TAB_THREE_SUCCESS = "GET_DETAILS_EXPERT_TAB_THREE_SUCCESS";
export const GET_DETAILS_EXPERT_TAB_THREE_FAILED = "GET_DETAILS_EXPERT_TAB_THREE_FAILED";
