// FORGOT PASSWORD ACTIVE STEP
export const SET_FORGOT_PASSWORD_ACTIVE_STEP_REQUESTED = "SET_FORGOT_PASSWORD_ACTIVE_STEP_REQUESTED";

// TRIGGER EMAIL OTP - FORGOT PASSSWORD
export const TRIGGER_FORGOT_PASSWORD_EMAIL_OTP_REQUEESTED = "TRIGGER_FORGOT_PASSWORD_EMAIL_OTP_REQUEESTED";
export const TRIGGER_FORGOT_PASSWORD_EMAIL_OTP_SUCCESS = "TRIGGER_FORGOT_PASSWORD_EMAIL_OTP_SUCCESS";
export const TRIGGER_FORGOT_PASSWORD_EMAIL_OTP_FAILED = "TRIGGER_FORGOT_PASSWORD_EMAIL_OTP_FAILED";

// SET TRIGGER EMAIL OTP - ERROR MESSAGES
export const SET_TRIGGER_EMAIL_OTP_ERRORS_REQUESTED = "SET_TRIGGER_EMAIL_OTP_ERRORS_REQUESTED";

// VERIFY EMAIL OTP - FORGOT PASSWORD
export const VERIFY_FORGOT_PASSWORD_EMAIL_OTP_REQUESTED = "VERIFY_FORGOT_PASSWORD_EMAIL_OTP_REQUESTED";
export const VERIFY_FORGOT_PASSWORD_EMAIL_OTP_SUCCESS = "VERIFY_FORGOT_PASSWORD_EMAIL_OTP_SUCCESS";
export const VERIFY_FORGOT_PASSWORD_EMAIL_OTP_FAILED = "VERIFY_FORGOT_PASSWORD_EMAIL_OTP_FAILED";

// SET VERIFY EMAIL OTP - ERROR MESSAGES
export const SET_VERIFY_EMAIL_OTP_ERRORS_REQUESTED = "SET_VERIFY_EMAIL_OTP_ERRORS_REQUESTED";


// RESET PASSWORD - FORGOT PASSWORD
export const RESET_FORGOT_PASSWORD_REQUESTED = "RESET_FORGOT_PASSWORD_REQUESTED";
export const RESET_FORGOT_PASSWORD_SUCCESS = "RESET_FORGOT_PASSWORD_SUCCESS";
export const RESET_FORGOT_PASSWORD_FAILED = "RESET_FORGOT_PASSWORD_FAILED";

// SET RESET PASSWORD - ERROR MESSAGES
export const SET_RESET_PASSWORD_ERRORS_REQUESTED = "SET_RESET_PASSWORD_ERRORS_REQUESTED";

