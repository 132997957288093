import { useEffect, useState } from "react";
import { Button } from "reactstrap";
import ProjectEvaluationDetailsModal from "./projectEvaluationDetailsModal";
import ProjectTargetUserTypeDetailsModal from "./projectTargetUserTypeDetailsModal";
import { useDispatch, useSelector } from "react-redux";
import { getProjectDetails } from "../../../redux/reduxes/customer/projects-new/projectAction";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const ProjectDetailsPreview = ({projectId}) => {
  const dispatch = useDispatch();
  // Fetch Data
  const projectDetailsLoading = useSelector(state => state.projectNew.projectDetailsLoading);
  const details = useSelector(state => state.projectNew.projectDetails);
  // const test= useSelector(state => state.projectNew.projectDetails);
  const test = useSelector((state) => state);


  // Get Project Details
  useEffect(() => {
    const data = {
      projectId: projectId,
    }
    dispatch(getProjectDetails(data));
  }, []);

  // Evaluation Material Modal
  const [viewEvaluationDetailsModal, setViewEvaluationDetailsModal] = useState(false);
  const [evaluationId, setEvaluationId] = useState(null);
  const evaluationDetailsModal = (stat, id) => {
    setViewEvaluationDetailsModal(stat);
    setEvaluationId(id);
  }

  // Target User Type Modal
  const [viewTargetUserTypeDetailsModal, setViewTargetUserTypeDetailsModal] = useState(false);
  const [personaId, setPersonaId] = useState(null);
  const targetUserTypeDetailsModal = (stat, id) => {
    setViewTargetUserTypeDetailsModal(stat);
    setPersonaId(id);
  }
  // console.log("Bill M=>",details.locations[0].city,"-",details.locations[0].country)
  const realUserType = details && details.project && details.project.realUserType
  return (
    <>

      <div className="us-customer__project-details">
        {/* Project Basic Info */}
        <div className="us-form__list-bgsuccess2 shadow-sm d-flex align-items-center px-4 px-md-8 py-6 mb-8">
          <div className="d-flex align-items-center me-auto">
              <h6 className="text-sm head-font font-semibold mb-0">Thanks for submitting the project. The Useralia support team will contact you within 48 hours</h6>
          </div>
        </div>

        <div className="us-form__list-bgprimary2 shadow-sm d-flex align-items-center px-4 px-md-8 py-6 mb-8">
          <div className="d-flex align-items-center me-auto">
              {projectDetailsLoading ?
                <Skeleton
                  width="150px"
                  height="22px"
                  baseColor="#E2E2E2"
                  highlightColor="#E9EAF0"
                  borderRadius={0}
                  enableAnimation={true}
                />
              :
                <div className="head-font font-semibold text-sm text-capitalize me-3">
                  { details && details.project && details.project.title}
                </div>
              }
                 {/* <Link to={`/customer/projects/create-project/false/edit?project=${projectId}`}>
            <Button color="link"  type="button" className="us-button__link p-0">
              <img src="/assets/icons/icon-edit-blue.svg" alt="icon-edit-blue" width="25px" height="25px" />
            </Button>
         </Link> */}
          </div>
          {projectDetailsLoading ?
            <Skeleton
              width="150px"
              height="22px"
              baseColor="#E2E2E2"
              highlightColor="#E9EAF0"
              borderRadius={0}
              enableAnimation={true}
            />
            :
            <div className="bg-tertiary2 text-white body-font text-xs py-1 px-2 rounded-sm ms-auto text-capitalize">
              {details && details.project && details.project.phase}
            </div>
          }
        </div>


        {/* Evaluation Details */}
        <div className="us-form__wrap-card">
          <div className="mb-12">
            <div className="d-flex align-items-center mb-6">
              <div className="d-flex align-items-center">
                <img src="/assets/icons/icon-flag-bg.svg" alt="icon-flag-bg" />
                <h6 className="text-sm head-font font-semibold mb-0 ms-2">Reason for Evaluation</h6>
              </div>
              <div className="ms-auto">
              {/* <Link to={`/customer/projects/create-project/false/edit?project=${projectId}tab=1`}>
              <Button color="link" type="button" className="us-button__link p-0">
                  <img src="/assets/icons/icon-edit-blue.svg" alt="icon-edit-blue" width="25px" height="25px" />
                </Button>
              </Link> */}
              </div>
            </div>

            <div>
              <div className="head-font text-xs font-semibold mb-1">Why do you want to Evaluate?</div>
              {projectDetailsLoading ?
                <Skeleton
                  height="22px"
                  baseColor="#E2E2E2"
                  highlightColor="#E9EAF0"
                  borderRadius={0}
                  enableAnimation={true}
                />
                :
                <ol className="us-terms__lists">
                  {details && details.EvaluationReason && details.EvaluationReason.length > 0 && details.EvaluationReason.forEach((reasons) => {
                    return (
                      <>
                        {reasons && reasons.evaluationReason && reasons.evaluationReason.length > 0 && reasons.evaluationReason.map((list, index) => {
                          return (
                              <li key={index} className="us-terms__list body-font text-sm font-normal">{list}</li>
                          )
                        })}
                      </>
                    )
                  })}
                </ol>
              }
            </div>
          </div>

          {/* Evaluation Material - List */}
          <div>
            <div className="d-flex align-items-center mb-6">
              <img src="/assets/icons/icon-flag-bg.svg" alt="icon-flag-bg" />
              <h6 className="text-sm head-font font-semibold mb-0 ms-2">Evaluation Materials</h6>
            </div>

            {/* Evaluation Material Selected Options */}
            {projectDetailsLoading ?
              <Skeleton
                count={2}
                width="300px"
                height="70px"
                baseColor="#E2E2E2"
                highlightColor="#E9EAF0"
                borderRadius={10}
                enableAnimation={true}
                className="rows rows-cols-2 m-2"
                inline={true}
              />
            :
              <ul className="us-form__lists  d-flex align-items-start flex-wrap w-100 gap-2">
                {details && details.EvaluationMaterial && details.EvaluationMaterial.length > 0 ?
                  <>
                    {details.EvaluationMaterial.map((list, index) => {
                      const { _id, appName, appType } = list;
                      return (
                        <li key={index} className="us-form__list us-form__list-evaluation d-flex align-items-start justify-content-between">
                          <div className="text-capitalize">
                            <div className="us-form__list-title text-sm font-medium mb-1">{appName}</div>
                            <div className="us-form__list-subtitle text-sm">{appType}</div>
                          </div>

                          <Button onClick={() => evaluationDetailsModal(true, _id)} type="button" color="link" className="us-button__link text-sm text-primary p-0 ms-3">View</Button>
                        </li>
                      )
                    })}
                  </>
                  :
                  <div className="us-form__error">No Evaluation Materials Found</div>
                }
              </ul>
            }
          </div>
        </div>

        {/* Target Location and Demographics */}
        <div className="us-form__wrap-card">
          <div className="mb-12">
            <div className="d-flex align-items-center mb-6">
              <div className="d-flex align-items-center">
                <img src="/assets/icons/icon-flag-bg.svg" alt="icon-flag-bg" />
                <h6 className="text-sm head-font font-semibold mb-0 ms-2">Target Location &amp; Demographics</h6>
              </div>
              <div className="ms-auto">
              {/* <Link to={`/customer/projects/create-project/false/edit?project=${projectId}`}>
              <Button color="link" type="button" className="us-button__link p-0">
                  <img src="/assets/icons/icon-edit-blue.svg" alt="icon-edit-blue" width="25px" height="25px" />
                </Button>
              </Link> */}

              </div>
            </div>

             {/* <div className="mb-6">
              <div className="head-font text-xs font-semibold mb-1">Target location</div>
              <div className="us-para body-font text-xs font-normal">India, UAE, Indonesia</div>
            </div> */}
            {console.log(details && details.project, "LOIKOIOLOIOLOIOL")}
            <div className="mb-5">
              <div className="head-font text-xs font-semibold mb-1">Target location</div>
              {projectDetailsLoading ?
                <Skeleton
                  height="30px"
                  baseColor="#E2E2E2"
                  highlightColor="#E9EAF0"
                  borderRadius={6}
                  enableAnimation={true}
                />
                :
                <>
                {details&&details.locations&&details.locations.map((list, index) => {
                  return(
                <div key={index} className="us-para body-font text-xs font-normal">{list.city==null?list.state:list.city}-{list.country}</div>)})}</>
              }
            </div>
            <div>
              <div className="head-font text-xs font-semibold mb-1">Budget</div>
              {projectDetailsLoading ?
                <Skeleton
                  height="30px"
                  baseColor="#E2E2E2"
                  highlightColor="#E9EAF0"
                  borderRadius={6}
                  enableAnimation={true}
                />
                :
                <div className="us-para body-font text-xs font-normal">{details && details.project && details.project.budget}</div>
              }
            </div>
          </div>

          {/*Target User Type */}
          <div>
            <div className="d-flex align-items-center mb-6">
              <img src="/assets/icons/icon-flag-bg.svg" alt="icon-flag-bg" />
              <h6 className="text-sm head-font font-semibold mb-0 ms-2">Target User Types</h6>
            </div>

            {/* Target User Type - List */}
            {projectDetailsLoading  ?
              <Skeleton
                count={2}
                width="300px"
                height="70px"
                baseColor="#E2E2E2"
                highlightColor="#E9EAF0"
                borderRadius={10}
                enableAnimation={true}
                className="rows rows-cols-2 m-2"
                inline={true}
              />
              :
              <ul className="us-form__lists  d-flex align-items-start flex-wrap w-100 gap-2">
                {details && details.UserType && details.UserType.length > 0 ?
                  <>
                    {details.UserType.map((persona, index) => {
                      const { _id, name, language } = persona;
                      return (
                        <li key={index} className="us-form__list us-form__list-evaluation d-flex align-items-start justify-content-between text-capitalize">
                          <div>
                            {realUserType &&
                              <div className="us-form__list-title text-sm font-medium mb-1">{name}</div>
                            }
                            <div className="us-form__list-subtitle text-sm">{language}</div>
                          </div>

                          <Button onClick={() => targetUserTypeDetailsModal(true, _id)} type="button" color="link" className="us-button__link text-sm text-primary p-0 ms-3">View</Button>
                        </li>
                      )
                    })}
                  </>
                  :
                  <div className="us-form__error">No Evaluation Materials Found</div>
                }
              </ul>
            }
          </div>
        </div>

        {/* Media Uploads / Attachments */}
        {/* <div className="us-form__wrap-card">
          <div className="d-flex align-items-center mb-8">
            <img src="/assets/icons/icon-attachment-bg.svg" alt="icon-flag-bg" />
            <h6 className="text-sm head-font font-semibold mb-0 ms-2">2 attachment</h6>
          </div>

          <ul className="us-form__lists d-flex align-items-center flex-wrap w-100 gap-2">
            <li className="us-form__list us-form__list-bgprimary2 d-flex align-items-start">
              <div className="me-10">
                <img src="/assets/icons/icon-file.svg" alt="icon-file" />
                <span className="us-form__list-title text-xs font-medium mt-1">some-document.docx</span>
              </div>
              <Button color="link" className="us-button__link ms-auto p-0">
                <img src="/assets/icons/icon-download.svg" alt="icon-download" />
              </Button>
            </li>
          </ul>
        </div> */}
      </div>

      {/* Evaluation Details Modal */}
      {viewEvaluationDetailsModal &&
        <ProjectEvaluationDetailsModal
          viewEvaluationDetailsModal={viewEvaluationDetailsModal}
          setViewEvaluationDetailsModal={setViewEvaluationDetailsModal}
        evaluationId={evaluationId}
        projectId={projectId}
          setEvaluationId={setEvaluationId}
        />
      }

      {/* Target User Type Modal */}
      {viewTargetUserTypeDetailsModal &&
        <ProjectTargetUserTypeDetailsModal
          viewTargetUserTypeDetailsModal={viewTargetUserTypeDetailsModal}
          setViewTargetUserTypeDetailsModal={setViewTargetUserTypeDetailsModal}
          personaId={personaId}
        setPersonaId={setPersonaId}
        projectId={projectId}
          realUserType={details && details.project && details.project.realUserType}
        />
      }
    </>
  )
}
export default ProjectDetailsPreview;

