import { Button, Toast } from "reactstrap";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastBody } from "reactstrap";
import { stopToastr } from "../../../redux/reduxes/global/toastr/actions";


const ToastrSuccess = ({ hideToastrAction}) => {
  const dispatch = useDispatch();

  // Fetch Data
  const content = useSelector(state => state.toastr.content);
  const type = useSelector(state => state.toastr.type);
  const showToast = useSelector(state => state.toastr.show);

  // Hide Toastr
  const hideToastr = () => {
    dispatch(hideToastrAction());
  }

  useEffect(() => {
    if (showToast) {
      setTimeout(function () {
        dispatch(stopToastr());
      }, 3500);
    }
  }, [showToast]);

  return (
    <>
      <Toast isOpen={showToast} className="us-toastr position-relative w-100">
        <ToastBody className={`us-toastr__body position-fixed d-flex align-items-center justify-content-between ${type == "success" ? "us-toastr__body-success" : "us-toastr__body-failed"}`}>
          <div className="us-toastr__content">{content}</div>
          <Button onClick={() => hideToastr()} type="button" color="link" className="us-button__link us-toastr__button p-0 ms-5">&times;</Button>
        </ToastBody>
      </Toast>
    </>
  )
}
export default ToastrSuccess;