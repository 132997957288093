import { call, put, takeEvery } from 'redux-saga/effects'
import instance from "../../../../api/api_instance";

async function getApi(data) {
  // const projectId = localStorage.getItem("projectId");
  // const evaluationId = localStorage.getItem("evaluationId");
  try {
    const result = instance({
      url: `v1/project/${data.projectId}/evaluation-material/${data.evaluationId}`,
      method: "DELETE",
      data: data.data,
    }).then((response) => {
      return response;
    })
    return await result;
  } catch (error) {
    throw error;
  }
}

function* fetchProjects(action) {
  try {
    const res = yield call(getApi, action.payload);
    const toastrData = {
      content: `${res.data.name} Evaluation Material deleted Successfully`,
      type: "success"
    }
    yield put({ type: "DELETE_EVALUATION_MATERIALS_SUCCESS", allEvaluationMaterials: res.data.data });
    const data = {
      projectId: action.payload.projectId,
    };
    yield put({ type: 'GET_PROJECT_DETAILS_REQUESTED', payload: data });
    yield put({ type: "SET_TOASTER_SUCCESS", data: toastrData });

  } catch (e) {
    const toastrData = {
      content: "Failed to delete this Evaluation Material",
      type: "failed"
    }
    if (e.response.status === 406 || e.response.status === 404) {
      yield put({ type: "DELETE_EVALUATION_MATERIALS_FAILED", message: e.response.data });
      yield put({ type: "SET_TOASTER_SUCCESS", data: toastrData });
    } else {
      yield put({ type: "DELETE_EVALUATION_MATERIALS_FAILED", message: "Some error occurred" });
      yield put({ type: "SET_TOASTER_SUCCESS", data: toastrData });
    }


    if (e.response.status === 500) {
      const toastrFailedData = {
        content: e.response.data.errors ? e.response.data.errors : "Something went wrong!",
        type: "failed"
      };
      yield put({ type: "SET_TOASTER_SUCCESS", data: toastrFailedData });
    }
  }
}

function* deleteEvaluationMaterialsSaga() {
  yield takeEvery("DELETE_EVALUATION_MATERIALS_REQUESTED", fetchProjects);
}

export default deleteEvaluationMaterialsSaga;