import { call, put, takeEvery } from 'redux-saga/effects'
import instance from "../../../../api/api_instance";

async function getApi(data) {
  try {
    const result = instance({
      url: "v1/remove-profile-picture",
      method: "PATCH",
      data: data,
    }).then((response) => {
      return response;
    })
    return await result;
  } catch (error) {
    throw error;
  }
}
function* fetchProfile(action) {
  try {
    const res = yield call(getApi, action.payload);
    const toastrData = {
      content: "Profile Picture deleted successfully",
      type: "success"
    }
    yield put({ type: "REMOVE_PROFILE_PICTURE_SUCCESS", profileDetails: res.data.data });
    yield put({ type: "GET_PROFILE_DETAILS_REQUESTED", payload: {} });
    yield put({ type: "SET_TOASTR_SUCCESS", data: toastrData });
  } catch (e) {
    if (e.response.status === 400 || e.response.status === 404 || e.response.status === 409) {
      yield put({ type: "REMOVE_PROFILE_PICTURE_FAILED", message: e.response.data.message });
    } else {
      yield put({ type: "REMOVE_PROFILE_PICTURE_FAILED", message: "Some error occurred!" });
    }
  }
}

function* removeProfilePictureSaga() {
  yield takeEvery("REMOVE_PROFILE_PICTURE_REQUESTED", fetchProfile);
}

export default removeProfilePictureSaga;
