import axios from "axios";

const checkEnvironment=(environment)=> {
  let baseUrl = "";
  switch(environment) {
    case "local":
      baseUrl = process.env.REACT_APP_API_URL_LOCAL;
      break;
      case "development":
        baseUrl = process.env.REACT_APP_API_URL_DEVELOPMENT;
      break;
      case "staging":
        baseUrl = process.env.REACT_APP_API_URL_STAGING;
      break;
      case "production":
        baseUrl = process.env.REACT_APP_API_URL_PRODUCTION;
        break;
    default:
      baseUrl = process.env.REACT_APP_API_URL_LOCAL;
  }
  return baseUrl;
}
const baseUrl = checkEnvironment(process.env.REACT_APP_ENVIRONMENT )
const instance = axios.create({
  baseURL: baseUrl,
  headers: {
    "Content-Type": "application/json",
    'Accept': 'application/json',
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache',
    'Expires': '0',
    
    // 'Authorization': `Bearer ${localStorage.getItem('token')}`
    // 'admin': 'true'
    // timeout : 1000,
  },
});

export default instance;