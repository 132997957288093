import * as type from "./types";

// GET ALL PROJECTS
export function getAllProjects(data) {
  return {
    type: type.GET_ALL_PROJECTS_REQUESTED,
    payload: data,
  };
}

// GET PROJECT DETAILS
export function getProjectDetails(data) {
  return {
    type: type.GET_PROJECT_DETAILS_REQUESTED,
    payload: data,
  };
}

// SET PROJECT ACTIVE TAB
export function setProjectActiveTab(data) { 
  return {
    type: type.SET_PROJECT_ACTIVE_TAB_REQUESTED,
    payload: data,
  }
}

//  SET MANAGE PROJECT
export function setManageProject(data) {
  return {
    type: type.SET_MANAGE_PROJECT,
    payload: data,
  }
}
 // CREAT PROJECT - PROJECT PHASE TAB
export function createProjectTabOne(data) {
  return {
    type: type.CREATE_PROJECT_TAB_ONE_REQUESTED, 
    payload: data,
  }
}

// UPDATE PROJECT - PROJECT PHASE TAB
export function updateProjectTabOne(data) {
  return {
    type: type.UPDATE_PROJECT_TAB_ONE_REQUESTED,
    payload: data,
  }
}

// UPDATE PROJECT EVALUATION REASONS
export function updateEvaluationReasons(data) {

  return {
    type: type.UPDATE_EVALUATION_REASONS_REQUESTED,
    payload: data,
  }
}

// UPDATE PROJECT - LOCATION & DEMOGRAPHICS TAB
export function updateProjectTabThree(data) {
  return {
    type: type.UPDATE_PROJECT_TAB_THREE_REQUESTED,
    payload: data,
  }
}

// GET ALL EVALUATION MATERIALS
export function getAllEvaluationMaterials(data) {
  return {
    type: type.GET_ALL_EVALUATION_MATERIALS_REQUESTED,
    payload: data,
  }
}

// ============== PROJECT PERSONA =================== //

// SET PROJECT PERSONA MODAL
export function setProjectPersonaModal(data) {
  return {
    type: type.SET_PROJECT_PERSONA_MODAL_REQUESTED,
    payload: data,
  }
}

// CREATE PROJECT PERSONA --- MULTIPLE PERSONAs
export function createProjectPersona(data) {
  return {
    type: type.CREATE_PROJECT_PERSONA_REQUESTED,
    payload: data,
  }
}

// GET ALL PROJECT PERSONAS
export function getAllProjectPersonas(data) {
  return {
    type: type.GET_ALL_PROJECT_PERSONAS_REQUESTED,
    payload: data,
  }
}

// GET PROJECT PERSONA DETAILS
export function getProjectPersonaDetails(data) {
  return {
    type: type.GET_PROJECT_PERSONA_DETAILS_REQUESTED,
    payload: data,
  }
}

// UPDATE PROJECT PERSONA
export function updateProjectPersona(data) {
  return {
    type: type.UPDATE_PROJECT_PERSONA_REQUESTED,
    payload: data,
  }
}

// DELETE PROJECT PERSONA
export function deleteProjectPersona(data) {
  return {
    type: type.DELETE_PROJECT_PERSONA_REQUESTED,
    payload: data,
  }
}


// DELETE DRAFT PROJECT
export function deleteDraftProject(data) {
  return {
    type: type.DELETE_DRAFT_PROJECT_REQUESTED,
    payload: data,
  }
}
