import { call, put, takeEvery } from 'redux-saga/effects'
import instance from "../../../../api/api_instance";

async function getApi(data) {
  try {
    const result = instance({
      url: "v1/profile-update-mobile-otp",
      method: "POST",
      data: data,
    }).then((response) => {
      return response;
    })
    return await result;
  } catch (error) {
    throw error;
  }
}
function* fetchProfile(action) {
  try {
    const res = yield call(getApi, action.payload);
    const toastrData = {
      content: "Mobile Number updated successfully",
      type: "success"
    }
    console.log(res)
    yield put({ type: "UPDATE_MOBILE_NUMBER_SUCCESS", profileDetails: res.data.data });
    yield put({ type: "GET_PROFILE_DETAILS_REQUESTED", profileDetails: res.data.data });
    yield put({ type: "SET_TOASTR_SUCCESS", data: toastrData });
  } catch (e) {
    if (e.response.status === 400 || e.response.status === 404 || e.response.status === 409 || e.response.status === 422) {
      yield put({ type: "UPDATE_MOBILE_NUMBER_FAILED", message: e.response.data.errors });
    } else {
      yield put({ type: "UPDATE_MOBILE_NUMBER_FAILED", message: "Some error occurred!" });
    }
  }

}

function* updateMobileNumberSaga() {
  yield takeEvery("UPDATE_MOBILE_NUMBER_REQUESTED", fetchProfile);
}

export default updateMobileNumberSaga;
