import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Button,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  UncontrolledDropdown,
} from "reactstrap";
import PageTitle from "../../../components/global/common/pageTitle";
import Layout from "../../../layout";
import ProjectCard from "../../../components/customer/projects/projectCard";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import PaginatedItems from "../../../components/global/common/paginatedItems";
import { useHistory } from "react-router-dom";
import { getAllProjects, setProjectActiveTab, setManageProject } from "../../../redux/reduxes/customer/projects-new/projectAction";
import LoadingAnimation from "../../../components/global/auth/loadingAnimation";
import NoProjects from "../../../components/global/common/NoProjects";

const tabs = [
  { id: 0, tabName: "All" },
  { id: 1, tabName: "Active" },
  { id: 2, tabName: "Draft" },
  { id: 3, tabName: "Completed" },
  { id: 4, tabName: "Rejected" },
];

const SkeletonLoader = () => {
  return (
    <Row className="g-3 row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-5 w-100">
      {[...Array(10)].map((index) => {
        return (
          <Col key={index}>
            <Skeleton
              count={1}
              width="100%"
              height="300px"
              baseColor="#E2E2E2"
              highlightColor="#E9EAF0"
              borderRadius={10}
              inline={true}
              enableAnimation={true}
            />
          </Col>
        );
      })}
    </Row>
  );
};

const Projects = () => {
  //Implementing Search
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchInputChange = (e) => {
    const data = {
      page: 0,
      limit: 15,
      search: e.target.value,
      type:
        currentActiveTab === 0
          ? "All"
          : currentActiveTab === 1
          ? "Active"
          : currentActiveTab === 2
          ? "Draft"
          : currentActiveTab === 3
          ? "Completed"
          : currentActiveTab === 4
          ? "Rejected"
          : "",
    };
    dispatch(getAllProjects(data));
    setSearchQuery(e.target.value);

  };

  const dispatch = useDispatch();
  const history = useHistory();

  // Fetch Data
  const allProjectsListLoading = useSelector((state) => state.projectNew.allProjectsListLoading);
  const allProjectsList = useSelector((state) => state.projectNew.allProjectsList);
  const allProjectsListPagination = useSelector((state) => state.projectNew.allProjectsListPagination);
  const analysedImane = useSelector((state) => state.auth.profileDetails);
  // Active Tab Element
  const [currentActiveTab, setCurrentActiveTab] = useState(0);
  const toggleCreateProjectTabs = (tab, type) => {
    setCurrentActiveTab(tab);
    const data = {
      type: type,
      page: 0,
      search:searchQuery,
      limit: 15,
    };
    dispatch(getAllProjects(data));
  };

  // Get All Projects
  useEffect(() => {
    const data = {
      page: 0,
      limit: 15,
      search:searchQuery,
      type:
        currentActiveTab === 0
          ? "All"
          : currentActiveTab === 1
          ? "Active"
          : currentActiveTab === 2
          ? "Draft"
          : currentActiveTab === 3
          ? "Completed"
          : currentActiveTab === 4
          ? "Rejected"
          : "",
    };
    dispatch(getAllProjects(data));
  }, []);

  // onPage Click Handler
  const handlePageClick = (e) => {
    const data = {
      page: e.selected,
      search:searchQuery,
      limit: 15,
      type:
        currentActiveTab === 0
          ? "All"
          : currentActiveTab === 1
          ? "Active"
          : currentActiveTab === 2
          ? "Draft"
          : currentActiveTab === 3
          ? "Completed"
          : currentActiveTab === 4
          ? "Rejected"
          : "",
    };
    dispatch(getAllProjects(data));
  };
  // Get Expert Report
  const handleExpertReportEvent = () => {
    history.push("/customer/projects/manage-project/expert");
    dispatch(setProjectActiveTab(0));
    dispatch(setManageProject({ edit: false }));
  };

  // Get Real User Report
  const handleRealUserReportEvent = () => {
    history.push("/customer/projects/manage-project/real_user");
    dispatch(setProjectActiveTab(0));
    dispatch(setManageProject({ edit: false }));
  };

  return (
    <>
      <PageTitle pageTitle="Projects" />
      <Layout>
        {/* ========= PROJECTS ========= */}
        <section className="us-section us-customer__projects shadow-lg">
          <div className="us-section__inwrap">
            <Container>
              <Row>
                <Col md="12">
                  {/* Get Started */}

                  <div className="us-customer__dashboard-reports d-lg-flex align-items-center bg-white mb-14">
                    <div className="p-5 p-md-10">
                      <h6 className="us-section__title-mini mb-2">
                        Get started
                      </h6>

                      <p className="us-para">
                      Complete your profile
                        <br className="d-none d-md-block" />
                        to find the best experts suitable for your projects.
                      </p>
                      <div className="d-md-inline-flex mt-6">
                        {/* <Button
                          onClick={(e) => handleExpertReportEvent(e)}
                          type="button"
                          color="primary"
                          className="us-button us-button__primary text-uppercase mt-4"
                        >
                          Get Expert Report
                        </Button> */}
                        <Button
                          onClick={(e) => handleRealUserReportEvent(e)}
                          type="button"
                          color="primary"
                          outline
                          className="us-button us-button__primary text-uppercase mt-4"
                        >
                          Get Real User Report
                        </Button>
                        <Button
                          onClick={(e) => handleExpertReportEvent(e)}
                          type="button"
                          color="primary"
                          className="us-button us-button__primary-outline text-uppercase mt-4 ms-md-4"
                        >
                          Get Expert Report
                        </Button>
                      </div>
                    </div>
                    <div className="d-none d-lg-block ms-auto p-5  " >
                      <img
                        src={analysedImane ? analysedImane : "/assets/images/customer/dashboard/dashboard-reports.png"}
                        alt="get-reports"
                        className="img-fluid d-block border"
                        width={analysedImane ? "350" : "300"}
                        height="100px"
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <h1 className="us-section__title mb-10">Manage Projects</h1>
                </Col>
                <Col md="6"></Col>
              </Row>

              <Row>
                <Col>
                  <div className="us-nav">
                    {/*  TABS */}
                    <Nav tabs className="us-nav__tabs">
                      <div className="us-nav__tabs-left d-flex align-items-start">
                        {tabs &&
                          tabs.map((tab, index) => {
                            const { id, tabName } = tab;
                            return (
                              <>
                                <NavItem
                                  key={index}
                                  className="us-nav__tab-item"
                                >
                                  <NavLink
                                    className={`us-nav__tab-link d-flex align-items-center px-3 py-3 ${
                                      currentActiveTab === id ? "active" : ""
                                    }`}
                                    onClick={() =>
                                      toggleCreateProjectTabs(id, tabName)
                                    }
                                  >
                                    <span className="us-nav__tab-title">
                                      {tabName}
                                    </span>
                                  </NavLink>
                                </NavItem>
                              </>
                            );
                          })}
                      </div>

                      <div className="us-nav__tabs-right d-flex align-items-center ms-auto mb-1">
                        {/* <div className="us-form__wrap d-flex align-items-center bg-white py-0.5 px-3 rounded-2">
                          <Label
                            htmlFor="sort_projects"
                            className="us-form__label text-nowrap text-xs mt-1 mb-0 me-1"
                          >
                            Order by:
                          </Label>
                          <Input
                            type="select"
                            name="sort_projects"
                            id="sort_projects"
                            className="us-form__input-select bg-transparent border-0 text-sm"
                          >
                            <option value="">Published</option>
                            <option value="">Completed</option>
                          </Input>
                        </div> */}

                        <div className="us-form__wrap ms-6">
                          <Input
                            type="search"
                            id="search_projects"
                            name="search_projects"
                            placeholder="Search Projects..."
                            className="us-form__input us-form__input-search py-1"
                            value={searchQuery}
                            onChange={handleSearchInputChange}
                          />
                        </div>
                      </div>
                    </Nav>

                    {/* TAB CONTENT */}
                    <TabContent
                      activeTab={currentActiveTab}
                      className="us-nav__tab-content mt-10"
                    >
                      {/* Active Projects Tab */}
                      <TabPane tabId={0} className="us-nav__tab-pane">
                        <Row className={`${allProjectsList.length > 0 ? "g-3 row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-5" : "row"}`} >
                          {allProjectsListLoading ? (
                            <SkeletonLoader />
                          ) : (
                            <>
                              {allProjectsList && allProjectsList.length > 0 ? (
                                <>
                                  {allProjectsList.map((project, index) => {
                                    const {
                                      _id,
                                      title,
                                      phase,
                                      projectStatus,
                                      createdAt,
                                      updatedAt,
                                      realUserType,
                                    } = project;
                                    return (
                                      <Col key={index}>
                                        <ProjectCard
                                          projectId={_id}
                                          projectTitle={title}
                                          projectPhase={phase}
                                          projectStatus={projectStatus}
                                          startedAt={createdAt}
                                          updatedAt={updatedAt}
                                          realUserType={realUserType}
                                          searchQuery={searchQuery}
                                          currentActiveTab={"All"}
                                        />
                                      </Col>
                                    );
                                  })}
                                </>
                              ) : (
                                <NoProjects />
                              )}
                            </>
                          )}
                          {/* Pagination */}
                          {allProjectsList.length >0 &&
                            <div className="d-flex align-items-center justify-content-end mt-10 col-12 col-sm-12 col-md-12 col-xl-12">
                              {allProjectsListLoading ? (
                                <Skeleton
                                  count={1}
                                  width="300px"
                                  height="60px"
                                  baseColor="#E2E2E2"
                                  highlightColor="#E9EAF0"
                                  borderRadius={5}
                                  className="mb-1"
                                  containerClassName="d-flex flex-wrap gap-2"
                                  inline={false}
                                  enableAnimation={true}
                                />
                              ) : (
                                <PaginatedItems
                                  itemsPerPage={
                                    allProjectsListPagination &&
                                    allProjectsListPagination.pageCount
                                  }
                                  handlePageClick={handlePageClick}
                                  pageCount={
                                    allProjectsListPagination &&
                                    Math.ceil(
                                      allProjectsListPagination.totalCount /
                                      allProjectsListPagination.pageCount
                                    )
                                  }
                                  currentPage={
                                    allProjectsListPagination &&
                                    allProjectsListPagination.currentPage
                                  }
                                  totalPages={
                                    allProjectsListPagination &&
                                    Math.ceil(
                                      allProjectsListPagination.totalCount /
                                      allProjectsListPagination.pageCount
                                    )
                                  }
                                // {allProjectsListPagination && allProjectsListPagination.totalPages}
                                />
                              )}
                            </div>
                          }
                        </Row>
                      </TabPane>

                      {/* Draft Projects Tab */}
                      <TabPane tabId={1} className="us-nav__tab-pane">
                        <Row className={`${allProjectsList.length > 0 ? "g-3 row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-5" : "row"}`}>
                          {allProjectsListLoading ? (
                            <SkeletonLoader />
                          ) : (
                            <>
                              {allProjectsList && allProjectsList.length > 0 ? (
                                <>
                                  {allProjectsList.map((project, index) => {
                                      const {
                                        _id,
                                        title,
                                        phase,
                                        projectStatus,
                                        createdAt,
                                        updatedAt,
                                        realUserType,
                                      } = project;
                                      return (
                                        <Col key={index}>
                                          <ProjectCard
                                            projectId={_id}
                                            projectTitle={title}
                                            projectPhase={phase}
                                            projectStatus={projectStatus}
                                            startedAt={createdAt}
                                            updatedAt={updatedAt}
                                            realUserType={realUserType}
                                            searchQuery={searchQuery}
                                            currentActiveTab={"Active"}

                                            // progressBar={progressBar}
                                            // progressText={progressText}
                                            // progressPercent={progressPercent}
                                          />
                                        </Col>
                                      );
                                    })}
                                </>
                              ) : (
                                <NoProjects />
                              )}
                            </>
                          )}

                          {/* Pagination */}
                          {allProjectsList.length>0 &&
                            <div className="d-flex align-items-center justify-content-end mt-10 col-12 col-sm-12 col-md-12 col-xl-12">
                              {allProjectsListLoading ? (
                                <Skeleton
                                  count={1}
                                  width="300px"
                                  height="60px"
                                  baseColor="#E2E2E2"
                                  highlightColor="#E9EAF0"
                                  borderRadius={5}
                                  className="mb-1"
                                  containerClassName="d-flex flex-wrap gap-2"
                                  inline={false}
                                  enableAnimation={true}
                                />
                              ) : (
                                <PaginatedItems
                                  itemsPerPage={
                                    allProjectsListPagination &&
                                    allProjectsListPagination.pageCount
                                  }
                                  handlePageClick={handlePageClick}
                                  pageCount={
                                    allProjectsListPagination &&
                                    Math.ceil(
                                      allProjectsListPagination.totalCount /
                                      allProjectsListPagination.pageCount
                                    )
                                  }
                                  currentPage={
                                    allProjectsListPagination &&
                                    allProjectsListPagination.currentPage
                                  }
                                  totalPages={
                                    allProjectsListPagination &&
                                    Math.ceil(
                                      allProjectsListPagination.totalCount /
                                      allProjectsListPagination.pageCount
                                    )
                                  }
                                />
                              )}
                            </div>
                          }
                        </Row>
                      </TabPane>

                      {/* Completed Projects Tab */}
                      <TabPane tabId={2} className="us-nav__tab-pane">
                        <Row className={`${allProjectsList.length > 0 ? "g-3 row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-5" : "row"}`}>
                          {allProjectsListLoading ? (
                            <SkeletonLoader />
                          ) : (
                            <>
                              {allProjectsList && allProjectsList.length > 0 ? (
                                <>
                                  {allProjectsList.map((project, index) => {
                                    const {
                                      _id,
                                      title,
                                      phase,
                                      projectStatus,
                                      createdAt,
                                      updatedAt,
                                      realUserType,
                                    } = project;
                                    return (
                                      <Col key={index}>
                                        <ProjectCard
                                          projectId={_id}
                                          projectTitle={title}
                                          projectPhase={phase}
                                          projectStatus={projectStatus}
                                          startedAt={createdAt}
                                          updatedAt={updatedAt}
                                          realUserType={realUserType}
                                          searchQuery={searchQuery}
                                          currentActiveTab={"Draft"}
                                          // progressBar={progressBar}
                                          // progressText={progressText}
                                          // progressPercent={progressPercent}
                                        />
                                      </Col>
                                    );
                                  })}
                                </>
                              ) : (
                                <NoProjects />
                              )}
                            </>
                          )}

                          {/* Pagination */}
                          {allProjectsList.length > 0 &&
                            <div className="d-flex align-items-center justify-content-end mt-10 col-12 col-sm-12 col-md-12 col-xl-12">
                              {allProjectsListLoading ? (
                                <Skeleton
                                  count={1}
                                  width="300px"
                                  height="60px"
                                  baseColor="#E2E2E2"
                                  highlightColor="#E9EAF0"
                                  borderRadius={5}
                                  className="mb-1"
                                  containerClassName="d-flex flex-wrap gap-2"
                                  inline={false}
                                  enableAnimation={true}
                                />
                              ) : (
                                <PaginatedItems
                                  itemsPerPage={
                                    allProjectsListPagination &&
                                    allProjectsListPagination.pageCount
                                  }
                                  handlePageClick={handlePageClick}
                                  pageCount={
                                    allProjectsListPagination &&
                                    Math.ceil(
                                      allProjectsListPagination.totalCount /
                                      allProjectsListPagination.pageCount
                                    )
                                  }
                                  currentPage={
                                    allProjectsListPagination &&
                                    allProjectsListPagination.currentPage
                                  }
                                  totalPages={
                                    allProjectsListPagination &&
                                    allProjectsListPagination.totalPages
                                  }
                                />
                              )}
                            </div>
                          }
                        </Row>
                      </TabPane>

                      {/* Cancelled Projects Tab */}
                      <TabPane tabId={3} className="us-nav__tab-pane">
                        <Row className={`${allProjectsList.length > 0 ? "g-3 row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-5" : "row"}`}>
                          {allProjectsListLoading ? (
                            <SkeletonLoader />
                          ) : (
                            <>
                              {allProjectsList && allProjectsList.length > 0 ? (
                                <>
                                  {allProjectsList.map((project, index) => {
                                    const {
                                      _id,
                                      title,
                                      phase,
                                      projectStatus,
                                      createdAt,
                                      updatedAt,
                                      realUserType,
                                    } = project;
                                    return (
                                      <Col key={index}>
                                        <ProjectCard
                                          projectId={_id}
                                          projectTitle={title}
                                          projectPhase={phase}
                                          projectStatus={projectStatus}
                                          startedAt={createdAt}
                                          updatedAt={updatedAt}
                                          realUserType={realUserType}
                                          searchQuery={searchQuery}
                                          currentActiveTab={"Completed"}
                                          // progressBar={progressBar}
                                          // progressText={progressText}
                                          // progressPercent={progressPercent}
                                        />
                                      </Col>
                                    );
                                  })}
                                </>
                              ) : (
                                <NoProjects />
                              )}
                            </>
                          )}
                          {/* Pagination */}
                          {allProjectsList.length > 0 &&
                            <div className="d-flex align-items-center justify-content-end mt-10 col-12 col-sm-12 col-md-12 col-xl-12">
                              {allProjectsListLoading ? (
                                <Skeleton
                                  count={1}
                                  width="300px"
                                  height="60px"
                                  baseColor="#E2E2E2"
                                  highlightColor="#E9EAF0"
                                  borderRadius={5}
                                  className="mb-1"
                                  containerClassName="d-flex flex-wrap gap-2"
                                  inline={false}
                                  enableAnimation={true}
                                />
                              ) : (
                                <PaginatedItems
                                  itemsPerPage={
                                    allProjectsListPagination &&
                                    allProjectsListPagination.pageCount
                                  }
                                  handlePageClick={handlePageClick}
                                  pageCount={
                                    allProjectsListPagination &&
                                    Math.ceil(
                                      allProjectsListPagination.totalCount /
                                      allProjectsListPagination.pageCount
                                    )
                                  }
                                  currentPage={
                                    allProjectsListPagination &&
                                    allProjectsListPagination.currentPage
                                  }
                                  totalPages={
                                    allProjectsListPagination &&
                                    allProjectsListPagination.totalPages
                                  }
                                />
                              )}
                            </div>
                          }
                        </Row>
                      </TabPane>

                       {/* Rejected Projects Tab */}
                       <TabPane tabId={4} className="us-nav__tab-pane">
                        <Row className={`${allProjectsList.length > 0 ? "g-3 row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-5" : "row"}`}>
                          {allProjectsListLoading ? (
                            <SkeletonLoader />
                          ) : (
                            <>
                              {allProjectsList && allProjectsList.length > 0 ? (
                                <>
                                  {allProjectsList.map((project, index) => {
                                    const {
                                      _id,
                                      title,
                                      phase,
                                      projectStatus,
                                      createdAt,
                                      updatedAt,
                                      realUserType,
                                    } = project;
                                    return (
                                      <Col key={index}>
                                        <ProjectCard
                                          projectId={_id}
                                          projectTitle={title}
                                          projectPhase={phase}
                                          projectStatus={projectStatus}
                                          startedAt={createdAt}
                                          updatedAt={updatedAt}
                                          realUserType={realUserType}
                                          // progressBar={progressBar}
                                          // progressText={progressText}
                                          // progressPercent={progressPercent}
                                        />
                                      </Col>
                                    );
                                  })}
                                </>
                              ) : (
                                <NoProjects />
                              )}
                            </>
                          )}
                          {/* Pagination */}
                          {allProjectsList.length > 0 &&
                            <div className="d-flex align-items-center justify-content-end mt-10 col-12 col-sm-12 col-md-12 col-xl-12">
                              {allProjectsListLoading ? (
                                <Skeleton
                                  count={1}
                                  width="300px"
                                  height="60px"
                                  baseColor="#E2E2E2"
                                  highlightColor="#E9EAF0"
                                  borderRadius={5}
                                  className="mb-1"
                                  containerClassName="d-flex flex-wrap gap-2"
                                  inline={false}
                                  enableAnimation={true}
                                />
                              ) : (
                                <PaginatedItems
                                  itemsPerPage={
                                    allProjectsListPagination &&
                                    allProjectsListPagination.pageCount
                                  }
                                  handlePageClick={handlePageClick}
                                  pageCount={
                                    allProjectsListPagination &&
                                    Math.ceil(
                                      allProjectsListPagination.totalCount /
                                      allProjectsListPagination.pageCount
                                    )
                                  }
                                  currentPage={
                                    allProjectsListPagination &&
                                    allProjectsListPagination.currentPage
                                  }
                                  totalPages={
                                    allProjectsListPagination &&
                                    allProjectsListPagination.totalPages
                                  }
                                />
                              )}
                            </div>
                          }
                        </Row>
                      </TabPane>
                    </TabContent>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </section>
        {/* <LoadingAnimation/> */}
      </Layout>
    </>
  );
};
export default Projects;
