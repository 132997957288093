import { call, debounce, put, takeEvery } from 'redux-saga/effects'
import instance from "../../../../api/api_instance";

async function getApi(data) {
  console.log("coming inside the KOLokp", data);
  // var formData = new FormData();
  // data.cities.forEach(item => {
  //   formData.append(`cities[]`, item.cityId);
  // });
  // formData.append(`budget`, data.budget);

  try {
    const result = instance({
      url: `v1/project-demographics/${data.projectId}`,
      method: "PATCH",
      data: data.data,
    }).then((response) => {
      return response;
    })
    return await result;
  } catch (error) {
    throw error;
  }
}
function* fetchProjects(action) {
  try {
    const res = yield call(getApi, action.payload);
    const toastrData = {
      content: "Location & Demographics added successfully",
      type: "success"
    }

    yield put({ type: "UPDATE_PROJECT_TAB_THREE_SUCCESS", projectTabOneData: res.data.data.project });
    yield put({ type: "SET_TOASTR_SUCCESS", data: toastrData });
    window.location.href = `/customer/projects/project-preview/${res.data.data.project._id}`;

  } catch (e) {
    if (e.response.status === 400 || e.response.status === 403 || e.response.status === 404 || e.response.status === 409) {
      yield put({ type: "UPDATE_PROJECT_TAB_THREE_FAILED", message: e.response.data.message });
    } else {
      yield put({ type: "UPDATE_PROJECT_TAB_THREE_FAILED", message: "Some error occurred!" });
    }

    if (e.response.status === 500) {
      const toastrFailedData = {
        content: e.response.data.errors ? e.response.data.errors : "Something went wrong!",
        type: "failed"
      };
      yield put({ type: "SET_TOASTER_SUCCESS", data: toastrFailedData });
    }
  }
}

function* updateProjectTabThreeSaga() {
  yield debounce(1000, "UPDATE_PROJECT_TAB_THREE_REQUESTED", fetchProjects);
}

export default updateProjectTabThreeSaga;
