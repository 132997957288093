import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import PageTitle from "../../../components/global/common/pageTitle";
import Layout from "../../../layout";
import ProjectPhaseTab from "../../../components/customer/projectCreationTabs/projectPhaseTab";
import EvaluationDetailsTab from "../../../components/customer/projectCreationTabs/evaluationDetails/evaluationDetailsTab";
import LocationDemographicsTab from "../../../components/customer/projectCreationTabs/locationDemographics/locationDemographicsTab";
import { useDispatch, useSelector } from "react-redux";
import { getProjectDetails , setProjectActiveTab, createProjectTabOne} from "../../../redux/reduxes/customer/projects-new/projectAction";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";

const tabs = [
  { id: 0, tabNumber: "1", tabName: "Project Phase" },
  { id: 1, tabNumber: "2", tabName: "Evaluation Details" },
  { id: 2, tabNumber: "3", tabName: "Location & Demographics" },
];

const CreateProject = ({ }) => {
  const { realUserType, mode } = useParams();
  const search = useLocation().search;
  const project = new URLSearchParams(search).get("project");
  const dispatch = useDispatch();


  // Fetch Data
  const projectActiveTab = useSelector((state) => state.projectNew.projectActiveTab);

  // Set Default Project Active Tab
  useEffect(() => {
    dispatch(setProjectActiveTab(0));
  }, []);

  // Set Default Project
  useEffect(() => {
    const data = {
      projectId: project,
    };
    dispatch(getProjectDetails(data));
  }, [project]);

  // Active Tab Element
  const toggleCreateProjectTabs = (tab) => {
    dispatch(setProjectActiveTab(tab));
  };

  // States
  const [state, setState] = useState({
    project_title: "",

    errors: {
      project_title: "",
    },
  });

  // show/Hide Input
  const [showInputField, setShowInputField] = useState(false);
  const toggleInputField = () => {
    setShowInputField(!showInputField);
  };

  // OnChange Handler
  const onChangeHandler = (event) => {
    const { value, name } = event.target;
    let errors = state.errors;
    switch (name) {
      case "project_title":
        errors.project_title =
          value === "" || value.replace(/\s+/g, "").length == 0
            ? "Enter Project Title"
            : "";
        break;
      default:
        break;
    }
    setState((prevProps) => ({
      ...prevProps,
      errors,
      [name]: value,
    }));
  };

  // Validate Form
  const validateForm = () => {
    let valid = true;
    let errors = state.errors;
    if (state.project_title == "") {
      errors.project_title = "Enter Project Title";
      valid = false;
    }
    setState((prevProps) => ({
      ...prevProps,
      errors: errors,
    }));
    return valid;
  };

  // OnSubmit Handler
  const onSubmitHandler = (event) => {
    event.preventDefault();
    if (validateForm()) {
      const data = {
        title: state.project_title,
      };
      dispatch(createProjectTabOne(data));
    }
  };

  return (
    <>
      <PageTitle pageTitle="Create Project" />
      <Layout>
        {/* CREATE A PROJECT SECTION : START */}
        <section className="us-section us-customer__create-project">
          <div className="us-section__inwrap">
            <Container>
              <Row className="mb-10">
                <Col md="6">
                  <div className="d-flex align-items-center w-100">
                    {showInputField ? (
                      <div
                        className={`us-form__wrap ${
                          showInputField && "animate"
                        }`}
                      >
                        <Form
                          onSubmit={(e) => onSubmitHandler(e)}
                          className="w-100"
                        >
                          <div className="us-form__input-group w-100 position-relative d-flex align-items-center">
                            <Input
                              type="text"
                              id="project_title"
                              name="project_title"
                              placeholder="Project Title"
                              className={`us-form__input ${
                                state.errors.project_title
                                  ? "us-form__input-error"
                                  : ""
                              }`}
                              value={state.project_title}
                              onChange={(e) => onChangeHandler(e)}
                            />
                            <div className="us-form__input-button ps-0 pe-1 position-absolute d-flex align-items-center">
                              <Button
                                type="button"
                                onClick={() => toggleInputField()}
                                color="link"
                                className="us-button__link p-0"
                              >
                                <img
                                  src="/assets/icons/icon-cancel-red.svg"
                                  alt="icon-cancel"
                                  className="img-fluid"
                                  style={{
                                    minWidth: "20px",
                                    minHeight: "20px",
                                    width: "22px",
                                    height: "22px",
                                  }}
                                />
                              </Button>
                              <Button
                                type="submit"
                                color="link"
                                className="us-button__link p-0 ms-3"
                              >
                                <img
                                  src="/assets/icons/icon-tick-circle-green.svg"
                                  alt="icon-tick-circle"
                                  className="img-fluid"
                                  style={{
                                    minWidth: "18px",
                                    minHeight: "18px",
                                    width: "20px",
                                    height: "20px",
                                  }}
                                />
                              </Button>
                            </div>
                          </div>
                          <div className="us-form__error">
                            {state.errors.project_title}
                          </div>
                        </Form>
                      </div>
                    ) : (
                      <>
                        <h1 className="us-section__title">
                          Creating new project
                        </h1>
                        {/* <Button onClick={() => toggleInputField()} color="link" className="us-button__link p-0">
                          <img src="/assets/icons/icon-edit-blue.svg" alt="icon-edit" className="ms-3" />
                        </Button> */}
                      </>
                    )}
                  </div>
                </Col>
                <Col md="6"></Col>
              </Row>

              <Row>
                <Col>
                  <div className="us-nav">
                    {/*  TABS */}
                    <Nav tabs className="us-nav__tabs">
                      {tabs &&
                        tabs.map((tab, index) => {
                          const { id, tabNumber, tabName } = tab;
                          return (
                            <>
                              <NavItem className="us-nav__tab-item" key={index}>
                                <NavLink
                                  className={`us-nav__tab-link d-flex align-items-center ${
                                    projectActiveTab == id ? "active" : ""
                                  }`}
                                  // onClick={() => toggleCreateProjectTabs(id)}
                                >
                                  <span className="us-nav__tab-number d-flex flex-column align-items-center justify-content-center me-2">
                                    {tabNumber}
                                  </span>
                                  <span className="us-nav__tab-title">
                                    {tabName}
                                  </span>
                                </NavLink>
                              </NavItem>
                            </>
                          );
                        })}
                    </Nav>

                    {/* TAB CONTENT */}
                    <TabContent
                      activeTab={projectActiveTab}
                      className="us-nav__tab-content mt-10"
                    >
                      {/* Project Objectives Tab */}
                      <TabPane tabId={0} className="us-nav__tab-pane">
                        <ProjectPhaseTab
                          projectActiveTab={projectActiveTab}
                          realUserType={realUserType}
                        />
                      </TabPane>

                      {/* Evaluation Details Tab */}
                      <TabPane tabId={1} className="us-nav__tab-pane">
                        <EvaluationDetailsTab
                          projectActiveTab={projectActiveTab}
                        />
                      </TabPane>

                      {/* Target Location and Demographics Tab */}
                      <TabPane tabId={2} className="us-nav__tab-pane">
                        <LocationDemographicsTab
                          projectActiveTab={projectActiveTab}
                        />
                      </TabPane>
                    </TabContent>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </section>
      </Layout>
    </>
  );
};
export default CreateProject;
