import React, { useState, useEffect } from "react";
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  FormGroup,
  Form,
  Input,
  Label,
  Button,
} from "reactstrap";
import Icon from "react-eva-icons";
import { useDispatch, useSelector } from "react-redux";
import {
  updateExpertTabTwo,
  getExpertDetailsStepTwo,
  getExpertDetailsStepOne
} from "../../../../redux/reduxes/customer/expertRegistration/action";
import StepTwoTabs from "./stepTwoTabs";

const { v4: uuidv4 } = require("uuid");


const ProfessionalTab = ({
  toggleExpertRegistrationTabs,
  duplicateUXFilter,
  exp,
  duplicateUIFilter,
  duplicateMarketingFilter,
  duplicateBusinessFilter,
  uxInfos,
  uiInfos,
  marketingInfos,
  businessInfos
}) => {
  const dispatch = useDispatch();
  const expertRegistrationActiveTab = useSelector((state) => state.expertRegistration.expertActiveStep);
  const stepTwoData = useSelector((state) => state.expertRegistration.stepTwoData);
  const stepOneData = useSelector((state) => state.expertRegistration.stepOneData);

  // set I'm data
  const [iAm, setIam] = useState([]);
  useEffect(() => {
      const sortedIm = stepOneData && stepOneData.iAm ? stepOneData.iAm : [];
      stepOneData && stepOneData.iAm && setIam(sortedIm);
    }, [stepOneData]);


  // Active Tab Element
  const [currentProfessionalInfoTab, setCurrentProfessionalInfoTab] =useState(1);

  // //UX DESIGN USESTATES
  const [uxDesign, setUXElements] = useState([...uxInfos]);
  const [checkedUXItems, setCheckedUXItems] = useState([]);
  const [inputUXValue, setUXInputValue] = useState("");
  const [uxWarningMessageDuplicates, setUxWarningMessageDuplicates] = useState("");
  // // END

  // //UI DESIGN USESTATES
  const [uiDesign, setUIElements] = useState([...uiInfos]);
  const [checkedUItems, setCheckedUItems] = useState([]);
  const [inputUIValue, setUInputValue] = useState("");
  const [uiWarningMessageDuplicates,setUiWarningMessageDuplicates]=useState("")

  // // END

    // //marketing USESTATES
    const [marketing, setMarketingElements] = useState([...marketingInfos]);
    const [checkedMarketingItems, setCheckedMarketingItems] = useState([]);
    const [inputMarketingValue, setMarketingInputValue] = useState("");
    const [marketingWarningMessageDuplicates, setMarketingWarningMessageDuplicates]=useState("")
    // // END

  // //business USESTATES
  const [business, setBusinessElements] = useState([...businessInfos]);
  const [checkedBusinessItems, setCheckedBusinesItems] = useState([]);
  const [inputBusinessValue, setBusinessInputValue] = useState("");
  const [businessWarningMessageDuplicates, setBusinessWarningMessageDuplicates]=useState("")
  // // END

  // Get page one and two data
  useEffect(() => {
    if (expertRegistrationActiveTab === 1) {
      dispatch(getExpertDetailsStepTwo());
      dispatch(getExpertDetailsStepOne());
    }
  }, [expertRegistrationActiveTab]);

  // Sync to state on update
  useEffect(() => {
    stepTwoData && stepTwoData.uxDesignScope && setCheckedUXItems(stepTwoData.uxDesignScope);
    stepTwoData && stepTwoData.uiDesignScope && setCheckedUItems(stepTwoData.uiDesignScope);
    stepTwoData && stepTwoData.marketingExpert && setCheckedMarketingItems(stepTwoData.marketingExpert);
    stepTwoData && stepTwoData.businessConsultant && setCheckedBusinesItems(stepTwoData.businessConsultant);
  }, [stepTwoData]);

  // //EXPERIMENTAL
  useEffect(() => {
    const defaultUXinfos = uxInfos&&uxInfos.map(item => (item.id));
    const defaultUIinfos = uiInfos && uiInfos.map(item => (item.id));
    const defaultMarketinginfos = marketingInfos&&marketingInfos.map(item => (item.id));
    const defaultBusinessinfos = businessInfos&&businessInfos.map(item => (item.id));
      // checked ux items
    //UX

    if (stepTwoData&& stepTwoData.uxDesignScope) {
      const inUXinfo = stepTwoData.uxDesignScope
      const inUIinfo = stepTwoData.uiDesignScope
      const inMarketinginfo = stepTwoData.marketingExpert
      const inBusinessinfo = stepTwoData.businessConsultant

      if (inUXinfo) {
        const uniqueElements = inUXinfo.filter((item) => !defaultUXinfos.includes(item));

        uniqueElements.forEach((i) => { if (!duplicateUXFilter.has(i.toLowerCase().replace(/\s/g, ''))) { uxDesign.push({ id: i, title: i }); duplicateUXFilter.set(i.toLowerCase().replace(/\s/g, ''), uuidv4()); } });
        //
        // uxDesign
      }
      if (inUIinfo) {
        const uniqueElements = inUIinfo.filter((item) => !defaultUIinfos.includes(item));
        uniqueElements.forEach((i) => {
          if (!duplicateUIFilter.has(i.toLowerCase().replace(/\s/g, ''))) {
            uiDesign.push({ id: i, title: i });
            duplicateUIFilter.set(i.toLowerCase().replace(/\s/g, ''), uuidv4());
          }
        });
        //
        // uxDesign
      }
      if (inMarketinginfo) {
        // console.log(defaultMarketinginfos, "coming inside inMarketinginfo");
        const uniqueElements = inMarketinginfo.filter((item) => !defaultMarketinginfos.includes(item));
        // console.log(uniqueElements, "uniqueElements coming inside this LPLPLPLPLPl");
        uniqueElements.forEach((i) => {
          if (!duplicateMarketingFilter.has(i.toLowerCase().replace(/\s/g, ''))) {
            marketing.push({ id: i, title: i });
            duplicateMarketingFilter.set(i.toLowerCase().replace(/\s/g, ''), uuidv4());
          }
        });
        //
        // uxDesign
      }
      if (inBusinessinfo) {
        const uniqueElements = inBusinessinfo.filter((item) => !defaultBusinessinfos.includes(item));
        uniqueElements.forEach((i) => {
          if (!duplicateBusinessFilter.has(i.toLowerCase().replace(/\s/g, ''))) {
            business.push({ id: i, title: i });
            duplicateBusinessFilter.set(i.toLowerCase().replace(/\s/g, ''), uuidv4());
          }
        });
        //
        // uxDesign
      }
    }
  }, [stepTwoData]);

  const test2 = (e) => {
    e.preventDefault();
  };
  return (
    <>
      <div className="us-nav us-form__wrap-card">
        <Form onSubmit={test2}>
          {/*   SUB TABS */}
          <Nav tabs className="us-nav__tabs">
            {iAm.map((data, index) => {
              return (
                <NavItem className="us-nav__tab-item">
                <NavLink
                  className={`us-nav__tab-link d-flex align-items-center ${
                    currentProfessionalInfoTab == index+1 ? "active" : ""
                  }`}
                  // onClick={() => toggleProfessionalInfoTabs("1")}
                >
                  <span className="us-nav__tab-title">
                  {data}
                  </span>
                </NavLink>
              </NavItem>
  )
            })}
          </Nav>

          {/* TAB CONTENT */}
          <TabContent
            activeTab={currentProfessionalInfoTab}
            className="us-nav__tab-content mt-10"
          >
            {/* Professional Info Tabs */}
            {iAm.map((data, index) => {
              return(
                <StepTwoTabs
                  iAm={iAm}
                  type={data}
                  index={index}
                  lastTab={stepOneData.iAm.length === index + 1}
                  duplicateUXFilter={duplicateUXFilter}
                  duplicateUIFilter={duplicateUIFilter}
                  duplicateMarketingFilter={duplicateMarketingFilter}
                  duplicateBusinessFilter={duplicateBusinessFilter}

                  toggleExpertRegistrationTabs={toggleExpertRegistrationTabs}
                  setCurrentProfessionalInfoTab={setCurrentProfessionalInfoTab}
                  currentProfessionalInfoTab={currentProfessionalInfoTab}
                  // uxInfos={uxInfos}
                  // uiInfos={uiInfos}
                  // marketingInfos={marketingInfos}
                  // businessInfos={businessInfos}

                  uxDesign= {uxDesign}
                  checkedUXItems= {checkedUXItems}
                  inputUXValue= {inputUXValue}
                  uxWarningMessageDuplicates={uxWarningMessageDuplicates}
                  setUXElements={setUXElements}
                  setCheckedUXItems={setCheckedUXItems}
                  setUXInputValue={setUXInputValue}
                  setUxWarningMessageDuplicates={setUxWarningMessageDuplicates}
                  uiDesign={uiDesign}
                  checkedUItems={checkedUItems}
                  inputUIValue={inputUIValue}
                  uiWarningMessageDuplicates={uiWarningMessageDuplicates}
                  setUIElements={setUIElements}
                  setCheckedUItems={setCheckedUItems}
                  setUInputValue={setUInputValue}
                  setUiWarningMessageDuplicates={setUiWarningMessageDuplicates}
                  business={business}
                  checkedBusinessItems={checkedBusinessItems}
                  inputBusinessValue={inputBusinessValue}
                  businessWarningMessageDuplicates={businessWarningMessageDuplicates}
                  setBusinessElements={setBusinessElements}
                  setCheckedBusinesItems={setCheckedBusinesItems}
                  setBusinessInputValue={setBusinessInputValue}
                  setBusinessWarningMessageDuplicates={setBusinessWarningMessageDuplicates}
                  marketing={marketing}
                  checkedMarketingItems={checkedMarketingItems}
                  inputMarketingValue={inputMarketingValue}
                  marketingWarningMessageDuplicates={marketingWarningMessageDuplicates}
                  setMarketingElements={setMarketingElements}
                  setCheckedMarketingItems={setCheckedMarketingItems}
                  setMarketingInputValue={setMarketingInputValue}
                  setMarketingWarningMessageDuplicates={setMarketingWarningMessageDuplicates}
                />
              )
             })
            }
          </TabContent>
        </Form>
      </div>
    </>
  );
};
export default ProfessionalTab;
