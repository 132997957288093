const checkEnvironment=(environment)=> {
  let baseUrl = "";
  switch(environment) {
    case "local":
      baseUrl = process.env.REACT_APP_API_URL_LOCAL;
      break;
      case "development":
        baseUrl = process.env.REACT_APP_API_URL_DEVELOPMENT;
      break;
      case "staging":
        baseUrl = process.env.REACT_APP_API_URL_STAGING;
      break;
      case "production":
        baseUrl = process.env.REACT_APP_API_URL_PRODUCTION;
        break;
    default:
      baseUrl = process.env.REACT_APP_API_URL_LOCAL;
  }
  return baseUrl;
}
const baseUrl = checkEnvironment(process.env.REACT_APP_ENVIRONMENT);

export const linkedInAuth = () => {
  window.open(`${baseUrl}v1/auth/linkedin/registration`, "_self");
  console.log(`${baseUrl}v1/auth/linkedin/registration`, "_self");
};

export const linkLinkedInRedirect = () => {
  // console.log(localStorage.getItem('token'),"localStorage.getItem('token)")
  window.open(`${baseUrl}v1/auth/linkedin/link?token=${localStorage.getItem('token')}`, "_self");
}
// export const linkLinkedInRedirect = () => {
//   window.open(`${baseUrl}v1/auth/linkedin/callback?token=${localStorage.getItem('token')}`, "_self");
// }