import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Button, Col, FormGroup } from "reactstrap";
import {
  getAllCountriesList,
  getAllStatesList,
  getAllCitiesList,
  clearAllStateCities,
} from "../../../../redux/reduxes/customer/general/actions";
import { useDispatch, useSelector } from "react-redux";

const SelectLocation = ({ handleLocationData, existingLocations, removeLocationFromList }) => {
  const dispatch = useDispatch();
  // Fetch Data
  const allCountriesList = useSelector(
    (state) => state.general.allCountriesList
  );
  const allStatesList = useSelector((state) => state.general.allStatesList);
  const allCitiesList = useSelector((state) => state.general.allCitiesList);

  // Get Countries, States and Cities List
  useEffect(() => {
    dispatch(getAllCountriesList());
  }, []);

  // Get the Countries List and set to New State
  const [searchCountry, setSearchCountry] = useState({});
  useEffect(() => {
    const countryNames = [];
    allCountriesList &&
      allCountriesList.length > 0 &&
      allCountriesList.forEach((items) => {
        countryNames.push({ label: items.label, value: items.value });
      });
    setSearchCountry([...countryNames]);
  }, [allCountriesList]);

  // Get the States List and set to New State
  const [searchStates, setSearchStates] = useState({});
  useEffect(() => {
    setSearchStates(allStatesList);
  }, [allStatesList]);

  // Get the Cities List and set to New State
  const [searchCities, setSearchCities] = useState({});
  useEffect(() => {
    allCitiesList.length > 0 && setSearchCities(allCitiesList);
  }, [allCitiesList]);

  // onChange Country Selection
  const [countryValue, setCountryValue] = useState("");
  const [selectedCountryId, setSelectedCountryId] = useState(null);

  // onChange States Selection
  const [stateValue, setStateValue] = useState("");
  const [selectedStateId, setSelectedStateId] = useState(null);

  // onChange City Selection and Add to List
  const [cityValue, setCityValue] = useState("");
  const [locationList, setLocationList] = useState([]);
  const [cityWarningMessage, setcityWarningMessage] = useState(null);
  const [cityError, setCityError] = useState("");

  const [selectedStates, setSelectedStates] = useState("");
  const [selectedCities, setSelectedCities] = useState(null);
  const [selectedCityId, setSelectedCityId] = useState(null);

  const onSelectCountryHandler = (obj) => {
    setCityError("");
    setCountryValue(obj);
    setSelectedCountryId(obj.value);
    const stateNames = [];
    allStatesList &&
      allStatesList.length > 0 &&
      allStatesList.forEach((items) => {
        stateNames.push({ label: items.label, value: items.value });
      });
    setSelectedStates([...stateNames]);
    const data = {
      countryId: obj.value,
    };
    dispatch(getAllStatesList(data));
    setStateValue("");
    setCityValue("");
    // setLocationList([]);
  };

  const onSelectStatesHandler = (obj) => {

    setCityError("");
    setStateValue(obj);
    setSelectedStateId(obj.value);

    const cityNames = [];
    allCitiesList &&
      allCitiesList.length > 0 &&
      allCitiesList.forEach((items) => {
        cityNames.push({ label: items.label, value: items.label });
      });
    setSelectedCities([...cityNames]);
    setSearchCities([obj]);
    const data = {
      countryId: selectedCountryId,
      stateId: obj.value,
    };
    dispatch(getAllCitiesList(data));
    setCityValue("");
    // setLocationList([]);
  };

  const onChangeCityHandler = (obj) => {
    setCityError("");
    console.log(obj._id, "the location obj", locationList.cityId, "hhh", existingLocations.city_id);
    const allCityIds =[...locationList.map(obj => String(obj.cityId)), ...existingLocations.map(obj => String(obj.city_id))]
    if (allCityIds.includes(obj._id)) {
      setCityError("Select a different location.");
    } else {
      setCityValue(obj);
      setLocationList([...locationList,
        {
          countryName: countryValue.label,
          stateName: stateValue.label,
          cityName: obj.label,
          cityId: obj.value,
          stateAsCity: selectedStateId === obj.value,
        },
      ]);
    }

  };

  const confirmDeleteLocation = (i) => {
    let arr = locationList.splice(i, 1)
    let arr1 = locationList.slice()
    setLocationList(arr1);
  }

  useEffect(() => {
    handleLocationData(locationList);
  }, [locationList]);

  return (
    <>
      <FormGroup className="row g-2 us-form__wrap">
        <Col md="4">
          <Select
            id="country"
            name="country"
            placeholder="Select Country"
            classNamePrefix="select"
            className="us-form__input-searchSelect"
            value={countryValue}
            options={searchCountry}
            onChange={(e) => onSelectCountryHandler(e)}
          />
        </Col>
        <Col md="4">
          <Select
            id="state"
            name="state"
            placeholder="Select State"
            classNamePrefix="select"
            className="us-form__input-searchSelect"
            value={stateValue}
            options={searchStates}
            onChange={(e) => onSelectStatesHandler(e)}
          />
        </Col>
        <Col md="4">
          <Select
            id="city"
            name="city"
            placeholder="Select City"
            classNamePrefix="select"
            className="us-form__input-searchSelect"
            value={cityValue}
            options={searchCities}
            onChange={(e) => onChangeCityHandler(e)}
          />
        </Col>

        {/* Selected States and Cities */}
        {((locationList.length+existingLocations.length > 0)) &&
          <Col sm="12">
            <ul className="us-form__lists d-flex align-items-start flex-wrap w-100 gap-2 mt-3">
              {locationList&&locationList.map((list, index) => {
                const { countryName, stateName, cityName } = list;
                return (
                  <>
                    <li key={index} className="us-form__list w-sm d-flex align-items-center justify-content-between">
                      <div className="us-form__list-title text-xs">
                        <span>{cityName},</span> <br />
                        <span> {stateName},</span> <br />
                        <span>{countryName}</span>
                      </div>
                      <Button onClick={() => confirmDeleteLocation(index)} type="button" color="link" className="us-button__link p-0 ms-3">
                        <img src="/assets/icons/icon-cancel-red.svg" alt="icon-cancel" width="16" height="16" />
                      </Button>
                    </li>
                  </>
                )
              })}
              {existingLocations.map((list, index) => {
                const { country, state, city, city_id } = list;
                return (
                  <>
                    <li key={index} className="us-form__list w-sm d-flex align-items-center justify-content-between">
                      <div className="us-form__list-title text-xs">
                        <span>{city}</span> <br />
                        <span> {state},</span> <br />
                        <span>{country}</span>
                      </div>
                      <Button onClick={() => removeLocationFromList(city_id)} type="button" color="link" className="us-button__link p-0 ms-3">
                        <img src="/assets/icons/icon-cancel-red.svg" alt="icon-cancel" width="16" height="16" />
                      </Button>
                    </li>
                  </>
                )
              })}

            </ul>
          </Col>
          // :
          // <div className="us-form__error">Select atleast one Location</div>
        }
        {cityError && cityError!="" && <div className="us-form__error">{cityError}</div>}
      </FormGroup>
    </>
  );
};
export default SelectLocation;
