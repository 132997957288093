import { call, put, takeEvery } from 'redux-saga/effects'
import instance from "../../../../api/api_instance";

async function getApi(data) {
  // console.log(data, "THe data coming inside ghbjnjbhfbcjnkjzdxj");
  try {
    // console.log(data.projectId, "momomoma MOGUDR");
    const result = instance({
      url: `v1/project/${data.projectId}/evaluation-material-step-one`,
      method: "POST",
      data: data.data,
    }).then((response) => {
      return response;
    })
    return await result;
  } catch (error) {
    throw error;
  }
}
function* fetchProjects(action) {
  try {
    const res = yield call(getApi, action.payload);
    const toastrData = {
      content: "Evaluation Material Step One added successfully",
      type: "success"
    }
    const evaluationResData = yield put({ type: "CREATE_EVALUATION_STEP_ONE_SUCCESS", evaluationMaterialData: res.data.data });
    yield put({ type: "SET_TOASTR_SUCCESS", data: toastrData });
    localStorage.setItem("evaluationId", evaluationResData.evaluationMaterialData.evaluationMaterial._id);
  } catch (e) {
    if (e.response.status === 400 || e.response.status === 403 || e.response.status === 404 || e.response.status === 409) {
      yield put({ type: "CREATE_EVALUATION_STEP_ONE_FAILED", message: e.response.data.message });
    } else {
      yield put({ type: "CREATE_EVALUATION_STEP_ONE_FAILED", message: "Some error occurred!" });
    }

    if (e.response.status === 500) {
      const toastrFailedData = {
        content: e.response.data.errors ? e.response.data.errors : "Something went wrong!",
        type: "failed"
      };
      yield put({ type: "SET_TOASTER_SUCCESS", data: toastrFailedData });
    }
  }
}

function* createEvaluationStepOneSaga() {
  yield takeEvery("CREATE_EVALUATION_STEP_ONE_REQUESTED", fetchProjects);
}

export default createEvaluationStepOneSaga;
