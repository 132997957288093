import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { getProfileDetails, setUpdateFullNameModal, updateProfileFullName } from "../../../redux/reduxes/customer/profile/actions";


const UpdateFullNameModal = ({ setShowUpdateFullNameModal }) => {
  const dispatch = useDispatch();

  // Fetch Data
  const firstName = useSelector(state => state.profile.firstName);
  const lastName = useSelector(state => state.profile.lastName);
  const updateFullNameLoading = useSelector(state => state.profile.updateFullNameLoading);

  useEffect(() => {
    dispatch(getProfileDetails());
  }, [])

  // States
  const [state, setState] = useState({
    first_name: "",
    last_name: "",

    errors: {
      first_name: "",
      last_name: "",
    }
  });

  // Get the User Details
  useEffect(() => {
    firstName && lastName && setState((prevProps) => ({
      ...prevProps,
      first_name: firstName,
      last_name: lastName,
    }));
  }, [firstName, lastName]);

  // onChangeHandler
  const onChangeHandler = (event) => {
    const { name, value } = event.target;
    let errors = state.errors;
    switch (name) {
      case 'first_name':
        errors.first_name = value === "" || value.replace(/\s+/g, "").length == 0 ? "Enter First Name" : value.replace(/\s+/g, "").length >=29? "Should not exceed 30 characters!" : ""
        break;
      case 'last_name':
        errors.last_name = value === "" || value.replace(/\s+/g, "").length == 0 ? "Enter Last Name" : ""
        break;
      default:
        break;
    }
    setState((prevProps) => ({
      ...prevProps,
      [name]: value,
    }));
  }

  // Validate Form
  const validateForm = () => {
    let valid = true;
    let errors = state.errors;
    if (state.first_name == "") {
      errors.first_name = "First Name is required";
      valid = false;
    }
    if (state.last_name == "") {
      errors.last_name = "Last Name is required";
      valid = false;
    }
    setState((prevProps) => ({
      ...prevProps,
      errors: errors,
    }));
    return valid;
  }

  // onSubmit Handler
  const onSubmitHandler = async (event) => {
    event.preventDefault();
    if (validateForm()) {
      const data = {
        firstName: state.first_name.replace(/\s+/g, "").trim(),
        lastName: state.last_name.replace(/\s+/g, "").trim(),
      }
      dispatch(updateProfileFullName(data));
      dispatch(setUpdateFullNameModal());
    }
  }

  return (
    <>
      <Modal size="md" isOpen={setShowUpdateFullNameModal}  className="us-modal__dialog">
        <ModalBody className="us-modal__body">
          <h4 className="us-section__title-mini text-center mb-8 mb-md-12">Update Full Name</h4>
          <Form onSubmit={(e) => onSubmitHandler(e)}>
            <Row>
              <Col md="6">
                <FormGroup className="us-form__wrap mb-6">
                  <Label htmlFor="first_name" className="us-form__label">First Name <span className="text-danger">*</span></Label>
                  <Input
                    type="text"
                    id="first_name"
                    name="first_name"
                    placeholder="First Name"
                    className={`us-form__input ${state.errors.first_name ? "us-form__input-error" : ""}`}
                    value={state.first_name}
                    maxLength={30}
                    onChange={(e) => onChangeHandler(e)}
                  />
                  <div className="us-form__error">{state.errors.first_name}</div>
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup className="us-form__wrap">
                  <Label htmlFor="last_name" className="us-form__label">Last Name <span className="text-danger">*</span></Label>
                  <Input
                    type="text"
                    id="last_name"
                    name="last_name"
                    placeholder="Last Name"
                    className={`us-form__input ${state.errors.last_name ? "us-form__input-error" : ""}`}
                    value={state.last_name}
                    onChange={(e) => onChangeHandler(e)}
                  />
                  <div className="us-form__error">{state.errors.last_name}</div>
                </FormGroup>
              </Col>
            </Row>

            <div className="d-flex align-items-center justify-content-center mt-10">
              <Button onClick={(e) => dispatch(setUpdateFullNameModal(false))} type="button" color="danger" outline className="us-button us-button__danger-outline py-3 px-8 me-4">Cancel</Button>
              <Button
                type="submit"
                color="primary"
                disabled={updateFullNameLoading}
                className={`us-button us-button__primary px-8 py-3 ${updateFullNameLoading ? "us-button__disabled" : "us-button__primary"}`}
              >
                {updateFullNameLoading ? "Updating..." : "Update"}
              </Button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </>
  )
}
export default UpdateFullNameModal;