import React, { useEffect, useState } from 'react'
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  FormGroup,
  Form,
  Input,
  Label,
  Button,
} from "reactstrap";
import Icon from "react-eva-icons";
import { useDispatch, useSelector } from "react-redux";
import {
  updateExpertTabTwo
} from "../../../../redux/reduxes/customer/expertRegistration/action";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import SkeletonLoader from '../../SkeletonLoader';


const StepTwoTabs = ({
  iAm,
  type,
  index,
   lastTab,
  duplicateUXFilter,
  duplicateUIFilter,
  duplicateMarketingFilter,
  duplicateBusinessFilter,

  toggleExpertRegistrationTabs,
  currentProfessionalInfoTab,
  setCurrentProfessionalInfoTab,

  uxDesign,
  checkedUXItems,
  inputUXValue,
  uxWarningMessageDuplicates,
  setUXElements,
  setCheckedUXItems,
  setUXInputValue,
  setUxWarningMessageDuplicates,
  uiDesign,
  checkedUItems,
  inputUIValue,
  uiWarningMessageDuplicates,
  setUIElements,
  setCheckedUItems,
  setUInputValue,
  setUiWarningMessageDuplicates,
  business,
  checkedBusinessItems,
  inputBusinessValue,
  businessWarningMessageDuplicates,
  setBusinessElements,
  setCheckedBusinesItems,
  setBusinessInputValue,
  setBusinessWarningMessageDuplicates,
  marketing,
  checkedMarketingItems,
  inputMarketingValue,
  marketingWarningMessageDuplicates,
  setMarketingElements,
  setCheckedMarketingItems,
  setMarketingInputValue,
  setMarketingWarningMessageDuplicates,
}) => {
  const { v4: uuidv4 } = require("uuid");
  const dispatch = useDispatch();

  // STATE VALUES
  const updateExpertStepTwoLoading = useSelector((state) => state.expertRegistration.updateExpertStepTwoLoading)
  const stepTwoData = useSelector((state) => state.expertRegistration.stepTwoData);

    // EXPERIMENTAL
    const [message, setMessage] = useState(null);
    const [loading, setLoading] = useState(false);
    //END

    // HANDLE UX CHANGE!!
  const handleCheckboxChange = (event) => {
      setMessage(null);
      const value = event.target.id;
    const isChecked = event.target.checked;
    setCheckedUXItems((prevCheckedItems) => {
        if (isChecked) {
          return [...prevCheckedItems, value];
        } else {
          return prevCheckedItems.filter((item) => item !== value);
        }
      });
    };
  // END

    // HANDLE UI CHANGE!!
    const handleUICheckboxChange = (event) => {
      setMessage(null);
      const value = event.target.id;
      const isChecked = event.target.checked;
      setCheckedUItems((prevCheckedItems) => {
        if (isChecked) {
          return [...prevCheckedItems, value];
        } else {
          return prevCheckedItems.filter((item) => item !== value);
        }
      });
    };
  // END

   // HANDLE Marketing CHANGE!!
  const handleMarketingCheckboxChange = (event) => {
    setMessage(null);
    console.log(checkedMarketingItems)
    const value = event.target.id;
    const isChecked = event.target.checked;
    setCheckedMarketingItems((prevCheckedItems) => {
      if (isChecked) {
        return [...prevCheckedItems, value];
      } else {
        return prevCheckedItems.filter((item) => item !== value);
      }
    });
  };
  // END

  // HANDLE Business CHANGE!!
  const handleBusinessCheckboxChange = (event) => {
    setMessage(null);
    const value = event.target.id;
    const isChecked = event.target.checked;
    setCheckedBusinesItems((prevCheckedItems) => {
      if (isChecked) {
        return [...prevCheckedItems, value];
      } else {
        return prevCheckedItems.filter((item) => item !== value);
      }
    });
};
// END


   // UX
   const handleChange = (event) => {
    setUXInputValue(event.target.value);
    setUxWarningMessageDuplicates("")
  };
  // END

  // UI
  const handleUIChange = (event) => {
    setMessage(null);
    setUInputValue(event.target.value);
    setUiWarningMessageDuplicates("")
  };
  // END
    // MARKETING
    const handleMarketingChange = (event) => {
      setMarketingInputValue(event.target.value);
      setBusinessWarningMessageDuplicates("");
    };
    // END

    // BUSINESS
    const handleBusinessChange = (event) => {
      setMessage(null);
      setBusinessInputValue(event.target.value);
      setMarketingWarningMessageDuplicates("");
    };
  // END

    // UX
    const handleSubmit = (event) => {
      event.preventDefault();
      // VALIDATION OF UX INPUT FIELD
      if (
        inputUXValue.trim().length > 2 &&
        inputUXValue.trim().length < 100 &&
        !duplicateUXFilter.has(inputUXValue.toLowerCase().replace(/\s/g, ""))
      ) {
        setUXElements([
          ...uxDesign,
          { id: inputUXValue, title: inputUXValue.trim() },
        ]);
        duplicateUXFilter.set(
          inputUXValue.toLowerCase().replace(/\s/g, ""),
          uuidv4()
        );
      }else{
      if (inputUXValue.trim().length < 3){
        setUxWarningMessageDuplicates("Should be more than 2 characters.")
      }
      else if (inputUXValue.trim().length > 99){
        setUxWarningMessageDuplicates("Shouldn't be more than 100 characters.")
      }
      else if (duplicateUXFilter.has(inputUXValue.toLowerCase().replace(/\s/g, ""))){
        setUxWarningMessageDuplicates("This item is already added.")}
    }
      setUXInputValue("");
      // You can now use the inputValue in your application logic
  };

  // UI
  const handleUISubmit = (event) => {
    event.preventDefault(); // Prevents the default form submission behavior
    // VALIDATION OF UI INPUT FIELD

    if (
      inputUIValue.trim().length > 2 &&
      inputUIValue.trim().length < 100 &&
      !duplicateUIFilter.has(inputUIValue.toLowerCase().replace(/\s/g, ""))
    ) {
      setUIElements([
        ...uiDesign,
        { id: inputUIValue, title: inputUIValue.trim() },
      ]);
      duplicateUIFilter.set(
        inputUIValue.toLowerCase().replace(/\s/g, ""),
        uuidv4()
      );
    }else{
      if (inputUIValue.trim().length < 3){
        setUiWarningMessageDuplicates("Should be more than 2 characters.")
      }
      else if (inputUIValue.trim().length > 99){
        setUiWarningMessageDuplicates("Shouldn't be more than 100 characters.")
      }
      else if (duplicateUIFilter.has(inputUIValue.toLowerCase().replace(/\s/g, ""))){
        setUiWarningMessageDuplicates("This item is already added.")}}
    setUInputValue("");

    // You can now use the inputValue in your application logic
  };


  // MARKETING
  const handleMarketingSubmit = (event) => {
    event.preventDefault(); // Prevents the default form submission behavior
    // VALIDATION OF UI INPUT FIELD

    if (
      inputMarketingValue.trim().length > 2 &&
      inputMarketingValue.trim().length < 100 &&
      !duplicateMarketingFilter.has(inputMarketingValue.toLowerCase().replace(/\s/g, ""))
    ) {
      setMarketingElements([
        ...marketing,
        { id: inputMarketingValue, title: inputMarketingValue.trim() },
      ]);
      duplicateMarketingFilter.set(
        inputMarketingValue.toLowerCase().replace(/\s/g, ""),
        uuidv4()
      );
    }else{
      if (inputMarketingValue.trim().length < 3){
        setMarketingWarningMessageDuplicates("Should be more than 2 characters.")
      }
      else if (inputMarketingValue.trim().length > 99){
        setMarketingWarningMessageDuplicates("Shouldn't be more than 100 characters.")
      }
      else if (duplicateMarketingFilter.has(inputMarketingValue.toLowerCase().replace(/\s/g, ""))){
        setMarketingWarningMessageDuplicates("This item is already added.")
      }
    }

    setMarketingInputValue("");

    // You can now use the inputValue in your application logic
  };

  // UI

// MARKETING
const handleBusinessSubmit = (event) => {
  event.preventDefault(); // Prevents the default form submission behavior
  // VALIDATION OF UI INPUT FIELD

  if (
    inputBusinessValue.trim().length > 2 &&
    inputBusinessValue.trim().length < 100 &&
    !duplicateBusinessFilter.has(inputBusinessValue.toLowerCase().replace(/\s/g, ""))
  ) {
    setBusinessElements([
      ...business,
      { id: inputBusinessValue, title: inputBusinessValue.trim() },
    ]);
    duplicateBusinessFilter.set(
      inputBusinessValue.toLowerCase().replace(/\s/g, ""),
      uuidv4()
    );
  }else{
    if (inputBusinessValue.trim().length < 3){
      setBusinessWarningMessageDuplicates("Should be more than 2 characters.")
    }
    else if (inputBusinessValue.trim().length > 99){
      setBusinessWarningMessageDuplicates("Shouldn't be more than 100 characters.")
    }
    else if (duplicateBusinessFilter.has(inputBusinessValue.toLowerCase().replace(/\s/g, ""))){
      setBusinessWarningMessageDuplicates("This item is already added.")
    }
  }
setBusinessInputValue("");

  // You can now use the inputValue in your application logic
};
  //END

  const handleformSubmition = (event) => {
    // event.preventDefault();
    if (iAm.includes("UX Design & Research Expert") && checkedUXItems.length === 0) {
      setMessage("Select at least one specialty");
    } else if (iAm.includes("UI Design Expert") && checkedUItems.length === 0) {
      setMessage("Select at least one specialty");
    } else if (iAm.includes("Marketing Expert") && checkedMarketingItems.length === 0) {
      setMessage("Select at least one specialty");
    } else if (iAm.includes("Business Consultant") && checkedBusinessItems.length === 0) {
      setMessage("Select at least one specialty");
    } else {
      setMessage(null);
      const data = {
        uxDesignScope: iAm.includes("UX Design & Research Expert") ? checkedUXItems : [],
        uiDesignScope: iAm.includes("UI Design Expert") ? checkedUItems : [],
        // uxDesignScope: [],
        // uiDesignScope: [],
        marketingExpert: iAm.includes("Marketing Expert") ? checkedMarketingItems : [],
        businessConsultant: iAm.includes("Business Consultant") ? checkedBusinessItems : [],
      };
      dispatch(updateExpertTabTwo(data));
    }
  };

  const toggleProfessionalInfoTabs = (tab, direction) => {
    setMessage(null);
    if (lastTab && direction == "next") {
      handleformSubmition();
    } else if (tab == 0 && direction == "back") {
      toggleExpertRegistrationTabs(0);
    } else{
      if (currentProfessionalInfoTab != tab) {
        setCurrentProfessionalInfoTab(tab);
      }
    }
  };

  return (
    <TabPane tabId={index+1} className="us-nav__tab-pane">
      {type == "UX Design & Research Expert" ?
        <>
         <h4 className="text-md text-lg-xl font-bold">
                  What scope are you most comfortable with?
                </h4>
                <p className="text-sm color-gray">
                  Which of these product types do you specialize in?
      </p>
      <FormGroup className="us-form__wrap mb-12 mt-5">
                  <div className="w-100 d-flex align-items-start flex-wrap gap-2 gap-md-3">
                  {updateExpertStepTwoLoading && <>{Array(8).fill(<SkeletonLoader />)}</>}
                    {uxDesign?
                      uxDesign.map((app, index) => {
                        const { id, title } = app;
                        return (
                          <div className=" bg-white" key={index}>
                            <Input
                              type="checkbox"
                              id={title}
                              name="project_phase"
                              className="us-form__custom-checkbox"
                              onChange={handleCheckboxChange}
                              checked={checkedUXItems.includes(title)}
                            // onClick={()=>{return checkedItems.includes(id)}}
                            />
                            <Label
                              htmlFor={title}
                              className="us-form__custom-label  text-start radius-35 py-5 px-4"
                            >
                              <p className="text-md text-nowrap pe-14">{title}</p>
                            </Label>
                          </div>
                        );
                      }):<>{Array(8).fill(<SkeletonLoader />)}</>}
                  </div>
                </FormGroup>
                <FormGroup className="us-form__wrap">
                  <Input
                    type="text"
                    id="portfolio_url"
                    name="portfolio_url"
                    className="us-form__input"
                    placeholder="Others"
                    value={inputUXValue}
                    onChange={handleChange}
                  // onKeyPress={event => event.key === "Enter" && handleChange}
                  />

                  <div className="mt-2 text-end">
                    <Button
                      onClick={handleSubmit}
                      type="button"
                      className="us-button__link text-primary font-medium border-0 bg-transparent"
                    >
                      Add more{" "}
                      <Icon
                        name="plus"
                        size="large" // small, medium, large, xlarge
                        fill="#7F7F7F"
                      />
                    </Button>
                  </div>
                  <p style={{ color: 'red' }}>{uxWarningMessageDuplicates}</p>
                  <div className="mt-8 mt-md-14 text-end">
                    <Button
                      onClick={() => toggleProfessionalInfoTabs(index, "back")}
                      type="button"
                      color="primary"
                      outline
                      className="us-button us-button__primary-outline text-uppercase px-10 me-6"
                    >
                      Back
                    </Button>
                    <Button
                      onClick={() => toggleProfessionalInfoTabs(index+2, "next")}
                      // onClick={() =>dispatch(getTest("0"))}
                      type="button"
                      color="primary"
                      className={`us-button us-button__primary text-uppercase px-10 us-button__primary`}
                    >
                      {"Continue"}
                    </Button>
                  </div>
          </FormGroup>
        </> : type == "UI Design Expert" ?
           <>
            <h4 className="text-md text-lg-xl font-bold">
                  What scope are you most comfortable with?
                </h4>
                <p className="text-sm color-gray">
                  Which of these product types do you specialize in?
                </p>

                <FormGroup className="us-form__wrap mb-12 mt-5">
                  <div className="w-100 d-flex align-items-start flex-wrap gap-2 gap-md-3">
                  {updateExpertStepTwoLoading && <>{Array(8).fill(<SkeletonLoader />)}</>}
                    {uiDesign ?
                      uiDesign.map((app, index) => {
                        const { id, title } = app;
                        return (
                          <div className=" bg-white" key={index}>
                            <Input
                              type="checkbox"
                              id={title}
                              name="project_phase"
                              className="us-form__custom-checkbox"
                              onChange={handleUICheckboxChange}
                              checked={checkedUItems.includes(title)}
                            />
                            <Label
                              htmlFor={title}
                              className="us-form__custom-label  text-start radius-35 py-5 px-4"
                            >
                              <p className="text-md text-nowrap pe-14">{title}</p>
                            </Label>
                          </div>
                        );
                      }):<>{Array(8).fill(<SkeletonLoader />)}</>}
                  </div>
                </FormGroup>
                <FormGroup className="us-form__wrap">
                  <Input
                    type="text"
                    id="portfolio_url"
                    name="portfolio_url"
                    className="us-form__input"
                    placeholder="Others"
                    value={inputUIValue}
                    onChange={handleUIChange}
                  />

                  <div className="mt-2 text-end">
                    <Button
                      type="button"
                      className="us-button__link text-primary font-medium border-0 bg-transparent"
                      onClick={handleUISubmit}
                    >
                      Add more{" "}
                      <Icon
                        name="plus"
                        size="large" // small, medium, large, xlarge
                        fill="#7F7F7F"
                      />
                    </Button>

                  </div>
                  <p style={{ color: 'red' }}>{uiWarningMessageDuplicates}</p>

                  <div className="us-form__error">{message}</div>
                  <div className="mt-8 mt-md-14 text-end">
                    <Button
                      onClick={() => toggleProfessionalInfoTabs(index, "back")}
                      type="button"
                      color="primary"
                      outline
                      className="us-button us-button__primary-outline text-uppercase px-10 me-6"
                    >
                      Back
                    </Button>
                    <Button
                      onClick={(e) => toggleProfessionalInfoTabs(index+2, "next")}
                      type="submit"
                      color="primary"
                      className={`us-button us-button__primary text-uppercase px-10 ${updateExpertStepTwoLoading ? "us-button__disabled" : "us-button__primary"
                        }`}
                    >
                      {updateExpertStepTwoLoading ? "Saving..." : "Continue"}
                    </Button>
                  </div>
                </FormGroup>
          </> : type == "Marketing Expert" ?
             <>
              <h4 className="text-md text-lg-xl font-bold">
                  What scope are you most comfortable with?
                </h4>
                <p className="text-sm color-gray">
                  Which of these product types do you specialize in?
                </p>

                <FormGroup className="us-form__wrap mb-12 mt-5">
                  <div className="w-100 d-flex align-items-start flex-wrap gap-2 gap-md-3">
                    {updateExpertStepTwoLoading && <>{Array(8).fill(<SkeletonLoader />)}</>}
                    {marketing?
                      marketing.map((app, index) => {
                        const { id, title } = app;
                        return (
                          <div className=" bg-white" key={index}>
                            <Input
                              type="checkbox"
                              id={title}
                              name="project_phase"
                              className="us-form__custom-checkbox"
                              onChange={handleMarketingCheckboxChange}
                              checked={checkedMarketingItems.includes(title)}
                            // onClick={()=>{return checkedMarketingItems}}
                            />
                            <Label
                              htmlFor={title}
                              className="us-form__custom-label text-start radius-35 py-5 px-4"
                            >
                              <p className="text-md text-nowrap pe-14">{title}</p>
                            </Label>
                          </div>
                        );
                      }):<>{Array(8).fill(<SkeletonLoader />)}</>}
                  </div>
                </FormGroup>
                <FormGroup className="us-form__wrap">
                  <Input
                    type="text"
                    id="portfolio_url"
                    name="portfolio_url"
                    className="us-form__input"
                    placeholder="Others"
                    value={inputMarketingValue}
                    onChange={handleMarketingChange}
                  // onKeyPress={event => event.key === "Enter" && handleChange}
                  />

                  <div className="mt-2 text-end">
                    <Button
                      onClick={handleMarketingSubmit}
                      type="button"
                      className="us-button__link text-primary font-medium border-0 bg-transparent"
                    >
                      Add more{" "}
                      <Icon
                        name="plus"
                        size="large" // small, medium, large, xlarge
                        fill="#7F7F7F"
                      />
                    </Button>
                </div>
                <p style={{ color: 'red' }}>{marketingWarningMessageDuplicates}</p>
                <div className="us-form__error">{message}</div>
                  <div className="mt-8 mt-md-14 text-end">
                    <Button
                      onClick={() => toggleProfessionalInfoTabs(index, "back")}
                      type="button"
                      color="primary"
                      outline
                      className="us-button us-button__primary-outline text-uppercase px-10 me-6"
                    >
                      Back
                    </Button>
                    <Button
                      onClick={() => toggleProfessionalInfoTabs(index+2, "next")}
                      // onClick={() =>dispatch(getTest("0"))}
                      type="button"
                      color="primary"
                      className={`us-button us-button__primary text-uppercase px-10 us-button__primary`}
                    >
                      {"Continue"}
                    </Button>
                  </div>
                </FormGroup>
            </>: type == "Business Consultant" ?
             <>
                <h4 className="text-md text-lg-xl font-bold">
                  What scope are you most comfortable with?
                </h4>
                <p className="text-sm color-gray">
                  Which of these product types do you specialize in?
                </p>

                <FormGroup className="us-form__wrap mb-12 mt-5">
                  <div className="w-100 d-flex align-items-start flex-wrap gap-2 gap-md-3">
                  {updateExpertStepTwoLoading && <>{Array(8).fill(<SkeletonLoader />)}</>}
                    {business?
                      business.map((app, index) => {
                        const { id, title } = app;
                        return (
                          <div className=" bg-white" key={index}>
                            <Input
                              type="checkbox"
                              id={title}
                              name="project_phase"
                              className="us-form__custom-checkbox"
                              onChange={handleBusinessCheckboxChange}
                              checked={checkedBusinessItems.includes(title)}
                            // onClick={()=>{return checkedItems.includes(id)}}
                            />
                            <Label
                              htmlFor={title}
                              className="us-form__custom-label  text-start radius-35 py-5 px-4"
                            >
                              <p className="text-md text-nowrap pe-14">{title}</p>
                            </Label>
                          </div>
                        );
                      }):<>{Array(8).fill(<SkeletonLoader />)}</>}
                  </div>
                </FormGroup>
                <FormGroup className="us-form__wrap">
                  <Input
                    type="text"
                    id="portfolio_url"
                    name="portfolio_url"
                    className="us-form__input"
                    placeholder="Others"
                    value={inputBusinessValue}
                    onChange={handleBusinessChange}
                  // onKeyPress={event => event.key === "Enter" && handleChange}
                  />

                  <div className="mt-2 text-end">
                    <Button
                      onClick={handleBusinessSubmit}
                      type="button"
                      className="us-button__link text-primary font-medium border-0 bg-transparent"
                    >
                      Add more{" "}
                      <Icon
                        name="plus"
                        size="large" // small, medium, large, xlarge
                        fill="#7F7F7F"
                      />
                    </Button>
                  </div>
                  <p style={{ color: 'red' }}>{businessWarningMessageDuplicates}</p>
                  <div className="us-form__error">{message}</div>
                  <div className="mt-8 mt-md-14 text-end">
                    <Button
                      onClick={() => toggleProfessionalInfoTabs(index, "back")}
                      type="button"
                      color="primary"
                      outline
                      className="us-button us-button__primary-outline text-uppercase px-10 me-6"
                    >
                      Back
                    </Button>
                    <Button
                      onClick={() => toggleProfessionalInfoTabs(index+2, "next")}
                      // onClick={() =>dispatch(getTest("0"))}
                      type="button"
                      color="primary"
                      className={`us-button us-button__primary text-uppercase px-10 us-button__primary`}
                    >
                      {"Continue"}
                    </Button>
                  </div>
                </FormGroup>
            </>:null
      }

    </TabPane>
)
}
export default StepTwoTabs;