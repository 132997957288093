import { Col, Container, Row } from "reactstrap";
import PageTitle from "../components/global/common/pageTitle";
import Layout from "../layout";



const PrivacyPolicy = () => {
  return (
    <>
      <PageTitle pageTitle="Privacy Policy" />

      <Layout>
        {/* Privacy Policy */}
        <section className="us-section us-section__privacy-policy">
          <div className="us-section__inwrap">
            <Container>
              <Row>
                <Col sm="12">
                  <h1 className="us-section__title font-extrabold text-center text-uppercase mb-12">Privacy Policy</h1>

                  {/* Privacy Policy for Experts and Useralia */}
                  <div className="us-privacy__experts-useralia mb-20">
                    <h2 className="us-section__title-mini mb-6">Privacy Policy for Experts and Useralia:</h2>
                    <ol className="us-terms__lists">
                      <div className="mb-5">
                        <div className="us-terms__subtitle font-medium mb-2">Collection of Information:</div>
                        <li className="us-terms__list">
                          Useralia may collect personal information from experts, such as name, contact details, professional background, and payment information.
                          This information is necessary for the registration and verification process, as well as facilitating communication and payments between
                          experts and product owners.
                        </li>
                      </div>

                      <div className="mb-5">
                        <div className="us-terms__subtitle font-medium mb-2">Use of Information:</div>
                        <li className="us-terms__list">The personal information provided by experts may be used for the following purposes: </li>
                        <ul className="us-terms__sublists">
                          <li className="us-terms__sublist">Verifying the identity and qualifications of experts.</li>
                          <li className="us-terms__sublist">Facilitating communication between experts and product owners</li>
                          <li className="us-terms__sublist">Processing payments and financial transactions</li>
                          <li className="us-terms__sublist">Providing customer support and resolving any issues or disputes</li>
                          <li className="us-terms__sublist">Sending important updates, notifications, and marketing communications related to Useralia</li>
                        </ul>
                      </div>

                      <div className="mb-5">
                        <div className="us-terms__subtitle font-medium mb-2">Disclosure of Information:</div>
                        <li className="us-terms__list">Useralia may disclose the personal information of experts in the following circumstances: </li>
                        <ul className="us-terms__sublists">
                          <li className="us-terms__sublist">To product owners or organizations seeking expert services on Useralia, for the purpose of facilitating collaboration and communication</li>
                          <li className="us-terms__sublist">To third-party service providers who assist in the operation and maintenance of Useralia's platform and services, subject to appropriate data protection and confidentiality measures</li>
                          <li className="us-terms__sublist">When required by law, regulation, legal process, or governmental request</li>
                        </ul>
                      </div>

                      <div className="mb-5">
                        <div className="us-terms__subtitle font-medium mb-2">Data Security:</div>
                        <li className="us-terms__list">
                          Useralia takes appropriate measures to protect the personal information of experts. This includes implementing technical and organizational security
                          measures to prevent unauthorized access, disclosure, alteration, or destruction of personal information. However, no data transmission or storage system
                          can guarantee 100% security, and Useralia cannot guarantee the absolute security of personal information.
                        </li>
                      </div>

                      <div className="mb-5">
                        <div className="us-terms__subtitle font-medium mb-2">Retention of Information:</div>
                        <li className="us-terms__list">
                          Useralia retains the personal information of experts for as long as necessary to fulfill the purposes outlined in this privacy policy,
                          unless a longer retention period is required or permitted by law. When personal information is no longer needed, Useralia will securely
                          delete or anonymize it.
                        </li>
                      </div>

                      <div className="mb-5">
                        <div className="us-terms__subtitle font-medium mb-2">Cookies and Tracking Technologies:</div>
                        <li className="us-terms__list">
                          Useralia may use cookies and similar tracking technologies to enhance the user experience and gather information about website usage.
                          These technologies may collect information such as IP addresses, browser type, device information, and website interactions. Experts have
                          the option to disable cookies through their browser settings, although this may impact certain functionalities of Useralia.
                        </li>
                      </div>

                      <div className="mb-5">
                        <div className="us-terms__subtitle font-medium mb-2">Third-Party Websites and Services:</div>
                        <li className="us-terms__list">
                          Useralia's platform may contain links to third-party websites or services. This privacy policy does not apply to such third-party websites
                          or services. Experts are encouraged to review the privacy policies of those websites or services before providing any personal information.
                        </li>
                      </div>

                      <div className="mb-5">
                        <div className="us-terms__subtitle font-medium mb-2">Updates to the Privacy Policy:</div>
                        <li className="us-terms__list">
                          Useralia may update this privacy policy from time to time to reflect changes in its practices or legal requirements. Experts will be notified
                          of any material changes to the privacy policy, and their continued use of Useralia after the changes will signify their acceptance of the
                          updated policy.
                        </li>
                      </div>
                    </ol>
                  </div>

                  {/* Privacy Policy for Product Owners and Useralia */}
                  <div className="us-privacy__owners-useralia mb-20">
                    <h2 className="us-section__title-mini mb-6">Privacy Policy for Product Owners and Useralia</h2>
                    <ol className="us-terms__lists">
                      <div className="mb-5">
                        <div className="us-terms__subtitle font-medium mb-2">Collection of Information:</div>
                        <li className="us-terms__list">
                          Useralia may collect personal information from product owners, such as name, contact details, company information, and payment information.
                          This information is necessary for the registration process, facilitating communication with experts, and processing payments for expert services.
                        </li>
                      </div>

                      <div className="mb-5">
                        <div className="us-terms__subtitle font-medium mb-2">Use of Information:</div>
                        <li className="us-terms__list">The personal information provided by product owners may be used for the following purposes:</li>
                        <ul className="us-terms__sublists">
                          <li className="us-terms__sublist">Verifying the identity and legitimacy of product owners</li>
                          <li className="us-terms__sublist">Facilitating communication between product owners and experts</li>
                          <li className="us-terms__sublist">Processing payments and financial transactions</li>
                          <li className="us-terms__sublist">Providing customer support and resolving any issues or disputes</li>
                          <li className="us-terms__sublist">Sending important updates, notifications, and marketing communications related to Useralia</li>
                        </ul>
                      </div>

                      <div className="mb-5">
                        <div className="us-terms__subtitle font-medium mb-2">Disclosure of Information:</div>
                        <li className="us-terms__list">Useralia may disclose the personal information of product owners in the following circumstances: </li>
                        <ul className="us-terms__sublists">
                          <li className="us-terms__sublist">To experts providing services on Useralia, for the purpose of facilitating collaboration and communication</li>
                          <li className="us-terms__sublist">To third-party service providers who assist in the operation and maintenance of Useralia's platform and services, subject to appropriate data protection and confidentiality measures</li>
                          <li className="us-terms__sublist">When required by law, regulation, legal process, or governmental request</li>
                        </ul>
                      </div>

                      <div className="mb-5">
                        <div className="us-terms__subtitle font-medium mb-2">Data Security:</div>
                        <li className="us-terms__list">
                          Useralia takes reasonable measures to protect the personal information of product owners. This includes implementing technical and
                          organizational security measures to prevent unauthorized access, disclosure, alteration, or destruction of personal information. However,
                          no data transmission or storage system can guarantee 100% security, and Useralia cannot guarantee the absolute security of personal
                          information.
                        </li>
                      </div>

                      <div className="mb-5">
                        <div className="us-terms__subtitle font-medium mb-2">Retention of Information:</div>
                        <li className="us-terms__list">
                          Useralia retains the personal information of product owners for as long as necessary to fulfill the purposes outlined in this privacy policy,
                          unless a longer retention period is required or permitted by law. When personal information is no longer needed, Useralia will securely
                          delete or anonymize it.
                        </li>
                      </div>

                      <div className="mb-5">
                        <div className="us-terms__subtitle font-medium mb-2">Cookies and Tracking Technologies:</div>
                        <li className="us-terms__list">
                          Useralia may use cookies and similar tracking technologies to enhance the user experience and gather information about website usage.
                          These technologies may collect information such as IP addresses, browser type, device information, and website interactions.
                          Product owners have the option to disable cookies through their browser settings, although this may impact certain functionalities of Useralia.
                        </li>
                      </div>

                      <div className="mb-5">
                        <div className="us-terms__subtitle font-medium mb-2">Third-Party Websites and Services:</div>
                        <li className="us-terms__list">
                          Useralia's platform may contain links to third-party websites or services. This privacy policy does not apply to such third-party websites
                          or services. Product owners are encouraged to review the privacy policies of those websites or services before providing any personal information.
                        </li>
                      </div>

                      <div className="mb-5">
                        <div className="us-terms__subtitle font-medium mb-2">Updates to the Privacy Policy:</div>
                        <li className="us-terms__list">
                          Useralia may update this privacy policy from time to time to reflect changes in its practices or legal requirements. Product owners will be
                          notified of any material changes to the privacy policy, and their continued use of Useralia after the changes will signify their acceptance
                          of the updated policy.
                        </li>
                      </div>
                    </ol>
                  </div>

                  {/* Privacy Policy for Experts and Product Owners */}
                  <div className="us-privacy__expert-owners mb-20">
                    <h2 className="us-section__title-mini mb-6">Privacy Policy for Experts and Product Owners</h2>
                    <ol className="us-terms__lists">
                      <div className="mb-5">
                        <div className="us-terms__subtitle font-medium mb-2">Collection of Information:</div>
                        <li className="us-terms__list">
                          Useralia may collect personal information from experts and product owners, such as names, contact details, professional backgrounds,
                          and payment information. This information is necessary for the registration process, facilitating communication and collaboration between
                          experts and product owners, and processing payments.
                        </li>
                      </div>

                      <div className="mb-5">
                        <div className="us-terms__subtitle font-medium mb-2">Use of Information:</div>
                        <li className="us-terms__list">The personal information provided by experts and product owners may be used for the following purposes:</li>
                        <ul className="us-terms__sublists">
                          <li className="us-terms__sublist">Verifying the identities and qualifications of experts and product owners</li>
                          <li className="us-terms__sublist">Facilitating communication and collaboration between experts and product owners</li>
                          <li className="us-terms__sublist">Processing payments and financial transactions</li>
                          <li className="us-terms__sublist">Providing customer support and resolving any issues or disputes</li>
                          <li className="us-terms__sublist">Sending important updates, notifications, and marketing communications related to Useralia</li>
                        </ul>
                      </div>

                      <div className="mb-5">
                        <div className="us-terms__subtitle font-medium mb-2">Disclosure of Information:</div>
                        <li className="us-terms__list">Useralia may disclose the personal information of experts and product owners in the following circumstances:</li>
                        <ul className="us-terms__sublists">
                          <li className="us-terms__sublist">To facilitate communication, collaboration, and project management between experts and product owners</li>
                          <li className="us-terms__sublist">To third-party service providers who assist in the operation and maintenance of Useralia's platform and services, subject to appropriate data protection and confidentiality measures</li>
                          <li className="us-terms__sublist">When required by law, regulation, legal process, or governmental request</li>
                        </ul>
                      </div>

                      <div className="mb-5">
                        <div className="us-terms__subtitle font-medium mb-2">Data Security:</div>
                        <li className="us-terms__list">
                          Useralia takes reasonable measures to protect the personal information of experts and product owners. This includes implementing technical
                          and organizational security measures to prevent unauthorized access, disclosure, alteration, or destruction of personal information. However,
                          no data transmission or storage system can guarantee 100% security, and Useralia cannot guarantee the absolute security of personal
                          information.
                        </li>
                      </div>

                      <div className="mb-5">
                        <div className="us-terms__subtitle font-medium mb-2">Retention of Information:</div>
                        <li className="us-terms__list">
                          Useralia retains the personal information of experts and product owners for as long as necessary to fulfill the purposes outlined in this
                          privacy policy, unless a longer retention period is required or permitted by law. When personal information is no longer needed, Useralia
                          will securely delete or anonymize it.
                        </li>
                      </div>

                      <div className="mb-5">
                        <div className="us-terms__subtitle font-medium mb-2">Cookies and Tracking Technologies:</div>
                        <li className="us-terms__list">
                          Useralia may use cookies and similar tracking technologies to enhance the user experience and gather information about website usage.
                          These technologies may collect information such as IP addresses, browser type, device information, and website interactions. Users have the
                          option to disable cookies through their browser settings, although this may impact certain functionalities of Useralia.
                        </li>
                      </div>

                      <div className="mb-5">
                        <div className="us-terms__subtitle font-medium mb-2">Third-Party Websites and Services:</div>
                        <li className="us-terms__list">
                          Useralia's platform may contain links to third-party websites or services. This privacy policy does not apply to such third-party websites or
                          services. Users are encouraged to review the privacy policies of those websites or services before providing any personal information.
                        </li>
                      </div>

                      <div className="mb-5">
                        <div className="us-terms__subtitle font-medium mb-2">Updates to the Privacy Policy:</div>
                        <li className="us-terms__list">
                          Useralia may update this privacy policy from time to time to reflect changes in its practices or legal requirements. Users will be notified of
                          any material changes to the privacy policy, and their continued use of Useralia after the changes will signify their acceptance of the updated
                          policy.
                        </li>
                      </div>
                    </ol>
                  </div>

                    {/* Privacy Policy for Experts and Real Users */}
                    <div className="us-privacy__expert-users mb-20">
                    <h2 className="us-section__title-mini mb-6">Privacy Policy for Experts and Real Users</h2>
                    <ol className="us-terms__lists">
                      <div className="mb-5">
                        <div className="us-terms__subtitle font-medium mb-2">Collection of Information:</div>
                        <li className="us-terms__list">
                          Useralia may collect personal information from experts and real users, such as names, contact details, and feedback data. This information
                          is necessary for the registration process, facilitating communication between experts and real users, and improving the quality of Useralia's
                          services.
                        </li>
                      </div>

                      <div className="mb-5">
                        <div className="us-terms__subtitle font-medium mb-2">Use of Information:</div>
                        <li className="us-terms__list">The personal information provided by experts and real users may be used for the following purposes:</li>
                        <ul className="us-terms__sublists">
                          <li className="us-terms__sublist">Verifying the identities and qualifications of experts</li>
                          <li className="us-terms__sublist">Facilitating communication and feedback exchange between experts and real users</li>
                          <li className="us-terms__sublist">Analyzing feedback data to improve Useralia's services</li>
                          <li className="us-terms__sublist">Sending important updates, notifications, and marketing communications related to Useralia</li>
                        </ul>
                      </div>

                      <div className="mb-5">
                        <div className="us-terms__subtitle font-medium mb-2">Disclosure of Information:</div>
                        <li className="us-terms__list">Useralia may disclose the personal information of experts and real users in the following circumstances:</li>
                        <ul className="us-terms__sublists">
                          <li className="us-terms__sublist">To facilitate communication and feedback exchange between experts and real users</li>
                          <li className="us-terms__sublist">To third-party service providers who assist in the operation and maintenance of Useralia's platform and services, subject to appropriate data protection and confidentiality measures</li>
                          <li className="us-terms__sublist">When required by law, regulation, legal process, or governmental request</li>
                        </ul>
                      </div>

                      <div className="mb-5">
                        <div className="us-terms__subtitle font-medium mb-2">Data Security:</div>
                        <li className="us-terms__list">
                          Useralia takes reasonable measures to protect the personal information of experts and real users. This includes implementing technical and
                          organizational security measures to prevent unauthorized access, disclosure, alteration, or destruction of personal information. However,
                          no data transmission or storage system can guarantee 100% security, and Useralia cannot guarantee the absolute security of
                          personal information.
                        </li>
                      </div>

                      <div className="mb-5">
                        <div className="us-terms__subtitle font-medium mb-2">Retention of Information:</div>
                        <li className="us-terms__list">
                          Useralia retains the personal information of experts and real users for as long as necessary to fulfill the purposes outlined in this privacy
                          policy, unless a longer retention period is required or permitted by law. When personal information is no longer needed, Useralia will
                          securely delete or anonymize it.
                        </li>
                      </div>

                      <div className="mb-5">
                        <div className="us-terms__subtitle font-medium mb-2">Cookies and Tracking Technologies:</div>
                        <li className="us-terms__list">
                          Useralia may use cookies and similar tracking technologies to enhance the user experience and gather information about website usage.
                          These technologies may collect information such as IP addresses, browser type, device information, and website interactions. Users have
                          the option to disable cookies through their browser settings, although this may impact certain functionalities of Useralia.
                        </li>
                      </div>

                      <div className="mb-5">
                        <div className="us-terms__subtitle font-medium mb-2">Third-Party Websites and Services:</div>
                        <li className="us-terms__list">
                          Useralia's platform may contain links to third-party websites or services. This privacy policy does not apply to such third-party websites
                          or services. Users are encouraged to review the privacy policies of those websites or services before providing any personal information.
                        </li>
                      </div>

                      <div className="mb-5">
                        <div className="us-terms__subtitle font-medium mb-2">Updates to the Privacy Policy:</div>
                        <li className="us-terms__list">
                          Useralia may update this privacy policy from time to time to reflect changes in its practices or legal requirements. Users will be notified of
                          any material changes to the privacy policy, and their continued use of Useralia after the changes will signify their acceptance of the
                          updated policy.
                        </li>
                      </div>
                    </ol>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </section>
      </Layout>
    </>
  )
}
export default PrivacyPolicy;