import { call, put, takeEvery, debounce } from 'redux-saga/effects'
import instance from "../../../../api/api_instance";
import * as types from "./types";

async function getApi(data) {
  try {
    const result = instance({
      url: "v1/profile-details",
      method: "GET",
    }).then((response) => {
      return response;
    })
    return await result;
  } catch (error) {
    throw error;
  }
}

function* fetchProfile(action) {
  try {
    const res = yield call(getApi, action.payload);
    if (res.status === 200) {
      yield put({ type: "GET_PROFILE_DETAILS_SUCCESS", profileDetails: res.data.data });
    } else {
      yield put({ type: "GET_PROFILE_DETAILS_FAILED", message: "" });
    }
  } catch (e) {
    if (e.response.status === 406 || e.response.status === 404 || e.response.status === 403) {
      yield put({ type: "GET_PROFILE_DETAILS_FAILED", message: e.response.data.error });
    }
  }
}

function* userDetailsSaga() {
  yield debounce(2000, types.GET_PROFILE_DETAILS_REQUESTED, fetchProfile);
}

export default userDetailsSaga;
