import { useEffect, useState } from "react";
import { Button, Collapse, Container, DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalBody, NavItem, NavbarToggler, UncontrolledDropdown } from "reactstrap";
import { Link, NavLink, useLocation } from 'react-router-dom';
import { isLogin } from '../utils/index';
import Cookies from "js-cookie";
import DeleteModal from "../components/global/common/deleteModal";
import { useDispatch, useSelector } from "react-redux";
import ToastrSuccess from "../components/global/common/toastrSuccess";
import { stopToastr } from "../redux/reduxes/global/toastr/actions";
import { registerUserStep } from "../redux/reduxes/global/auth/actions";
import { getProfileDetails } from "../redux/reduxes/customer/profile/actions";
import Skeleton from "react-loading-skeleton";


const Header = ({ headerClassName }) => {
  let location = useLocation();
  const dispatch = useDispatch();

  // Fetch Data
  const profileDetailsLoading = useSelector(state => state.profile.profileDetailsLoading);
  const firstName = useSelector(state => state.profile.firstName);
  const lastName = useSelector(state => state.profile.lastName);
  const profilePic = useSelector(state => state.profile.profilePic);
  const email = useSelector(state => state.profile.email);
  const logoutUserLoading = useSelector(state => state.auth.logoutUserLoading);
  const showToastr = useSelector(state => state.toastr.show);

  useEffect(() => {
    isLogin() && dispatch(getProfileDetails());
  }, [dispatch])

  // Show/Hide Header Menu on Mobile
  const [showHeaderMenu, setShowHeaderMenu] = useState(false);
  const toggleHeaderMenu = () => {
    setShowHeaderMenu(!showHeaderMenu)
  }

  // Logout Modal
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const logout = (event) => {
    Cookies.remove("token");
    Cookies.remove("isLogin");
    localStorage.clear();
    window.location.replace("/");
  }

  // Set to Register Step On Register Link
  const onRegisterClick = () => {
    dispatch(registerUserStep(0));
    window.location.href = "/register";
  }

  const profileDetails = useSelector(state => state.profile.profileDetails);

  return (
    <>
      {isLogin() === true ?
        // POST LOGIN
        <header className={`us-header d-flex flex-column justify-content-center position-fixed start-0 top-0 w-100 ${headerClassName}`}>
          <Container fluid={true} className="us-header__inwrap px-4 px-md-6">
            <div className="us-header__row d-flex align-items-center justify-content-lg-between w-100">
              <div className="us-header__left d-flex align-items-center">
                <div className="us-header__brandwrap">
                  <a href="https://useralia.com/">
                    <img src="/assets/logo.svg" alt="useralia-logo" className="img-fluid" />
                  </a>
                </div>

                <Collapse navbar isOpen={showHeaderMenu} className="us-header__collapse d-lg-flex align-items-lg-center ms-md-10">
                  <div className="d-lg-flex align-items-lg-center">
                    {/* <NavItem className="us-header__nav-item">
                      <NavLink to="/" className="us-header__nav-link d-flex align-items-center">
                        <img src="/assets/icons/icon-dashboard-red.svg" alt="icon-dashboard" width="20px" height="20px" className="d-block d-lg-none" />
                        <span className="ms-4 ms-md-0">Dashboard</span>
                      </NavLink>
                    </NavItem> */}

                    <NavItem className="us-header__nav-item">
                      <NavLink to="/customer/projects" className="us-header__nav-link d-flex align-items-center">
                        <img src="/assets/icons/icon-projects-red.svg" alt="icon-projects" width="20px" height="20px" className="d-block d-lg-none" />
                        <span className="ms-4 ms-md-0">Projects</span>
                      </NavLink>
                    </NavItem>

                    {profileDetails?.expertRegistrationStatus==="not-started" && <NavItem className="us-header__nav-item">
                      <NavLink to="/expert-registration" className="us-header__nav-link d-flex align-items-center">
                        <img src="/assets/icons/icon-settings.svg" alt="icon-settings" width="24px" height="24px" className="d-block d-lg-none" />
                        <span className="ms-3 ms-md-0" style={{ whiteSpace: 'nowrap' }}>Register as Expert</span>
                      </NavLink>
                    </NavItem>}

                    <NavItem className="us-header__nav-item">
                      <NavLink to="/customer/account-settings" className="us-header__nav-link d-flex align-items-center">
                        <img src="/assets/icons/icon-settings.svg" alt="icon-settings" width="24px" height="24px" className="d-block d-lg-none" />
                        <span className="ms-3 ms-md-0" style={{ whiteSpace: 'nowrap' }}>Account Settings</span>
                      </NavLink>
                    </NavItem>

                    {/* <NavItem className="us-header__nav-item d-block d-lg-none">
                      <NavLink to="/" className="us-header__nav-link d-flex align-items-center">
                        <img src="/assets/icons/icon-calender-red.svg" alt="icon-calender" width="17px" height="17px" />
                        <span className="ms-5 ms-md-0">Calender</span>
                      </NavLink>
                    </NavItem> */}
                  </div>

                  {/* For Mobile & Tablet Devices */}
                  <div className="w-100 mt-auto">
                    <NavItem className="us-header__nav-item d-block d-lg-none">
                      <Button type="button" color="link" onClick={() => setShowLogoutModal(true)} className="us-header__nav-link d-flex align-items-center p-0">
                        <img src="/assets/icons/icon-logout.svg" alt="icon-logout" width="20px" height="20px" />
                        <span className="ms-3">Sign out</span>
                      </Button>
                    </NavItem>

                    {/* <NavItem className="us-header__nav-item me-0 d-block d-lg-none">
                      <NavLink to="/" className="us-header__nav-link us-button us-button__primary w-100 text-center">Register as an Expert</NavLink>
                    </NavItem> */}
                  </div>
                </Collapse>
              </div>

              <div className="us-header__right d-flex align-items-center ms-auto">
                {/* <NavItem className="us-header__nav-item d-none d-lg-block">
                  <NavLink to="/dashboard" className="us-header__nav-link">Register as an Expert</NavLink>
                </NavItem> */}

                {/* <NavItem className="us-header__nav-item d-none d-lg-block">
                  <NavLink to="/dashboard" className="us-header__nav-link">
                    <img src="/assets/icons/icon-calender.svg" alt="icon-calender" width="20px" height="20px" />
                  </NavLink>
                </NavItem>

                <NavItem className="us-header__nav-item mb-0">
                  <NavLink to="/dashboard" className="us-header__nav-link">
                    <img src="/assets/icons/icon-bell.svg" alt="icon-bell" width="20px" height="20px" />
                  </NavLink>
                </NavItem> */}

                <UncontrolledDropdown className="us-header__dropdown mb-0 me-6 me-md-0">
                  <DropdownToggle className="us-header__dropdown-toggle us-header__dropdown-profile d-flex align-items-center">
                    {profileDetailsLoading ?
                      <Skeleton width="130px" height="30px" baseColor="#E2E2E2" highlightColor="#E9EAF0" borderRadius={0} enableAnimation={true} />
                      :
                      <span className="mt-1">Hi {firstName}!</span>
                    }

                    {profileDetailsLoading ?
                      <Skeleton width="40px" height="40px" circle={true} baseColor="#E2E2E2" highlightColor="#E9EAF0" borderRadius={0} className="ms-2" enableAnimation={true} />
                      :
                      <span className="us-header__dropdown-profileimg">
                        <img src={profilePic} alt={firstName} className="img-fluid d-block" />
                      </span>
                    }
                  </DropdownToggle>
                  <DropdownMenu className="us-header__dropdown-menu us-header__dropdown-profilemenu">
                    <div className="us-header__dropdown-profileinfo d-flex align-items-center">
                      {profileDetailsLoading ?
                        <Skeleton width="40px" height="40px" circle={true} baseColor="#E2E2E2" highlightColor="#E9EAF0" borderRadius={0} enableAnimation={true} />
                        :
                        <div className="us-header__dropdown-profileimg ms-0">
                          <img src={profilePic} alt={firstName} className="img-fluid d-block" />
                        </div>
                      }
                      {profileDetailsLoading ?
                        <Skeleton count={2} width="130px" height="15px" className="ms-2" baseColor="#E2E2E2" highlightColor="#E9EAF0" borderRadius={0} enableAnimation={true} />
                        :
                        <div className="ms-3">
                          <div className="us-header__dropdown-profilename head-font text-xs font-semibold">{firstName} {lastName}</div>
                          <small className="us-header__dropdown-profilemail text-xxs font-medium">{email}</small>
                        </div>
                      }
                    </div>

                    {/* <DropdownItem className="us-header__dropdown-item">
                      <NavLink to="" className="us-header__dropdown-link d-flex align-items-center">
                        <img src="/assets/icons/icon-expert.svg" alt="icon-expert" />
                        <span className="ms-3">Switch to an Expert</span>
                      </NavLink>
                    </DropdownItem> */}
                    <DropdownItem className="us-header__dropdown-item">
                      <NavLink to="/customer/account-settings" exact={true} className="us-header__dropdown-link d-flex align-items-center">
                        <img src="/assets/icons/icon-settings.svg" alt="icon-settings" />
                        <span className="ms-3">Account Settings</span>
                      </NavLink>
                    </DropdownItem>
                    <DropdownItem className="us-header__dropdown-item">
                      <a href="https://useralia.com/get-started/" target="_blank" className="us-header__dropdown-link d-flex align-items-center">
                        <img src="/assets/icons/icon-support.svg" alt="icon-support" />
                        <span className="ms-3">Support</span>
                      </a>
                    </DropdownItem>
                    <DropdownItem onClick={() => setShowLogoutModal(true)} className="us-header__dropdown-link d-flex align-items-center">
                      <img src="/assets/icons/icon-logout.svg" alt="icon-logout" />
                      <span className="ms-3">Sign out</span>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>

                <NavbarToggler onClick={toggleHeaderMenu} className={`us-header__toggler d-block d-lg-none ${showHeaderMenu ? "animate-toggler-front" : "animate-toggler-back"}`}>
                  {showHeaderMenu ?
                    <img src="/assets/icons/icon-close-black.svg" alt="icon-menu" width="24px" height="24px" />
                    :
                    <img src="/assets/icons/icon-menu.svg" alt="icon-close" width="24px" height="24px" />
                  }
                </NavbarToggler>
              </div>
            </div>
          </Container>
        </header>
        :
        // PRE LOGIN
        <header className={`us-header d-flex flex-column justify-content-center position-fixed start-0 top-0 w-100 ${headerClassName}`}>
          <Container fluid={true} className="us-header__inwrap px-6">
            <div className="us-header__row d-flex align-items-center justify-content-between w-100">
              <div className="us-header__left">
                <div className="us-header__brandwrap">
                  <a href="https://useralia.com/">
                    <img src="/assets/logo.svg" alt="useralia-logo" className="img-fluid" />
                  </a>
                </div>
              </div>

              <div className="us-header__right ms-auto">
                <div className="us-header__prelogin">
                  {location.pathname == "/login" ?
                    <div className="us-para head-font d-flex align-items-center">
                      <span className="d-none d-lg-block">Don't have an account?</span>
                      {/* <Link to="/register" exact className="us-button__link text-primary text-sm font-semibold ms-3">Create an account</Link> */}
                      <Button onClick={() => onRegisterClick()} type="button" color="link" className="us-button__link text-primary text-sm font-semibold ms-3 p-0" style={{ textDecoration: "none" }}>Create an account</Button>
                    </div>
                    : (location.pathname === "/register") ?
                      <div className="us-para head-font  d-flex align-items-center">
                        <span className="d-none d-lg-block">Already have an account?</span>
                        <Link to="/login" exact className="us-button__link text-primary text-sm font-semibold ms-3 p-0">Login</Link>
                      </div>
                      :
                      (location.pathname.includes('/analyser'))?
                      <div className="us-para head-font  d-flex align-items-center">
                        <span className="d-none d-lg-block">Already have an account?</span>
                        <Link to="/login" exact className="us-button__link text-primary text-sm font-semibold ms-3 p-0">Login</Link>
                      </div>
                      :
                      (location.pathname === "/reset-password") ?
                        <div className="us-para head-font  d-flex align-items-center">
                          <span className="d-none d-lg-block">Already have an account?</span>
                          <Link to="/login" exact className="us-button__link text-primary text-sm font-semibold ms-3 p-0">Login</Link>
                        </div>
                        :
                        null
                  }
                </div>
              </div>
            </div>
          </Container>
        </header>
      }

      {/* Toastr Message */}
      {showToastr &&
        <ToastrSuccess
          hideToastrAction={stopToastr}
        />
      }

      {/* Logout Modal */}
      <Modal size="md" className="us-modal__dialog" isOpen={showLogoutModal} toggle={() => setShowLogoutModal(!showLogoutModal)}>
        <ModalBody className="us-modal__body text-center">
          <div>
            <h6 className="us-section__title-mini">Are you sure, you want to Logout?</h6>

            <div className="d-flex align-items-center justify-content-center mt-10">
              <Button onClick={() => setShowLogoutModal(false)} color="danger" type="button" className="us-button us-button__danger-outline text-uppercase px-8">Cancel</Button>
              <Button
                color="primary"
                type="button"
                disabled={logoutUserLoading}
                onClick={(e) => logout(e)}
                className={`us-button us-button__primary text-uppercase px-10 ms-5 ${logoutUserLoading ? "us-button__disabled" : "us-button__primary"}`}
              >
                {logoutUserLoading ? "Logging Out..." : "Logout"}
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}
export default Header;