import * as type from "./types";

//set expert reg active tab
export function setRegistrationActiveTab(data) {
    return {
        type: type.SET_ACTIVE_STEP_REQUESTED,
        payload: data,
    }
}

//CREATE EXPERT STEP ONE
export function createExpertTabOne(data) {
    return {
        type: type.CREATE_EXPERT_TAB_ONE_REQUESTED,
        payload: data,
    }
}

// UPDATE EXPERT STEP ONE
export function updateExpertTabOne(data) {
    return{
        type: type.UPDATE_EXPERT_TAB_ONE_REQUESTED,
        payload: data,
    }
}


// UPDATE EXPERT STEP TWO
export function updateExpertTabTwo(data) {
    return{
        type: type.UPDATE_EXPERT_TAB_TWO_REQUESTED,
        payload: data,
    }
}

// UPDATE EXPERT STEP THREE
export function updateExpertTabThree(data) {
    return{
        type: type.UPDATE_EXPERT_TAB_THREE_REQUESTED,
        payload: data,
    }
}
// GET DETAILS STEP ONE
export function getExpertDetailsStepOne(data) {
    return{
        type: type.GET_DETAILS_EXPERT_TAB_ONE_REQUESTED,
        payload: data,
    }
}
// GET DETAILS STEP TWO
export function getExpertDetailsStepTwo(data) {
    return{
        type: type.GET_DETAILS_EXPERT_TAB_TWO_REQUESTED,
        payload: data,
    }
}
// GET DETAILS STEP THREE
export function getExpertDetailsStepThree(data) {
    return{
        type: type.GET_DETAILS_EXPERT_TAB_THREE_REQUESTED,
        payload: data,
    }
}