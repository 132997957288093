import { call, put, takeEvery } from 'redux-saga/effects'
import instance from "../../../../api/api_instance";
import { isLogin } from '../../../../utils';
async function getApi(data) {

  try {
    const result = instance({
      url: `v1/analyzer/screenshot/${data.projectURL}/${data.urlType}`,
      method: "POST",
      data: data.data,
    }).then((response) => {
      return response;
    })
    return await result;
  } catch (error) {
    throw error;
  }
}

function* takeScreenShot(action) {

  try {
    const res = yield call(getApi, action.payload);
    if (res.status == 200) {
      yield put({ type: "GET_SCREENSHOT_SUCCESS", profileDetails: res.data });
      if (isLogin()) {
        setTimeout(function () {
          window.location.href = "/customer/projects";
        }, 1000);
      }
    } else {
      yield put({ type: "GET_SCREENSHOT_FAILED", message: "" });
    }
  } catch (e) {
    if (e.response.status === 406 || e.response.status === 404 || e.response.status === 422) {
      yield put({ type: "GET_SCREENSHOT_FAILED", message: e.response.data.error });
    }
    if (e.response.status === 500 || e.response.status === 403) {
      yield put({ type: "GET_SCREENSHOT_FAILED", message: "This website is not available" });
    }
  }
}

function* screenShotOfProject() {
  yield takeEvery("GET_SCREENSHOT_REQUESTED", takeScreenShot);
}

export default screenShotOfProject;
