import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, ModalBody } from "reactstrap";
import { getAllEvaluationMaterialDetails } from "../../../redux/reduxes/customer/evalutionMaterial/projectAction";


const ProjectEvaluationDetailsModal = ({ viewEvaluationDetailsModal, setViewEvaluationDetailsModal, evaluationId, projectId }) => {
  const dispatch = useDispatch();

  // Fetch Data
  const details = useSelector(state => state.evelutionMaterials.allEvaluationDetails);
  const medias = useSelector(state => state.evelutionMaterials.evaluationMaterialMedias);

  console.log(medias, "cwejhcwj");

  const openNewTab = (e) => {
    // console.log(e,"events")
    window.open(e, '_blank');
  };

  // Get Evaluation Details
  useEffect(() => {
    const data = {
      evaluationId: evaluationId,
      projectId:projectId,
    }
    dispatch(getAllEvaluationMaterialDetails(data));
  }, []);
  console.log(details, "FTFTFTFTFASTYYGVUAHUIAHJS");
  return (
    <>
      <Modal className="us-modal__dialog" size="lg" isOpen={viewEvaluationDetailsModal} toggle={setViewEvaluationDetailsModal}>
        <ModalBody className="us-modal__body us-modal__body-overflow">
          <div>
            <h5 className="us-section__title-block  text-xl text-lg-3xl mb-6">Evaluation Material Details!!</h5>
            <div className="mb-4 bg-light-blue2 rounded p-5">
              <div className="us-section__title-block body-font font-medium text-capitalize text-2xl">{details && details.appName}</div>
              <div className="us-para body-font text-sm font-normal text-capitalize">{details && details.appType}</div>
            </div>
            <div className="bg-light-blue rounded ps-5 pt-5 pe-5 pb-1 mb-5">

            <div className="mb-6 border-bottomm">
              <div className="head-font text-md font-bold mb-1">What industry category does your product belong to?</div>
              <ol className="us-terms__lists">
                {details && details.categories && details.categories.length > 0 && details.categories.map((category, index) => {
                  return (
                    <>
                      <li key={index} className="us-terms__list body-font text-sm font-normal text-capitalize">{category.title}</li>
                    </>
                  )
                })}
              </ol>
            </div>

            <div className="mb-6 border-bottomm">
              <div className="head-font text-md font-bold mb-1">Is your Product Multilingual?</div>
              <ol className="us-terms__lists">
                {details && details.multilinguals && details.multilinguals.length > 0 && details.multilinguals.map((lang, index) => {
                  return (
                    <>
                      <li key={index} className="us-terms__list body-font text-sm font-normal text-capitalize">{lang.language}</li>
                    </>
                  )
                })}
              </ol>
            </div>

            <div className="mb-6 border-bottomm">
              <div className="head-font text-md font-bold mb-1">Does it require accessibility Testing?</div>
                <ol className="us-terms__lists">
                {details && details.accessibilityTesting && details.accessibilityTesting.length > 0 ?
                  <>
                    {details.accessibilityTesting.map((testing, index) => {
                      return (
                        <>
                          <li key={index} className="us-terms__list body-font text-sm font-normal text-capitalize">{testing}</li>
                        </>
                      )
                    })}
                  </>
                  :
                  <div>No Options Found</div>
                }
              </ol>
            </div>

            <div className="mb-6 border-bottomm pb-2">
              <div className="head-font text-md font-bold mb-1">App/Play store/Prototype URL</div>
              {details && details.prototypes && details.prototypes.length > 0 && details.prototypes.map((prototype, index) => {
                return (
                  <div key={index} className={`${index > 0 ? "mb-2" : null} overflow-hidden`} >
                    <div className="us-para body-font text-sm font-normal text-capitalize">{prototype.title}</div>
                    <a href={prototype.url} target="_blank" className="us-para body-font text-sm font-normal mb-3 link-color w-75">{prototype.url}</a>
                  </div>
                )
              })}
            </div>

            <div className="mb-6 border-bottomm pb-2">
              <div className="head-font text-md font-bold mb-1">Specify Test Scenario</div>
              <ol className="us-terms__lists">

                {details.testScenarioNeedHelp? "Assistance required !":details &&  details.testScenarios && details.testScenarios.length > 0 ? details.testScenarios.map((scenario, index) => {
                  return (
                    <>
                      <li className="us-terms__list body-font text-sm font-normal text-capitalize">{scenario}</li>
                    </>
                  )
                }
                  ) :
                    <>
                <span>No Options Found</span>
                  </>
                  }
              </ol>
            </div>

            <div className="">
              <div className="head-font text-md font-bold mb-1">Expectations from this Research</div>
              <ol className="us-terms__lists">

                { details.researchExpectationNeedHelp? "Assistance required !":details && details.researchExpectations && details.researchExpectations.length > 0 ? details.researchExpectations.map((expectation, index) => {
                  return (
                    <>
                      <li className="us-terms__list body-font text-sm font-normal text-capitalize">{expectation}</li>
                    </>
                  )
                }) :
                <span>No Options Found</span>
                }
              </ol>
            </div>
            </div>
            {
              medias.length > 0 &&
              <ul className="us-form__file-lists d-flex align-items-start justify-content-start flex-wrap gap-4 pt-2">
              {medias.map((file, index) => {
                return (
                  <>
                    <li
                      className="us-form__file-list position-relative"
                      key={index}
                    >
                      <div className="us-form__file-listImg">
                        <a href= {file.originalUrl} target="_blank" rel="noreferrer"><img src={file.thumbnailUrl} alt={file._id} /></a>
                      </div>
                      {/* <Button
                        onClick={toggle}
                        type="button"
                        color="link"
                        className="us-form__file-listbtn d-flex flex-column align-items-center justify-content-between position-absolute p-0"
                      >
                        <img
                          src="/assets/icons/icon-cancel-red.svg"
                          alt="icon-cancel"
                          width={18}
                          height={18}
                        />
                      </Button>
                      <Modal
                        isOpen={modal}
                        toggle={toggle}
                        size="md"
                        className="us-modal__dialog"
                      >
                        <ModalBody className="us-modal__body">
                          <h5 className="us-section__title-block mb-3 text-center">
                            Delete Evaluation Material
                          </h5>
                          <p className="text-center">
                            {" "}
                            Are you sure you want to delete this material?
                          </p>
                          <div className="border-0 d-flex justify-content-center mt-6">
                            <Button
                              onClick={toggle}
                              color="danger"
                              type="button"
                              className="us-button us-button__danger-outline text-uppercase px-8"
                            >
                              {" "}
                              NO
                            </Button>
                            <Button
                              color="primary"
                              type="button"
                              className={`us-button us-button__primary text-uppercase px-10 ms-5 us-button__primary`}
                              onClick={() =>
                                handleRemoveMediaFromUploadedList(file._id)
                              }
                            >
                              Yes
                            </Button>
                          </div>
                        </ModalBody>
                      </Modal> */}
                    </li>
                  </>
                );
              })}
            </ul>

            }

            <div className="text-end mt-10">
              <Button onClick={() => setViewEvaluationDetailsModal(false)} type="button" color="primary" outline className="us-button us-button__primary-outline text-uppercase px-10">Close</Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}
export default ProjectEvaluationDetailsModal;