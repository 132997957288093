import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Form, FormGroup, Label, Input } from "reactstrap";
import { getExpertDetails } from "../../../redux/reduxes/customer/profile/actions";
import { useDispatch, useSelector } from "react-redux";
const data = ["UX Design and Research Expert", "UI Design Expert"];

const ExpertRegistrationDetails = () => {
  const dispatch = useDispatch();
  const expertDetails = useSelector(state => state.profile.expertRegistrationDetails);
  // console.log(expertDetails, "expertDetails");
  const [domains, setDomains] = useState([]);

  // Get provider details
  useEffect(() => {
    dispatch(getExpertDetails());
  }, []);

  useEffect(() => {

    expertDetails && expertDetails.yearOfExpertise && expertDetails.yearOfExpertise.forEach(data => {
      // if(data)
      let tempDomain = [];
      for (const key in data) {
        if (data.hasOwnProperty(key) && key !="_id") {
          const value = data[key];
          tempDomain.push(
            {
              name: key == "uxDesignAndResearcher" ? "UX Design & Research Expert" :
                key == "uiDesignExpert" ? "UI Design Expert" :
                  key == "marketingExpert" ? "Marketing Expert" :
                    key == "businessConsultant" ? "Business Consultant" : "",
              value: value
            }
          )
        }
      }
      setDomains(tempDomain);
    })

  }, [expertDetails]);


  return (
    <>
        <Row className="g-4">
        <Col sm="12 text-end">
        <Link to="/expert-registration" exact={true} className="us-button__link text-lg font-bold text-primary p-0 btn btn-link">Edit</Link>
        {/* <button
          type="button"
          className="us-button__link text-lg font-bold text-primary p-0 btn btn-link"
        >
          Edit
        </button> */}
      </Col>
      <Col sm="12">
        <h5 className="text-xl font-bold mb-2">Personal info</h5>
        <div className="us-form__wrap-card">
          <article className="mb-4">
            <div className="mb-4">
              <div className="b-2">
                <p className="text-md font-bold mb-2">I am</p>
              </div>
              <ul className="d-flex flex-wrap gap-3 ">
                {expertDetails&&expertDetails.iAm&&expertDetails.iAm.length>0&&expertDetails.iAm.map((dataItem, index) => {
                  return (
                    <li
                      className="text-sm rounded-5 border px-5 py-2 mb-3"
                      key={index}
                    >
                      {dataItem}
                    </li>
                  );
                })}
                {/* <button
                  type="button"
                  className="us-button__link text-sm text-primary p-0 btn btn-link"
                >
                  +5 View
                </button> */}
              </ul>
            </div>
            <div className="mb-4">
              <FormGroup switch className="d-flex align-items-center">
                <Input type="switch" disabled checked={expertDetails&&expertDetails.marketFit&&expertDetails.marketFit}/>
                <Label check className="ms-2">
                  <div className="text-md font-bold">
                    Evaluate product market fit
                  </div>
                  <div className="text-xs">
                    Validate your product/srvice for market fit
                  </div>
                </Label>
              </FormGroup>
            </div>
            <div className="mb-4 d-flex align-items-center">
              <p className="text-md me-3">Individual</p>
              <FormGroup switch className="d-flex align-items-center">
                <Input type="switch" disabled/>
              </FormGroup>
              <div className="ms-3 opacity-03">
                <p className="text-md">Organization</p>
                <p className="text-xs">This feature is coming soon</p>
              </div>
            </div>
            <div>
              <div className="b-2">
                <p className="text-md font-bold mb-2">App/Website/Portfolio URL</p>
                </div>
                {expertDetails && expertDetails.portfolioUrl && expertDetails.portfolioUrl.length > 0 && expertDetails.portfolioUrl.map(data => {
                  return (
                    <a className="us-form__input w-100 link-color" href={data.url}>{data.url}</a>
                  )
                })}
            </div>
          </article>
        </div>
      </Col>
      <Col sm="12">
        <h5 className="text-xl font-bold mb-2">Professional info</h5>
        <div className="us-form__wrap-card">
          <article className="mb-4">
            <div className="gap-3 mb-2">
            {expertDetails&&expertDetails.iAm&&expertDetails.iAm.length>0&&expertDetails.iAm.map((dataItem, index) => {
        return (
          <>
          <div className="text-md font-bold mb-2" key={index}>
          {dataItem}
          </div>
          {dataItem==='UI Design Expert' && <ul className="d-flex flex-wrap gap-3 mb-4">
          {expertDetails && expertDetails.uiDesignScope&& expertDetails.uiDesignScope.length > 0 && expertDetails.uiDesignScope.map((dataItem, index) => {
            return (
              <li
                className="text-sm rounded-5 border px-5 py-2 mb-3"
                key={index}
              >
                {dataItem}
              </li>
            );
          })}
        </ul>}
          {dataItem==='Marketing Expert' && <ul className="d-flex flex-wrap gap-3 mb-4">
          {expertDetails && expertDetails.marketingExpert&& expertDetails.marketingExpert.length > 0 && expertDetails.marketingExpert.map((dataItem, index) => {
            return (
              <li
                className="text-sm rounded-5 border px-5 py-2 mb-3"
                key={index}>
                {dataItem}
              </li>
            );
          })}
        </ul>}
          {dataItem==='Business Consultant' && <ul className="d-flex flex-wrap gap-3 mb-4">
          {expertDetails && expertDetails.businessConsultant&& expertDetails.businessConsultant.length > 0 && expertDetails.businessConsultant.map((dataItem, index) => {
            return (
              <li
                className="text-sm rounded-5 border px-5 py-2 mb-3"
                key={index}
              >
                {dataItem}
              </li>
            );
          })}
        </ul>}
          {dataItem==='UX Design & Research Expert' && <ul className="d-flex flex-wrap gap-3 mb-4">
          {expertDetails && expertDetails.uxDesignScope&& expertDetails.uxDesignScope.length > 0 && expertDetails.uxDesignScope.map((dataItem, index) => {
            return (
              <li
                className="text-sm rounded-5 border px-5 py-2 mb-3"
                key={index}
              >
                {dataItem}
              </li>
            );
          })}
        </ul>}
        </>
        );
    })}
            </div>

          </article>
          {/* <article className="mb-4">
            <div className="d-flex justify-content-between mb-2">
              <p className="text-md font-bold mb-0">UI Design Expert</p>
            </div>
            <ul className="d-flex flex-wrap gap-3 ">
              {expertDetails && expertDetails.uxDesignScope&& expertDetails.uxDesignScope.length > 0 && expertDetails.uxDesignScope.map((dataItem, index) => {
                return (
                  <li
                    className="text-sm rounded-5 border px-5 py-2 mb-3"
                    key={index}
                  >
                    {dataItem}
                  </li>
                );
              })}
            </ul>
          </article> */}
        </div>
      </Col>
      <Col sm="12">
        <h5 className="text-xl font-bold mb-2">Domain Expertise</h5>
        <div className="us-form__wrap-card">
          <article className="mb-4">
            <div className="d-flex justify-content-between mb-2">
              <p className="text-md font-bold mb-0">Media Expertise</p>

            </div>
            <ul className="d-flex flex-wrap gap-3 ">
              {expertDetails && expertDetails.mediaExperties&& expertDetails.mediaExperties.length > 0 && expertDetails.mediaExperties.map((dataItem, index) => {
                return (
                  <li
                    className="text-sm rounded-5 border px-5 py-2 mb-3"
                    key={index}
                  >
                    {dataItem}
                  </li>
                );
              })}
              {/* <button
                type="button"
                className="us-button__link text-sm text-primary p-0 btn btn-link"
              >
                +5 View
              </button> */}
            </ul>
          </article>
          <article className="mb-4">
            <div className="d-flex justify-content-between mb-2">
              <p className="text-md font-bold mb-0">
                Evaluation methods you are fluent with
              </p>
            </div>
            <ul className="d-flex flex-wrap gap-3 ">
              {expertDetails && expertDetails.expertEvaluation&& expertDetails.expertEvaluation.length > 0 && expertDetails.expertEvaluation.map((dataItem, index) => {
                return (
                  <li
                    className="text-sm rounded-5 border px-5 py-2 mb-3"
                    key={index}
                  >
                    {dataItem}
                  </li>
                );
              })}
              {/* <button
                type="button"
                className="us-button__link text-sm text-primary p-0 btn btn-link"
              >
                +5 View
              </button> */}
            </ul>
          </article>
          <article className="mb-4">
            <div className="d-flex justify-content-between mb-2">
              <p className="text-md font-bold mb-0">Years of Expertise</p>
            </div>
            <ul className="d-flex flex-wrap gap-3 ">
                {domains && domains.length > 0 && domains.map((dataItem, index) => {
                // console.log(dataItem, "dataItem HUHUHUH");
                return (
                  <li
                    className="text-sm rounded-5 border px-5 py-2 mb-3"
                    key={index}
                  >
                    {dataItem.name} : { dataItem.value}
                  </li>
                );
              })}
              {/* <button
                type="button"
                className="us-button__link text-sm text-primary p-0 btn btn-link"
              >
                +5 View
              </button> */}
            </ul>
          </article>
          <article className="mb-4">
            <div className="d-flex justify-content-between mb-2">
              <p className="text-md font-bold mb-0">Specialised geography</p>
            </div>
            <ul className="d-flex flex-wrap gap-3 ">
                {expertDetails && expertDetails.specializedGeography&& expertDetails.specializedGeography.length > 0 && expertDetails.specializedGeography.map((dataItem, index) => {
                const { country, state, city, city_id } = dataItem;
                return (
                  <li
                    className="text-sm rounded-5 border px-5 py-2 mb-3"
                    key={index}
                  >
                    {city ? city : state},
                    <br />
                    {state},
                    <br />
                    {country}
                  </li>
                );
              })}
              {/* <button
                type="button"
                className="us-button__link text-sm text-primary p-0 btn btn-link"
              >
                +5 View
              </button> */}
            </ul>
          </article>
          <article className="mb-4">
            <div className="d-flex justify-content-between mb-2">
              <p className="text-md font-bold mb-0">Industry Expertise</p>
            </div>
            <ul className="d-flex flex-wrap gap-3 ">
              {expertDetails && expertDetails.industryExperties && expertDetails.industryExperties.length > 0 && expertDetails.industryExperties.map((dataItem, index) => {
                return (
                  <li
                    className="text-sm rounded-5 border px-5 py-2 mb-3"
                    key={index}
                  >
                    {dataItem}
                  </li>
                );
              })}
              {/* <button
                type="button"
                className="us-button__link text-sm text-primary p-0 btn btn-link"
              >
                +5 View
              </button> */}
            </ul>
          </article>
        </div>
      </Col>
    </Row >
      {/* more details : End */}
    </>
  );
};
export default ExpertRegistrationDetails;
