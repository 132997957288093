import { call, put, takeEvery } from 'redux-saga/effects'
import instance from "../../../../api/api_instance";

async function getApi(data) {
  // const projectId = localStorage.getItem("projectId");
  try {
    const result = instance({
      url: `v1/project/${data.projectId}/evaluation-materials`,
      method: "GET",
      data: data,
    }).then((response) => {
      return response;
    })
    return await result;
  } catch (error) {
    throw error;
  }
}
function* fetchProjects(action) {
  try {
    const res = yield call(getApi, action.payload);
    console.log(res, "data in saga");
    yield put({ type: "GET_ALL_EVALUATION_MATERIALS_SUCCESS", allEvaluationMaterials: res.data.data });
  } catch (e) {
    // // if (e.response.status === 400 || e.response.status === 403 || e.response.status === 404 || e.response.status === 409) {
    // //   yield put({ type: "GET_ALL_EVALUATION_MATERIALS_FAILED", message: e.response.data.message });
    // // } else {
    // //   yield put({ type: "GET_ALL_EVALUATION_MATERIALS_FAILED", message: "Some error occurred!" });
    // // }

    // if (e.response.status === 500) {
    //   const toastrFailedData = {
    //     content: e.response.data.errors ? e.response.data.errors : "Something went wrong!",
    //     type: "failed"
    //   };
    //   yield put({ type: "SET_TOASTER_SUCCESS", data: toastrFailedData });
    // }
  }
}

function* getAllEvaluationMaterialsSaga() {
  yield takeEvery("GET_ALL_EVALUATION_MATERIALS_REQUESTED", fetchProjects);
}

export default getAllEvaluationMaterialsSaga;
