import { useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import { Button, Row, Col, Form, FormGroup, Input, Label } from "reactstrap";
import { useHistory } from 'react-router-dom';

import Stepper from "react-stepper-horizontal/lib/Stepper";
// import EvaluationMaterialStepOne from "./evaluationMaterialStepOne";
// import EvaluationMaterialStepTwo from "./evaluationMaterialStepTwo";
// import EvaluationMaterialStepThree from "./evaluationMaterialStepThree";
import { useSelector } from "react-redux";

const EvaluationMaterialModal = (setShowEvaluationMaterialModal) => {
  // Fetch Data

  let history = useHistory();

  const handleClick = () => {

    history.push('/'); // Redirect to the '/other' page
    window.location.reload()
  };

  const evaluationMaterialActiveStep = useSelector(
    (state) => state.evelutionMaterials.evaluationMaterialActiveStep
  );

  const details = useSelector(
    (state) => state.projectNew.projectDetails.project
  );
  console.log(details, "okokokooK");
  const projectId = details && details._id;
  console.log(evaluationMaterialActiveStep, "evaluationMaterialActiveStep");

  // States
  // const [activeStep, setActiveStep] = useState(0);
  

  return (
    <>
      
      <Modal size="md" className="us-modal__dialog" isOpen={setShowEvaluationMaterialModal}>
        <ModalBody className="us-modal__body text-center">
          <div>
            <h6 className="us-section__title-mini mb-1">Congratulations !</h6>
            <p className="us-para">Your request has been submitted.</p>

            <div className="mt-14">
              <Button
                color="primary"
                type="button"
                className={`us-button us-button__primary text-uppercase px-10 ms-5 us-button__primary`}
                // onClick={() => dispatchTheFunction()}
                onClick={handleClick}
              >
                {"Continue"}
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};
export default EvaluationMaterialModal;

