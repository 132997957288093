import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Button, Col, FormGroup, Label } from "reactstrap";
import { getAllCitiesList, getAllCountriesList, getAllStatesList, clearAllStateCities } from "../../../../redux/reduxes/customer/general/actions";
import { useDispatch, useSelector } from "react-redux";

const SelectLocation = ({ handleLocationData }) => {
  const dispatch = useDispatch();

  // Fetch Data
  const allCountriesList = useSelector(state => state.general.allCountriesList);
  const allStatesList = useSelector(state => state.general.allStatesList);
  const allCitiesList = useSelector(state => state.general.allCitiesList);

  // Get Countries, States and Cities List
  useEffect(() => {
    dispatch(getAllCountriesList());
    // dispatch(getAllStatesList());
    // dispatch(getAllCitiesList());
  }, []);

  // Get the Countries List and set to New State
  const [searchCountry, setSearchCountry] = useState({});
  useEffect(() => {
    const countryNames = [];
    allCountriesList && allCountriesList.length > 0 && allCountriesList.forEach(items => {
      countryNames.push({ label: items.label, value: items.value });
    });
    setSearchCountry([...countryNames]);
  }, [allCountriesList]);


  // onChange Country Selection
  const [countryValue, setCountryValue] = useState("");
  const [selectedCountryId, setSelectedCountryId] = useState(null);
  const [selectedStates, setSelectedStates] = useState("");

  const onSelectCountryHandler = (obj) => {
    setCountryValue(obj);
    setSelectedCountryId(obj.value);
    const stateNames = [];
    allStatesList && allStatesList.length > 0 && allStatesList.forEach(items => {
      stateNames.push({ label:items.label, value: items.value});
    });
    setSelectedStates([...stateNames]);
    const data = {
      countryId: obj.value,
    }
    dispatch(getAllStatesList(data));
    setStateValue("");
    setcityWarningMessage(null)

  }

  // Get the States List and set to New State
  const [searchStates, setSearchStates] = useState({});
  useEffect(() => {
    setSearchStates(allStatesList);
  }, [allStatesList]);

  // onChange States Selection
  const [stateValue, setStateValue] = useState("");
  const [selectedStateId, setSelectedStateId] = useState(null);
  const [selectedCities, setSelectedCities] = useState(null);

  // Get the Cities List and set to New State
  const [searchCities, setSearchCities] = useState({});
  useEffect(() => {
    allCitiesList.length > 0 && setSearchCities(allCitiesList);
  }, [allCitiesList]);

  const onSelectStatesHandler = (obj) => {
    setStateValue(obj);
    setSelectedStateId(obj.value);

    const cityNames = [];
    allCitiesList && allCitiesList.length > 0 && allCitiesList.forEach(items => {
      cityNames.push({ label: items.label, value: items.label });
    });
    setSearchCities([obj])
    setSelectedCities([...cityNames]);
    const data = {
      countryId: selectedCountryId,
      stateId: obj.value,
    }
    dispatch(getAllCitiesList(data));
  }



  // onChange City Selection and Add to List
  const [cityValue, setCityValue] = useState("");
  const [selectedCityId, setSelectedCityId] = useState(null);
  const [locationList, setLocationList] = useState([]);
  const [cityWarningMessage,setcityWarningMessage]=useState(null)

  const onChangeCityHandler = (obj) => {
    setSelectedCityId(obj.value);

    setLocationList((locationList)=>{
      const cityExists = locationList.some(cityObj => cityObj.cityId === obj.value)
      if (!cityExists){
      return [...locationList, {
        countryName: countryValue.label,
        stateName: stateValue.label,
        cityName: obj.label,
        cityId: obj.value,
        stateAsCity: selectedStateId == obj.value ? true : false
        }]
      }
      // setcityWarningMessage("Cant do that !")
      setcityWarningMessage("Select a different location.")
      
      return locationList});
    setCountryValue("");
    setStateValue("");
    setCityValue("");

    dispatch(clearAllStateCities());
  }

  useEffect(() => {
    handleLocationData(locationList);
  }, [locationList]);

  // Remove Selected Location from List
  const confirmDeleteLocation = (index) => {
    const updatedLocationList = [...locationList];
    updatedLocationList.splice(index, 1);
    setLocationList(updatedLocationList);
  }


  return (
    <>
       <FormGroup className="row g-2 us-form__wrap">
        <Label htmlFor="country" className="us-form__label font-medium mb-2">Specify target audience location <span className="text-danger">*</span></Label>
        <Col md="4">
          <Select
            id="country"
            name="country"
            placeholder="Select Country"
            classNamePrefix="select"
            className="us-form__input-searchSelect"
            value={countryValue}
            options={searchCountry}
            onChange={(e) => onSelectCountryHandler(e)}
          />
        </Col>
        <Col md="4">
          <Select
            id="state"
            name="state"
            placeholder="Select State"
            classNamePrefix="select"
            className="us-form__input-searchSelect"
            value={stateValue}
            options={searchStates}
            onChange={(e) => onSelectStatesHandler(e)}
          />
        </Col>
        <Col md="4">
          <Select
            id="city"
            name="city"
            placeholder="Select City"
            classNamePrefix="select"
            className="us-form__input-searchSelect"
            value={cityValue}
            options={searchCities}
            onChange={(e) => onChangeCityHandler(e)}
          />
        </Col>


        {/* Selected States and Cities */}
        {(locationList && locationList.length > 0) &&
          <Col sm="12">
            <ul className="us-form__lists d-flex align-items-start flex-wrap w-100 gap-2 mt-3">
              {locationList.map((list, index) => {
                const { countryName, stateName, cityName } = list;
                return (
                  <>
                    <li key={index} className="us-form__list w-sm d-flex align-items-center justify-content-between">
                      <div className="us-form__list-title text-xs">
                        <span>{cityName},</span> <br />
                        <span> {stateName},</span> <br />
                        <span>{countryName}</span>
                      </div>
                      <Button onClick={() => confirmDeleteLocation(index)} type="button" color="link" className="us-button__link p-0 ms-3">
                        <img src="/assets/icons/icon-cancel-red.svg" alt="icon-cancel" width="16" height="16" />
                      </Button>
                    </li>
                  </>
                )
              })}
            </ul>
          </Col>
          // :
          // <div className="us-form__error">Select atleast one Location</div>
        }
        <p style={{ color: 'red' }}>{cityWarningMessage}</p>
      </FormGroup>
    </>
  )
}
export default SelectLocation;