import { call, put, takeEvery } from 'redux-saga/effects'
import instance from "../../../../api/api_instance";

async function getApi(data) {
  try {
    const result = instance({
      url: "v1/forgot-password-reset",
      method: "POST",
      data: data,
    }).then((response) => {
      return response;
    })
    return await result;
  } catch (error) {
    throw error;
  }
}
function* fetchAuth(action) {
  try {
    const res = yield call(getApi, action.payload);
    if (res.status == 200) {
      window.location.replace("/login");
    }
    const toastrData = {
      content: "Password changed successfully",
      type: "success"
    }
    yield put({ type: "RESET_FORGOT_PASSWORD_SUCCESS", data: res.data.data });
    yield put({ type: "SET_TOASTR_SUCCESS", data: toastrData });
  } catch (e) {
    if (e.response.status === 400 || e.response.status === 404 || e.response.status === 409) {
      yield put({ type: "RESET_FORGOT_PASSWORD_FAILED", message: e.response.data.message });
    } else {
      yield put({ type: "RESET_FORGOT_PASSWORD_FAILED", message: "Some error occurred!" });
    }
  }
}

function* resetForgotPasswordSaga() {
  yield takeEvery("RESET_FORGOT_PASSWORD_REQUESTED", fetchAuth);
}

export default resetForgotPasswordSaga;
