import { call, put, takeEvery } from "redux-saga/effects";
import instance from "../../../../api/api_instance";

async function getApi(data) {
  try {
    const result = await instance({
      url: `v1/project-details/${data.projectId}`, 
      method: "GET",
    }).then((response) => {
      return response;
    });
    return result;
  } catch (error) {
    console.log(error, "errorerror");
    throw error;
  }
}
function* fetchProjects(action) {
  try {
    const res = yield call(getApi, action.payload);
    console.log(res.data.data, "The res coming inside get  details  success");
    yield put({
      type: "GET_PROJECT_DETAILS_SUCCESS",
      projectDetails: res.data.data,
    });
  } catch (e) {
    console.log(e, "fetchProjects error");
    if (
      e.response.status === 400 ||
      e.response.status === 403 ||
      e.response.status === 404 ||
      e.response.status === 409
    ) {
      yield put({
        type: "GET_PROJECT_DETAILS_FAILED",
        message: e.response.data.message,
      });
    } else {
      yield put({
        type: "GET_PROJECT_DETAILS_FAILED",
        message: "Some error occurred!",
      });
    }
  }
}

function* getProjectDetailsSaga() {
  yield takeEvery("GET_PROJECT_DETAILS_REQUESTED", fetchProjects);
}

export default getProjectDetailsSaga;
