import React from 'react';
import PageTitle from '../../components/global/common/pageTitle';
import Layout from '../../layout';
import { Col, Container, Row } from 'reactstrap';
import {  useDispatch, useSelector } from 'react-redux';
import VerifyOTP from '../../components/global/auth/verifyOTP';
import RegistrationForm from '../../components/global/auth/registrationForm';
import { useEffect } from 'react';
import { getRegisterStep } from '../../redux/reduxes/global/auth/actions';

const Register = () => {
  const dispatch = useDispatch();

  // Fetch Data
  const registerStep = useSelector(state => state.auth.registerStep);
  console.log(registerStep, "registerStep huhuhuhuhuhuhu");

  // useEffect(() => {
  //   dispatch(getRegisterStep())
  // }, [])

  return (
    <>
      <PageTitle pageTitle="Register" />

      <Layout>
        {/* Register Section */}
        <section className='us-section us-section__auth-register' style={{ background: "url('/assets/images/auth/auth-bg.png') no-repeat 80% 10%"}}>
          <div className="us-section__inwrap">
            <Container>
              <Row>
                <Col lg="6" xl="6" className='my-auto'>
                  <div className="us-auth__left d-none d-lg-block">
                    <div className="us-auth__left-imgwrap">
                      <img src="/assets/images/auth/auth-banner.png" alt="auth-banner" className='img-fluid' />
                    </div>
                  </div>
                </Col>

                <Col md={{ size:10, offset:1 }} lg={{size:6, offset:0}} xl="5">
                  <div className="us-auth__right px-6 py-8 px-lg-10 py-lg-10">
                    {registerStep === 0 ?

                      // Registration Form
                      <RegistrationForm />

                    :
                      // Verify Email and Mobile OTP
                      <VerifyOTP />
                    }
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </section>
      </Layout>
    </>
  )
}
export default Register;
