import { useState, useEffect } from "react";
import OtpInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { resendUpdateEmailOTP, setUpdateEmailModal, updateProfileEmail, updateEmailOTPVerification, resetUpdateEmailErrors, resetUpdateEmailOTPVerificationErrors, resetTheEmailUpdateStep } from "../../../redux/reduxes/customer/profile/actions";


const UpdateEmailModal = ({ setShowUpdateEmailModal }) => {
  const dispatch = useDispatch();

  // Fetch Data
  const email = useSelector(state => state.profile.email);
  const emailOtpToken = useSelector(state => state.profile.emailOtpToken);
  const updateEmailLoading = useSelector(state => state.profile.updateEmailLoading);
  const updateEmailError = useSelector(state => state.profile.updateEmailError);
  const updateEmailStep = useSelector(state => state.profile.updateEmailStep);
  const updateEmailOTPVerificationLoading = useSelector(state => state.profile.updateEmailOTPVerificationLoading);
  const updateEmailOTPVerificationError = useSelector(state => state.profile.updateEmailOTPVerificationError);
  const profileDetails = useSelector(state => state.profile.profileDetails);

  // States
  const [state, setState] = useState({
    email: "",
    email_otp: "",

    errors: {
      email: "",
      email_otp: "",
    }
  });

  // Get Email ID
  useEffect(() => {
    profileDetails && profileDetails.email && setState((prevProps) => ({
      ...prevProps,
      email: profileDetails.email,
    }))
  }, [profileDetails]);


  // Reset Email Errors
  useEffect(() => {
    let errors = { ...state.errors };
    if (updateEmailError && updateEmailError.length > 0) {
      updateEmailError.forEach(error => {
        switch (error.context.key) {
          case "email":
            errors.email = error.message;
            break;
          default:
            break;
        }
      });
    } else {
      errors.email = "";
    }
    setState((prevProps) => ({
      ...prevProps,
      errors,
    }));
  }, [updateEmailError]);

  // Reset Verify OTP Errors
  useEffect(() => {
    dispatch(resetUpdateEmailOTPVerificationErrors());
  }, [])

  // onChange Handler
  const onChangeHandler = (event) => {
    event.preventDefault();
    const validEmailRegex = RegExp(
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    );
    const { name, value } = event.target;
    let errors = state.errors;
    switch (name) {
      case "email":
        errors.email = validEmailRegex.test(value) ? "" : "Enter a valid email ID!";
        break;
      default:
        break;
    }
    setState((prevProps) => ({
      ...prevProps,
      errors,
      [name]: value,
    }));
    // dispatch(resetUpdateEmailErrors());
  }

  // On Email Change Handler
  const onUpdateEmailOTPHandler = (event) => {
    let errors = state.errors;
    errors.email_otp = "";
    setState((prevProps) => ({
      ...prevProps,
      errors,
      email_otp: event
    }));
    dispatch(resetUpdateEmailOTPVerificationErrors());
  }

  // Validate Form
  const validateUpdateEmailForm = () => {
    let valid = true;
    let errors = state.errors;
    if (state.email == "") {
      errors.email = "Email ID is required."
      valid = false;
    }
    setState((prevProps) => ({
      ...prevProps,
      errors: errors,
    }))
    return valid;
  }

  // Validate Update Email OTP Form
  const validateUpdateEmailOTPForm = () => {
    let valid = true;
    let errors = state.errors;
    if (state.email_otp.length < 6) {
      errors.email_otp = "Invalid OTP";
      valid = false;
    }
    setState((prevProps) => ({
      ...prevProps,
      errors: errors,
    }))
    return valid;
  }

  // onSubmit Handler
  const onEmailSubmitHandler = async (event) => {
    event.preventDefault();
    if (validateUpdateEmailForm()) {
      const data = {
        email: state.email,
      }
      dispatch(updateProfileEmail(data));
    }
  }


  // OTP Expiration Time ====== EMAIL
  const [timeRemaining, setTimeRemaining] = useState(120); // Time remaining in seconds
  const [isRunning, setIsRunning] = useState(true) // Whether the countdown is running or paused

  // Decrement Time remaining by every 1 second  ====== EMAIL
  useEffect(() => {
    let interval;
    if (isRunning && timeRemaining > 0) {
      interval = setInterval(() => {
        setTimeRemaining(prevTime => prevTime - 1)
      }, 1000)
    }
    return () => clearInterval(interval);
  }, [isRunning, timeRemaining]);

  // Reset the Timer to 2 Minutes ====== EMAIL
  const handleEmailResetTime = () => {
    setIsRunning(true);
    setTimeRemaining(120);
  }

  // Format the Time remaining as MM:SS ====== EMAIL
  const minutes = Math.floor(timeRemaining / 60).toString().padStart(2, '0');
  const seconds = (timeRemaining % 60).toString().padStart(2, '0');
  const timeString = `${minutes}:${seconds}`;

  // Resend Email OTP Confirmation
  const confirmResendEmailOTP = (event) => {
    event.preventDefault();
    const data = {
      email: state.email,
    }
    dispatch(resendUpdateEmailOTP(data));
    handleEmailResetTime();
  }


  //  on Verify OTP Submit Handler
  const onVerifyOTPSubmitHandler = async (event) => {
    event.preventDefault();
    if (validateUpdateEmailOTPForm()) {
      const data = {
        email: state.email,
        emailOtp: state.email_otp,
        emailOtpToken: emailOtpToken,
        emailUpdate: true,
      }
      dispatch(updateEmailOTPVerification(data));
    }
  }

  // onCancelEditing
  const onEmailCancelEditing = () => {
    setState((prevProps) => ({
      ...prevProps,
      email: "",

      errors: {
        email: "",
      }
    }));
    // dispatch(updateProfileEmail());
    dispatch(setUpdateEmailModal(false));
  }

  // On OTP Cancel Editing
  const onEmailOTPVerifyCancelEditing = () => {
    setState((prevProps) => ({
      ...prevProps,
      email_otp: "",

      errors: {
        email_otp:"",
      }
    }));
    // dispatch(updateEmailOTPVerification());
    dispatch(setUpdateEmailModal(false));
    dispatch(resetTheEmailUpdateStep(false));
  }


  return (
    <>
      <Modal size="md" isOpen={setShowUpdateEmailModal} className="us-modal__dialog">
        <ModalBody className="us-modal__body">
          <h4 className="us-section__title-mini text-center mb-8 mb-md-12">{updateEmailStep == 0 ? "Update Email" : "Verify Email ID"}</h4>
          {updateEmailStep == 0 ?
            <Form onSubmit={(e) => onEmailSubmitHandler(e)}>
              <Row>
                <Col sm="12">
                  <FormGroup className="us-form__wrap">
                    <Label htmlFor="email" className="us-form__label">Email ID <span className="text-danger">*</span></Label>
                    <Input
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Enter Email ID"
                      className={`us-form__input ${state.errors.email ? "us-form__input-error" : ""}`}
                      value={state.email}
                      onChange={(e) => onChangeHandler(e)}
                    />
                    <div className="us-form__error">{state.errors.email}</div>
                    {/* {updateEmailError && <div className="us-form__error">{updateEmailError}</div>} */}
                  </FormGroup>

                  <div className="d-flex align-items-center justify-content-center mt-10">
                    <Button onClick={(e) => onEmailCancelEditing(e)} type="button" color="danger" outline className="us-button us-button__danger-outline py-3 px-8 me-4">Cancel</Button>
                    <Button
                      type="submit"
                      color="primary"
                      disabled={updateEmailLoading||  email == state.email}
                      className={`us-button us-button__primary py-3 px-8 ${updateEmailLoading ? "us-button__disabled" : "us-button__primary"}`}
                    >
                      {updateEmailLoading ? "Updating..." : "Update"}
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          :
            <Form onSubmit={(e) => onVerifyOTPSubmitHandler(e)}>
              <Row>
                <Col sm="12">
                  <FormGroup className="us-form__wrap">
                    <Label htmlFor="verify_email_top" className="us-form__label mb-0">Enter OTP received on Email</Label>
                    <div className="us-para mb-3">{email}</div>
                    <OtpInput
                      id="verify_email_top"
                      name="verify_email_top"
                      numInputs={6}
                      isInputNum={true}
                      className="me-2"
                      inputStyle="us-form__input-otp"
                      value={state.email_otp}
                      onChange={(e) => onUpdateEmailOTPHandler(e)}
                    />
                    <div className="us-form__error">{state.errors.email_otp}</div>
                    {updateEmailOTPVerificationError && <div className="us-form__error">{updateEmailOTPVerificationError}</div>}
                  </FormGroup>
                  <div className='flex items-center mt-3'>
                    {(timeString == "00:00" || state.errors.email_otp == "OTP expired") &&
                      <Button type="button" color="link" onClick={(e) => confirmResendEmailOTP(e)} className="us-button__link text-primary text-sm font-semibold p-0">Resend OTP</Button>
                    }
                    {timeString != "00:00" &&
                      <div className="color-gray3 text-md font-medium">
                        <div>{timeString}</div>
                      </div>
                    }
                  </div>
                </Col>
              </Row>

              <div className="d-flex align-items-center justify-content-center mt-10">
                <Button onClick={(e) => onEmailOTPVerifyCancelEditing(e)} type="button" color="danger" outline className="us-button us-button__danger-outline py-3 px-8 me-4">Cancel</Button>
                <Button
                  type="submit"
                  color="primary"
                  disabled={updateEmailOTPVerificationLoading}
                  className={`us-button us-button__primary py-3 px-8 ${updateEmailOTPVerificationLoading ? "us-button__disabled" : "us-button__primary"}`}
                >
                  {updateEmailOTPVerificationLoading ? "Verifying..." : "Verify"}
                </Button>
              </div>
            </Form>
            }
        </ModalBody>
      </Modal>
    </>
  )
}
export default UpdateEmailModal;