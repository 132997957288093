import { call, put, takeEvery } from 'redux-saga/effects'
import instance from "../../../../api/api_instance";

async function getApi(data) {
  try {
    const result = instance({
      url: `v1/categories?paginate=${data.paginate}`,
      method: "GET",
      data: data,
    }).then((response) => {
      return response;
    })
    return await result;
  } catch (error) {
    throw error;
  }
}
function* fetchProjects(action) {
  try {
    const res = yield call(getApi, action.payload);
    yield put({ type: "GET_ALL_PROJECT_CATEGORIES_SUCCESS", allProjectCategories: res.data });
  } catch (e) {
    if (e.response.status === 400 || e.response.status === 403 || e.response.status === 404 || e.response.status === 409) {
      yield put({ type: "GET_ALL_PROJECT_CATEGORIES_FAILED", message: e.response.data.message });
    } else {
      yield put({ type: "GET_ALL_PROJECT_CATEGORIES_FAILED", message: "Some error occurred!" });
    }

    if (e.response.status === 500) {
      const toastrFailedData = {
          content: e.response.data.errors ? e.response.data.errors : "Something went wrong!",
          type: "failed"
      };
      yield put({ type: "SET_TOASTER_SUCCESS", data: toastrFailedData });
    }
  }
}

function* getAllProjectCategoriesSaga() {
  yield takeEvery("GET_ALL_PROJECT_CATEGORIES_REQUESTED", fetchProjects);
}

export default getAllProjectCategoriesSaga;
