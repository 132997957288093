

const ActiveProjects = () => {
  return (
    <>
      <div className="us-customer__dashboard-projects">
        <h1 className="us-section__title mb-4">Projects</h1>
        <div className="us-form__wrap-card">
          <div className="text-center py-10">
            <img src="/assets/images/customer/dashboard/projects-default.png" alt="projects" />
            <div className="us-para font-normal mt-5">No active projects at the moment.</div>
          </div>
        </div>
      </div>
    </>
  )
}
export default ActiveProjects;