import React, { useState, useEffect } from "react";
import { Button, Col, Form, FormGroup, Input, Label } from "reactstrap";
import EvaluationMaterialModal from "./evaluationMaterialModal";
import { useDispatch, useSelector } from "react-redux";
import { getAllEvaluationMaterials, updateEvaluationReasons, setProjectActiveTab} from "../../../../redux/reduxes/customer/projects-new/projectAction";
import { setEvelutionMaterialModal,  deleteEvaluationMaterials, } from "../../../../redux/reduxes/customer/evalutionMaterial/projectAction";
import DeleteModal from "../../../global/common/deleteModal";
import { useParams, useLocation } from "react-router-dom";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

// Evaluate Checkbox
const evaluationList = [
  { id: 'Experiencing Low Traffic & High Bounce Rate', title: 'Experiencing Low Traffic & High Bounce Rate' },
  { id: 'Brand Refresh', title: 'Brand Refresh' },
  { id: 'Not sure, Need help', title: 'Not sure, Need help' },
]


const EvaluationDetailsTab = ({ projectActiveTab }) => {
  const dispatch = useDispatch();
  const urlParams = new URLSearchParams(window.location.search);
  const mode = urlParams.get('mode');
  const search = useLocation().search;
  const projectId = new URLSearchParams(search).get('project');
  let { realUserType } = useParams();


  // Fetch Data
  const evaluationReason = useSelector(state => state.projectNew.evaluationReason);
  const showManageEvelutionMaterial = useSelector(
    (state) => state.evelutionMaterials.showManageEvelutionMaterial
  );
  const allEvaluationMaterials = useSelector(
    (state) => state.projectNew.projectDetails.EvaluationMaterial
  );
  const updateEvaluationReasonsLoading = useSelector(
    (state) => state.projectNew.updateEvaluationReasonsLoading
  );
  const deleteEvaluationMaterialsLoading = useSelector(
    (state) => state.projectNew.deleteEvaluationMaterialsLoading
  );
  const updateEvaluationReasonsError = useSelector(
    (state) => state.projectNew.updateEvaluationReasonsError
  );
  const details = useSelector(
    (state) => state.projectNew.projectDetails.project
  );
  const project = details && details._id;


  // console.log(details, "sdsdsdssdkOKIsjidsok");
  //  const project="jijidncvimx"
  // console.log(updateEvaluationReasonsError, "kokidnvhbjjnuddbvcuj");

  // Get all Evaluation Materials
  useEffect(() => {
    if (projectActiveTab == 1) {
      dispatch(getAllEvaluationMaterials());
    }
  }, []);

  // States
  const [state, setState] = useState({
    evaluationReasonIds: [],
    evaluationMaterial: [],

    errors: {
      evaluationReasonIds: "",
      evaluationMaterial: "",
    },
  });

  // set the state with the details
  useEffect(() => {
    let reasonIds = [];
    function arraysHaveSameContent(arr1, arr2) {
      console.log(arr1, "tcxgtvcgtvgtvyscxcyvyhsy", arr2, "ugygybyyuybygy", JSON.stringify([...arr1].sort()) !== JSON.stringify([...arr2].sort()));
      return JSON.stringify([...arr1].sort()) !== JSON.stringify([...arr2].sort());
  }
    if (
      details &&
      details.evaluationReason &&
      details.evaluationReason.length > 0
    ) {
      reasonIds = details.evaluationReason;
    }
    // details.EvaluationReason.forEach((data) => {
    //   reasonIds.push(data._id);
    // });

    details &&
      details.evaluationReason &&
      details.evaluationReason &&
      setState((prevProps) => ({
        ...prevProps,
        // evaluationReasonIds: reasonIds,
        evaluationMaterial: details.EvaluationMaterial,
      }));
    const arr1 = details && details.evaluationReason && details.evaluationReason;
    const arr2 = state.evaluationReasonIds;
    if (arr2.length == 0 && details && details.evaluationReason && details.evaluationReason && arraysHaveSameContent(arr1, arr2)) {
      console.log("coming inside the UHUHUHHU");
      setState((prevProps) => ({
        ...prevProps,
        evaluationReasonIds: reasonIds
      }));
    }


  }, [details]);

  // Set Evaluation Materials to State
  useEffect(() => {
    setState((prevProps) => ({
      ...prevProps,
      evaluationMaterial: allEvaluationMaterials,
    }));
  }, [allEvaluationMaterials]);

  // Get Selected Evaluation on Back Tab
  // useEffect(() => {
  //   evaluationReason &&
  //     setState((prevProps) => ({
  //       ...prevProps,
  //       evaluationReasonIds: evaluationReason,
  //     }));
  // }, []);

  // onCheckbox Handler
  const onCheckboxHandler = (event) => {
    let errors = state.errors;
    if (event.target.checked) {
      setState((prevProps) => ({
        ...prevProps,
        evaluationReasonIds: [...state.evaluationReasonIds, event.target.value],
      }));
    } else {
      setState((prevProps) => ({
        ...prevProps,
        evaluationReasonIds: state.evaluationReasonIds.filter(
          (item) => item != event.target.value
        ),
      }));
    }
    errors.evaluationReasonIds = "";
    setState((prevProps) => ({
      ...prevProps,
      errors,
    }));
  };

  // Validate Form
  const validateForm = () => {
    let valid = true;
    let errors = state.errors;
    if (state.evaluationReasonIds.length === 0) {
      errors.evaluationReasonIds = "Select atleast One answer";
      valid = false;
    }
    if (state.evaluationMaterial.length === 0) {
      errors.evaluationMaterial = "Add the type of project to be evaluated";
      valid = false;
    }
    setState((prevProps) => ({
      ...prevProps,
      errors: errors,
    }));
    return valid;
  };

  // onSubmit Handler
  const onSubmitHandler = async (event) => {
    event.preventDefault();
    if (validateForm()) {
      if (state.evaluationMaterial.length > 0) {
        const data = {
          projectId: project,
          data: {
            evaluationReason: state.evaluationReasonIds,
          },
        };
        dispatch(updateEvaluationReasons(data));
        // dispatch(setProjectActiveTab(2));
      }
    }
  };

  // Evaluation Material Modal
  const [update, setUpdate] = useState(mode == "edit" ? true : false);
  const [isUpdate, setIsUpdate] = useState(mode == "edit" ? true : false);
  const [evaluationId, setEvaluationId] = useState(null);
  const [deleteEvaluationMaterialModal, setDeleteEvaluationMaterialModal] =
    useState(false);
  console.log(mode, "typeof mode");
  // Create Evaluation Material
  const evaluationMaterialModal = (stat, type, id) => {
    dispatch(
      setEvelutionMaterialModal({ show: true, isUpdate: false, id: "" })
    );
    let errors = state.errors;
    errors.evaluationMaterial = "";
    setState((prevProps) => ({
      ...prevProps,
      errors: errors,
    }));
  };

  // Update Evaluation Material
  const confirmUpdateEvaluationMaterial = (stat, id) => {
    // console.log(id, "GBINONSJBJNjncskjxbfdijnisndijw");
    // setUpdate(true);
    setEvaluationId(id);
    // evaluationMaterialModal(stat, true);
    // setUpdate(stat);
    // setIsUpdate(true);
    dispatch(setEvelutionMaterialModal({ show: true, isUpdate: true, id: id }));
  };

  // Delete Evaluation Material
  const confirmDeleteEvaluationMaterial = (stat, id) => {
    setDeleteEvaluationMaterialModal(stat);
    setEvaluationId(id);
  };

  // console.log(evaluationId, "ID outside");
  return (
    <>
      <div className="us-customer__project-evaluation">
        <Form onSubmit={(e) => onSubmitHandler(e)}>
          {/* ===========  WHY DO YOU WANT TO EVALUATE ? ========== */}
          <div className="us-form__wrap-card">
            <div className="head-font text-sm font-bold mb-4">
              Why do you want your product evaluated?
            </div>
            <div className="w-100 d-inline-flex d-md-flex flex-column flex-md-row align-items-start flex-wrap gap-1 gap-md-3">
              {evaluationList &&
                evaluationList.map((evaluate, index) => {
                  const { id, title } = evaluate;
                  console.log(
                    state.evaluationReasonIds,
                    " here is the comp",
                    title
                  );
                  return (
                    <>
                      <FormGroup
                        className="us-form__wrap position-relative"
                        key={index}
                      >
                        <Input
                          type="checkbox"
                          id={id}
                          name="evaluationReason"
                          className="us-form__custom-checkbox"
                          value={id}
                          onChange={(e) => onCheckboxHandler(e)}
                          checked={state.evaluationReasonIds.includes(id)}
                        />
                        <Label
                          htmlFor={id}
                          className="us-form__custom-label us-form__custom-label--right rounded-full"
                        >
                          <div className="us-form__custom-label--title me-10">
                            {title}
                          </div>
                        </Label>
                      </FormGroup>
                    </>
                  );
                })}
            </div>
            <div className="us-form__error">
              {state.errors.evaluationReasonIds}
            </div>
          </div>

          {/* =========== EVALUATION MATERIAL ============== */}
          <div className="us-form__wrap-card">
            <div className="d-flex flex-column flex-md-row align-items-start">
              <div>
                <div className="text-sm font-bold mb-1 head-font">
                  Evaluation Material
                </div>
                <p className="us-para color-gray3">
                  Add the type of digital product you want to evaluate. Feel
                  free to include multiple products.{" "}
                </p>
              </div>

              <div className="mt-4 mt-md-0 ms-auto">
                <Button
                  onClick={() => evaluationMaterialModal(false, false, "")}
                  color="primary"
                  outline
                  className="us-button us-button__primary-outline"
                >
                  Add Evaluation Material +
                </Button>
              </div>
            </div>

            <div className="us-form__error">
              {state.errors.evaluationMaterial}
            </div>

            {/* Evaluation Material Selected Options */}
            {state.evaluationMaterial &&
              state.evaluationMaterial.length > 0 && (
                <ul className="us-form__lists  d-flex align-items-start flex-wrap w-100 gap-2 mt-10 mt-md-5">
                  {state.evaluationMaterial.map((evaluation, index) => {
                    const { _id, appName, appType, status } = evaluation;
                    return (
                      <>
                        <li
                          key={index}
                          className="us-form__list us-form__list-evaluation d-flex align-items-start justify-content-between"
                        >
                          <div className="d-flex align-items-start">
                            <Button
                              onClick={() =>
                                confirmUpdateEvaluationMaterial(true, _id)
                              }
                              type="button"
                              color="link"
                              className="us-button__link p-0"
                            >
                              <img
                                src="/assets/icons/icon-edit-blue.svg"
                                alt="icon-cancel"
                                width="22"
                                height="22"
                              />
                            </Button>
                            <div className="ms-2">
                              <div className="us-form__list-title text-sm text-capitalize font-medium mb-1">
                                {appName}
                              </div>
                              <div className="us-form__list-subtitle text-sm mb-1">
                                {appType}
                              </div>
                              <div className="us-form__list-subtitle text-sm">
                                {status}
                              </div>
                            </div>
                          </div>
                          <Button
                            onClick={() =>
                              confirmDeleteEvaluationMaterial(true, _id)
                            }
                            type="button"
                            color="link"
                            className="us-button__link p-0 ms-3"
                          >
                            <img
                              src="/assets/icons/icon-cancel-red.svg"
                              alt="icon-cancel"
                              width="24"
                              height="24"
                            />
                          </Button>
                        </li>
                      </>
                    );
                  })}
                </ul>
              )}
          </div>
          <div className="us-form__error">{updateEvaluationReasonsError}</div>

          <div className="mt-8 mt-md-14 text-end">
            {projectId ?
              <Button
                onClick={() => dispatch(setProjectActiveTab(0))}
                type="button"
                color="primary"
                outline
                className="us-button us-button__primary-outline text-uppercase px-10 me-6"
              >
                Back
              </Button> :
              <Link
                to={`/customer/projects/manage-project/${realUserType}?project=${project}`}
                onClick={() => dispatch(setProjectActiveTab(0))}
                className="us-button us-button__primary-outline text-uppercase px-10 me-6"
              >
                Back
              </Link>
            }
            <Button
              type="submit"
              color="primary"
              disabled={updateEvaluationReasonsLoading}
              className={`us-button us-button__primary text-uppercase px-10 ${
                updateEvaluationReasonsLoading
                  ? "us-button__disabled"
                  : "us-button__primary"
              }`}
            >
              {updateEvaluationReasonsLoading ? "Saving..." : "Continue"}
            </Button>
          </div>
        </Form>
      </div>

      {/* Evaluation Material Modal */}
      {showManageEvelutionMaterial && (
        <EvaluationMaterialModal
          projectActiveTab={projectActiveTab}
          setShowEvaluationMaterialModal={showManageEvelutionMaterial}
          evaluationId={evaluationId}
          update={update}
          projectId={project}
          setUpdate={setUpdate}
        />
      )}

      {/* Delete Evaluation Material */}
      {deleteEvaluationMaterialModal && (
        <DeleteModal
          headText="Remove Evaluation Material"
          bodyText="Are you sure, you want to remove Evaluation Material?"
          // projectId={project}
          modalValue={deleteEvaluationMaterialModal}
          modalAction={setDeleteEvaluationMaterialModal}
          deleteAction={deleteEvaluationMaterials}
          deleteLoading={deleteEvaluationMaterialsLoading}
          evaluationId={evaluationId}
        />
      )}
    </>
  );
}
export default EvaluationDetailsTab;