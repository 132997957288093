import { call, put, takeEvery } from 'redux-saga/effects'
import instance from "../../../../api/api_instance";
import TokenService from '../../../services/tokenServices';

async function getApi(data) {
  const updatedData = {
    firstName: data.firstName,
    lastName: data.lastName,
  }
  try {
    const result = instance({
      url: "v1/profile-name",
      method: "PATCH",
      data: updatedData,
    }).then((response) => {
      return response;
    })
    return await result;
  } catch (error) {
    throw error;
  }
}

function* fetchProfile(action) {

  try {
    const res = yield call(getApi, action.payload);
    const toastrData = {
      content: "Full Name update successfully",
      type: "success"
    }
    yield put({ type: "UPDATE_FULL_NAME_SUCCESS", data: res.data.data });
    yield put({ type: "GET_PROFILE_DETAILS_REQUESTED", payload: {} });
    yield put({ type: "SET_TOASTR_SUCCESS", data: toastrData });
  } catch (e) {
    if (e.response.status === 406 || e.response.status === 422) {
      yield put({ type: "UPDATE_FULL_NAME_FAILED", message: e.response.data.error.message });
    }
  }
}

function* updateFullNameSaga() {
  yield takeEvery("UPDATE_FULL_NAME_REQUESTED", fetchProfile);
}

export default updateFullNameSaga;
