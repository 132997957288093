import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { addProfilePicture, setUpdateProfilePictureModal } from "../../../redux/reduxes/customer/profile/actions";


const AddProfilePictureModal = ({ setShowProfilePictureModal }) => {
  const dispatch = useDispatch();

  // Fetch Data
  const profileDetails = useSelector(state => state.profile.profileDetails);
  const addProfilePictureLoading = useSelector(state => state.profile.addProfilePictureLoading);
// console.log(profileDetails, "kokokokok");
  // States
  const [state, setState] = useState({
    file: [],
  });

  // Get Update Profile Pic
  useEffect(() => {
    profileDetails && setState((prevProps) => ({
      ...prevProps,
      // file: profileDetails.profilePic,
    }))
  }, [profileDetails]);


  // onChange Handler
  const [profileImage, setProfileImage] = useState(null)
  const onChangeHandler = (event) => {
    const [file] = event.target.files;
    if (file.type != "") {
      setProfileImage(URL.createObjectURL(file));
      setState((prevProps) => ({
        ...prevProps,
        file: event.target.files[0],
      }));
    }
  }

  // onSubmit Handler
  const onSubmitHandler = async (event) => {
    event.preventDefault();
    let formData = new FormData();
    if (profileImage && profileImage.length != 0) {
      formData.append('file', state.file);
    }
    dispatch(addProfilePicture(formData));
  }

  return (
    <>
      <Modal size="md" isOpen={setShowProfilePictureModal} className="us-modal__dialog">
        <ModalBody className="us-modal__body">
          <h4 className="us-section__title-mini text-center mb-8 mb-md-12">Update Profile Picture</h4>
          <Form onSubmit={(e) => onSubmitHandler(e)}>
            <Row>
              <Col sm="12">
                <FormGroup className="us-form__wrap">
                  <Label htmlFor="profilePicture" className="us-form__label">Profile Picture <span className="text-danger">*</span></Label>
                  <Label className="us-form__input-file--label position-relative z-10 text-center w-100 overflow-hidden">
                    <Input
                       type="file"
                       id="profilePicture"
                       name="profilePicture"
                       className="us-form__input-file position-relative w-100"
                       accept="image/png, image/jpg, image/jpeg"
                      //  value={profileImage}
                       onChange={(e) => onChangeHandler(e)}
                    />
                    {console.log(profileDetails.profilePic, "profileDetails.profilePic")}
                    {
                      profileImage ? null : <div className="us-form__input-file--info position-absolute top-0 start-0 d-flex flex-column align-items-center justify-content-center w-100">
                      <img src="/assets/icons/icon-file-bg.svg" alt="icon-file-bg" />
                      {
                        !profileDetails.profilePicAttached  ? <div className="us-para text-md font-normal mt-3 mb-1">Upload Profile Picture, or <span className="color-primary font-medium">Browse</span></div> : null
                      }


                    </div>
                    }

                    <div className="us-form__input-file--uploaded position-absolute top-0 start-0 d-flex flex-column align-items-center justify-content-center w-100">
                      {profileImage ?
                        // <img src={profileImage} alt="profile-pic" className="" height="150px" width="150px" />
                        <div style={{
                          height: "140px",
                          width: "140px",
                          backgroundImage: `url(${profileImage})`,
                          backgroundSize:"cover",
                          backgroundPosition:"center"
                        }}  className="rounded-circle overflow-hidden profile-edit cursor-pointer">
                          <div className="profile-edit__overlay h-100 w-100 d-flex align-items-center justify-content-center"><img src="/assets/icons/icon-edit-white.png" alt="" style={{height:"25px",width:"25px"}} /></div>
                        </div>
                      : (profileDetails && profileDetails.profilePicAttached) ?
                      <div style={{
                        height: "140px",
                        width: "140px",
                        backgroundImage: `url(${profileDetails.profilePic})`,
                        backgroundSize:"cover",
                        backgroundPosition:"center"
                      }}  className="rounded-circle overflow-hidden profile-edit cursor-pointer">
<div className="profile-edit__overlay h-100 w-100 d-flex align-items-center justify-content-center"><img src="/assets/icons/icon-edit-white.png" alt="" style={{height:"25px",width:"25px"}} /></div>
                      </div>
                          :null
                      }
                    </div>
                  </Label>
                </FormGroup>
              </Col>
            </Row>

            <div className="d-flex align-items-center justify-content-center mt-6">
              <Button onClick={() => dispatch(setUpdateProfilePictureModal(false))} type="button" color="danger" outline className="us-button us-button__danger-outline py-3 px-8 me-4">Cancel</Button>
              {console.log(state.file.length, "HUHUHUHUH99UHUHU")}
              <Button
                type="submit"
                color="primary"
                disabled={addProfilePictureLoading || state.file.length == 0}
                className={`us-button us-button__primary py-3 px-8 ${addProfilePictureLoading ? "us-button__disabled" : "us-button__primary"}`}
              >
                {addProfilePictureLoading ? "Updating..." : "Update"}
              </Button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </>
  )
}
export default AddProfilePictureModal;