import React, { useState } from 'react';
import Header from './header';
import Footer from './footer';

const Layout = ({ children }) => {
  return (
    <>
      <Header />

      <main className="us-main pt-16 pt-lg-5">
        <div className='w-full flex items-start justify-between'>

            {children}

          </div>
      </main>
      <Footer/>
    </>

  )
}
export default Layout;