import { useEffect, useState } from "react";
import { Button } from "reactstrap";
import ProjectPersonaModal from "./projectPersonaModal";
import { useDispatch, useSelector } from "react-redux";
import { deleteProjectPersona, getAllProjectPersonas, setProjectPersonaModal } from "../../../../redux/reduxes/customer/projects-new/projectAction";
import DeleteModal from "../../../global/common/deleteModal";


const AddMultiplePersonas = ({ projectActiveTab, error }) => {
  const dispatch = useDispatch();

  // Fetch Data
  const setShowProjectPersonaModal = useSelector(state => state.projectNew.setShowProjectPersonaModal);
  const allProjectPersonas = useSelector(state => state.projectNew.allProjectPersonas);
  const deleteProjectPersonaLoading = useSelector(state => state.projectNew.deleteProjectPersonaLoading);

  const details = useSelector(state => state.projectNew.projectDetails);
  const project = details._id;

  // Get all Personas
  useEffect(() => {
    if (projectActiveTab == 2) {
      dispatch(getAllProjectPersonas(project));
    }
  }, [])

  // States
  const [state, setState] = useState({
    allPersonasList: [],
    errors: {
      allPersonasList: "",
    }
  });


  // Get the personas and set to state
  useEffect(() => {
    setState((prevProps) => ({
      ...prevProps,
      allPersonasList: allProjectPersonas,
    }));
  }, [allProjectPersonas]);


  // Create, Update and Delete Persona
  // const [update, setUpdate] = useState(false);
  const [personaId, setPersonaId] = useState(null);
  const [deletePersonaModal, setDeletePersonaModal] = useState(false);

  // Create Persona Modal
  const projectPersonaModal = (stat) => {
    // setUpdate(false);
    dispatch(setProjectPersonaModal({show:stat, edit: false, id:""}))
  }

  // Update Persona Modal
  const confirmUpdateProjectPersonaModal = (stat, id) => {
    // setUpdate(true);
    // setPersonaId(id);
    dispatch(setProjectPersonaModal({show:stat, edit: true, id:id}));
  }

  // Delete Persona Modal
  const confirmDeleteProjectPersonaModal = (stat, id) => {
    setDeletePersonaModal(stat);
    setPersonaId(id);
  }

  return (
    <>
      <div className="us-form__wrap-card">
        <div className="d-flex flex-column flex-md-row align-items-md-center justify-content-between">
          <div>
            <div className="head-font text-sm font-bold mb-1">Target User Type</div>
            <p className="us-para color-gray3 mb-4">Add one or more targeted user persona</p>
          </div>
          <div className="ms-auto">
            <Button onClick={() => projectPersonaModal(true)} type="button" color="primary" outline className="us-button us-button__primary-outline">Add multiple target users +</Button>
          </div>
        </div>


        {/* Selected Personas List */}
        {(state.allPersonasList && state.allPersonasList.length > 0) &&
          <ul className="us-form__lists  d-flex align-items-start flex-wrap w-100 gap-2 mt-8 mt-md-5">
            {state.allPersonasList.map((persona, index) => {
              const { _id, name, language } = persona;
              return (
                <>
                  <li key={_id} className="us-form__list us-form__list-evaluation d-flex align-items-start justify-content-between">
                    <div className="d-flex align-items-start">
                      <Button onClick={() => confirmUpdateProjectPersonaModal(true, _id)} type="button" color="link" className="us-button__link p-0">
                        <img src="/assets/icons/icon-edit-blue.svg" alt="icon-cancel" width="22" height="22" />
                      </Button>
                      <div className="ms-2">
                        <div className="us-form__list-title text-sm font-medium mb-1">{name}</div>
                        <div className="us-form__list-subtitle text-sm">{language}</div>
                      </div>
                    </div>
                    <Button onClick={() => confirmDeleteProjectPersonaModal(true, _id)} type="button" color="link" className="us-button__link p-0 ms-3">
                      <img src="/assets/icons/icon-cancel-red.svg" alt="icon-cancel" width="24" height="24" />
                    </Button>
                  </li>
                </>
              )
            })}
          </ul>
        // :
        //   <div className="us-form__error">Add atleast one Project Persona</div>
        }
        <div className="us-form__error">{error}</div>
      </div>

       {/* Create/Update Persona Modal */}
       {setShowProjectPersonaModal &&
        <ProjectPersonaModal
          projectActiveTab={projectActiveTab}
          setShowProjectPersonaModal={setShowProjectPersonaModal}
          // update={update}
          // setUpdate={setUpdate}
          personaId={personaId}
        />
      }

      {/* Delete Persona Modal */}
      {deletePersonaModal &&
        <DeleteModal
          headText="Remove Project Persona"
          bodyText="Are you sure, you want to remove Project Persona?"
          personaId={personaId}
          modalValue={deletePersonaModal}
          modalAction={setDeletePersonaModal}
          deleteAction={deleteProjectPersona}
          deleteLoading={deleteProjectPersonaLoading}
        />
      }
    </>
  )
}
export default AddMultiplePersonas;