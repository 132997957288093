import * as type from './types';

const initialState = {
  // Get Profile Details
  firstName: "",
  lastName: "",
  email: "",
  mobileNumber: "",
  profilePic:null,
  profileDetails:{},
  profileDetailsLoading: false,
  profileDetailsError: null,
  linkedInLinked: false,

  // Add Profile Picture
  addProfilePictureLoading: false,
  addProfilePictureError: null,

  // Set Update Profile Picture Modal
  setShowProfilePictureModal: false,

  // Remove Profile Picture
  removeProfilePictureLoading: false,
  removeProfilePictureError: null,

  // Update Full Name
  updateFullNameLoading: false,
  updateFullNameError: null,

  // Set Update FullName Modal
  setShowUpdateFullNameModal: false,

  // Update Email
  emailOtpToken:"",
  updateEmailLoading: false,
  updateEmailError: [],
  updateEmailStep: 0,

  // Set Update Profile Email Modal
  setShowUpdateEmailModal: false,

  // Update Email OTP Verification
  updateEmailOTPVerificationLoading: false,
  updateEmailOTPVerificationError: null,

  // Resend Update Email OTP
  resendUpdateEmailOTPLoading: false,
  resendUpdateEmailOTPError: null,


  // Update Mobile Number
  mobileOtpToken: "",
  updateMobileNumberLoading: false,
  updateMobileNumberError: [],
  updateMobileStep: 0,

  // Set Update Mobile Number Modal
  setShowUpdateMobileNumberModal: false,

  // Update Mobile Number OTP Verification
  updateMobileOTPVerificationLoading: false,
  updateMobileOTPVerificationError: null,

  // Resend Update Mobile OTP
  resendUpdateMobileOTPLoading: false,
  resendUpdateMobileOTPError: null,

  // Change Password
  changePasswordLoading: false,
  changePasswordError: "",

  // Change Password
  expertDetailsLoading: false,
  expertDetailsError: "",
  expertRegistrationDetails: {},

  // Set Change Password Modal
  setShowChangePasswordModal: false,

    // Set Change Password Modal when linked Unlink
  linkedinChangePasswordModal: false,

  //LINKEDIN UNLINK PASSWORD UPDATE
  linkedinUnlinkPasswordUpdateLoading: false,
  linkedinUnlinkPasswordUpdateError: "",


}

export default function profile(state = initialState, action) {
  switch (action.type) {
    // Get User Details
    case type.GET_PROFILE_DETAILS_REQUESTED:
      return {
        ...state,
        profileDetailsLoading: true,
      }
    case type.GET_PROFILE_DETAILS_SUCCESS:
      return {
        ...state,
        profileDetailsLoading: false,
        profileDetails: action.profileDetails,
        firstName: action.profileDetails.firstName,
        lastName: action.profileDetails.lastName,
        email: action.profileDetails.email,
        mobileNumber: action.profileDetails.mobileNumber,
        profilePic: action.profileDetails.profilePic,
        linkedInLinked: action.profileDetails.linkedInLinked,
      }
    case type.GET_PROFILE_DETAILS_FAILED:
      return {
        ...state,
        profileDetailsLoading: false,
        profileDetailsError: action.message,
      }

    // Add Profile Picture
    case type.ADD_PROFILE_PICTURE_REQUESTED:
      return {
        ...state,
        addProfilePictureLoading: true,
      }
    case type.ADD_PROFILE_PICTURE_SUCCESS:
      return {
        ...state,
        addProfilePictureLoading: false,
        setShowProfilePictureModal: false,
      }
    case type.ADD_PROFILE_PICTURE_FAILED:
      return {
        ...state,
        addProfilePictureLoading: false,
        addProfilePictureError: action.message,
      }

    // Set Update Profile Picture Modal
    case type.SET_UPDATE_PROFILE_PICTURE_MODAL_REQUESTED:
      return {
        ...state,
        setShowProfilePictureModal: action.payload,
      }

    // Remove Profile Picture
    case type.REMOVE_PROFILE_PICTURE_REQUESTED:
      return {
        ...state,
        removeProfilePictureLoading: true,
      }
    case type.REMOVE_PROFILE_PICTURE_SUCCESS:
      return {
        ...state,
        removeProfilePictureLoading: false,
      }
    case type.REMOVE_PROFILE_PICTURE_FAILED:
      return {
        ...state,
        removeProfilePictureLoading: false,
        removeProfilePictureError: action.message,
      }


    // Update Full Name
    case type.UPDATE_FULL_NAME_REQUESTED:
      return {
        ...state,
        updateFullNameLoading: true,
      }
    case type.UPDATE_FULL_NAME_SUCCESS:
      return{
        ...state,
        updateFullNameLoading: false,
        setShowUpdateFullNameModal: false,
      }
    case type.UPDATE_FULL_NAME_FAILED:
      return {
        ...state,
        updateFullNameLoading: false,
        updateFullNameError: action.message,
      }

    // Set Update FullName Modal
    case type.SET_UPDATE_FULLNAME_MODAL_REQUESTED:
      return {
        ...state,
        setShowUpdateFullNameModal: action.payload,
      }

    // Update Email
    case type.UPDATE_EMAIL_REQUESTED:
      return {
        ...state,
        updateEmailLoading: true,
      }
    case type.UPDATE_EMAIL_SUCCESS:
      return {
        ...state,
        updateEmailLoading: false,
        email: action.profileDetails.email,
        emailOtpToken: action.profileDetails.emailOtpToken,
        setShowUpdateEmailModal: true,
        updateEmailStep: 1,
      }
    case type.UPDATE_EMAIL_FAILED:
      return {
        ...state,
        updateEmailLoading: false,
        updateEmailError: action.message,
      }

    // Set Update Email Modal
    case type.SET_UPDATE_EMAIL_MODAL_REQUESTED:
      return {
        ...state,
        setShowUpdateEmailModal: action.payload,
      }

    // Set Update Email Errors
    case type.RESET_UPDATE_EMAIL_ERRORS_REQUESTED:
      return {
        ...state,
        updateEmailError: [],
      }


    // Update Profile Email OTP Verification
    case type.UPDATE_EMAIL_OTP_VERIFICATION_REQUESTED:
      return {
        ...state,
        updateEmailOTPVerificationLoading: true,
      }
    case type.UPDATE_EMAIL_OTP_VERIFICATION_SUCCESS:
      return {
        ...state,
        updateEmailOTPVerificationLoading: false,
        setShowUpdateEmailModal: false,
        updateEmailStep: 0,
      }
      case type.UPDATE_EMAIL_STEP_RESET:
        return {
          ...state,
          updateEmailStep: 0,
      }
      case type.UPDATE_MOBILE_STEP_RESET:
        return {
          ...state,
          updateMobileStep: 0,
        }
    case type.UPDATE_EMAIL_OTP_VERIFICATION_FAILED:
      return {
        ...state,
        updateEmailOTPVerificationLoading: false,
        updateEmailOTPVerificationError: action.message,
      }

    // Set Update Email OTP Errors
    case type.RESET_UPDATE_EMAIL_OTP_ERRORS_REQUESTED:
      return {
        ...state,
        updateEmailOTPVerificationError: "",
        updateEmailError:[],
      }

    // Resend Update Email OTP
    case type.RESEND_UPDATE_EMAIL_OTP_REQUESTED:
      return {
        ...state,
        resendUpdateEmailOTPLoading: true,
      }
    case type.RESEND_UPDATE_EMAIL_OTP_SUCCESS:
      return {
        ...state,
        resendUpdateEmailOTPLoading: false,
        emailOtpToken:action.data.emailOtpToken,
      }
    case type.RESEND_UPDATE_EMAIL_OTP_FAILED:
      return {
        ...state,
        resendUpdateEmailOTPLoading: false,
        resendUpdateEmailOTPError: action.message,
      }

    // Update Profile Mobile Number
    case type.UPDATE_MOBILE_NUMBER_REQUESTED:
      return {
        ...state,
        updateMobileNumberLoading: true,
      }

    case type.UPDATE_MOBILE_NUMBER_SUCCESS:
      return {
        ...state,
        updateMobileNumberLoading: false,
        mobileNumber: action.mobileNumber,
        // mobileOtpToken: action.profileDetails.mobileOtpToken,
        // updateMobileStep: 0,
        setShowUpdateMobileNumberModal: false
      }

    case type.UPDATE_MOBILE_NUMBER_FAILED:
      return {
        ...state,
        updateMobileNumberLoading: false,
        updateMobileNumberError: action.message,
      }

    // Set Update Mobile Number Modal
    case type.SET_UPDATE_MOBILE_NUMBER_MODAL_REQUESTED:
      return {
        ...state,
        setShowUpdateMobileNumberModal: action.payload,
      }

    // Reset Update Mobile Number Errors
    // case type.RESET_UPDATE_MOBILE_NUMBER_ERRORS_REQUESTED:
    //   return {
    //     ...state,
    //     updateMobileNumberError: [],
    //     updateRegisterMobileNumberError: [],

    //   }

    // Update Mobile Number OTP Verification
    case type.UPDATE_MOBILE_OTP_VERIFICATION_REQUESTED:
      return {
        ...state,
        updateMobileOTPVerificationLoading: true,
      }
    case type.UPDATE_MOBILE_OTP_VERIFICATION_SUCCESS:
      return {
        ...state,
        updateMobileOTPVerificationLoading: false,
        setShowUpdateMobileNumberModal: false,
        updateMobileStep: 0,
      }
    case type.UPDATE_MOBILE_OTP_VERIFICATION_FAILED:
      return {
        ...state,
        updateMobileOTPVerificationLoading: false,
        updateMobileOTPVerificationError: action.message,
      }

    // Reset Mobile Number OTP Verification Errors
    case type.RESET_UPDATE_MOBILE_OTP_VERIFICATION_ERRORS_REQUESTED:
      return {
        ...state,
        updateMobileOTPVerificationError: "",
        updateMobileNumberError: [],
        updateRegisterMobileNumberError: [],


      }

    // Resend Update Mobile OTP
    case type.RESEND_UPDATE_MOBILE_OTP_REQUESTED:
      return {
        ...state,
        resendUpdateMobileOTPLoading: true,
      }
    case type.RESEND_UPDATE_MOBILE_OTP_SUCCESS:
      return {
        ...state,
        resendUpdateMobileOTPLoading: false,
        mobileOtpToken: action.data.mobileOtpToken,
      }
    case type.RESEND_UPDATE_MOBILE_OTP_FAILED:
      return {
        ...state,
        resendUpdateMobileOTPLoading: false,
        resendUpdateMobileOTPError: action.message,
      }


    // Change Password
    case type.CHANGE_PASSWORD_REQUESTED:
      return {
        ...state,
        changePasswordLoading: true,
      }
    case type.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        changePasswordLoading: false,
        setShowChangePasswordModal: false,
      }
    case type.CHANGE_PASSWORD_FAILED:
      return {
        ...state,
        changePasswordLoading: false,
        changePasswordError: action.message,
      }
        // GET EXPERT REGISTRATION DETAILS
        case type.GET_EXPERT_DETAILS_REQUESTED:
          return {
            ...state,
            expertDetailsLoading: true,
          }
        case type.GET_EXPERT_DETAILS_SUCCESS:
          return {
            ...state,
            expertDetailsLoading: false,
            expertRegistrationDetails: action.payload.responseData,
          }
        case type.GET_EXPERT_DETAILS_FAILED:
          return {
            ...state,
            expertDetailsLoading: false,
            expertDetailsError: action.message,
          }

    // Set Change Password Modal
    case type.SET_CHANGE_PASSWORD_MODAL_REQUESTED:
      return {
        ...state,
        setShowChangePasswordModal: action.payload,
      }


    // Redux test!!
    case type.CANCEL_LINKEDIN_PASSWORD:
      return {
        ...state,
        setShowUnlinkLinkedIn: action.payload,
      }

    // Change Password Reset Error Messages
    case type.CHANGE_PASSWORD_RESET_ERROR_MESSAGES_REQUESTED:
      return {
        ...state,
        changePasswordError: "",
      }
    // LINKED IN UNLINK
    case type.UNLINK_LINKED_IN_SUCCESS:
      return {
        ...state,
        profileDetailsLoading: false,
        profileDetails: action.profileDetails,
        firstName: action.profileDetails.firstName,
        lastName: action.profileDetails.lastName,
        email: action.profileDetails.email,
        mobileNumber: action.profileDetails.mobileNumber,
        profilePic: action.profileDetails.profilePic,
        linkedInLinked: action.profileDetails.linkedInLinked,
      }
      case type.UNLINK_SET_PASSWORD_MODAL:
        return {
          ...state,
          linkedinChangePasswordModal: action.payload,
        }
    case type.UNLINK_LINKED_IN_FAILURE:
      return {
        ...state,
        profileDetailsLoading: false,
      }

    // UPDATE THE PASSWORD ON LINKEDIN UNLINK
    case type.UNLINK_PASSWORD_CREATE_REQUESTED:
      return {
        ...state,
        linkedinUnlinkPasswordUpdateLoading: true,
      }
    case type.UNLINK_PASSWORD_CREATE_SUCCESS:
      return {
        ...state,
        linkedinUnlinkPasswordUpdateLoading: false,
        linkedinChangePasswordModal: false,
      }
    case type.UNLINK_PASSWORD_CREATE_FAILURE:
      return {
        ...state,
        linkedinUnlinkPasswordUpdateLoading: false,
        linkedinUnlinkPasswordUpdateError: action.message,
      }

    default:
      return state;
  }
}
