// REGISTER USER
export const REGISTER_USER_REQUESTED = "REGISTER_USER_REQUESTED";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_FAILED = "REGISTER_USER_FAILED";

// REGISTER STEP
export const REGISTER_STEP_REQUESTED = "REGISTER_STEP_REQUESTED";

// REGISTER RESET ERROR MESSAGES
export const REGISTER_RESET_ERROR_MESSAGES_REQUESTED = "REGISTER_RESET_ERROR_MESSAGES_REQUESTED";



// LOGIN USER
export const LOGIN_USER_REQUESTED = "LOGIN_USER_REQUESTED";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_FAILED = "LOGIN_USER_FAILED";

// LOGIN RESET ERROR MESSAGE
export const LOGIN_RESET_ERROR_MESSAGES_REQUESTED = 'LOGIN_RESET_ERROR_MESSAGES_REQUESTED';

// LOGOUT
export const LOGOUT_USER_REQUESTED = "LOGOUT_USER_REQUESTED";
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS";
export const LOGOUT_USER_FAILED = "LOGOUT_USER_FAILED";

// LINKEDIN USER
export const LINKED_IN_USER = "LINKED_IN_USER";

// LINKEDIN LOGIN TOKEN
export const LINKEDIN_LOGIN_TOKEN_REQUESTED = "LINKEDIN_LOGIN_TOKEN_REQUESTED";
export const LINKEDIN_LOGIN_TOKEN_SUCCESS = "LINKEDIN_LOGIN_TOKEN_SUCCESS";
export const LINKEDIN_LOGIN_TOKEN_FAILED = "LINKEDIN_LOGIN_TOKEN_FAILED";


// EMAIL & MOBILE OTP CONFIRMATION
export const CONFIRM_EMAIL_MOBILE_OTP_REQUESTED = "CONFIRM_EMAIL_MOBILE_OTP_REQUESTED";
export const CONFIRM_EMAIL_MOBILE_OTP_SUCCESS = "CONFIRM_EMAIL_MOBILE_OTP_SUCCESS";
export const CREATE_PROJECT_AFTER_OTP_REQUEST = "CREATE_PROJECT_AFTER_OTP_REQUEST";
export const CREATE_PROJECT_AFTER_OTP_SUCCESS ="CREATE_PROJECT_AFTER_OTP_SUCCESS";
export const CREATE_PROJECT_AFTER_OTP_FAILED="CREATE_PROJECT_AFTER_OTP_FAILED";
export const CONFIRM_EMAIL_MOBILE_OTP_FAILED = "CONFIRM_EMAIL_MOBILE_OTP_FAILED";

// EMAIL & MOBILE OTP VERIFICATION
export const VERIFY_EMAIL_MOBILE_OTP_REQUESTED = "VERIFY_EMAIL_MOBILE_OTP_REQUESTED";
export const VERIFY_EMAIL_MOBILE_OTP_SUCCESS = "VERIFY_EMAIL_MOBILE_OTP_SUCCESS";
export const VERIFY_EMAIL_MOBILE_OTP_FAILED = "VERIFY_EMAIL_MOBILE_OTP_FAILED";

// RESET VERIFY ERROR
export const RESET_VERIFY_EMAIL_MOBILE_OTP_ERRORS = "RESET_VERIFY_EMAIL_MOBILE_OTP_ERRORS";


// UPDATE EMAIL
export const UPDATE_REGISTER_EMAIL_REQUESTED = "UPDATE_REGISTER_EMAIL_REQUESTED";
export const UPDATE_REGISTER_EMAIL_SUCCESS = "UPDATE_REGISTER_EMAIL_SUCCESS";
export const UPDATE_REGISTER_EMAIL_FAILED = "UPDATE_REGISTER_EMAIL_FAILED";

// RESET UPDATE EMAIL ERRORS
export const RESET_UPDATE_EMAIL_ERRORS_REQUESTED = "RESET_UPDATE_EMAIL_ERRORS_REQUESTED";

// SET UPDATE EMAIL MODAL
export const SET_REGISTER_UPDATE_EMAIL_MODAL_REQUESTED = "SET_REGISTER_UPDATE_EMAIL_MODAL_REQUESTED";



// UPDATE MOBILE NUMBER
export const UPDATE_REGISTER_MOBILE_NUMBER_REQUESTED = "UPDATE_REGISTER_MOBILE_NUMBER_REQUESTED";
export const UPDATE_REGISTER_MOBILE_NUMBER_SUCCESS = "UPDATE_REGISTER_MOBILE_NUMBER_SUCCESS";
export const UPDATE_REGISTER_MOBILE_NUMBER_FAILED = "UPDATE_REGISTER_MOBILE_NUMBER_FAILED";


// SET UPDATE MOBILE NUMBER MODAL
export const SET_REGISTER_UPDATE_MOBILE_NUMBER_MODAL_REQUESTED = "SET_REGISTER_UPDATE_MOBILE_NUMBER_MODAL_REQUESTED";




// RESEND EMAIL OTP CONFIRMATION
export const RESEND_EMAIL_OTP_CONFIRMATION_REQUESTED = "RESEND_EMAIL_OTP_CONFIRMATION_REQUESTED";
export const RESEND_EMAIL_OTP_CONFIRMATION_SUCCESS = "RESEND_EMAIL_OTP_CONFIRMATION_SUCCESS";
export const RESEND_EMAIL_OTP_CONFIRMATION_FAILED = "RESEND_EMAIL_OTP_CONFIRMATION_FAILED";

// RESEND MOBILE OTP CONFIRMATION
export const RESEND_MOBILE_OTP_CONFIRMATION_REQUESTED = "RESEND_MOBILE_OTP_CONFIRMATION_REQUESTED";
export const RESEND_MOBILE_OTP_CONFIRMATION_SUCCESS = "RESEND_MOBILE_OTP_CONFIRMATION_SUCCESS";
export const RESEND_MOBILE_OTP_CONFIRMATION_FAILED = "RESEND_MOBILE_OTP_CONFIRMATION_FAILED";

// RESET MOBILE NUMBER
export const RESET_MOBILE_NUMBER_REQUESTED = "RESET_MOBILE_NUMBER_REQUESTED";

// RESET EMAIL
export const RESET_EMAIL_REQUESTED = "RESET_EMAIL_REQUESTED";

export const CHANGE_USER_EMAIL_REQUESTED="CHANGE_USER_EMAIL_REQUESTED";

// TO GET SCREENSHOT

export const GET_SCREENSHOT_REQUESTED="GET_SCREENSHOT_REQUESTED";

export const GET_SCREENSHOT_SUCCESS ="GET_SCREENSHOT_SUCCESS";

export const GET_SCREENSHOT_FAILED="GET_SCREENSHOT_FAILED";
