import React, { useState,useEffect } from "react";
import { Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import PageTitle from "../../components/global/common/pageTitle";
import Layout from "../../layout";
import { useDispatch, useSelector } from "react-redux";
import PersonalInfoTab from "../../components/customer/account-settings/expertRegistrationTabs/personalInfoTab";
import ProfessionalTab from "../../components/customer/account-settings/expertRegistrationTabs/professionalTab";
import DomainExpertise from "../../components/customer/account-settings/expertRegistrationTabs/domainExpertiseTab";
import { setRegistrationActiveTab } from "../../redux/reduxes/customer/expertRegistration/action";
//
let mapMe=new Map()
const uxInfos = [
  {
    id: "Planning and conducting user research studies",
    title: "Planning and conducting user research studies",
  },
  {
    id: "Defining research objectives and questions",
    title: "Defining research objectives and questions",
  },
  {
    id: "Identifying target participants",
    title: "Identifying target participants",
  },
  { id: "Defining user personas", title: "Defining user personas" },
  {
    id: "Analyzing Information Strategy",
    title: "Analyzing Information Strategy",
  },
  { id: "Fact gathering and analysis", title: "Fact gathering and analysis" },
  { id: "Insightful on visual designs", title: "Insightful on visual designs" },
  {
    id: "Comprehensive understanding of user flows and wireframes",
    title: "Comprehensive understanding of user flows and wireframes",
  },
  {
    id: "Planning and Drafting Research Reports and Deliverables",
    title: "Planning and Drafting Research Reports and Deliverables",
  },
  {
    id: "Apt in usability testing and evaluation",
    title: "Apt in usability testing and evaluation",
  },
  {
    id: "Analyzing competitive research",
    title: "Analyzing competitive research",
  },
];
const uiInfos = [
  {
    id: "Design Systems and Style Guides",
    title: "Design Systems and Style Guides",
  },
  { id: "UI Prototyping", title: "UI Prototyping" },
  {
    id: "Planning and Drafting Research Report and Deliverables",
    title: "Planning and Drafting Research Report and Deliverables",
  },
  { id: "Iconography and Graphics", title: "Iconography and Graphics" },
];
const marketingInfos = [
  {
    id: "Marketing Strategy Development",
    title: "Marketing Strategy Development",
  },
  {
    id: "Market Research and Analysis",
    title: "Market Research and Analysis"
  },
  {
    id: "Branding and Positioning",
    title: "Branding and Positioning",
  },
  {
    id: "Content Marketing / SEO / SEM / SMO / SMM",
    title: "Content Marketing / SEO / SEM / SMO / SMM"
  },
  {
    id: "Lead Generation",
    title: "Lead Generation"
  },
  {
    id: "Planning and Drafting Research Reports and Deliverables",
    title: "Planning and Drafting Research Reports and Deliverables"
  },
];

const businessInfos = [
  {
    id: "Analysis and Assessment",
    title: "Analysis and Assessment",
  },
  {
    id: "Financial Analysis and Planning",
    title: "Financial Analysis and Planning"
  },
  {
    id: "Business Strategies",
    title: "Business Strategies",
  },
  {
    id: "Business Development",
    title: "Business Development"
  },
  {
    id: "Strategic Planning",
    title: "Strategic Planning"
  },
  {
    id: "Risk Assessment and Management",
    title: "Risk Assessment and Management"
  },
  {
    id: "Business Analytics",
    title: "Business Analytics"
  },
  {
    id: "Revenue Generation Planning",
    title: "Revenue Generation Planning"
  },
  {
    id: "Market Research",
    title: "Market Research"
  },
  {
    id: "Market Analysis",
    title: "Market Analysis"
  },
  {
    id: "CX Expertise",
    title: "CX Expertise"
  },
];

const infos = [
  {
    id: "Websites",
    title: "Websites",
  },
  {
    id: "Web application",
    title: "Web application",
  },
  {
    id: "Mobile",
    title: "Mobile",
  },
  { id: "Wearables", title: "Wearables" },
  {
    id: "Desktop applications",
    title: "Desktop applications",
  },
  { id: "IoT", title: "IoT" },
  { id: "Smart home applications", title: "Smart home applications" },
  {
    id: "Virtual Reality(VR)",
    title: "Virtual Reality(VR)",
  },
  {
    id: "Augmented Reality(AR)",
    title: "Augmented Reality(AR)",
  },
  {
    id: "Dashboard and Analytics",
    title: "Dashboard and Analytics",
  },
  {
    id: "Point of Sale (POS)",
    title: "Point of Sale (POS)",
  },
];

//Fluent
const fluents = [
  {
    id: "Cognitive Walkthrough",
    title: "Cognitive Walkthrough",
  },
  {
    id: "Accessibility Evaluation",
    title: "Accessibility Evaluation",
  },
  {
    id: "Surveys and Questionnaires",
    title: "Surveys and Questionnaires",
  },
  { id: "User Interviews", title: "User Interviews" },
  {
    id: "Task Analysis",
    title: "Task Analysis",
  },
  { id: "A/B Testing", title: "A/B Testing" },
  { id: "Eye Tracking", title: "Eye Tracking" },
  {
    id: "Card Sorting",
    title: "Card Sorting",
  },
  {
    id: "First Click Testing",
    title: "First Click Testing",
  },
  {
    id: "Remote Testing",
    title: "Remote Testing",
  },
  {
    id: "Emotional Analysis",
    title: "Emotional Analysis",
  },
  {
    id: "Usability Benchmarking",
    title: "Usability Benchmarking",
  },
  {
    id: "Persona Development",
    title: "Persona Development",
  },
  {
    id: "Prototype Testing",
    title: "Prototype Testing",
  },
  {
    id: "Tree Testing",
    title: "Tree Testing",
  },
  {
    id: "Desirability Studies",
    title: "Desirability Studies",
  },
  {
    id: "Think-Aloud Protocol",
    title: "Think-Aloud Protocol",
  },
  {
    id: "Formative Evaluation",
    title: "Formative Evaluation",
  },
  {
    id: "Ethnographic Field Studies",
    title: "Ethnographic Field Studies",
  },
  {
    id: "Usability Metrics Analysis",
    title: "Usability Metrics Analysis",
  },
];

//Expertise
const expertises = [
  {
    id: "Accounting",
    title: "Accounting",
  },
  {
    id: "Airlines/Aviation",
    title: "Airlines/Aviation",
  },
  {
    id: "Apparel & Fashion",
    title: "Apparel & Fashion",
  },
  { id: "Automative", title: "Automative" },
  {
    id: "Banking",
    title: "Banking",
  },
  { id: "Biotechnology", title: "Biotechnology" },
  { id: "Computer Software", title: "Computer Software" },
  {
    id: "Construction",
    title: "Construction",
  },
  {
    id: "Consumer Electronics",
    title: "Consumer Electronics",
  },
  {
    id: "Consumer Goods",
    title: "Consumer Goods",
  },
  {
    id: "Education Management",
    title: "Education Management",
  },
  {
    id: "Financial Services",
    title: "Financial Services",
  },
  {
    id: "Healthcase",
    title: "Healthcase",
  },
  {
    id: "Hospitality",
    title: "Hospitality",
  },
  {
    id: "Information Technology & Services",
    title: "Information Technology & Services",
  },
  {
    id: "Internet",
    title: "Internet",
  },
  {
    id: "Management Consulting",
    title: "Management Consulting",
  },
  {
    id: "Marketing & Advertising",
    title: "Marketing & Advertising",
  },
  {
    id: "Media Production",
    title: "Media Production",
  },
  {
    id: "Pharmaceuticals",
    title: "Pharmaceuticals",
  },
  {
    id: "Real Estate",
    title: "Real Estate",
  },
  {
    id: "Retail",
    title: "Retail",
  },
  {
    id: "Telecommunications",
    title: "Telecommunications",
  },
  {
    id: "Transportation/Trucking/Railroad",
    title: "Transportation/Trucking/Railroad",
  },
  {
    id: "Utilities",
    title: "Utilities",
  },
];

// HASHMAP FOR GENERAL  TAB
const duplicateGeneralFilter = new Map();
// uxInfos.forEach((v, i) => {
//   duplicateUXFilter.set(v.id.toLowerCase().replace(/\s/g, ""), i.toString());
// });

// HASHMAP FOR UX  TAB
const duplicateUXFilter = new Map();
uxInfos.forEach((v, i) => {
  duplicateUXFilter.set(v.id.toLowerCase().replace(/\s/g, ""), i.toString());
});
// HASHMAP FOR UI  TAB
const duplicateUIFilter = new Map();
uiInfos.forEach((v, i) => {
  duplicateUIFilter.set(v.id.toLowerCase().replace(/\s/g, ""), i.toString());
});
// HASHMAP FOR MARKETING  TAB
const duplicateMarketingFilter = new Map();
marketingInfos.forEach((v, i) => {
  duplicateMarketingFilter.set(v.id.toLowerCase().replace(/\s/g, ""), i.toString());
});
// HASHMAP FOR BUSINESS  TAB
const duplicateBusinessFilter = new Map();
businessInfos.forEach((v, i) => {
  duplicateBusinessFilter.set(v.id.toLowerCase().replace(/\s/g, ""), i.toString());
});

// const n = uxInfos.length;
uxInfos.forEach((v, i) => {
  duplicateUXFilter.set(
    v.id.toLowerCase().replace(/\s/g, ""),
    i.toString()
  );
});
uiInfos.forEach((v, i) => {
  duplicateUIFilter.set(
    v.id.toLowerCase().replace(/\s/g, ""),
    i.toString()
  );
});
marketingInfos.forEach((v, i) => {
  duplicateMarketingFilter.set(
    v.id.toLowerCase().replace(/\s/g, ""),
    i.toString()
  );
});
businessInfos.forEach((v, i) => {
  duplicateBusinessFilter.set(
    v.id.toLowerCase().replace(/\s/g, ""),
    i.toString()
  );
});
// =========================== NEW INIT MAP STATE ===========================
infos.forEach((v, i) => {
  duplicateGeneralFilter.set(v.id.toLowerCase().replace(/\s/g, ""), i.toString());
});
// const n = infos.length;
fluents.forEach((v, i) => {
  duplicateGeneralFilter.set(
    v.id.toLowerCase().replace(/\s/g, ""),
    i.toString()
  );
});
// const m = fluents.length;
expertises.forEach((v, i) => {
  duplicateGeneralFilter.set(
    v.id.toLowerCase().replace(/\s/g, ""),
    i.toString()
  );
});
const ExpertRegistration = () => {

  // console.log("Bounty hunter")
  const dispatch = useDispatch();
  const expertRegistrationActiveTab = useSelector((state) => state.expertRegistration.expertActiveStep);
  // console.log(expertRegistrationActiveTab, "expertRegistrationActiveTab ygygyg");
  const testArray=[]
  const toggleExpertRegistrationTabs = (tab) => {
    if (expertRegistrationActiveTab !== tab) {
      dispatch(setRegistrationActiveTab(tab));
    }
  };
  // console.log(expertRegistrationActiveTab, "expertRegistrationActiveTab");
  // Personal Info


  return (
    <>
      <PageTitle pageTitle="Expert Registration" />

      <Layout>
        <section className="us-section us-section__expert-registration">
          <div className="us-section__inwrap">
            <Container>
              <Row>
                <Col sm="12">
                  <h1 className="us-section__title mb-10">Expert Registration</h1>

                  <div className="us-nav">
                  {/* {console.log(expertRegistrationActiveTab, "expertRegistrationActiveTab")} */}

                    {/*  TABS */}
                    <Nav tabs className="us-nav__tabs">
                      <NavItem className="us-nav__tab-item">
                        <NavLink
                          className={`us-nav__tab-link d-flex align-items-center ${expertRegistrationActiveTab == 0 ? "active" : ""}`}
                          // onClick={() => toggleExpertRegistrationTabs(0)}
                        >
                          <span className="us-nav__tab-number d-flex flex-column align-items-center justify-content-center me-2">1</span>
                          <span className="us-nav__tab-title">Personal Info</span>
                        </NavLink>
                      </NavItem>

                      <NavItem className="us-nav__tab-item">
                        <NavLink
                          className={`us-nav__tab-link d-flex align-items-center ${expertRegistrationActiveTab == 1 ? "active" : ""}`}
                          // onClick={() => toggleExpertRegistrationTabs(1)}
                        >
                          <span className="us-nav__tab-number d-flex flex-column align-items-center justify-content-center me-2">2</span>
                          <span className="us-nav__tab-title">Professional Info</span>
                        </NavLink>
                      </NavItem>

                      <NavItem className="us-nav__tab-item">
                        <NavLink
                          className={`us-nav__tab-link d-flex align-items-center ${expertRegistrationActiveTab == 2 ? "active" : ""}`}
                          // onClick={() => toggleExpertRegistrationTabs(2)}
                        >
                          <span className="us-nav__tab-number d-flex flex-column align-items-center justify-content-center me-2">3</span>
                          <span className="us-nav__tab-title">Domain Expertise</span>
                        </NavLink>
                      </NavItem>
                    </Nav>

                    {/* TAB CONTENT */}
                    <TabContent activeTab={expertRegistrationActiveTab} className="us-nav__tab-content mt-10">

                      {/* Personal Info Tab */}
                      <TabPane tabId={0} className="us-nav__tab-pane">

                        <PersonalInfoTab toggleExpertRegistrationTabs={toggleExpertRegistrationTabs}/>

                      </TabPane>


                      {/* Professional Info Tab */}
                      <TabPane tabId={1} className="us-nav__tab-pane">

                        <ProfessionalTab
                          uxInfos={uxInfos}
                          uiInfos={uiInfos}
                          marketingInfos={marketingInfos}
                          businessInfos={businessInfos}
                          toggleExpertRegistrationTabs={toggleExpertRegistrationTabs}
                          duplicateUXFilter={duplicateUXFilter}
                          duplicateUIFilter={duplicateUIFilter}
                          duplicateMarketingFilter={duplicateMarketingFilter}
                          duplicateBusinessFilter={duplicateBusinessFilter}
                          exp={mapMe}
                        />
                      </TabPane>
                      {/* {console.log(testArray,"testArrayOne")} */}
                      {/* Domain Expertise Tab */}
                      <TabPane tabId={2} className="us-nav__tab-pane">
                        <DomainExpertise toggleExpertRegistrationTabs={toggleExpertRegistrationTabs} duplicateMediaFilter={duplicateGeneralFilter}  exp={mapMe} />
                      </TabPane>
                    </TabContent>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </section>
      </Layout>
    </>
  )
}
export default ExpertRegistration;