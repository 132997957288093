import { call, put, takeEvery } from 'redux-saga/effects'
import instance from "../../../../api/api_instance";

async function getApi(data) {
  var formData = new FormData();
  data.files.forEach((items) => {
    formData.append('files', items);
  });
  formData.append('prototypes', JSON.stringify(data.prototypes));
  formData.append("deleteMedias", JSON.stringify(data.deletingFiles));
  try {
    const result = instance({
      url: `v1/project/${data.projectId}/evaluation-material-step-two/${data.evaluationId}`,
      method: "PATCH",
      data: formData,
    }).then((response) => {
      return response;
    })
    return await result;
  } catch (error) {
    throw error;
  }
}
function* updateEvelutionMaterial(action) {
  try {
    const res = yield call(getApi, action.payload);
    const toastrData = {
      content: "Evaluation Material updated successfully",
      type: "success"
    }
    yield put({ type: "UPDATE_EVALUATION_MATERIALS_STEP_TWO_SUCCESS", data: res.data.data });
    yield put({ type: "SET_TOASTR_SUCCESS", data: toastrData });

  } catch (e) {
    if (e.response.status === 400 || e.response.status === 404 || e.response.status === 409) {
      yield put({ type: "UPDATE_EVALUATION_MATERIALS_STEP_TWO_FAILED", message: e.response.data.message });
    } else {
      yield put({ type: "UPDATE_EVALUATION_MATERIALS_STEP_TWO_FAILED", message: "Some error occurred!" });
    }

    if (e.response.status === 500) {
      const toastrFailedData = {
        content: e.response.data.errors ? e.response.data.errors : "Something went wrong!",
        type: "failed"
      };
      yield put({ type: "SET_TOASTER_SUCCESS", data: toastrFailedData });
    }
  }
}

function* updateEvaluationMAterialStepTwoSaga() {
  yield takeEvery("UPDATE_EVALUATION_MATERIALS_STEP_TWO_REQUESTED", updateEvelutionMaterial);
}

export default updateEvaluationMAterialStepTwoSaga;
