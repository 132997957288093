import { Button, Col, FormGroup, Input } from "reactstrap";


const AddPortfolioUrl = ({ handlePortfolioUrlChange, value, key, indexNumber, allPortfolio, handleRemovePortfolio, urlError }) => {
  return (
    <>
      <FormGroup key={key} className="row us-form__input-showInput">
        <Col md="11">
          <Input
            type="text"
            id="appUrl"
            name="appUrl"
            placeholder="Portfolio URL"
            className="us-form__input"
            value={value.url}
            onChange={(e) => handlePortfolioUrlChange(e.target.value, indexNumber)}
          />
          <div className="us-form__error">{urlError}</div>
        </Col>

        {allPortfolio && allPortfolio.length > 1 &&
          <Col md="1" className="my-auto ">
           <Button  onClick={() => handleRemovePortfolio(indexNumber)} type="button" color="link" className="us-button__link p-0 mx-auto d-block">
              <img src="/assets/icons/icon-delete-red.svg" alt="" />
          </Button>
          </Col>
        }

      </FormGroup>
    </>
  )
}
export default AddPortfolioUrl;