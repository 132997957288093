import { Button, FormGroup, Input, Label } from "reactstrap";


const ProjectDeliveriesTab = () => {
  return (
    <>
      <div className="us-form__wrap-card us-custproject__deliveries position-relative overflow-hidden">
        <div className="us-custproject__deliveries-top position-relative">
          <div className="us-custproject__timestamp-wrap d-inline-flex align-items-center justify-content-center position-relative w-100 mb-6">
            <div className="us-custproject__timestamp position-relative d-inline">Today</div>
          </div>

          <div className="us-custproject__chat d-flex align-items-start mb-5">
            <div className="us-custproject__chat-img">
              <img src="https://picsum.photos/40/40" alt="" />
            </div>
            <div className="us-custproject__chat-content ms-3">
              <div className="us-custproject__chat-info">
                <div className="us-para font-medium mb-1">
                  <span>Wendy</span> |
                  <span className="text-xs color-gray3 font-normal ms-2">18:04</span>
                </div>
                <p className="us-para">Expert uploaded a file! File will be ready for download after being reviewed by Team Useralia</p>
              </div>
            </div>
          </div>
        </div>

        {/* UPLOAD PROJECT DOCUMENTS */}
        <div className="us-custproject__deliveries-bottom position-absolute bottom-0 start-0 w-100 px-8">
          <FormGroup className="us-form__wrap">
            <Label htmlFor="project_documents" className="us-form__input-file--label position-relative z-10 text-center w-100 h-sm rounded-3 overflow-hidden">
              <Input
                type="file"
                id="project_documents"
                name="project_documents"
                className="us-form__input-file position-relative h-sm"
              />
              <div className="us-form__input-file--info position-absolute top-0 start-0 d-flex flex-column flex-md-row align-items-center w-100 h-sm px-6">
                <div className="text-start">
                  <div className="us-para text-md font-normal mb-1">Upload necessary files and documents</div>
                  <small className="color-gray3">You can upload files and documents for experts to view</small>
                </div>
                <Button color="primary" type="button" className="us-button us-button__primary py-2 text-xs text-uppercase d-flex align-items-center ms-auto">
                  <span className="me-2">Upload Files</span>
                  <img src="/assets/icons/icon-upload-light.svg" alt="icon-upload" className="p-1" />
                </Button>
              </div>
            </Label>
          </FormGroup>
        </div>
      </div>
    </>
  )
}
export default ProjectDeliveriesTab;