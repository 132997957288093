import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import { resetTriggerEmailOTPErrors, triggerForgotPasswordEmailOTP } from "../../../redux/reduxes/global/resetPassword/actions";
import { useEffect } from "react";


const VerifyEmail = () => {
  const dispatch = useDispatch();

  // Fetch Data
  const triggerEmailOtpLoading = useSelector(state => state.resetPassword.triggerEmailOtpLoading);
  const triggerEmailOtpError = useSelector(state => state.resetPassword.triggerEmailOtpError);


  // States
  const [state, setState] = useState({
    email: "",

    errors: {
      email: "",
    }
  });

  // Reset Errors
  useEffect(() => {
    dispatch(resetTriggerEmailOTPErrors());
  }, [])

  // On Change Handler
  const onChangeHandler = (event) => {
    event.preventDefault();
    const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
    const { name, value } = event.target;
    let errors = state.errors;
    switch (name) {
      case "email":
        errors.email = validEmailRegex.test(value) ? "" : "Enter a valid email ID!";
        break;
      default:
        break;
    }
    setState((prevProps) => ({
      ...prevProps,
      errors,
      [name]: value,
    }));
    dispatch(resetTriggerEmailOTPErrors());
  }

  // Validate Form
  const validateForm = () => {
    let valid = true;
    let errors = state.errors;
    if (state.email == "") {
      errors.email = "Email ID is required."
      valid = false;
    }
    setState((prevProps) => ({
      ...prevProps,
      errors: errors,
    }))
    return valid;
  }

  // onSubmit Handler
  const onSubmitHandler = async (event) => {
    event.preventDefault();
    if (validateForm()) {
      const data = {
        email: state.email,
      }
      dispatch(triggerForgotPasswordEmailOTP(data))
    }
  }


  return (
    <>
      <div>
        <h1 className="us-section__title text-center mb-10">Forgot Password</h1>

        <Form onSubmit={(e) => onSubmitHandler(e)}>
          <FormGroup className="us-form__wrap mb-10">
            <Label htmlFor="email" className="us-form__label mb-1">Enter your registered Email ID <span className="text-danger">*</span></Label>
            <p className="text-sm color-gray3 mb-3">We will send you a OTP to validate your account authenticity</p>
            <Input
              type="email"
              id="email"
              name="email"
              placeholder="Enter your registered Email ID"
              className={`us-form__input ${state.errors.email ? "us-form__input-error" : ""}`}
              value={state.email}
              onChange={(e) => onChangeHandler(e)}
            />
            <div className="us-form__error">{state.errors.email}</div>
            {triggerEmailOtpError && <div className="us-form__error">{triggerEmailOtpError}</div>}
          </FormGroup>

          <Button
            type="submit"
            color="primary"
            className={`us-button us-button__primary w-100 text-uppercase ${triggerEmailOtpLoading ? "us-button__disabled" : "us-button__primary"}`}
            disabled={triggerEmailOtpLoading}
          >
            {triggerEmailOtpLoading ? "Loading..." : "Continue"}
          </Button>
        </Form>
      </div>
    </>
  )
}
export default VerifyEmail;