import * as type from "./types";

// SET EVELUTION MATERIAL POPUP
export function setEvelutionMaterialModal(data) {
  return {
    type: type.SET_EVELUTION_MATERIAL_POPUP,
    payload: data,
  };
}
// GET ALL EVALUATION MATERIAL DETAILS
export function getAllEvaluationMaterialDetails(data) {
  return {
    type: type.GET_ALL_EVALUATION_MATERIALS_DETAILS_REQUESTED,
    payload: data,
  }
}

// CREATE PROJECT - EVALUATION MATERIAL - STEP ONE
export function createEvaluationStepOne(data) {
  return {
    type: type.CREATE_EVALUATION_STEP_ONE_REQUESTED,
    payload: data,
  }
}

// UPDATE EVELUTUION MATERIAL STEP ONE
export function updateEvelutionMaterialStepOne(data) {
  return {
    type: type.UPDATE_EVALUATION_MATERIALS_STEP_ONE_REQUESTED,
    payload: data,
  }
}

// UPDATE EVELUTUION MATERIAL STEP TWO
export function updateEvelutionMaterialStepTwo(data) {
  return {
    type: type.UPDATE_EVALUATION_MATERIALS_STEP_TWO_REQUESTED,
    payload: data,
  }
}

// UPDATE EVELUTUION MATERIAL STEP THREE
export function updateEvelutionMaterialStepThree(data) {
  return {
    type: type.UPDATE_EVALUATION_MATERIALS_STEP_THREE_REQUESTED,
    payload: data,
  }
}

// DELETE EVALUATION MATERIALS
export function deleteEvaluationMaterials(data) {
  return {
    type: type.DELETE_EVALUATION_MATERIALS_REQUESTED,
    payload: data,
  }
}

// GET EVALUATION MATERIAL  DETAILS - STEP ONE
export function getEvaluationStepOneDetails(data) {
  return {
    type: type.GET_EVALUATION_STEP_ONE_DETAILS_REQUESTED,
    payload: data,
  }
}

// CREATE PROJECT - EVALUATION MATERIAL - STEP TWO
export function getEvaluationStepTwoDetails(data) {
  return {
    type: type.GET_EVALUATION_STEP_TWO_DETAILS_REQUESTED,
    payload: data,
  }
}

// GET EVALUATION MATERIALS DETAILS - STEP THREE
export function getEvaluationStepThreeDetails(data) {
  return {
    type: type.GET_EVALUATION_STEP_THREE_DETAILS_REQUESTED,
    payload: data,
  }
}

// Delete EVELUTUION MATERIAL FILES
export function deleteEvelutionMaterialsFiles(data) {
  return {
    type: type.DELETE_EVALUATION_MATERIALS_FILES_REQUESTED,
    payload: data,
  }
}

// EVALUATION MATERIAL ACTIVE STEP
export function setEvaluationMaterialActiveStep(data) {
  return {
    type: type.SET_EVALUATION_MATERIAL_ACTIVE_STEP_REQUESTED,
    payload: data,
  }
}
// DELETE EVALUATION MATERIAL PROTOTYPE
export function deleteEvaluationMaterialPrototype(data) {
  return {
    type: type.DELETE_EVALUATION_MATERIAL_PROTOTYPE_REQUESTED,
    payload: data,
  }
}