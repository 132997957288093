import { Link } from "react-router-dom";
import { Row, Col, } from "reactstrap";
import ExpertRegistrationDetails from "./expertRedistrationDetails";
import { useSelector } from "react-redux";


// const data = ["UX Design and Research Expert", "UI Design Expert"];

const ExpertProfileTab = () => {
  // const expert = true;
  const profileDetails = useSelector(state => state.profile.profileDetails);
  return (
    <>
      {(profileDetails&&profileDetails.expertRegistrationStatus)&&(profileDetails.expertRegistrationStatus === "not-started" || profileDetails.expertRegistrationStatus === "started")?
        <Row className="g-3 ">
          <Col sm="12 us-form__wrap-card  align-items-center">
            <Row className="g-3">
              <Col sm="12">
                <div className="d-flex justify-content-between flex-column flex-md-row align-items-center">
                  <div className="mb-3 mb-md-0">
                    <h4 className="text-xl font-bold">Become and Expert</h4>
                    <p className="text-lg mt-2">Few tips to get started</p>
                  </div>
                  <div>
                    <img
                      src="/assets/images/customer/account-settings/become-expert.svg"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="g-3">
              <Col sm="12">
                <Link
                  to="/expert-registration"
                  exact={true}
                  className="us-button us-button__primary ms-auto"
                >
                  Register as an Expert
                </Link>
              </Col>
            </Row>
            {/* <Link to="/expert-registration" exact={true} className="us-button us-button__primary ms-auto">Register as an Expert</Link> */}
          </Col>
          <Col sm="12 position-relative overflow-hidden us-form__wrap-card">
            <div className=" d-flex justify-content-between flex-column flex-md-row align-items-center">
              <div className="mb-3 mb-md-0">
                <h4 className="text-xl font-bold">How it works</h4>
                <p className="text-lg mt-2">Few tips to get started</p>
              </div>
              <div>
                <img
                  src="/assets/images/customer/account-settings/how-it-works.svg"
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
          </Col>
          {/* <Col sm="12">
          <div className="us-form__wrap-card d-flex align-items-center">
            <Link to="/" exact={true} className="us-button__link underline">Learn More</Link>

 <Link to="/expert-registration" exact={true} className="us-button us-button__primary ms-auto">Register as an Expert</Link>
          </div>
        </Col> */}
        </Row>
        :

        <ExpertRegistrationDetails />

}

      {/* more details : End */}
    </>
  );
};
export default ExpertProfileTab;
