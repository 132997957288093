import { Button, Col, FormGroup, Input } from "reactstrap";


const AddPrototypeUrl = ({ handlePrototypeTitleChange, handlePrototypeUrlChange, value, key, indexNumber, allPrototypes, handleRemovePrototype, titleError, urlError }) => {
  return (
    <>
      <FormGroup key={key} className="row us-form__input-showInput">
        <Col md="5">
          <Input
            type="text"
            id="appDescription"
            name="appDescription"
            placeholder="Short title or description e.g. staging url, wireframes"
            className="us-form__input"
            value={value.title}
            onChange={(e) => handlePrototypeTitleChange(e.target.value, indexNumber)}
          />
          <div className="us-form__error">{titleError}</div>
        </Col>
        <Col md="6">
          <Input
            type="text"
            id="appUrl"
            name="appUrl"
            placeholder="Enter App Live URL, prototype link here..."
            className="us-form__input"
            value={value.url}
            onChange={(e) => handlePrototypeUrlChange(e.target.value, indexNumber)}
          />
          <div className="us-form__error">{urlError}</div>
        </Col>

        {allPrototypes && allPrototypes.length > 1 &&
          <Col md="1" className="my-auto ">
           <Button  onClick={() => handleRemovePrototype(indexNumber,value)} type="button" color="link" className="us-button__link p-0 mx-auto d-block">
              <img src="/assets/icons/icon-delete-red.svg" alt="" />
          </Button>
          </Col>
        }

      </FormGroup>
    </>
  )
}
export default AddPrototypeUrl;