import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import AddPrototypeUrl from "./addPrototypeUrl";
// import {
//   // createEvaluationStepTwo,
//   getEvaluationStepOneDetails,
//   deleteEvelutionMaterialsFiles,
//   getEvaluationStepTwoDetails,
//   setEvaluationMaterialActiveStep,
//   updateEvelutionMaterialStepTwo,
// } from "../../../../redux/reduxes/customer/projects/projectActions";
import { updateEvelutionMaterialStepTwo, deleteEvelutionMaterialsFiles, getEvaluationStepTwoDetails, setEvaluationMaterialActiveStep, deleteEvaluationMaterialPrototype } from "../../../../redux/reduxes/customer/evalutionMaterial/projectAction";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";

// icons
const audioIcon = "/assets/icons/file-upload-icons/image-audio.png";
const cssIcon = "/assets/icons/file-upload-icons/u-file-ico-css.png";
const csvIcon = "/assets/icons/file-upload-icons/u-file-ico-csv.png";
const docIcon = "/assets/icons/file-upload-icons/u-file-ico-doc.png";
const htmlIcon = "/assets/icons/file-upload-icons/u-file-ico-html.png";
const jpgIcon = "/assets/icons/file-upload-icons/u-file-ico-jpg.png";
const jsonIcon = "/assets/icons/file-upload-icons/u-file-ico-json.png";
const odpIcon = "/assets/icons/file-upload-icons/u-file-ico-odp.png";
const odsIcon = "/assets/icons/file-upload-icons/u-file-ico-ods.png";
const odtIcon = "/assets/icons/file-upload-icons/u-file-ico-odt.png";
const pdfIcon = "/assets/icons/file-upload-icons/u-file-ico-pdf.png";
const pngIcon = "/assets/icons/file-upload-icons/u-file-ico-png.png";
const pptIcon = "/assets/icons/file-upload-icons/u-file-ico-ppt.png";
const textIcon = "/assets/icons/file-upload-icons/u-file-ico-txt.png";
const videoIcon = "/assets/icons/file-upload-icons/u-file-ico-video.png";
const xlsIcon = "/assets/icons/file-upload-icons/u-file-ico-xls.png";
const xmlIcon = "/assets/icons/file-upload-icons/u-file-ico-xml.png";
const zipIcon = "/assets/icons/file-upload-icons/u-file-ico-zip.png";
const defaultIcon = "/assets/icons/file-upload-icons/image-other.png";

const EvaluationMaterialStepTwo = ({ projectId, update }) => {
  const dispatch = useDispatch();

  // const search = useLocation().search;
  // const project = new URLSearchParams(search).get('project');
  // console.log(project, "huhhdhuhuhuhduhuhuhduhuhuhd IJIUHUIJIJIJIIK");

  // Fetch Data
  const updateEvaluationStepTwoLoading = useSelector((state) => state.evelutionMaterials.updateEvaluationStepTwoLoading);
  const details = useSelector((state) => state.evelutionMaterials.evaluationStepTwoDetails);

  const evaluationId = useSelector(state => state.evelutionMaterials.evaluationId);

  // console.log(details, "shvrjerk");

  // States
  const [state, setState] = useState({
    prototypes: [],
    files: [],
    uploadedFiles: [],

    errors: {
      prototypes: "",
      files: "",
    },
  });

  // Add Multiple Prototype URLs
  const [allPrototypes, setAllPrototypes] = useState([
    {
      title: "",
      url: "",
      titleError: "",
      urlError: "",
    },
    ...state.prototypes,
  ]);
  const handleAddPrototype = () => {
    const newPrototype = { title: "", url: "" };
    setAllPrototypes([...allPrototypes, newPrototype]);
  };
console.log(details, "details outside");
// Get Details
useEffect(() => {
  let oldPrototypes =
    details &&
    details.evaluationMaterial &&
    details.evaluationMaterial.prototypes &&
    details.evaluationMaterial.prototypes.length === 0
      ? allPrototypes
      : [];
  console.log("Adding Evaluation Material", details);
  details &&
    details.evaluationMaterial &&
    details.evaluationMaterial.attachedMedias &&
    setState((prevProps) => ({
      ...prevProps,
      prototypes: details.evaluationMaterial.prototypes,
      uploadedFiles: details.evaluationMaterial.attachedMedias,
    }));
  details &&
    details.evaluationMaterial &&
    details.evaluationMaterial.prototypes &&
    setAllPrototypes([
      ...oldPrototypes,
      ...details.evaluationMaterial.prototypes,
    ]);
}, [details]);

// Dispatch Details
useEffect(() => {
  const data = {
    evaluationId: evaluationId,
    projectId: projectId,
  };
  dispatch(getEvaluationStepTwoDetails(data));
}, []);

// onChange Prototype Title
const handlePrototypeTitleChange = (value, indexNumber) => {
  const updatingAllPrototypes = allPrototypes.map((items, index) => {
    if (indexNumber === index) {
      return {
        ...items,
        title: value,
        titleError: value === "" ? "Enter Prototype Title" : "",
      };
    }
    return items;
  });
  setAllPrototypes(updatingAllPrototypes);
};

// onChange Prototype URL
const handlePrototypeUrlChange = (value, indexNumber) => {
  const validUrlRegex = new RegExp(
    "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?"
  );
  const updatingAllPrototypes = allPrototypes.map((items, index) => {
    if (indexNumber === index) {
      return {
        ...items,
        url: value,
        urlError:
          value === ""
            ? "Enter Prototype URL"
            : !validUrlRegex.test(value)
            ? "Invalid Url"
            : "",
      };
    }
    return items;
  });
  setAllPrototypes(updatingAllPrototypes);
};

// Remove the Prototype Inputs
const handleRemovePrototype = (index, prototype) => {
  if (prototype._id) {
    //Make Backend call Here Redux
    const data = {
      prototypeId: prototype._id,
      evaluationId: evaluationId,
      projectId: projectId,
    };
    dispatch(deleteEvaluationMaterialPrototype(data));
  }
  const filteredPrototypes = [...allPrototypes];
  filteredPrototypes.splice(index, 1);
  setAllPrototypes(filteredPrototypes);
};

// onMedia Upload Handler
const onMediaUploadHandler = (event) => {
  const newFilesUploads = [];
  const selectedFiles = event.target.files;
  let errors = state.errors;
  if (selectedFiles.length == 0) {
    errors.files = "Upload project documents";
  } else if (selectedFiles.length > 10) {
    errors.files = "Maximum file upload limit is 10 files";
  } else {
    errors.files = "";
  }

  const maxSize = 1 * 1024 * 1024 * 1024; // 1GB in Bytes
  for (let i = 0; i < selectedFiles.length; i++) {
    const file = selectedFiles[i];
    if (file.size > maxSize) {
      errors.files = "File size exceeds the maximum limit of 1 GB";
      break;
    } else {
      newFilesUploads.push(selectedFiles[i]);
    }
  }

  setState((prevProps) => ({
    ...prevProps,
    files: [...state.files, ...newFilesUploads],
    errors,
  }));
};

// Remove Uploaded Media from the List
const handleRemoveMediaFromList = (index) => {
  let errors = state.errors;
  const removedFiles = [...state.files];
  removedFiles.splice(index, 1);
  setState((prevProps) => ({
    ...prevProps,
    files: removedFiles,
    errors,
  }));
};

// Validate Form
const validateForm = () => {
  let valid = true;
  let errors = state.errors;
  const prototypeErrors = allPrototypes;
  prototypeErrors.forEach((item) => {
    if (item.title === "") {
      item.titleError = "Prototype Title is required.";
      valid = false;
    }
    if (item.url === "") {
      item.urlError = "Prototype URL is required.";
      valid = false;
    }
  });
  setAllPrototypes([...prototypeErrors]);
  if (state.files.length > 10) {
    errors.files = "Maximum file upload limit is 10 files";
    valid = false;
  }
  setState((prevProps) => ({
    ...prevProps,
    errors: errors,
  }));
  return valid;
};

// onSubmit Handler
const onSubmitHandler = async (event) => {
  event.preventDefault();

  // Remove errors states from the list
  const filteredPrototypesList = [];
  allPrototypes &&
    allPrototypes.forEach((items) => {
      if (!items._id) {
        filteredPrototypesList.push({ title: items.title, url: items.url });
      }
    });

  // set files to new object
  const filteredFiles = [];
  state.files &&
    state.files.forEach((items) => {
      filteredFiles.push(items);
    });

  if (validateForm()) {
    const data = {
      prototypes: filteredPrototypesList,
      files: filteredFiles,
      projectId: projectId,
      evaluationId: evaluationId,
      deletingFiles: [],
    };
    dispatch(updateEvelutionMaterialStepTwo(data));
  }
};

// Toggle Input to Show/Hide the APP URL inputs
const [showAppUrlInput, setShowAppUrlInput] = useState(false);
const toggleInputEvent = () => {
  setShowAppUrlInput(!showAppUrlInput);
};

// delete uploaded file
const handleRemoveMediaFromUploadedList = (id) => {
  const data = {
    prototypes: [],
    files: [],
    projectId: projectId,
    evaluationId: evaluationId,
    deletingFiles: [id],
  };
  dispatch(deleteEvelutionMaterialsFiles(data));
  toggle();
};

// modal states
const [modal, setModal] = useState(false);
const toggle = () => setModal(!modal);

return (
  <>
    <Form onSubmit={(e) => onSubmitHandler(e)}>
      {/* APP/PLAY STORE/PROTOTYPE URL */}
      <FormGroup className="us-form__wrap mb-8">
        <Label htmlFor="appUrl" className="us-form__label">
          Enter app store/play store/prototype/website URL (Eg:
          https://www.xyz.com/)
        </Label>
        {/* <div onClick={toggleInputEvent}>
            <Input
              type="text"
              id="appUrl"
              placeholder="Enter App Live URL, prototype link here..."
              className={`us-form__input us-form__input-toggleInput ${showAppUrlInput ? "d-none" : 'd-block'}`}
            />
          </div> */}

        {/* {showAppUrlInput && */}
        <div
          className={`${
            allPrototypes && allPrototypes.length > 3 && "us-form__input-lists"
          }`}
        >
          {allPrototypes &&
            allPrototypes.length > 0 &&
            allPrototypes.map((prototype, index) => {
              return (
                <AddPrototypeUrl
                  allPrototypes={allPrototypes}
                  key={index}
                  indexNumber={index}
                  value={prototype}
                  handlePrototypeTitleChange={handlePrototypeTitleChange}
                  handlePrototypeUrlChange={handlePrototypeUrlChange}
                  handleRemovePrototype={handleRemovePrototype}
                  titleError={prototype.titleError}
                  urlError={prototype.urlError}
                />
              );
            })}
        </div>
        {/* } */}

        <div className="mt-6 text-end">
          <Button
            onClick={handleAddPrototype}
            type="button"
            color="link"
            className="us-button__link text-sm text-primary body-font font-medium p-0"
          >
            Add another URL +
          </Button>
        </div>
      </FormGroup>

      {/* Show Uploaded Files */}
      {state.uploadedFiles && state.uploadedFiles.length > 0 && (
        <>
          <div className="font-medium mt-10 mb-5">Uploaded files:</div>
          <ul className="us-form__file-lists d-flex align-items-start justify-content-start flex-wrap gap-4 pt-2">
            {state.uploadedFiles.map((file, index) => {
              return (
                <>
                  <li
                    className="us-form__file-list position-relative"
                    key={index}
                  >
                    <div className="us-form__file-listImg">
                      <img src={file.thumbnailUrl} alt={file._id} />
                    </div>
                    <Button
                      onClick={toggle}
                      type="button"
                      color="link"
                      className="us-form__file-listbtn d-flex flex-column align-items-center justify-content-between position-absolute p-0"
                    >
                      <img
                        src="/assets/icons/icon-cancel-red.svg"
                        alt="icon-cancel"
                        width={18}
                        height={18}
                      />
                    </Button>
                    <Modal
                      isOpen={modal}
                      toggle={toggle}
                      size="md"
                      className="us-modal__dialog"
                    >
                      <ModalBody className="us-modal__body">
                        <h5 className="us-section__title-block mb-3 text-center">
                          Delete Evaluation Material
                        </h5>
                        <p className="text-center">
                          {" "}
                          Are you sure you want to delete this material?
                        </p>
                        <div className="border-0 d-flex justify-content-center mt-6">
                          <Button
                            onClick={toggle}
                            color="danger"
                            type="button"
                            className="us-button us-button__danger-outline text-uppercase px-8"
                          >
                            {" "}
                            NO
                          </Button>
                          <Button
                            color="primary"
                            type="button"
                            className={`us-button us-button__primary text-uppercase px-10 ms-5 us-button__primary`}
                            onClick={() =>
                              handleRemoveMediaFromUploadedList(file._id)
                            }
                          >
                            Yes
                          </Button>
                        </div>
                      </ModalBody>
                    </Modal>
                  </li>
                </>
              );
            })}
          </ul>
        </>
      )}

      {/* UPLOAD PROJECT DOCUMENTS */}
      <FormGroup className="us-form__wrap">
        <Label className="us-form__label">
          Upload project/app document, research report, etc. (If any)
        </Label>{" "}
        <br />
        <Label
          htmlFor="files"
          className="us-form__input-file--label position-relative z-10 text-center w-100 overflow-hidden"
        >
          <Input
            type="file"
            id="files"
            name="files"
            className="us-form__input-file position-relative"
            multiple
            onChange={(e) => onMediaUploadHandler(e)}
          />
          <div className="us-form__input-file--info position-absolute top-0 start-0 d-flex flex-column align-items-center justify-content-center w-100">
            <img src="/assets/icons/icon-file-bg.svg" alt="icon-file-bg" />
            <div className="us-para text-md font-normal mt-3 mb-1">
              Upload or drop files here, or{" "}
              <span className="color-primary font-medium">Browse</span>
            </div>
            {/* <small className="color-gray3">support zip, docx, pdf, doc</small> */}
          </div>
        </Label>
        <div className="us-form__error">{state.errors.files}</div>
      </FormGroup>

      {/* Show Files to upload */}
      {state.files && state.files.length > 0 && (
        <>
          <div className="font-medium mt-10 mb-5">
            Selected files to upload:
          </div>
          <ul className="us-form__file-lists d-flex align-items-start justify-content-start flex-wrap gap-4 pt-2">
            {state.files.map((file, index) => {
              // {
              //   console.log(file, "type");
              // }
              return (
                <>
                  <li
                    className="us-form__file-list position-relative"
                    key={index}
                  >
                    <div className="us-form__file-listImg">
                      <img
                        src={
                          file.type.includes("image/")
                            ? URL.createObjectURL(file)
                            : file.type.includes("audio/")
                            ? audioIcon
                            : file.type.includes("video/")
                            ? videoIcon
                            : file.type === "application/x-zip-compressed"
                            ? zipIcon
                            : file.type === "application/zip"
                            ? zipIcon
                            : file.type === "application/pdf"
                            ? pdfIcon
                            : file.type === "text/css"
                            ? cssIcon
                            : file.type === "text/csv"
                            ? csvIcon
                            : file.type ===
                              "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                            ? docIcon
                            : file.type === "application/msword"
                            ? docIcon
                            : file.type === "text/html"
                            ? htmlIcon
                            : file.type === "application/json"
                            ? jsonIcon
                            : file.type ===
                              "application/vnd.oasis.opendocument.spreadsheet"
                            ? odsIcon
                            : file.type ===
                              "application/vnd.oasis.opendocument.text"
                            ? odtIcon
                            : file.type === "application/pdf"
                            ? pdfIcon
                            : file.type === "application/vnd.ms-powerpoint"
                            ? pptIcon
                            : file.type ===
                              "application/vnd.openxmlformats-officedocument.presentationml.presentation"
                            ? pptIcon
                            : file.type === "text/plain"
                            ? textIcon
                            : file.type === "application/vnd.ms-excel"
                            ? xlsIcon
                            : file.type ===
                              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            ? xlsIcon
                            : file.type.includes("xml")
                            ? xmlIcon
                            : file.name.includes("odp")
                            ? odpIcon
                            : file.name.includes("ods")
                            ? odsIcon
                            : defaultIcon
                        }
                        alt={file.name}
                      />
                    </div>
                    <Button
                      onClick={() => handleRemoveMediaFromList(index)}
                      type="button"
                      color="link"
                      className="us-form__file-listbtn d-flex flex-column align-items-center justify-content-between position-absolute p-0"
                    >
                      <img
                        src="/assets/icons/icon-cancel-red.svg"
                        alt="icon-cancel"
                        width={18}
                        height={18}
                      />
                    </Button>
                  </li>
                </>
              );
            })}
          </ul>
        </>
      )}

      <div className="d-flex align-items-center justify-content-md-end mt-10 mt-md-20">
        <Button
          onClick={() => dispatch(setEvaluationMaterialActiveStep(0))}
          type="button"
          color="primary"
          outline
          className="us-button us-button__primary-outline px-8 me-5 text-uppercase"
        >
          Previous
        </Button>
        <Button
          type="submit"
          color="primary"
          disabled={updateEvaluationStepTwoLoading}
          className={`us-button us-button__primary  text-uppercase ${
            updateEvaluationStepTwoLoading
              ? "us-button__disabled px-10"
              : "us-button__primary px-12"
          }`}
        >
          {updateEvaluationStepTwoLoading ? "Saving..." : "Next"}
        </Button>
      </div>
    </Form>
  </>
);
};
export default EvaluationMaterialStepTwo;
