import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import auth from '../reduxes/global/auth/authReducer';
import profile from '../reduxes/customer/profile/profileReducer';
import resetPassword from '../reduxes/global/resetPassword/resetPasswordReducer';
import toastr from '../reduxes/global/toastr/toastrReducer';
// import project from '../reduxes/customer/projects/projectReducer';
import projectNew from "../reduxes/customer/projects-new/projectReducer";
import evelutionMaterials from "../reduxes/customer/evalutionMaterial/projectReducer";
import general from "../reduxes/customer/general/reducer";
import expertRegistration from "../reduxes/customer/expertRegistration/reducer";

const persistConfig = {
  key: "root",
  storage: storage,

};

const authPersistConfig = {
  key: 'auth',
  storage: storage,
  // whitelist: [
  //   "email",
  //   "mobileNumber",
  //   "registerUser",
  //   "registerUserLoading",
  //   "registerUserError",
  //   "registerStep",
  //   "linkedInUser",
  //   "accessToken",
  //   "emailOtpToken",
  //   "mobileOtpToken",
  //   "confirmEmailMobileOTPDetails",
  //   "loginUser",
  //   "setShowRegisterUpdateEmailModal",
  //   "setShowRegisterUpdateMobileNumberModal",
  //   "linkedInLoginTokenData",
  //   "profileDetails",
  // ],
  blacklist: [
    "confirmEmailMobileOTPLoading",
    "confirmEmailMobileOTPError",
    "verifyEmailMobileOTPLoading",
    "verifyEmailMobileOTPError",
    "loginUserLoading",
    "loginUserError",
    "logoutUserLoading",
    "logoutUserError",
    "updateRegisterEmailLoading",
    "updateRegisterEmailError",
    "updateRegisterMobileNumberLoading",
    "resendEmailOTPConfirmationLoading",
    "resendEmailOTPConfirmationError",
    "resendEmailOTPVerificationLoading",
    "resendEmailOTPVerificationError",
    "resendMobileOTPConfirmationLoading",
    "resendMobileOTPConfirmationError",
    "linkedInLoginTokenLoading",
    "linkedInLoginTokenError",
    "screenShotLoading",
  ]
}

const rootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, auth),
  resetPassword: resetPassword,
  profile: profile,
  toastr: toastr,
  // project: project,
  projectNew: projectNew,
  evelutionMaterials: evelutionMaterials,
  general: general,
  expertRegistration: expertRegistration,
});
export default persistReducer(persistConfig, rootReducer);