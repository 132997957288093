import { call, put, takeEvery } from 'redux-saga/effects'
import instance from "../../../../api/api_instance";

async function getApi(data) {

  var formData = new FormData();
  // formData.append('prototypes', JSON.stringify(data.prototypes));
  data.files.forEach((items) => {
    formData.append('files', items);
  });
  formData.append('prototypes', JSON.stringify(data.prototypes));
  formData.append("deleteMedias", JSON.stringify(data.deletingFiles));
  // const evaluationId = localStorage.getItem("evaluationId");
  try {
    const result = instance({
      url: `v1/project/${data.projectId}/evaluation-material-step-two/${data.evaluationId}`,
      method: "PATCH",
      data: formData,
    }).then((response) => {
      return response;
    })
    return await result;
  } catch (error) {
    throw error;
  }
}
function* fetchProjects(action) {
  try {
    const res = yield call(getApi, action.payload);
    const toastrData = {
      content: "Evaluation Material Step Two added successfully",
      type: "success"
    }
    if (action.payload.deletingFiles.length === 0) {
      yield put({ type: "DELETE_EVALUATION_MATERIALS_FILES_SUCCESS", evaluationMaterialData: res.data.data });
      const data = {
        projectId: action.payload.projectId,
        evaluationId: action.payload.evaluationId,
      }
      yield put({ type: "GET_EVALUATION_STEP_THREE_DETAILS_REQUESTED", payload: data });
    } else {
      const data = {
        projectId: action.payload.projectId,
        evaluationId: action.payload.evaluationId,
      }
      yield put({ type: "GET_EVALUATION_STEP_TWO_DETAILS_REQUESTED", payload: data });
    }
    yield put({ type: "SET_TOASTR_SUCCESS", data: toastrData });
  } catch (e) {
    if (e.response.status === 400 || e.response.status === 403 || e.response.status === 404 || e.response.status === 409) {
      yield put({ type: "DELETE_EVALUATION_MATERIALS_FILES_FAILED", message: e.response.data.message });
    } else {
      yield put({ type: "DELETE_EVALUATION_MATERIALS_FILES_FAILED", message: "Some error occurred!" });
    }

    if (e.response.status === 500) {
      const toastrFailedData = {
        content: e.response.data.errors ? e.response.data.errors : "Something went wrong!",
        type: "failed"
      };
      yield put({ type: "SET_TOASTER_SUCCESS", data: toastrFailedData });
    }
  }
}

function* deleteEvaluationMaterialsFilesSaga() {
  yield takeEvery("DELETE_EVALUATION_MATERIALS_FILES_REQUESTED", fetchProjects);
}

export default deleteEvaluationMaterialsFilesSaga;
