import * as type from './types';

const initialState = {
  show: false,
  content: "",
  type: "success",
}

export default function toastr(state = initialState, action) {
  switch (action.type) {
    case type.SET_TOASTR_SUCCESS:
      return {
        ...state,
        show: true,
        content: action.data.content,
        type: action.data.type,
      }

    case type.ADD_LOCAL_TOASTR_SUCCESS:
      return {
        ...state,
        show: true,
        content: action.payload.content,
        type: action.payload.type,
      }

    case type.SET_TOASTR_CLOSED:
      return {
        ...state,
        show: false,
        content: "",
        type: "success",
      }

    default:
      return state;
  }
}