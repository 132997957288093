import React from 'react';
import PageTitle from '../common/pageTitle';
import Layout from '../../../layout';
import { Col, Container, Row } from 'reactstrap';
import {  useDispatch, useSelector } from 'react-redux';
import VerifyOTP from './verifyOTP';
import { motion, AnimatePresence } from "framer-motion";
import RegistrationForm from './registrationForm';
import { useEffect } from 'react';
import { getScreenShots } from '../../../redux/reduxes/global/auth/actions';


const ProjectRegisterPage = ({ screenShot}) => {
  const dispatch = useDispatch();
  const screenShotError = useSelector((state) => state.auth.screenShotError);

  // Fetch Data
  const registerStep = useSelector(state => state.auth.registerStep);

  // useEffect(() => {
  //   dispatch(getRegisterStep())
  // }, [])

  return (
    <>
      <PageTitle pageTitle="Register" />

      <Layout>
        {/* Register Section */}

        <section
          className="us-section us-section__auth-register"
          style={{
            background:
              "url('/assets/images/auth/auth-bg.png') no-repeat 80% 10%",
          }}
        >
          <div className="us-section__inwrap">
            <Container>
              <Row>
                <Col lg="6" xl="6" className="my-auto">
                  <div className="us-auth__left d-none d-lg-block">
                    <div className="us-auth__left-imgwrap text-center">
                      {screenShotError == "" ?
                        <>
                         <h2 className="mb-5">We’ve found your project!</h2>
                      <h4 className="mb-5 font-medium">
                        Complete the registration to initiate your evaluation.
                          </h4>
                        </>
                        :
                        <>
                         <h2 className="mb-5">We could not find your project!</h2>
                      <h4 className="mb-5 font-medium">
                      Enter a valid URL or continue registering.
                          </h4>
                        </>
}

                      {/* <img src="/assets/images/auth/auth-banner.png" alt="auth-banner" className='img-fluid' /> */}
                      {/* {TEST HERE} */}

                      <motion.div
                        initial={{ opacity: 0.1 }}
                        animate={{ opacity: 1 }}
                        transition={{
                          duration: 1.2,
                          // bounce: 0.6,
                        }}
                        exit={{ opacity: 0, transition: { duration: 0.2 } }}
                        className="overflow-hidden mb-4 border"
                        style={{ borderRadius: "4em 4em 4em 10em" }}
                      >
                        <img
                        src={screenShot || "/assets/images/auth/useralia-img.png"}
                          // src="https://useralia-dev.sgp1.digitaloceanspaces.com/snapshots/1699606437779.jpg"
                          alt="auth-banner"
                          className="w-100 h-100"
                          style={{objectFit:"cover",objectPosition:"top"}}
                        />
                      </motion.div>
                      {/* {TEST ENDS} */}
                    </div>
                  </div>
                </Col>

                <Col
                  md={{ size: 10, offset: 1 }}
                  lg={{ size: 6, offset: 1 }}
                  xl="5"
                >
                  <div className="us-auth__right px-6 py-8 px-lg-10 py-lg-10">
                    {registerStep === 0 ? (
                      // Registration Form
                      <RegistrationForm />
                    ) : (
                      // Verify Email and Mobile OTP
                      <VerifyOTP />
                    )}
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </section>
      </Layout>
    </>
  );
}
export default ProjectRegisterPage;
