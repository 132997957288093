import React from "react";
import {
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom';

import CheckLogin from "./checkIsLogin";
import PublicRoute from "./publicRoute";
import GeneralRoute from "./generalRoute";
import CustomerPrivateRoute from "./customerPrivateRoute";

import Login from "../containers/authentication/login";
import Register from "../containers/authentication/register";
import ProRegister from "../containers/authentication/createProjectAndRegister"
import CreateProject from "../containers/customer/projects/createProject";
import ProjectPreview from "../containers/customer/projects/projectPreview";
import Projects from "../containers/customer/projects";
import ProjectDetails from "../containers/customer/projects/projectDetails";
import AccountSettings from "../containers/customer/account-settings";
import EmailSelection from "../containers/customer/account-settings/emailSelection";
import Dashboard from "../containers/customer/dashboard";
import ExpertRegistration from "../containers/authentication/expertRegistration";
import ResetPassword from "../containers/authentication/resetPassword";
import PrivacyPolicy from "../containers/privacyPolicy";
import TermsConditions from "../containers/termsConditions";
import LinkedInLogin from "../containers/authentication/linkedInLogin";
import NotFound from "../containers/notFound";
import InternalServerError from "../containers/internalServerError";
// import verifyEmailOTP from "../containers/customer/account-settings/emailOTP";
// import verifyEmailOTP from "../containers/customer/account-settings/emailOTP";




const AppRoutes = () => {
  return (
    <Router>
      <Switch>
        {/* Authentication */}
        <CheckLogin restricted={false} path="/" exact={true} />
        <PublicRoute
          component={Login}
          restricted={true}
          path="/login"
          exact={true}
        />
        <PublicRoute
          component={Register}
          restricted={true}
          path="/register"
          exact={true}
        />
        <PublicRoute
          component={ResetPassword}
          restricted={true}
          path="/reset-password"
          exact={true}
        />
        <PublicRoute
          component={LinkedInLogin}
          restricted={true}
          path="/linkedin-login/:linkedInToken"
          exact={true}
        />

        <GeneralRoute
          component={ProRegister}
          restricted={true}
          path="/analyser/:projectURL/:urlType"
          exact={true}
        />

        {/* Terms & Conditions == Privacy Policy */}
        <PublicRoute
          component={PrivacyPolicy}
          path="/privacy-policy"
          exact={true}
        />
        <PublicRoute
          component={TermsConditions}
          path="/terms-conditions"
          exact={true}
        />

        {/* 500  */}

        <PublicRoute
          component={InternalServerError}
          path="/server-error"
          exact={true}
        />

        {/* Expert Registration  */}
        <CustomerPrivateRoute
          component={ExpertRegistration}
          path="/expert-registration"
          exact={true}
        />

        {/* Customer Dashboard */}
        <CustomerPrivateRoute
          component={Dashboard}
          path="/customer"
          exact={true}
        />

        {/* Customer Projects Routes */}
        <CustomerPrivateRoute
          component={Projects}
          path="/customer/projects"
          exact={true}
        />
        <CustomerPrivateRoute
          component={CreateProject}
          path="/customer/projects/manage-project/:realUserType"
          exact={true}
        />
        <CustomerPrivateRoute
          component={ProjectPreview}
          path="/customer/projects/project-preview/:id"
          exact={true}
        />
        <CustomerPrivateRoute
          component={ProjectDetails}
          path="/customer/projects/project-details/:id"
          exact={true}
        />

        {/* Customer Account Settings Routes */}
        <CustomerPrivateRoute
          component={AccountSettings}
          path="/customer/account-settings"
          exact={true}
        />

        {/* EXPERIMENTAL ZONE */}
        <CustomerPrivateRoute
          component={EmailSelection}
          path="/customer/email-selection"
          exact={true}
        />

        {/* <PublicRoute
          component={verifyEmailOTP}
          path="/customer/email-selection_otp"
          exact={true}
        /> */}



        {/* 404 page : Keep it at the end */}
        <PublicRoute component={NotFound} />
      </Switch>
    </Router>
  );
};
export default AppRoutes;