import PageTitle from "../../../components/global/common/pageTitle";
import Layout from "../../../layout";
import {
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  UncontrolledDropdown,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
} from "reactstrap";
import { useState, useEffect,useRef } from "react";
import ProfileTab from "../../../components/customer/account-settings/profileTab";
import ExpertProfileTab from "../../../components/customer/account-settings/expertProfileTab";
import BillingPaymentsTab from "../../../components/customer/account-settings/billingInvoicesTab";
import BillingInvoicesTab from "../../../components/customer/account-settings/billingInvoicesTab";
import PaymentMethodsTab from "../../../components/customer/account-settings/paymentMethodsTab";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addToastr } from "../../../redux/reduxes/global/toastr/actions";
import { updateProfileEmail } from "../../../redux/reduxes/customer/profile/actions";
import { useHistory } from 'react-router-dom';

const AccountSettings = () => {
  const dispatch = useDispatch();
  const emailIds = useRef(null);
  const [data, setData] = useState([]);
  // Active Tab Element

  const updateEmailError = useSelector(state => state.profile.updateEmailError);
  const [currentActiveTab, setCurrentActiveTab] = useState("1");
  const toggleCreateProjectTabs = (tab) => {
    if (currentActiveTab != tab) {
      setCurrentActiveTab(tab);
    }
  };
  // use url /customer/account-settings/?oldEmailId=testing&newEmailId=bhdbshgdhsgdh
  const location = useLocation();

  useEffect(() => {
     const searchParams = new URLSearchParams(location.search);
     const oldEmail = searchParams.get("oldEmailId");
     const newEmail = searchParams.get("newEmailId");
     const errorMsg = searchParams.get("error");
     const tabIdParam = searchParams.get("tab_id");
     emailIds.current = [oldEmail,newEmail];
     if(errorMsg) {
      const toastrFailedData = {
        content: errorMsg,
        type: "failed"
      };
      dispatch(addToastr(toastrFailedData));
     }
     if(oldEmail && newEmail) {
      setModal(true);
     }
     else {
     }
     setData([oldEmail, newEmail]);

     if (tabIdParam) {
      setCurrentActiveTab(tabIdParam);
    }
  }, [location]);
// console.log( emailIds.current,'vv')
  const [modal, setModal] = useState(false);
  const [selectedValue, setSelectedValue] = useState('');
  const toggle = () => setModal(!modal);

  const history = useHistory();

  const handleRadioChange = event => {
    setSelectedValue(event.target.value);
  };
    // onSubmit Handler
    const onEmailSubmitHandler = async (event) => {
      event.preventDefault();


      if(selectedValue === data[0]){
        setModal(false)

      }
      else{
        const data = {
          email: selectedValue,
        }
      dispatch(updateProfileEmail(data));

      }

    }
  return (
    <>
      <PageTitle pageTitle="Account Settings" />
      <Layout>
        {/* ========= PROJECTS ========= */}
        <section className="us-section us-customer__projects">
          <div className="us-section__inwrap">
            <Container>
              <Row>
                <Col md="6">
                  <h1 className="us-section__title mb-10">Account Settings</h1>
                </Col>
                <Col md="6"></Col>
              </Row>
              <Row>
                <Col>
                  <div className="us-nav">
                    {/*  TABS */}
                    <Nav tabs className="us-nav__tabs">
                      <NavItem className="us-nav__tab-item">
                        <NavLink
                          className={`us-nav__tab-link d-flex align-items-center px-3 py-3 ${
                            currentActiveTab == 1 ? "active" : ""
                          }`}
                          onClick={() => toggleCreateProjectTabs("1")}
                        >
                          <span className="us-nav__tab-title">Profile</span>
                        </NavLink>
                      </NavItem>

                       <NavItem className="us-nav__tab-item">
                        <NavLink
                          className={`us-nav__tab-link d-flex align-items-center px-3 py-3 ${
                            currentActiveTab == 2 ? "active" : ""
                          }`}
                          onClick={() => toggleCreateProjectTabs("2")}
                        >
                          <span className="us-nav__tab-title">
                            Expert Profile (Register as an Expert)
                          </span>
                        </NavLink>
                      </NavItem>
{/*
                      <NavItem className="us-nav__tab-item">
                        <NavLink
                          className={`us-nav__tab-link d-flex align-items-center px-3 py-3 ${
                            currentActiveTab == 3 ? "active" : ""
                          }`}
                          onClick={() => toggleCreateProjectTabs("3")}
                        >
                          <span className="us-nav__tab-title">
                            Billing Invoices
                          </span>
                        </NavLink>
                      </NavItem>

                      <NavItem className="us-nav__tab-item">
                        <NavLink
                          className={`us-nav__tab-link d-flex align-items-center px-3 py-3 ${
                            currentActiveTab == 4 ? "active" : ""
                          }`}
                          onClick={() => toggleCreateProjectTabs("4")}
                        >
                          <span className="us-nav__tab-title">
                            Payment Methods
                          </span>
                        </NavLink>
                      </NavItem> */}
                    </Nav>

                    {/* TAB CONTENT */}
                    <TabContent
                      activeTab={currentActiveTab}
                      className="us-nav__tab-content mt-10"
                    >
                      {/* Profile Tab */}
                      <TabPane tabId="1" className="us-nav__tab-pane">
                        <ProfileTab />
                      </TabPane>

                      {/* Expert Profile Tab */}
                      <TabPane tabId="2" className="us-nav__tab-pane">
                        <ExpertProfileTab />
                      </TabPane>

                      {/* Billing Invoices Tab */}
                      {/* <TabPane tabId="3" className="us-nav__tab-pane">
                        <BillingInvoicesTab />
                      </TabPane> */}

                      {/* Payment Methods Tab */}
                      {/* <TabPane tabId="4" className="us-nav__tab-pane">
                        <PaymentMethodsTab />
                      </TabPane> */}
                    </TabContent>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </section>
      </Layout>
      <Modal isOpen={modal} toggle={toggle} size="md"  className="us-modal__dialog">
        <ModalBody className="us-modal__body">
          <h5 className="us-section__title-block mb-8 mb-md-12 text-center">Choose email</h5>
          <Form >
            <FormGroup tag="fieldset">
              <FormGroup className="mb-2">
                <Input name="radio1" type="radio"  value={data && data[0]} checked={selectedValue ===  data[0]}
            onChange={handleRadioChange} />
                <Label className="ms-2" >{data && data[0]}</Label>
              </FormGroup>
              <FormGroup >
                <Input name="radio1" type="radio" value={data && data[1]} checked={selectedValue ===  data[1]}
            onChange={handleRadioChange} />
                <Label className="ms-2" >{data && data[1]}</Label>
              </FormGroup>
            </FormGroup>
          </Form>
          <p
          className="text-danger text-md mb-0 text-capitalize">{updateEmailError[0] && updateEmailError[0].message}</p>
        </ModalBody>
        <ModalFooter className="border-0 pt-0">
        <div className="d-flex align-items-center justify-content-center mt-6 w-100">
        <Button onClick={toggle} color="danger" type="button" className="us-button us-button__danger-outline text-uppercase px-8"> Cancel</Button>
              <Button
                color="primary"
                type="button"
                className={`us-button us-button__primary text-uppercase px-10 ms-5 us-button__primary`}

                onClick={onEmailSubmitHandler}
              >
                Update
              </Button>
        </div>
        </ModalFooter>
      </Modal>
    </>
  );
};
export default AccountSettings;