import React from 'react'

function NoProjects() {
  return (
    <div className='d-flex justify-content-center p-10'>
      <div style={{maxWidth:"350px"}}>
        <img src="/assets/no-projects.svg" alt="no projects" className='img-fluid' />
        <h2 className='text-md font-semibold text-center mt-5'>No projects at the moment</h2>
      </div>

    </div>
  )
}

export default NoProjects