import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import { resetForgotPassword, resetPasswordErrors } from "../../../redux/reduxes/global/resetPassword/actions";
import { useEffect } from "react";


const ChangePassword = () => {
  const dispatch = useDispatch();

  // Fetch Data
  const otp = useSelector(state => state.resetPassword.otp);
  const emailOtpToken = useSelector(state => state.resetPassword.emailOtpToken);
  const resetPasswordLoading = useSelector(state => state.resetPassword.resetPasswordLoading);
  const resetPasswordError = useSelector(state => state.resetPassword.resetPasswordError);

  // States
  const [state, setState] = useState({
    new_password: "",
    new_password_confirmation: "",

    errors: {
      new_password: "",
      passwordUpperCase: "",
      passwordDigit: "",
      passwordSpecialCharacters: "",
      new_password_confirmation: "",
    }
  });

  // Reset Errors
  useEffect(() => {
    dispatch(resetPasswordErrors());
  }, [])

  // Change Handler
  const onChangeHandler = (event) => {
    event.preventDefault();
    const upperCaseLetters = RegExp(/[A-Z]/g);
    const digits = RegExp(/[0-9]/g);
    const specialCharacters = RegExp(/^(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]).*$/);

    const { value, name } = event.target;
    let errors = state.errors;
    switch (name) {
      case 'new_password':
        if (!upperCaseLetters.test(value)) {
          errors.passwordUpperCase = "Password should have atleast one Uppercase letter."
        } else {
          errors.passwordUpperCase = "";
        }
        if (!digits.test(value)) {
          errors.passwordDigit = "Password should have atleast one Digit."
        } else {
          errors.passwordDigit = "";
        }
        if (!specialCharacters.test(value)) {
          errors.passwordSpecialCharacters = "Password should have atleast one Special character."
        } else {
          errors.passwordSpecialCharacters = "";
        }
        if (value.length < 8) {
          errors.new_password = "Password must be minimum of 8 characters long."
        } else {
          errors.new_password = "";
        }
        break;
      case 'new_password_confirmation':
        if (value == "") {
          errors.new_password_confirmation = "Enter Confirm Password."
        } else {
          errors.new_password_confirmation = "";
        }
        if (value != state.new_password) {
          errors.new_password_confirmation = "Password doesn't match."
        } else {
          errors.new_password_confirmation = "";
        }
        break;
      default:
        break;
    }
    setState((prevProps) => ({
      ...prevProps,
      errors, [name]: value
    }));
    dispatch(resetPasswordErrors());
  }

  // Validate Form
  const validateForm = (errors) => {
    let valid = true;
    Object.values(errors).forEach(val => val.length > 0 && (val = false));
    if (
      state.new_password == "" ||
      state.errors.passwordUpperCase != "" ||
      state.errors.passwordDigit != "" ||
      state.errors.passwordSpecialCharacters != "" ||
      state.new_password.length < 8 ||
      state.new_password_confirmation == "" ||
      state.new_password_confirmation != state.new_password
    )
      valid = false;
    return valid;
  }

  // Form Submit
  const onSubmitHandler = async (event) => {
    event.preventDefault();
    if (validateForm(state.errors)) {
      const data = {
        password: state.new_password,
        otp: otp,
        emailOtpToken: emailOtpToken,
      };
      dispatch(resetForgotPassword(data))
    } else {
      let errors = state.errors;
      if (state.new_password == "" && state.errors.upperCaseLetters != "" && state.errors.digits != "" && state.errors.specialCharacters != "" && state.new_password.length < 8)
        errors.new_password = "New Password is required."
      if (state.new_password_confirmation == "") {
        errors.new_password_confirmation = "Confirm New Password is required."
      } else if (state.new_password_confirmation != state.new_password) {
        errors.new_password_confirmation = "Confirm Password doesn't match."
      } else {
        errors.new_password_confirmation = "";
      }

      setState((prevProps) => ({
        ...prevProps,
        errors: errors
      }));
    }
  }

  // Show/Hide New Password
  const [showNewPassword, setShowNewPassword] = useState(false);
  const toggleNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  }

  // Show/Hide Confirm Password
  const [showConfirmNewPassword, setShowConfrimNewPassword] = useState(false);
  const toggleConfirmNewPassword = () => {
    setShowConfrimNewPassword(!showConfirmNewPassword);
  }

  return (
    <>
      <div>
        <h1 className="us-section__title text-center mb-10">Forgot Password</h1>

        <Form onSubmit={(e) => onSubmitHandler(e)}>
          <FormGroup className="us-form__wrap mb-6">
            <Label htmlFor="new_password" className="us-form__label">New Password <span className="text-danger">*</span></Label>
            <div className="us-form__input-group position-relative d-flex align-items-center">
              <Input
                type={showNewPassword ? "text" : "password"}
                name="new_password"
                id="new_password"
                placeholder="New Password"
                className={`us-form__input ${state.errors.new_password ? "us-form__input-error" : "" }`}
                value={state.new_password}
                onChange={(e) => onChangeHandler(e)}
                autoComplete="new_password"
              />
              <Button type="button" onClick={() => toggleNewPassword()}  className="us-form__input-button position-absolute">
                {showNewPassword ?
                  <img  src="/assets/icons/icon-eye-show.svg" alt="icon-eye-show" className="img-fluid"  />
                :
                  <img src="/assets/icons/icon-eye-hide.svg"  alt="icon-eye-hide" className="img-fluid" />
                }
              </Button>
            </div>
            <div className="us-form__error">{state.errors.new_password}</div>
            <div className="us-form__error"> {state.errors.passwordUpperCase}  </div>
            <div className="us-form__error">{state.errors.passwordDigit}</div>
            <div className="us-form__error">{state.errors.passwordSpecialCharacters}</div>
          </FormGroup>

          <FormGroup className="us-form__wrap mb-10">
            <Label htmlFor="new_password_confirmation" className="us-form__label">Confirm New Password <span className="text-danger">*</span></Label>
            <div className="us-form__input-group position-relative d-flex align-items-center">
              <Input
                type={showConfirmNewPassword ? "text" : "password"}
                name="new_password_confirmation"
                id="new_password_confirmation"
                placeholder="New Password"
                className={`us-form__input ${state.errors.new_password_confirmation ? "us-form__input-error" : "" }`}
                value={state.new_password_confirmation}
                onChange={(e) => onChangeHandler(e)}
                autoComplete="new_password_confirmation"
              />
              <Button type="button" onClick={() => toggleConfirmNewPassword()}  className="us-form__input-button position-absolute">
                {showConfirmNewPassword ?
                  <img  src="/assets/icons/icon-eye-show.svg" alt="icon-eye-show" className="img-fluid"  />
                :
                  <img src="/assets/icons/icon-eye-hide.svg"  alt="icon-eye-hide" className="img-fluid" />
                }
              </Button>
            </div>
            <div className="us-form__error">{state.errors.new_password_confirmation}</div>
            {resetPasswordError && <div className="us-form__error">{resetPasswordError}</div>}
          </FormGroup>

          <Button
            type="submit"
            color="primary"
            disabled={resetPasswordLoading}
            className={`us-button us-button__primary text-uppercase w-100 ${resetPasswordLoading ? "us-button__disabled" : "us-button__primary"}`}
          >
            {resetPasswordLoading ? "Loading..." : "Change Password"}
          </Button>
        </Form>
      </div>
    </>
  )
}
export default ChangePassword;