import React, { useState } from "react";
import { Button, Card, CardBody, CardFooter, CardSubtitle, CardTitle, Progress } from "reactstrap";
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { setManageProject, deleteDraftProject } from "../../../redux/reduxes/customer/projects-new/projectAction";
import DeleteModal from "../../global/common/deleteModal";


const ProjectCard = ({
  projectTitle,
  projectPhase,
  projectStatus,
  progressBar,
  progressText,
  progressPercent,
  projectId,
  startedAt,
  updatedAt,
  realUserType,
  searchQuery,
  currentActiveTab,
}) => {
  const dispatch = useDispatch();
  const [deleteDraftProjectModal, setDeleteDraftProjectModal] = useState(false);
  // const [evaluationId, setEvaluationId] = useState(null);
  const confirmDeleteEvaluationMaterial = (stat, id) => {

    setDeleteDraftProjectModal(stat);
    // setEvaluationId(id);
  }
  return (
    <>
      <Card className="us-card us-card__customer-project h-100">
        <CardBody className="us-card__body d-flex flex-column card-primary">
          <CardTitle className="us-card__body-title mb-3">
            {projectTitle}
          </CardTitle>

          <div
            className={`us-card__body-status d-inline-flex align-items-center font-medium mt-3 mb-4 py-2
              ${projectStatus === "PROJECT SUBMITTED"
                ? "projectSubmitted"
                : projectStatus === "PROJECT INITIATED"
                  ? "projectInProgress"
                  : projectStatus === "PROJECT IN DRAFT"
                    ? "projectInDraft"
                    : projectStatus === "SENT TO EXPERT"
                      ? "sentToExpert"
                      : projectStatus === "WAITING FOR PAYMENT" &&
                      "waitingForPayments"
              }
            `}
          >
            <img
              src="/assets/icons/icon-progress.svg"
              alt="icon-progress"
              width="16"
              height="16"
              className="me-1"
            />
            <span>{projectStatus}</span>
          </div>
          <div className="d-flex flex-wrap gap-1">
            {/* <CardSubtitle className="us-card__body-subtitle"></CardSubtitle> */}
            {projectPhase && <div className="text-xs us-card__tag p-2 text-nowrap">
              {projectPhase}
            </div>}
            {realUserType ? (
              <div className="text-xs us-card__tag p-2 text-nowrap">
                User report
              </div>
            ) : (
              <div className="text-xs us-card__tag p-2 text-nowrap">
                Expert Report
              </div>
            )}
          </div>
          <div className="us-card__body-title text-secondary mt-auto">
            <div className="mt-5">
              {projectStatus === "PROJECT IN DRAFT" ? (
                <span className="text-xxs">Created on: {startedAt}</span>
              ) : projectStatus === "PROJECT SUBMITTED" ? (
                <span className="text-xxs">Published on: {updatedAt}</span>
              ) : null}

            </div>
          </div>
        </CardBody>

        <CardFooter className="us-card__footer">
          {/* {projectStatus !== "PROJECT IN DRAFT" && ( */}
          <div className="us-card__progress-inwrap">
            <Progress
              className="us-card__progress w-100"
              value={0}
              aria-valuemin={0}
              aria-valuemax={100}
              style={{ height: "4px" }}
            />
            <div className="us-card__progress-text d-flex align-items-center text-xxs color-gray3 mt-3">
              <span className="me-2">{progressText}</span>
              <span className="ms-auto">{0}%</span>
            </div>
          </div>
          {/* )} */}
          <div className="mt-5 d-flex align-items-end justify-content-between">
            {projectStatus == "PROJECT IN DRAFT" ? (
              <>
                <Button
                  className="us-button d-inline-flex align-items-center us-button--delete text-danger py-1 px-2 mr-2 mr-5 btn-secondary-noHover"
                  onClick={() =>
                    confirmDeleteEvaluationMaterial(true, projectId)
                  }
                >
                  Delete
                  {/* <img
                    className="ms-1"
                    src="/assets/icons/icon-arrow-right-white.svg"
                    alt="icon-arrow-right-white"
                    width="20"
                    height="20"
                  /> */}
                </Button>
                <Link
                  onClick={() => dispatch(setManageProject({ edit: true }))}
                  to={`/customer/projects/manage-project/${realUserType ? "real_user" : "expert"
                    }?project=${projectId}`}
                  exact={true}
                  className="us-button d-inline-flex align-items-center bg-dark3 py-1 px-2 "
                >
                  Edit
                  <img
                    className="ms-1"
                    src="/assets/icons/icon-arrow-right-white.svg"
                    alt="icon-arrow-right-white"
                    width="20"
                    height="20"
                  />
                </Link>
              </>
            ) : (
              <Link
                to={`/customer/projects/project-details/${projectId}`}
                exact={true}
                className="us-button d-inline-flex align-items-center bg-dark3 py-1 px-2 ms-auto"
              >
                View
                <img
                  className="ms-1"
                  src="/assets/icons/icon-arrow-right-white.svg"
                  alt="icon-arrow-right-white"
                  width="20"
                  height="20"
                />
              </Link>
            )}
          </div>
        </CardFooter>
      </Card>
      {/* Delete Evaluation Material */}
      {deleteDraftProjectModal && (
        <DeleteModal
          headText="Delete Project Permanently"
          bodyText="Are you sure, you want to delete this project permanently from the draft?"
          id={projectId}
          modalValue={deleteDraftProjectModal}
          modalAction={setDeleteDraftProjectModal}
          deleteAction={deleteDraftProject}
          // projectType={projectStatus}
          // projectName={projectTitle}
          search={searchQuery}
          currentTab={currentActiveTab}
        // deleteLoading={deleteEvaluationMaterialsLoading}
        />
      )}
    </>
  );
};
export default ProjectCard;