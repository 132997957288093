import { Button, Col, Container, Row } from "reactstrap";
import PageTitle from "../components/global/common/pageTitle";
import Layout from "../layout";
import { Link } from "react-router-dom/cjs/react-router-dom";
const InternalServerError = () => {
  return (
    <>
      <PageTitle pageTitle="Internal Server Error" />
      <Layout>
        <section className="us-section" style={{ height: "100vh" }}>
          <div className="container d-flex justify-content-center flex-column align-items-center h-100">
            <img src="/assets/interal-server-error.svg" alt="" className="img-fluid" style={{zIndex:'4'}} />
            <h1 className="text-4xl text-lg-10xl font-bold color-dark3" style={{marginTop:"-100px"}}>
             500
            </h1>
            <p className="text-uppercase text-xl text-lg-3xl color-dark3 mb-3">
              Internal Server Error
            </p>
            {/* <p className="text-sm color-dark3 text-center color-dark2 col-12 col-lg-6">
              Introducing new solution + Successful introduction and adoption +
              Launch plan, marketing strategies, and coordination.
            </p> */}
            <Link to="/">
              <Button
                color="primary"
                type="button"
                className="us-button us-button__primary text-uppercase px-10 ms-5 us-button__primary mt-4"
              >
                Go back home
              </Button>
            </Link>
          </div>
        </section>
      </Layout>
    </>
  );
};
export default InternalServerError;
