import React from 'react';
import PageTitle from '../../components/global/common/pageTitle';
import Layout from '../../layout';
import { Col, Container, Row } from 'reactstrap';
import {  useDispatch, useSelector } from 'react-redux';
import VerifyOTP from '../../components/global/auth/verifyOTP';
import RegistrationForm from '../../components/global/auth/registrationForm';
import { useEffect } from 'react';
import { getScreenShots } from '../../redux/reduxes/global/auth/actions';
import ProjectRegisterPage from '../../components/global/auth/projectAndRegisterPage'
// import LoadingAnimation from "../../../components/global/auth/loadingAnimation";
import LoadingAnimation from "../../components/global/auth/loadingAnimation";
import { useParams } from 'react-router-dom';


const ProRegister = () => {
  const dispatch = useDispatch();
  const { projectURL, mode, urlType } = useParams();

  useEffect(() => {
    const toastrFailedData = { projectURL, urlType };
    dispatch(getScreenShots(toastrFailedData));
 }, []);

//  auth.screenShotLoading
 const screenShotLoading = useSelector((state) => state.auth.screenShotLoading);
 const screenShot = useSelector((state) => state.auth.profileDetails);
//  console.log(screenShotLoading,"screenShotLoading")
//  const screenShotLoading = false;


return(
  // <Col md={{ size:10, offset:1 }} lg={{size:6, offset:0}} xl="12">
  //   <div className="us-auth__right px-6 py-8 px-lg-10 py-lg-10">

  //   </div>
  // </Col>
  <>
  {screenShotLoading?<LoadingAnimation/>:<ProjectRegisterPage screenShot={ screenShot}/>}
  </>
)

}
export default ProRegister;
