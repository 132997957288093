import { Col, Container, Row } from "reactstrap"
import PageTitle from "../components/global/common/pageTitle"
import Layout from "../layout"


const TermsConditions = () => {
  return (
    <>
      <PageTitle pageTitle="Terms and Conditions" />

      <Layout>
        {/* Terms and Conditions */}
        <section className="us-section us-section__privacy-policy">
          <div className="us-section__inwrap">
            <Container>
              <Row>
                <Col sm="12">
                  <h1 className="us-section__title font-extrabold text-center text-uppercase mb-12">Terms &amp; Conditions</h1>

                  {/* Terms and Conditions for Experts using Useralia */}
                  <div className="us-terms__experts-useralia mb-20">
                    <h2 className="us-section__title-mini mb-6">Terms and Conditions for Experts using Useralia:</h2>
                    <ol className="us-terms__lists">
                      <div className="mb-5">
                        <div className="us-terms__subtitle font-medium mb-2">Acceptance of Terms:</div>
                        <li className="us-terms__list">
                          By registering and using Useralia as an expert, you agree to abide by these terms and conditions. These terms govern your use of Useralia
                          and its services. If you do not agree to these terms, you should not use Useralia.
                        </li>
                      </div>

                      <div className="mb-5">
                        <div className="us-terms__subtitle font-medium mb-2">Expert Registration:</div>
                        <li className="us-terms__list">
                          To become an expert on Useralia, you must provide accurate and complete information during the registration process. You must be at
                          least 18 years old and possess the necessary qualifications and expertise in your respective field.
                        </li>
                      </div>

                      <div className="mb-5">
                        <div className="us-terms__subtitle font-medium mb-2">Expert Profile:</div>
                        <li className="us-terms__list">
                          You are solely responsible for the information you provide in your expert profile. The information must be accurate, up-to-date, and not
                          misleading. You should not impersonate any other person or misrepresent your qualifications or experience.
                        </li>
                      </div>

                      <div className="mb-5">
                        <div className="us-terms__subtitle font-medium mb-2">Expert Services:</div>
                        <li className="us-terms__list">
                          As an expert on Useralia, you will have the opportunity to provide your services to product owners and organizations. You agree to provide
                          high-quality and professional services, adhere to industry best practices, and maintain confidentiality where required.
                        </li>
                      </div>

                      <div className="mb-5">
                        <div className="us-terms__subtitle font-medium mb-2">Useralia's Role:</div>
                        <li className="us-terms__list">
                          Useralia serves as a platform to connect experts with product owners. Useralia does not endorse or guarantee the quality, accuracy, or
                          reliability of the services provided by experts. The relationship between experts and product owners is independent, and Useralia is not
                          a party to any agreements or contracts between them.
                        </li>
                      </div>

                      <div className="mb-5">
                        <div className="us-terms__subtitle font-medium mb-2">Compensation and Payments:</div>
                        <li className="us-terms__list">
                          As an expert, you have the right to set your own fees for the services you provide on Useralia. Useralia will handle the collection of fees
                          from product owners on your behalf and facilitate payments to you, subject to the agreed-upon payment terms. Useralia may deduct a
                          commission or service fee from the payments made to experts.
                        </li>
                      </div>

                      <div className="mb-5">
                        <div className="us-terms__subtitle font-medium mb-2">Intellectual Property:</div>
                        <li className="us-terms__list">
                          By using Useralia as an expert, you retain ownership of your intellectual property rights. However, you grant Useralia a non-exclusive,
                          worldwide, royalty-free license to display and use your content for the purpose of promoting your services on the platform.
                        </li>
                      </div>

                      <div className="mb-5">
                        <div className="us-terms__subtitle font-medium mb-2">Termination:</div>
                        <li className="us-terms__list">
                          Useralia reserves the right to terminate your expert account if you violate these terms and conditions, engage in fraudulent activities,
                          provide false information, or engage in any conduct that is detrimental to Useralia's reputation or the interests of product owners.
                        </li>
                      </div>

                      <div className="mb-5">
                        <div className="us-terms__subtitle font-medium mb-2">Indemnification:</div>
                        <li className="us-terms__list">
                          You agree to indemnify and hold Useralia harmless from any claims, liabilities, damages, expenses, or losses arising out of your use of
                          Useralia as an expert, including but not limited to any disputes with product owners or any breach of these terms and conditions.
                        </li>
                      </div>

                      <div className="mb-5">
                        <div className="us-terms__subtitle font-medium mb-2">Modification of Terms:</div>
                        <li className="us-terms__list">
                          Useralia may modify these terms and conditions at any time. Any changes will be notified to you, and your continued use of Useralia
                          after the modifications will constitute your acceptance of the revised terms.
                        </li>
                      </div>
                    </ol>
                  </div>

                  {/* Terms and Conditions for Product Owners using Useralia */}
                  <div className="us-terms__owners-useralia mb-20">
                    <h2 className="us-section__title-mini mb-6">Terms and Conditions for Product Owners using Useralia:</h2>
                    <ol className="us-terms__lists">
                      <div className="mb-5">
                        <div className="us-terms__subtitle font-medium mb-2">Acceptance of Terms:</div>
                        <li className="us-terms__list">
                          By registering and using Useralia as a product owner, you agree to abide by these terms and conditions. These terms govern your use
                          of Useralia and its services. If you do not agree to these terms, you should not use Useralia.
                        </li>
                      </div>

                      <div className="mb-5">
                        <div className="us-terms__subtitle font-medium mb-2">Product Owner Registration:</div>
                        <li className="us-terms__list">
                          To use Useralia as a product owner, you must provide accurate and complete information during the registration process. You represent
                          that you have the necessary authority to represent the product and organization you are registering on behalf of.
                        </li>
                      </div>

                      <div className="mb-5">
                        <div className="us-terms__subtitle font-medium mb-2">Use of Expert Services:</div>
                        <li className="us-terms__list">
                          Useralia provides a platform for product owners to access expert services. You agree to engage with experts in a mutually respectful
                          and professional manner. You acknowledge that the opinions and advice provided by experts on Useralia are independent and may vary. It is your responsibility to evaluate and make decisions based on the information provided.
                        </li>
                      </div>

                      <div className="mb-5">
                        <div className="us-terms__subtitle font-medium mb-2">Payment and Fees:</div>
                        <li className="us-terms__list">
                          By utilizing the services of experts on Useralia, you agree to pay the fees specified by the respective experts. Useralia will facilitate the
                          collection and processing of payments on behalf of the experts. The fees and payment terms will be agreed upon between you and the
                          expert. Useralia may deduct a service fee or commission from the payments made to experts.
                        </li>
                      </div>

                      <div className="mb-5">
                        <div className="us-terms__subtitle font-medium mb-2">Confidentiality:</div>
                        <li className="us-terms__list">
                          You understand and acknowledge that the information, data, and materials you provide to experts on Useralia may be of a sensitive
                          or confidential nature. It is your responsibility to ensure that any confidential information is properly protected. Useralia is not
                          responsible for any breaches of confidentiality by experts or any third parties.
                        </li>
                      </div>

                      <div className="mb-5">
                        <div className="us-terms__subtitle font-medium mb-2">Relationship with Experts:</div>
                        <li className="us-terms__list">
                          Useralia acts as a platform to connect product owners with experts. The relationship between you and the experts is independent, and
                          Useralia is not a party to any agreements or contracts between you. Useralia does not endorse or guarantee the quality, accuracy, or
                          reliability of the services provided by experts.
                        </li>
                      </div>

                      <div className="mb-5">
                        <div className="us-terms__subtitle font-medium mb-2">User Conduct:</div>
                        <li className="us-terms__list">
                          You agree to use Useralia and engage with experts in a lawful and respectful manner. You will not use Useralia for any illegal or
                          unauthorized purposes. You will not harass, defame, or engage in any harmful or abusive behavior towards experts or other users of Useralia.
                        </li>
                      </div>

                      <div className="mb-5">
                        <div className="us-terms__subtitle font-medium mb-2">Intellectual Property:</div>
                        <li className="us-terms__list">
                          The intellectual property rights of any materials or content provided by experts remain with the respective experts. You are granted a
                          limited, non-exclusive license to use the materials provided by experts solely for the purpose of evaluating and improving your product.
                          You may not use the materials for any other purpose without obtaining the necessary permissions from the experts.
                        </li>
                      </div>

                      <div className="mb-5">
                        <div className="us-terms__subtitle font-medium mb-2">Termination:</div>
                        <li className="us-terms__list">
                          Useralia reserves the right to terminate your product owner account if you violate these terms and conditions, engage in fraudulent
                          activities, or engage in any conduct that is detrimental to Useralia's reputation or the interests of experts.
                        </li>
                      </div>

                      <div className="mb-5">
                        <div className="us-terms__subtitle font-medium mb-2">Indemnification:</div>
                        <li className="us-terms__list">
                          You agree to indemnify and hold Useralia harmless from any claims, liabilities, damages, expenses, or losses arising out of your use of
                          Useralia as a product owner, including but not limited to any disputes with experts or any breach of these terms and conditions.
                        </li>
                      </div>
                    </ol>
                  </div>

                  {/* Terms and Conditions for Experts and Product Owners using Useralia: */}
                  <div className="us-terms__experts-owners mb-20">
                    <h2 className="us-section__title-mini mb-6">Terms and Conditions for Experts and Product Owners using Useralia:</h2>
                    <ol className="us-terms__lists">
                      <div className="mb-5">
                        <div className="us-terms__subtitle font-medium mb-2">Relationship:</div>
                        <li className="us-terms__list">
                          Useralia serves as a platform to connect experts with product owners. Useralia does not endorse or guarantee the quality, accuracy,
                          or reliability of the services provided by experts. The relationship between experts and product owners is independent, and Useralia
                          is not a party to any agreements or contracts between them.
                        </li>
                      </div>

                      <div className="mb-5">
                        <div className="us-terms__subtitle font-medium mb-2">Communication and Engagement:</div>
                        <li className="us-terms__list">
                          Experts and product owners agree to communicate and engage with each other in a respectful and professional manner. Any discussions,
                          evaluations, or recommendations provided on Useralia should be conducted in good faith and with the intention of improving the product.
                        </li>
                      </div>

                      <div className="mb-5">
                        <div className="us-terms__subtitle font-medium mb-2">Confidentiality:</div>
                        <li className="us-terms__list">
                          Both experts and product owners acknowledge that the information, data, and materials shared on Useralia may be of a sensitive or
                          confidential nature. It is the responsibility of both parties to ensure the proper protection of any confidential information. Useralia is not
                          responsible for any breaches of confidentiality by experts or product owners.
                        </li>
                      </div>

                      <div className="mb-5">
                        <div className="us-terms__subtitle font-medium mb-2">Payment and Fees:</div>
                        <li className="us-terms__list">
                          Product owners agree to pay the fees specified by the experts for their services. Useralia will facilitate the collection and processing
                          of payments on behalf of the experts. The fees and payment terms will be agreed upon between the experts and product owners.
                          Useralia may deduct a service fee or commission from the payments made to experts.
                        </li>
                      </div>

                      <div className="mb-5">
                        <div className="us-terms__subtitle font-medium mb-2">Intellectual Property:</div>
                        <li className="us-terms__list">
                          The intellectual property rights of any materials or content provided by experts remain with the respective experts. Product owners are
                          granted a limited, non-exclusive license to use the materials provided by experts solely for the purpose of evaluating and improving
                          their product. Product owners may not use the materials for any other purpose without obtaining the necessary permissions from the experts.
                        </li>
                      </div>

                      <div className="mb-5">
                        <div className="us-terms__subtitle font-medium mb-2">Disputes:</div>
                        <li className="us-terms__list">
                          In the event of any disputes between experts and product owners, Useralia encourages both parties to resolve the matter amicably
                          through direct communication. Useralia is not responsible for mediating or resolving disputes between experts and product owners.
                        </li>
                      </div>

                      <div className="mb-5">
                        <div className="us-terms__subtitle font-medium mb-2">Termination:</div>
                        <li className="us-terms__list">
                          Useralia reserves the right to terminate the accounts of experts or product owners if they violate these terms and conditions, engage
                          in fraudulent activities, or engage in any conduct that is detrimental to Useralia's reputation or the interests of the other party.
                        </li>
                      </div>

                      <div className="mb-5">
                        <div className="us-terms__subtitle font-medium mb-2">Indemnification:</div>
                        <li className="us-terms__list">
                          Both experts and product owners agree to indemnify and hold Useralia harmless from any claims, liabilities, damages, expenses, or
                          losses arising out of their use of Useralia, including but not limited to any disputes between experts and product owners or any breach
                          of these terms and conditions.
                        </li>
                      </div>

                      <div className="mb-5">
                        <div className="us-terms__subtitle font-medium mb-2">Modification of Terms:</div>
                        <li className="us-terms__list">
                          Useralia may modify these terms and conditions at any time. Any changes will be notified to experts and product owners, and their
                          continued use of Useralia after the modifications will constitute their acceptance of the revised terms.
                        </li>
                      </div>
                    </ol>
                  </div>

                  {/* Terms and Conditions for Real Users Engaging with Useralia Experts:*/}
                  <div className="us-terms__users-useralia mb-20">
                    <h2 className="us-section__title-mini mb-6">Terms and Conditions for Real Users Engaging with Useralia Experts:</h2>
                    <ol className="us-terms__lists">
                      <div className="mb-5">
                        <div className="us-terms__subtitle font-medium mb-2">Acceptance of Terms:</div>
                        <li className="us-terms__list">
                          By engaging with Useralia experts as a real user, you agree to abide by these terms and conditions. These terms govern your interaction
                          with Useralia experts and their services. If you do not agree to these terms, you should not engage with Useralia experts.
                        </li>
                      </div>

                      <div className="mb-5">
                        <div className="us-terms__subtitle font-medium mb-2">Engagement with Useralia Experts:</div>
                        <li className="us-terms__list">
                          Useralia provides a platform for you to connect and engage with experts who offer their services. You understand that Useralia does
                          not endorse or guarantee the quality, accuracy, or reliability of the services provided by experts. It is your responsibility to evaluate
                          the suitability of the expert and their services for your specific needs.
                        </li>
                      </div>

                      <div className="mb-5">
                        <div className="us-terms__subtitle font-medium mb-2">Professional Conduct:</div>
                        <li className="us-terms__list">
                          When interacting with Useralia experts, you agree to maintain a professional and respectful demeanor. You will refrain from engaging
                          in any form of harassment, discrimination, or abusive behavior. Any communication or feedback provided should be constructive and
                          based on your genuine experience.
                        </li>
                      </div>

                      <div className="mb-5">
                        <div className="us-terms__subtitle font-medium mb-2">Expert Recommendations and Advice:</div>
                        <li className="us-terms__list">
                          Useralia experts may provide recommendations, advice, or suggestions based on their professional expertise. You acknowledge that the
                          opinions and suggestions offered by experts are independent and may vary. It is your responsibility to evaluate and make decisions based
                          on the information provided.
                        </li>
                      </div>

                      <div className="mb-5">
                        <div className="us-terms__subtitle font-medium mb-2">Compensation and Payments:</div>
                        <li className="us-terms__list">
                          The fees and payment terms for engaging with Useralia experts will be determined and agreed upon between you and the expert.
                          Useralia is not responsible for any financial transactions or disputes between you and the expert. You agree to make timely and
                          appropriate payments for the services rendered by the expert.
                        </li>
                      </div>

                      <div className="mb-5">
                        <div className="us-terms__subtitle font-medium mb-2">Confidentiality:</div>
                        <li className="us-terms__list">
                          You understand and acknowledge that the information, data, and materials shared with Useralia experts may be of a sensitive or
                          confidential nature. You agree to treat any confidential information shared by the expert with strict confidentiality and not disclose
                          it to any third parties without the explicit consent of the expert.
                        </li>
                      </div>

                      <div className="mb-5">
                        <div className="us-terms__subtitle font-medium mb-2">User Responsibilities:</div>
                        <li className="us-terms__list">
                          You are solely responsible for any actions you take based on the information or services provided by Useralia experts. You understand
                          that Useralia does not assume any liability for the outcomes or consequences of your decisions or actions.
                        </li>
                      </div>

                      <div className="mb-5">
                        <div className="us-terms__subtitle font-medium mb-2">Termination:</div>
                        <li className="us-terms__list">
                          Useralia reserves the right to terminate your access to Useralia or suspend your account if you violate these terms and conditions,
                          engage in fraudulent activities, or engage in any conduct that is detrimental to the reputation or interests of Useralia or its experts.
                        </li>
                      </div>

                      <div className="mb-5">
                        <div className="us-terms__subtitle font-medium mb-2">Indemnification:</div>
                        <li className="us-terms__list">
                          You agree to indemnify and hold Useralia and its experts harmless from any claims, liabilities, damages, expenses, or losses arising
                          out of your engagement with Useralia experts, including but not limited to any disputes or issues that may arise.
                        </li>
                      </div>

                      <div className="mb-5">
                        <div className="us-terms__subtitle font-medium mb-2">Modification of Terms:</div>
                        <li className="us-terms__list">
                          Useralia may modify these terms and conditions at any time. Any changes will be notified to you, and your continued engagement
                          with Useralia experts after the modifications will constitute your acceptance of the revised terms.
                        </li>
                      </div>
                    </ol>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </section>
      </Layout>
    </>
  )
}
export default TermsConditions