import * as type from './types';

const initialState = {
  forgotPasswordStep: 0,
  email: null,
  otp: null,
  emailOtpToken:"",
  successTime: '',

  // Trigger Email OTP
  triggerEmailOtpLoading: false,
  triggerEmailOtpError: null,

  // Verify Email OTP
  verifyEmailOtpLoading: false,
  verifyEmailOtpError: null,

  // Reset Password
  resetPasswordLoading: false,
  resetPasswordError: null,
}

export default function resetPassword(state = initialState, action) {
  switch (action.type) {
    // SET FORGOT PASSWORD ACTIVE STEP
    case type.SET_FORGOT_PASSWORD_ACTIVE_STEP_REQUESTED:
      return {
        ...state,
        forgotPasswordStep: action.payload,
      }

    // TRIGGER EMAIL OTP - FORGOT PASSWORD
    case type.TRIGGER_FORGOT_PASSWORD_EMAIL_OTP_REQUEESTED:
      return {
        ...state,
        triggerEmailOtpLoading: true,
      }
    case type.TRIGGER_FORGOT_PASSWORD_EMAIL_OTP_SUCCESS:
      return {
        ...state,
        triggerEmailOtpLoading: false,
        email: action.data.email,
        emailOtpToken: action.data.emailOtpToken,
        forgotPasswordStep: 1,
        successTime: new Date().getTime(),
      }
    case type.TRIGGER_FORGOT_PASSWORD_EMAIL_OTP_FAILED:
      return {
        ...state,
        triggerEmailOtpLoading: false,
        triggerEmailOtpError: action.message,
      }

    // SET TRIGGRT EMAIL OTP ERRORS
    case type.SET_TRIGGER_EMAIL_OTP_ERRORS_REQUESTED:
      return {
        ...state,
        triggerEmailOtpError: "",
      }

    // VERIFY EMAIL OTP - FORGOT PASSWORD
    case type.VERIFY_FORGOT_PASSWORD_EMAIL_OTP_REQUESTED:
      return {
        ...state,
        verifyEmailOtpLoading: true,
      }
    case type.VERIFY_FORGOT_PASSWORD_EMAIL_OTP_SUCCESS:
      return {
        ...state,
        verifyEmailOtpLoading: false,
        otp: action.data.otp,
        forgotPasswordStep: 2,
      }
    case type.VERIFY_FORGOT_PASSWORD_EMAIL_OTP_FAILED:
      return {
        ...state,
        verifyEmailOtpLoading: false,
        verifyEmailOtpError: action.message,
      }

    // SET VERIFY EMAIL OTP - ERROR MESSAGES
    case type.SET_VERIFY_EMAIL_OTP_ERRORS_REQUESTED:
      return {
        ...state,
        verifyEmailOtpError: action.message,
      }

    // RESET PASSWORD - FORGOT PASSWORD
    case type.RESET_FORGOT_PASSWORD_REQUESTED:
      return {
        ...state,
        resetPasswordLoading: true,
      }
    case type.RESET_FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPasswordLoading: false,
        otp: action.data.otp,
        emailOtpToken: action.data.data.emailOtpToken,
      }
    case type.RESET_FORGOT_PASSWORD_FAILED:
      return {
        ...state,
        resetPasswordLoading: false,
        resetPasswordError: action.message,
      }

    // SET RESET PASSWORD - ERROR MESSAGES
    case type.SET_RESET_PASSWORD_ERRORS_REQUESTED:
      return {
        ...state,
        resetPasswordError: action.message,
      }

    default:
      return state;
  }
}
