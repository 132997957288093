import * as type from './types';

// REGISTER USER
export function registerUser(data) {
  return {
    type: type.REGISTER_USER_REQUESTED,
    payload: data,
  }
}

// REGISTER USER STEP
export function registerUserStep(data) {
  return {
    type: type.REGISTER_STEP_REQUESTED,
    payload: data,
  }
}

// REGISTER RESET ERROR MESSAGES
export function resetRegisterErrorMessages(data) {
  return {
    type: type.REGISTER_RESET_ERROR_MESSAGES_REQUESTED,
    payload: data,
  }
}

// =========================== LOGIN ACTIONS =========================== //

// LOGIN USER
export function loginUser(data) {
  return {
    type: type.LOGIN_USER_REQUESTED,
    payload: data,
  }
}

// LOGIN RESET ERROR MESSAGES
export function resetLoginErrorMessages(data) {
  return {
    type: type.LOGIN_RESET_ERROR_MESSAGES_REQUESTED,
    payload: data,
  }
}

// LOGOUT USER
export function logoutUser(data) {
  return {
    type: type.LOGOUT_USER_REQUESTED,
    payload: data,
  }
}

// LINKEDIN USER
export function linkedInSignUp(data){
  return {
    type: type.LINKED_IN_USER,
    payload: data
  }
}

// LINKEDIN LOGIN TOKEN
export function linkedInLoginToken(data) {
  return {
    type: type.LINKEDIN_LOGIN_TOKEN_REQUESTED,
    payload: data,
  }
}

// EXPERIMENTAL
export function experimentalEmailSelector(data) {
  return {
    type: type.CHANGE_USER_EMAIL_REQUESTED,
    payload: data,
  }
}


{/* =====================  OTP VERIFICATION ==================== */ }

// CALL MOBILE EMAIL OTP TOKENS
// export function triggerEmailMobileOtp(data) {
//   return {
//     type: type.CONFIRM_EMAIL_MOBILE_OTP_REQUESTED,
//     payload: data,
//   }
// }

// EMAIL & MOBILE OTP CONFIRMATION
export function confirmEmailMobileOTP(data) {
  return {
    type: type.CONFIRM_EMAIL_MOBILE_OTP_REQUESTED,
    payload: data,
  }
}

// EMAIL & MOBILE OTP VERIFICATION
export function verifyEmailMobileOTP(data) {
  return {
    // IM HERE
    type: type.VERIFY_EMAIL_MOBILE_OTP_REQUESTED,
    payload: data,
  }
}

// SET EMAIL & MOBILE OTP CONFIRMATION ERRORS
export function resetVerifyEmailMobileOTPErrors(data) {
  return {
    type: type.RESET_VERIFY_EMAIL_MOBILE_OTP_ERRORS,
    payload: data,
  }
}

// UPDATE EMAIL
export function updateRegisterEmail(data) {
  return {
    type: type.UPDATE_REGISTER_EMAIL_REQUESTED,
    payload: data,
  }
}

// RESET UPDATE EMAIL ERRORS
export function resetUpdateRegisterEmailErrors(data) {
  return {
    type: type.RESET_UPDATE_EMAIL_ERRORS_REQUESTED,
    payload: data,
  }
}

// SET UPDATE EMAIL MODAL
export function setRegisterUpdateEmailModal(data) {
  return {
    type: type.SET_REGISTER_UPDATE_EMAIL_MODAL_REQUESTED,
    payload: data,
  }
}

// UPDATE MOBILE NUMBER
export function updateRegisterMobileNumber(data) {
  return {
    type: type.UPDATE_REGISTER_MOBILE_NUMBER_REQUESTED,
    payload: data,
  }
}

// RESET UPDATE MOBILE NUMBER ERRORS
// export function resetUpdateRegisterMobileNumberErrors(data) {
//   return {
//     type: type.RESET_UPDATE_MOBILE_NUMBER_ERRORS_REQUESTED,
//     payload: data,
//   }
// }

// SET UPDATE MOBILE NUMBER MODAL
export function setRegisterUpdateMobileNumber(data) {
  return {
    type: type.SET_REGISTER_UPDATE_MOBILE_NUMBER_MODAL_REQUESTED,
    payload: data,
  }
}



// ============== RESEND EMAIL & MOBILE OTP CONFIRMATION & VERIFICATION ============

// RESEND EMAIL OTP CONFIRMATION
export function resendEmailOTPConfirmation(data) {
  return {
    type: type.RESEND_EMAIL_OTP_CONFIRMATION_REQUESTED,
    payload: data,
  }
}


// RESEND MOBILE OTP CONFIRMATION
export function resendMobileOTPConfirmation(data) {
  return {
    type: type.RESEND_MOBILE_OTP_CONFIRMATION_REQUESTED,
    payload: data,
  }
}

// RESET MOBILE NUMBER ON CANCEL
export function resetMobileNumberOnCancel(data) {
  return {
    type: type.RESET_MOBILE_NUMBER_REQUESTED,
    payload: data,
  }
}

// RESET MOBILE NUMBER ON CANCEL
export function resetEmailOnCancel(data) {
  return {
    type: type.RESET_EMAIL_REQUESTED,
    payload: data,
  }
}

// GET SCREEN SHOTS OF PROJECT ACTION
export function getScreenShots(data) {
  return {
    type: type.GET_SCREENSHOT_REQUESTED,
    payload: data,
  }
}
