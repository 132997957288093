import { useEffect, useState } from "react";
import { Button, Col, Form, FormGroup, Input, Label } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { createProjectPersona, getProjectDetails,  setProjectActiveTab, updateProjectPersona, updateProjectTabThree, getAllProjectPersonas } from "../../../../redux/reduxes/customer/projects-new/projectAction";
import { getAllEducationListSelection , getAllProjectLanguages} from "../../../../redux/reduxes/customer/general/actions";
import SelectLocation from "./selectLocation";
import Select from "react-select";
import AddMultiplePersonas from "./addMultiplePersonas";
import { useParams } from 'react-router-dom';
import { useLocation } from "react-router-dom";

// Target User Type Checkbox
const targets = [
  { id: 'Informed', title: 'Informed', description: 'A straight shooter who knows the audience and needs. Transacts exactly what is in his/her mind' },
  { id: 'Window Shopper', title: 'Window Shopper', description: 'With free time in hand, curiously going around to view products and services. Unsure of needs' },
  { id: 'Boomerang', title: 'Boomerang', description: 'Master of procrastination, knows what he needs, but hesitant to make on the spot decision' },
  { id: 'Dora the Explorer', title: 'Dora the Explorer', description: 'Is informed of the need, watchful of market trends. Knows where to find the perfect deal' },
]

// Age Checkbox
const ages = [
  { id: '5 - 15 years old', title: '5 - 15 years old' },
  { id: '15 - 30 years old', title: '15 - 30 years old' },
  { id: '30 - 45 years old', title: '30 - 45 years old' },
  { id: '45+ Above', title: '45+ Above' },
]

// Gender Checkbox
const gender = [
  { id: 'Male', title: 'Male' },
  { id: 'Female', title: 'Female' },
  { id: 'Non-binary', title: 'Non-binary' },
  { id: 'Not-Applicable', title: 'Not-Applicable' },
]

// Budget Checkbox
const budget = [
  { id: '$50 - $100', title: '$50 - $100', description: 'You get 1 expert and 5 real user' },
  { id: '$100 - $500', title: '$100 - $500', description: 'You get 1 expert and 5 real user' },
  { id: '$5K and above', title: '$5K and above', description: 'You get 1 expert and 5 real user' },
  { id: 'Custom Project', title: 'Custom Project', description: 'Customize your project as per your need' },
]

const LocationDemographicsTab = ({ projectActiveTab }) => {
  const dispatch = useDispatch();
  const { mode } = useParams();
  const search = useLocation().search;
  // const project = new URLSearchParams(search).get('project');
  // Fetch Data
  const updateProjectTabThreeLoading = useSelector(state => state.projectNew.updateProjectTabThreeLoading);
  const allEducationList = useSelector(state => state.general.allEducationList);
  const allProjectLanguages = useSelector(state => state.general.allProjectLanguages);
  // const realUserType = useSelector(state => state.projectNew.realUserType ?? false);
  const personaDetails = useSelector(state => state.projectNew.projectPersonaDetails);
  const projectDetails = useSelector(state => state.projectNew.projectDetails);
  const allProjectPersonas = useSelector(state => state.projectNew.allProjectPersonas);


  const details = useSelector(state => state.projectNew.projectDetails.project);
  const project = details && details._id;
  const realUserType = details && details.realUserType;
console.log(realUserType, "The real u typ uin hbhyhybujbnd", allProjectPersonas);
  // Set Default Project
  useEffect(() => {
      const data = {
        projectId: project,
    }
    dispatch(getProjectDetails(data));
  }, [])

  // Get Languages and Eduction List
  useEffect(() => {
    const data = {
      projectId: project,
  }
    dispatch(getAllEducationListSelection());
    setSearchEducationList(allEducationList)
    setNewList(searchEducationList.map(item => ({
      id: item._id,
      label: item.name,
      value: item.name,
    })))
    dispatch(getAllProjectLanguages(project));
    dispatch(getAllProjectPersonas(data))

  }, [project, projectActiveTab]);

  // States
  const [state, setState] = useState({
    allPersonas: [],
    cities: [],
    projectAwareness: [],
    age: [],
    gender: [],
    language: [],
    educationLevel: [],
    budget: "",

    errors: {
      allPersonas: "",
      cities: "",
      projectAwareness: "",
      age: "",
      gender: "",
      language: "",
      educationLevel: "",
      budget: "",
    }
  });

  // Get Persona Details
  useEffect(() => {
    personaDetails && personaDetails.projectAwareness && setState((prevProps) => ({
      ...prevProps,
      projectAwareness: personaDetails && personaDetails.projectAwareness,
      age: personaDetails && personaDetails.age,
      gender: personaDetails && personaDetails.gender,
      language: personaDetails && personaDetails.language,
      educationLevel: personaDetails && personaDetails.educationLevel,
    }));
  }, [personaDetails]);

  // Dispatch to Persona Details
  useEffect(() => {
    const data = {
      personaId: personaDetails && personaDetails._id,
    }
    dispatch(updateProjectPersona(data));
  }, []);


  // onChange Target User Type Handler
  const onChangeProjectAwarenessHandler = (event) => {
    let errors = state.errors;
    if (event.target.checked) {
      setState((prevProps) => ({
        ...prevProps,
        projectAwareness: [...state.projectAwareness, event.target.value],
      }));
    } else {
      setState((prevProps) => ({
        ...prevProps,
        projectAwareness: state.projectAwareness.filter(item => item != event.target.value),
      }))
    }
    errors.projectAwareness = "";
    setState((prevProps) => ({
      ...prevProps,
      errors,
    }));
  };

  const onChangeLanguageHandler = (event) =>{
    const { value, name } = event.target;
    let errors = state.errors;
    errors.language = value == "" ? "Select Project Language" : "";
    console.log(value)

    if(!state.language.includes(value)){
    setState((prevProps) => ({
      ...prevProps,
      errors: errors,
      language: [...prevProps.language, value],
    }))}else{
      setState((prevProps) => ({
        ...prevProps,
        language: state.language.filter(item => item != event.target.value),
      }))
    }
  }

  // onChange Target Audience Age Handler
  const onChangeAgeHandler = (event) => {
    let errors = state.errors;
    let age = null
    if (event.target.checked) {
      switch(event.target.id){
        case '5 - 15 years old':
          age = '5 - 15 years old'
          break;
        case '15 - 30 years old':
          age = '15 - 30 years old'
          break;
        case '30 - 45 years old':
          age = '30 - 45 years old'
          break;
        case '45+ Above':
          age = '45+ Above'
          break;
        default:
          age='null'
      }
      setState((prevProps) => ({
        ...prevProps,
        age: [...state.age, age],
      }));
    } else {
      setState((prevProps) => ({
        ...prevProps,
        age: state.age.filter(item => item != event.target.value),
      }));
    }
    errors.age = "";
    setState((prevProps) => ({
      ...prevProps,
      errors,
    }))
    dispatch(getAllEducationListSelection(age));
    setSearchEducationList(allEducationList)
    // setNewList (searchEducationList.map(item => ({
    //   id: item._id,
    //   value:item.value,
    //   label: item.name,
    // })));
  }

  const onChangeGenderHandler = (event) =>{
    const { value, name } = event.target;
    let errors = state.errors;
    errors.gender = value == "" ? "Select your Gender" : "";
    console.log(value)

    if(!state.gender.includes(value)){
    setState((prevProps) => ({
      ...prevProps,
      errors: errors,
      gender: [...prevProps.gender, value],
    }))}else{
      setState((prevProps) => ({
        ...prevProps,
        gender: state.gender.filter(item => item != event.target.value),
      }))
    }
  }
  
  // onChange Gender Handler
  const onChangeHandler = (event) => {
    const { value, name } = event.target;
    
    let errors = state.errors;
    switch (name) {
      case 'budget':
        errors.budget = value == "" ? "Specify your project Budget." : "";
        break;
      default:
        break;
    }
    setState((prevProps) => ({
      ...prevProps,
      errors: errors,
      [name]: value,
    }));
  };


  // Get the Education Level and set to new state
  const [searchEducationList, setSearchEducationList] = useState([]);
  const [newList, setNewList] = useState([]);

  useEffect(() => {
    setSearchEducationList(allEducationList)
  }, [allEducationList]);

  // onChange Education Handler
  const [educationValue, setEducationValue] = useState("");
  const [educationId, setEducationId] = useState(null);
  const [selectedEducations, setSelectedEducations] = useState([]);

  const onChangeEducationHandler = (obj) => {
    let errors = state.errors
    const isFound = selectedEducations.some(o => o.id === obj.id);
    
      if (!isFound) {
        errors.educationLevel = ""
        setSelectedEducations([...selectedEducations, { id: obj.id, label: obj.label, value: obj.value }]);
        // const filteredEducations = searchEducationList.filter(item => item._id !== obj.id);
        // setSearchEducationList(filteredEducations);  
      } else {
        console.log('Object already exists in selectedEducations');
      }
  }

  // Remove Education Item from the List
  const confirmDeleteEducationItem = (obj) => {
    setSelectedEducations(selectedEducations.filter(option => option.value != obj));
    // const filteredEducations = allEducationList && allEducationList.filter(item => item.value == obj);
    // setSearchEducationList([...searchEducationList, filteredEducations[0]]);
  }

  // Validate Form
  const validateForm = () => {
    let valid = true;
    let errors = state.errors;
    if (state.projectAwareness.length === 0 && realUserType == false) {
      errors.projectAwareness = "Select atleast one Target User type.";
      valid = false;
    }
    if (citiesData.length === 0) {
      errors.cities = "Select atleast one location.";
      valid = false;
    }
    if (state.age.length === 0 && realUserType == false) {
      errors.age = "Select atleast one Age group.";
      valid = false;
    }
    if (state.gender == "" && realUserType == false) {
      errors.gender = "Select your Gender";
      valid = false;
    }
    if (state.language == "" && realUserType == false) {
      errors.language = "Select a Language";
      valid = false;
    }
    if (selectedEducations.length === 0 && realUserType == false) {
      errors.educationLevel = "Select atleast one Education Level";
      valid = false;
    }
    if (allProjectPersonas.length === 0&& realUserType == true) {
      errors.allPersonas = "Add atleast one persona.";
      valid = false;
    }
    if (state.budget == "") {
      errors.budget = "Specify your project Budget.";
      valid = false;
    }
    setState((prevProps) => ({
      ...prevProps,
      errors,
    }));
    return valid;
  }

    // reset the persona error
    useEffect(() => {
      let errors = state.errors;
      errors.allPersonas = "";
      setState((prevProps) => ({
        ...prevProps,
        errors,
      }));
    }, [allProjectPersonas])

  const [citiesData, setCitiesData] = useState([]);
  const handleLocationData = (cities) => {
    // console.log("ITS A HIT!!",cities)
    // if (!citiesData.includes(obj)) {
    //   // setSelectedEducations([...selectedEducations, { label: obj.label, value: obj.value }]);
    //   const filteredEducations = searchEducationList.filter(item => item._id != obj._id);
    //   // setSearchEducationList(filteredEducations);
    // }
    setCitiesData(cities);
    let errors = state.errors;
    errors.cities = "";
    setState((prevProps) => ({
      ...prevProps,
      errors,
    }));
  }

  // onSubmit Handler
  const onSubmitHandler = async (event) => {
    event.preventDefault();
    const educationIds = [];
    selectedEducations && selectedEducations.forEach(items => {
      educationIds.push(items.value);
    });
    if (validateForm()) {
      let cityIds = [];
      let stateIds = [];

      citiesData.forEach(city => {
        if (city.stateAsCity) {
          stateIds.push(city.cityId);
        } else {
          cityIds.push(city.cityId);
        }
      })
      if (realUserType == true) {
        const locationData = {
          projectId: project,
          data: {
            projectData: {
              cities: cityIds,
              states: stateIds,
              budget: state.budget,
            }
          }
        }
        dispatch(updateProjectTabThree(locationData));
      } else {
        const locationData = {
          projectId: project,
          data: {
            personaData: {
              projectAwareness: state.projectAwareness,
              age: state.age,
              gender: state.gender,
              language: state.language,
              educationLevel: educationIds,
            },
            projectData: {
              cities: cityIds,
              states: stateIds,
              budget: state.budget,
            }
          }
        }
        dispatch(updateProjectTabThree(locationData));
      }
    }
  }

  return (
    <>
      <div className="us-customer__project-location">
        <Form onSubmit={(e) => onSubmitHandler(e)}>
          {/* ======== DEMOGRAPHIC PROFILE ========== */}
          <div className="us-form__wrap-card">
            <div className="head-font text-sm font-bold mb-1">Demographic Profile</div>
            <p className="us-para color-gray3 mb-10">Define target user/persona for your product</p>

            {/* Target Audience Location*/}
            <SelectLocation
              handleLocationData={handleLocationData}
            />
             <div className="us-form__error"> {state.errors.cities}</div>

            {/* Single Persona */}
            {realUserType == false &&
              <>
                {/* Target User Type */}
                <FormGroup className="us-form__wrap mt-10">
                  <div className="head-font text-sm font-medium mb-4">Target User Type <span className="text-danger">*</span></div>
                  <div className="w-100 d-inline-flex d-md-flex flex-column flex-md-row align-items-start flex-wrap gap-3">
                    {targets && targets.map((target, index) => {
                      const { id, title, description } = target;
                      return (
                        <>
                          <div className="us-form__wrap position-relative" key={index}>
                            <Input
                              type="checkbox"
                              id={id}
                              name="projectAwareness"
                              className="us-form__custom-checkbox"
                              value={title}
                              onChange={(e) => onChangeProjectAwarenessHandler(e)}
                              checked={state.projectAwareness.includes(title)}
                            />
                            <Label htmlFor={id} className="us-form__custom-label us-form__custom-label--right rounded-min max-w-250">
                              <div className="us-form__custom-label--title mb-4 me-5 me-xl-10">{title}</div>
                              <div className="us-para color-gray3">{description}</div>
                            </Label>
                          </div>
                        </>
                      )
                    })}
                  </div>
                  <div className="us-form__error">{state.errors.projectAwareness}</div>
                </FormGroup>

                {/* Age of Audience */}
                <FormGroup className="us-form__wrap mt-10">
                  <div className="head-font text-sm font-medium mb-4">Specify Age of your target audience <span className="text-danger">*</span></div>
                  <div className="w-100 d-flex align-items-start flex-wrap gap-1 gap-md-3">
                    {ages && ages.map((age, index) => {
                      const { id, title } = age;
                      return (
                        <>
                          <FormGroup className="us-form__wrap position-relative" key={index}>
                            <Input
                              type="checkbox"
                              id={id}
                              name="age"
                              className="us-form__custom-checkbox"
                              value={title}
                              onChange={(e) => onChangeAgeHandler(e)}
                              checked={state.age.includes(title)}
                            />
                            <Label htmlFor={id} className="us-form__custom-label us-form__custom-label--right rounded-full">
                              <div className="us-form__custom-label--title me-5 me-xl-10">{title}</div>
                            </Label>
                          </FormGroup>
                        </>
                      )
                    })}
                  </div>
                  <div className="us-form__error">{state.errors.age}</div>
                </FormGroup>

                {/* Gender of Audience */}
                <FormGroup className="us-form__wrap mt-6">
                  <div className="head-font text-sm font-medium mb-4">Specify Gender <span className="text-danger">*</span></div>
                  <div className="w-100 d-flex align-items-start flex-wrap gap-1 gap-md-3">
                    {gender && gender.map((gen, index) => {
                      const { id, title } = gen;
                      return (
                        <>
                          <FormGroup className="us-form__wrap position-relative" key={index}>
                            <Input
                              type="checkbox"
                              id={id}
                              name="gender"
                              className="us-form__custom-checkbox"
                              value={id}
                              onChange={(e) => onChangeGenderHandler(e)}
                              checked={state.gender.includes(id)}
                            />
                            <Label htmlFor={id} className="us-form__custom-label us-form__custom-label--right rounded-full">
                              <div className="us-form__custom-label--title me-10">{title}</div>
                            </Label>
                          </FormGroup>
                        </>
                      )
                    })}
                  </div>
                  <div className="us-form__error">{state.errors.gender}</div>
                </FormGroup>

                {/* Audience Languages */}
                <FormGroup className="us-form__wrap mt-6">
                  <div className="head-font text-sm font-medium mb-4">Target Audience Language <span className="text-danger">*</span></div>
                  <div className="w-100 d-flex align-items-start flex-wrap gap-1 gap-md-3">
                    {allProjectLanguages && allProjectLanguages.length > 0 && allProjectLanguages.map((lang, index) => {
                      return (
                        <>
                          <FormGroup className="us-form__wrap position-relative" key={index}>
                            <Input
                              type="radio"
                              id={lang}
                              name="language"
                              className="us-form__custom-checkbox"
                              value={lang}
                              onChange={(e) => onChangeLanguageHandler(e)}
                              checked={state.language.includes(lang)}
                            />
                            <Label htmlFor={lang} className="us-form__custom-label us-form__custom-label--radioRightCenter rounded-full">
                              <div className="us-form__custom-label--title me-10">{lang}</div>
                            </Label>
                          </FormGroup>
                        </>
                      )
                    })}
                  </div>
                  <div className="us-form__error">{state.errors.language}</div>
                </FormGroup>

                {/* Audience Education Level */}
                <FormGroup className="row g-2 us-form__wrap mt-6">
                  <Label htmlFor="education" className="us-form__label font-medium mb-0">Audience Education Level <span className="text-danger">*</span></Label>
                  <Select
                    id="educationLevel"
                    name="educationLevel"
                    placeholder="Select Education Level"
                    classNamePrefix="select"
                    className="us-form__input-searchSelect w-100"
                    value={educationValue}
                    options={newList}
                    onChange={(e) => onChangeEducationHandler(e)}
                  />
                  <div className="us-form__error">{state.errors.educationLevel}</div>

                  {/* Selected Languages */}
                  {(selectedEducations && selectedEducations.length > 0) &&
                    <ul className="us-form__lists d-flex align-items-start flex-wrap w-100 gap-2 mt-5">
                      {selectedEducations.map((education, index) => {
                        return (
                          <li key={index} className="us-form__list w-sm rounded-100 d-flex align-items-center justify-content-between">
                            <div className="us-form__list-title text-xs">{education.label}</div>
                            <Button onClick={() => confirmDeleteEducationItem(education.value)} type="button" color="link" className="us-button__link p-0 ms-3">
                              <img src="/assets/icons/icon-cancel-red.svg" alt="icon-cancel" width="16" height="16" />
                            </Button>
                          </li>
                        )
                      })}
                    </ul>
                  }
                </FormGroup>
              </>
            }
          </div>

          {realUserType == true &&
            <>
              {/* MULTIPLE PERSONA --- FOR REAL USER REPORT */}
              <AddMultiplePersonas
              projectActiveTab={projectActiveTab}
              error={state.errors.allPersonas}
            />
            </>
          }


          {/* ============ BUDGET =========== */}
          <div className="us-form__wrap-card">
            <div className="head-font text-sm font-bold">Your Budget <span className="text-danger">*</span></div>
            <p className="us-para color-gray3 mb-4">Specify your project budget</p>
            <div className="w-100 d-inline-flex d-md-flex flex-column flex-md-row align-items-start flex-wrap gap-3">
              {budget && budget.map((cost, index) => {
                const { id, title, description } = cost;
                return (
                  <>
                    <div className="us-form__wrap position-relative" key={index}>
                      <Input
                        type="radio"
                        id={id}
                        name="budget"
                        className="us-form__custom-checkbox"
                        value={id}
                        onChange={(e) => onChangeHandler(e)}
                        checked={id === state.budget ? true : false}
                      />
                      <Label htmlFor={id} className="us-form__custom-label us-form__custom-label--radioRightCenter rounded-min max-w-250">
                        <div className="us-form__custom-label--title mb-4 me-5 me-xl-10">{title}</div>
                        <div className="us-para color-gray3">{description}</div>
                      </Label>
                    </div>
                  </>
                )
              })}
            </div>
            <div className="us-form__error">{state.errors.budget}</div>
          </div>

          <div className="mt-14 text-end">
            <Button onClick={() => dispatch(setProjectActiveTab(1))} color="primary" outline className="us-button us-button__primary-outline text-uppercase px-10 me-6">Back</Button>
            <Button
              type="submit"
              color="primary"
              disabled={updateProjectTabThreeLoading}
              className={`us-button us-button__primary text-uppercase px-8 ${updateProjectTabThreeLoading ? "us-button__disabled" : "us-button__primary"}`}
            >
              {updateProjectTabThreeLoading ? "Saving..." : "Create Project"}
            </Button>
          </div>
        </Form>
      </div>

    </>
  )
}
export default LocationDemographicsTab;