import React, { useEffect, useState } from "react";
import { Button, Col, Form, FormGroup, Input, Label } from "reactstrap";
import { Link } from "react-router-dom";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'
import { useDispatch, useSelector } from 'react-redux';
import { registerUser, resetRegisterErrorMessages,linkedInSignUp } from '../../../redux/reduxes/global/auth/actions';
import { linkedInAuth } from "../../../utils/socialLogin";
import { useLocation } from "react-router-dom";
import { Router } from "react-router-dom/cjs/react-router-dom.min";
import { isValidPhoneNumber } from 'react-phone-number-input'

const RegistrationForm = (props) => {
  const dispatch = useDispatch();

  const { search } = useLocation();
  let query = React.useMemo(() => new URLSearchParams(search), [search]);

  // Fetch Data
  const registerUserLoading = useSelector(state => state.auth.registerUserLoading);
  const registerUserError = useSelector(state => state.auth.registerUserError);
  const linkedInUser = useSelector((state) => state.auth.linkedInUser);

  useEffect(() => {
    if (query.get("email")) {
      dispatch(linkedInSignUp(true));
      validateForm();
    } else {
      dispatch(linkedInSignUp(false));
    }
  }, []);

  // States
  const [state, setState] = useState({
    first_name: query.get("firstName") || "",
    last_name: query.get("lastName") || "",
    mobile_number: "",
    emailExistAsNormal: query.get("user_exists") || "",
    email: (query.get("user_exists")) =="true"?  "" : query.get("email"),
    password: "",
    password_confirmation: "",
    terms_conditions: false,

    errors: {
      first_name: "",
      last_name: "",
      mobile_number: "",
      email: "",
      password: "",
      passwordUppercase: "",
      passwordDigit: "",
      passwordSpecialCharacters: "",
      password_confirmation: "",
      terms_conditions: "",
    },
  });
  // console.log(query.get("emailExistAsNormal")=="true", "emailExistAsNormal kokok", query.get("email"),"hhhhhhhhhhhhhh", state.email);

  // Dispatch Register Errors
  useEffect(() => {
    let errors = state.errors;
    registerUserError && registerUserError.forEach(error => {
      switch (error.context.key) {
        case 'mobileNumber':
          errors.mobile_number = error.message;
          break;
        case 'email':
          errors.email = error.message;
          break;
        default:
          break;
      }
    });
    if (registerUserError.length == 0) {
      errors.mobile_number = "";
      errors.email = "";
    }
    setState((prevProps) => ({
      ...prevProps,
      errors,
    }));
  }, [registerUserError]);

  // onChange Handler
  const onChangeHandler = (event) => {
    event.preventDefault();

    // Email Regular Expression Format
    const validEmailRegex = RegExp(
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    );
    const upperCaseLetters = RegExp(/[A-Z]/g);
    const digits = RegExp(/[0-9]/g);
    const specialCharacters = RegExp(
      /^(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]).*$/
    );

    const { name, value } = event.target;
    let errors = state.errors;
    switch (name) {
      case "first_name":
        errors.first_name = value === "" || value.replace(/\s+/g, "").length == 0 ? "Enter First Name" :  value.replace(/\s+/g, "").length >=29? "Should not exceed 30 characters!" : "";
        break;
      case "last_name":
        errors.last_name =  value === "" || value.replace(/\s+/g, "").length == 0 ? "Enter Last Name" : "";
        break;
      case "email":
        errors.email = validEmailRegex.test(value) ? "" : "Enter a valid email ID";
        break;
      case "password":
        if (!upperCaseLetters.test(value)) {
          errors.passwordUppercase =
            "Password should have atleast one Uppercase letter";
        } else {
          errors.passwordUppercase = "";
        }
        if (!digits.test(value)) {
          errors.passwordDigit = "Password should have atleast one Digit";
        } else {
          errors.passwordDigit = "";
        }
        if (!specialCharacters.test(value)) {
          errors.passwordSpecialCharacters =
            "Password should have atleast one Special character";
        } else {
          errors.passwordSpecialCharacters = "";
        }
        if (value.length < 8) {
          errors.password = "Password must be minimum of 8 characters long";
        } else {
          errors.password = "";
        }
        break;
      case "password_confirmation":
        if (value == "") {
          errors.password_confirmation = "Enter Confirm Password";
        } else {
          errors.password_confirmation = "";
        }
        if (value != state.password) {
          errors.password_confirmation = "Password doesn't match";
        } else {
          errors.password_confirmation = "";
        }
        break;
      default:
        break;
    }
    setState((prevProps) => ({
      ...prevProps,
      errors,
      [name]: value,
    }));
    // dispatch(resetRegisterErrorMessages());
  }

  // Phone Number Validation
  const handlePhoneNumberChange = (value) => {
    // dispatch(resetRegisterErrorMessages());
    let errors = state.errors;
    if (!value) {
      errors.mobile_number = "Mobile Number is Required";
    } else if (!isValidPhoneNumber(`+${value}`)) {
      errors.mobile_number = "Enter a valid mobile number";
    } else {
      errors.mobile_number = "";
    }
    setState((prevProps) => ({
      ...prevProps,
      errors,
      mobile_number: value,
    }));

  }

  // checkboxHandler - Terms & Conditions
  const onCheckboxHandler = (event) => {
    let errors = state.errors;
    setState((prevProps) => ({
      ...prevProps,
      terms_conditions: event.target.checked,
    }));
    errors.terms_conditions = "";
    setState((prevProps) => ({
      ...prevProps,
      errors,
    }));
  };

  // Validate Login Form
  const validateForm = () => {
    let valid = true;

    let errors = state.errors;
    if (state.first_name == "") {
      errors.first_name = "First Name is required.";
      valid = false;
    }
    if (state.last_name == "") {
      errors.last_name = "Last Name is required.";
      valid = false;
    }
    if (!state.mobile_number) {
      errors.mobile_number = "Mobile Number is required.";
      valid = false;
    } else if ((state.mobile_number || "").replace(/\ /g, "").length < 12) {
      errors.mobile_number = "Enter a valid mobile number, must be 10 digits.";
      valid = false;
    } else {
      errors.mobile_number = "";
    }
    if (state.email == "") {
      errors.email = "Email ID is required.";
      valid = false;
    }
    if (
      !linkedInUser &&
      state.password == "" &&
      state.errors.upperCaseLetters != "" &&
      state.errors.digits != "" &&
      state.errors.specialCharacters != "" &&
      state.password.length < 8
    ) {
      errors.password = "Password is required.";
      valid = false;
    }
    if (!linkedInUser && state.password_confirmation == "") {
      errors.password_confirmation = "Password is required.";
      valid = false;
    } else if (!linkedInUser && state.password_confirmation != state.password) {
      errors.password_confirmation = "Confirm Password doesn't match.";
      valid = false;
    } else {
      errors.password_confirmation = "";
    }

    if (!state.terms_conditions) {
      errors.terms_conditions = "Please accept the Terms and Conditions.";
      valid = false;
    }
    setState((prevProps) => ({
      ...prevProps,
      errors: errors,
    }));
    return valid;
  };
console.log("in jdncjnxjcnx in reg");
  // onSubmit Handler
  const onSubmitHandler = async (event) => {
    event.preventDefault();
    console.log(linkedInUser, "linkedInUser HUHUHUHUHUkijijiji");
    if (validateForm()) {
      console.log(linkedInUser, "linkedInUser HUHUHUHUHUkijijiji");
      const data = {
        first_name: state.first_name.replace(/\s+/g, "").trim(),
        last_name: state.last_name.replace(/\s+/g, "").trim(),
        mobile_number: state.mobile_number,
        email: state.email,
        password: state.password,
        password_confirmation: state.password_confirmation,
        terms_conditions: state.terms_conditions,
        linkedInUser: linkedInUser
      };
      if (linkedInUser) {
        data["socialId"] = query.get("socialId");
        data["fullName"] = query.get("fullName");
        // if (state.emailExistAsNormal == "true") {
          data["emailExistAsNormal"] = state.emailExistAsNormal;
          data["socialEmail"] = query.get("email");
          data["provider"] = query.get("provider");
        // }
      }
      // console.log(data, "oKOKOKOKOKOKOKOK");
      dispatch(registerUser(data));
    }
  };

  // Show/Hide Password Toggler
  const [showPassword, setShowPassword] = useState(false);
  const passwordToggler = () => {
    setShowPassword(!showPassword);
  };

  // Show/Hide Confirm Password Toggler
  const [showPasswordConfirmation, setShowPasswordConfirmation] =
    useState(false);
  const passwordConfirmationToggler = () => {
    setShowPasswordConfirmation(!showPasswordConfirmation);
  };



  return (
    <>
      <Form onSubmit={(e) => onSubmitHandler(e)}>
        <h1 className="us-section__title mb-6 text-center">
          Create an account
        </h1>

        {!linkedInUser &&
          <>
          <FormGroup className='us-form__wrap pb-2'>
            <Button type='button' color='primary' onClick={linkedInAuth} className='us-button us-button__primary us-button__primary-linkedin d-flex algn-items-center justify-content-center w-100'>
              <img src="/assets/icons/icon-linkedin.svg" alt="icon-linkedin" className='me-3' />
              <span className='text-uppercase mt-1'>Sign up with LinkedIn</span>
            </Button>
          </FormGroup>


        <div className="us-auth__other d-flex justify-content-center position-relative mb-5">
          or
          </div>
          </>
        }

        <FormGroup className="row us-form__wrap g-2">
          <Label htmlFor="first_name" className="us-form__label mb-1">
            Full name <span className="text-danger">*</span>
          </Label>
          <Col md="6">
            <Input
              type="text"
              name="first_name"
              id="first_name"
              placeholder="First Name"
              className={`us-form__input ${
                state.errors.first_name ? "us-form__input-error" : ""
              }`}
              value={state.first_name}
              maxLength={30}
              onChange={(e) => onChangeHandler(e)}
            />
            <div className="us-form__error">{state.errors.first_name}</div>
          </Col>
          <Col md="6">
            <Input
              type="text"
              name="last_name"
              id="last_name"
              placeholder="Last Name"
              className={`us-form__input ${
                state.errors.last_name ? "us-form__input-error" : ""
              }`}
              value={state.last_name}
              onChange={(e) => onChangeHandler(e)}
            />
            <div className="us-form__error">{state.errors.last_name}</div>
          </Col>
        </FormGroup>

        <FormGroup className="us-form__wrap">
          <Label htmlFor="mobile_number" className="us-form__label">
            Mobile Number <span className="text-danger">*</span>
          </Label>
          <PhoneInput
            id="mobile_number"
            name="mobile_number"
            country={"in"}
            value={state.mobile_number}
            specialLabel={""}
            onChange={(e) => handlePhoneNumberChange(e)}
            className={`us-form__input us-form__input--country overflow-unset d-flex align-items-center w-100 overflow-auto ${
              state.errors.mobile_number ? "us-form__input-error" : ""
            }`}
          />
          <div className="us-form__error">{state.errors.mobile_number}</div>
        </FormGroup>

        <FormGroup className="us-form__wrap">
          <Label style={{ marginBottom: '-1px' }} htmlFor="email" className="us-form__label">
            Email <span className="text-danger">*</span>
          </Label>
          {state.emailExistAsNormal == "true" && <div style={{ marginBottom: '10px' }} className="us-form__error">The email address {query.get("email")} is already in use. Kindly select an alternative email address.</div>}
          {/* <div className="checkbox-wrap-test"></div> */}
          <Input
            type="email"
            name="email"
            id="email"
            placeholder="Email ID"
            className={`us-form__input ${
              state.errors.email ? "us-form__input-error" : ""
            }`}
            value={state.email}
            onChange={(e) => onChangeHandler(e)}
          />
          <div className="us-form__error">{state.errors.email}</div>
        </FormGroup>

        {!linkedInUser && (
          <div>
            <FormGroup className="us-form__wrap">
              <Label htmlFor="password" className="us-form__label">
                Password <span className="text-danger">*</span>
              </Label>
              <div className="us-form__input-group position-relative d-flex align-items-center">
                <Input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  id="password"
                  placeholder="Password"
                  className={`us-form__input ${
                    state.errors.password ? "us-form__input-error" : ""
                  }`}
                  value={state.password}
                  onChange={(e) => onChangeHandler(e)}
                  autoComplete="password"
                />
                <Button
                  type="button"
                  onClick={() => passwordToggler()}
                  className="us-form__input-button position-absolute"
                >
                  {showPassword ? (
                    <img
                      src="/assets/icons/icon-eye-show.svg"
                      alt="icon-eye-show"
                      className="img-fluid"
                    />
                  ) : (
                    <img
                      src="/assets/icons/icon-eye-hide.svg"
                      alt="icon-eye-hide"
                      className="img-fluid"
                    />
                  )}
                </Button>
              </div>
              <div className="us-form__error">{state.errors.password}</div>
              <div className="us-form__error">
                {state.errors.passwordUppercase}
              </div>
              <div className="us-form__error">{state.errors.passwordDigit}</div>
              <div className="us-form__error">
                {state.errors.passwordSpecialCharacters}
              </div>
            </FormGroup>

            <FormGroup className="us-form__wrap">
              <Label htmlFor="password_confirmation" className="us-form__label">
                Confirm Password <span className="text-danger">*</span>
              </Label>
              <div className="us-form__input-group position-relative d-flex align-items-center">
                <Input
                  type={showPasswordConfirmation ? "text" : "password"}
                  name="password_confirmation"
                  id="password_confirmation"
                  placeholder="Confirm Password"
                  className={`us-form__input ${
                    state.errors.password_confirmation
                      ? "us-form__input-error"
                      : ""
                  }`}
                  value={state.password_confirmation}
                  onChange={(e) => onChangeHandler(e)}
                  autoComplete="password_confirmation"
                />
                <Button
                  type="button"
                  onClick={() => passwordConfirmationToggler()}
                  className="us-form__input-button position-absolute"
                >
                  {showPasswordConfirmation ? (
                    <img
                      src="/assets/icons/icon-eye-show.svg"
                      alt="icon-eye-show"
                      className="img-fluid"
                    />
                  ) : (
                    <img
                      src="/assets/icons/icon-eye-hide.svg"
                      alt="icon-eye-hide"
                      className="img-fluid"
                    />
                  )}
                </Button>
              </div>
              <div className="us-form__error">
                {state.errors.password_confirmation}
              </div>
            </FormGroup>
          </div>
        )}

        <FormGroup className="us-form__wrap ">
          <div className="d-flex align-items-start">
            <Input
              type="checkbox"
              id="terms_conditions"
              name="terms_conditions"
              className="us-form__custom-register--checkbox"
              value={state.terms_conditions}
              checked={state.terms_conditions}
              onChange={(e) => onCheckboxHandler(e)}
            />
            <Label
              htmlFor="terms_conditions"
              className="us-form__custom-register--label text-xs ps-2 mt-1"
            >
              I agree to the
              <Link target="_blank" to="/terms-conditions" exact className="us-button__link underline px-1">
                Useralia Terms.
              </Link>
              Learn about how we use and protect your data in our
              <Link target="_blank" to="/privacy-policy" exact className="us-button__link underline px-1">
                Privacy Policy.
              </Link>
            </Label>
          </div>
          <div className='us-form__error'>{state.errors.terms_conditions}</div>
        </FormGroup>


        <FormGroup className="us-form__wrap mt-5">
          <Button
            type="submit"
            color="primary"
            disabled={registerUserLoading}
            className={`us-button us-button__primary d-block w-100 text-uppercase ${registerUserLoading ? "us-button__disabled" : "us-button__primary"}`}
          >
            {registerUserLoading ? "Loading..." : "Register"}
          </Button>
        </FormGroup>

        <Label className="d-flex justify-content-center mt-6">Already have an account?<Link className='text-primary ms-2' to='/login'>Login</Link> </Label>

      </Form>
    </>
  );
};
export default RegistrationForm;
