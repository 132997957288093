import * as type from './types';

const initialState = {

  // Get All Project Categories
  allProjectCategories: [],
  allProjectCategoriesLoading: false,
  allProjectCategoriesError: null,

  // Get All Project Multilingual
  allProjectMultilingual: [],
  allProjectMultilingualLoading: false,
  allProjectMultilingualError: null,

  // Get All Countries List
  allCountriesList: [],
  allCountriesListLoading: false,
  allCountriesListError: null,

  // Get All States List
  allStatesList: [],
  allStatesListLoading: false,
  allStatesListError: null,

  // Get All Cities List
  allCitiesList: [],
  allCitiesListLoading: false,
  allCitiesListError: null,

  // Get All Education Lists
  allEducationList: [],
  allEducationListLoading: false,
  allEducationListError: null,

  // Get All Project Languages
  allProjectLanguages: [],
  allProjectLanguagesLoading: false,
  allProjectLanguagesError: null,
};

export default function general(state = initialState, action) {
  switch (action.type) {

     // GET ALL COUNTRIES LIST
     case type.GET_ALL_COUNTRIES_LIST_REQUESTED:
      return {
        ...state,
        allCountriesListLoading: true,
      };
    case type.GET_ALL_COUNTRIES_LIST_SUCCESS:
      return {
        ...state,
        allCountriesListLoading: false,
        allCountriesList: action.payload,
        allStatesList: [],
        allCitiesList: [],
      };
    case type.GET_ALL_COUNTRIES_LIST_FAILED:
      return {
        ...state,
        allCountriesListLoading: false,
        allCountriesListError: action.message,
      };

    // GET ALL STATES LIST
    case type.GET_ALL_STATES_LIST_REQUESTED:
      return {
        ...state,
        allStatesListLoading: true,
      };
    case type.GET_ALL_STATES_LIST_SUCCESS:
      return {
        ...state,
        allStatesListLoading: false,
        allStatesList: action.allStatesList,
        allCitiesList:[],
      };
    case type.GET_ALL_STATES_LIST_FAILED:
      return {
        ...state,
        allStatesListLoading: false,
        allStatesListError: action.message,
      };

    // GET ALL CITIES
    case type.GET_ALL_CITIES_LIST_REQUESTED:
      return {
        ...state,
        allCitiesListLoading: true,
      };
    case type.GET_ALL_CITIES_LIST_SUCCESS:
      return {
        ...state,
        allCitiesListLoading: false,
        allCitiesList: action.allCitiesList,

      };
    case type.GET_ALL_CITIES_LIST_FAILED:
      return {
        ...state,
        allCitiesListLoading: false,
        allCitiesListError: action.message,
      };

    // GET ALL EDUCATION LIST SELECTION
    case type.GET_ALL_EDUCATION_LIST_SELECTION_REQUESTED:
      return {
        ...state,
        allEducationListLoading: true,
      };
    case type.GET_ALL_EDUCATION_LIST_SELECTION_SUCCESS:
      return {
        ...state,
        allEducationListLoading: false,
        allEducationList: action.allEducationList,
      };
    case type.GET_ALL_EDUCATION_LIST_SELECTION_FAILED:
      return {
        ...state,
        allEducationListLoading: false,
        allEducationListError: action.message,
      };

    // GET ALL PROJECT LANGUAGES
    case type.GET_ALL_PROJECT_LANGUAGES_REQUESTED:
      return {
        ...state,
        allProjectLanguagesLoading: true,
      };
    case type.GET_ALL_PROJECT_LANGUAGES_SUCCESS:
      return {
        ...state,
        allProjectLanguagesLoading: false,
        allProjectLanguages: action.allProjectLanguages.languages,
      };
    case type.GET_ALL_PROJECT_LANGUAGES_FAILED:
      return {
        ...state,
        allProjectLanguagesLoading: false,
        allProjectLanguagesError: action.message,
      };

       // GET ALL CATEGORIES
       case type.GET_ALL_PROJECT_CATEGORIES_REQUESTED:
        return {
          ...state,
          allProjectCategoriesLoading: true,
        };
      case type.GET_ALL_PROJECT_CATEGORIES_SUCCESS:
        return {
          ...state,
          allProjectCategoriesLoading: false,
          allProjectCategories: action.allProjectCategories.categories,
        };
      case type.GET_ALL_PROJECT_CATEGORIES_FAILED:
        return {
          ...state,
          allProjectCategoriesLoading: false,
          allProjectCategoriesError: action.message,
      };

        // GET ALL MULTILINGUAL - EVALUATION MATERIAL STEP ONE
        case type.GET_ALL_PROJECT_MULTILINGUAL_REQUESTED:
          return {
            ...state,
            allProjectMultilingualLoading: true,
          };
        case type.GET_ALL_PROJECT_MULTILINGUAL_SUCCESS:
          return {
            ...state,
            allProjectMultilingualLoading: false,
            allProjectMultilingual: action.allProjectMultilingual.language,
          };
        case type.GET_ALL_PROJECT_MULTILINGUAL_FAILED:
          return {
            ...state,
            allProjectMultilingualLoading: false,
            allProjectMultilingualError: action.message,
      };

    // CLEAR ALL STATES AND CITIES
      case type.GET_ALL_STATES_AND_CITIES_LIST:
        return {
          ...state,
          allStatesList: [],
          allCitiesList: [],
      };

    default:
      return state;
  }
}