import * as type from "./types";

const initialState = {
  // General
  projectActiveTab: 0,
  edit: false,

  // Get All Projects
  allProjectsList: [],
  allProjectsListLoading: false,
  allProjectsListError: null,
  allProjectsListPagination: {},

  // project details
  projectDetails: {},
  projectDetailsLoading: false,
  projectDetailsError: null,

  // createProject step one
  createProjectTabOneLoading: false,
  createProjectTabOneError: null,

  // Update Project step one
  updateProjectTabOneLoading: false,
  updateProjectTabOneError: null,

  // Update Evaluation Reasons
  updateEvaluationReasonsLoading: false,
  updateEvaluationReasonsError: null,

  // update Project Tab Three
  updateProjectTabThreeLoading: false,
  updateProjectTabThreeError: null,

  // Get All Evaluation Materials List
  allEvaluationMaterials: [],
  allEvaluationMaterialsLoading: false,
  allEvaluationMaterialsError: null,

  // Set Project Persona Modal
  setShowProjectPersonaModal: false,
  personaEdit: false,
  personaEditId: "",

  // Create Project Persona
  createProjectPersona: [],
  createProjectPersonaLoading: false,
  createProjectPersonaError: null,

  // Get All Project Personas
  allProjectPersonas: [],
  allProjectPersonasLoading: false,
  allProjectPersonasError: null,

  // Get Project Persona Details
  projectPersonaDetails: {},
  projectPersonaDetailsLoading: false,
  projectPersonaDetailsError: null,

  // Update Project Persona
  updateProjectPersonaLoading: false,
  updateProjectPersonaError: null,

  // Delete Project Persona
  deleteProjectPersonaLoading: false,
  deleteProjectPersonaError: null,

    // Delete Project Draft
    deleteDraftProjectLoading: false,
    deleteDraftProjectError: null,


};

export default function projectNew(state = initialState, action) {
  switch (action.type) {
    // GET ALL PROJECTS
    case type.GET_ALL_PROJECTS_REQUESTED:
      return {
        ...state,
        allProjectsListLoading: true,
      };
    case type.GET_ALL_PROJECTS_SUCCESS:
      return {
        ...state,
        allProjectsListLoading: false,
        allProjectsList: action.allProjectsList.projects,
        allProjectsListPagination: action.allProjectsList.pagination,
      };
    case type.GET_ALL_PROJECTS_FAILED:
      return {
        ...state,
        allProjectsListLoading: false,
        allProjectsListError: action.message,
      };

    // GET PROJECT DETAILS
    case type.GET_PROJECT_DETAILS_REQUESTED:
      return {
        ...state,
        projectDetailsLoading: true,
      };
    case type.GET_PROJECT_DETAILS_SUCCESS:
      return {
        ...state,
        projectDetailsLoading: false,
        projectDetails: action.projectDetails,
        updateEvaluationReasonsError:null,
      };
    case type.GET_PROJECT_DETAILS_FAILED:
      return {
        ...state,
        projectDetailsLoading: false,
        projectDetailsError: action.message,
      };

    // SET PROJECT ACTIVE TAB
    case type.SET_PROJECT_ACTIVE_TAB_REQUESTED:
      return {
        ...state,
        projectActiveTab: action.payload,
      };
    // SET MANAGE PROJECT
    case type.SET_MANAGE_PROJECT:
      return {
        ...state,
        edit: action.payload.edit,
      };

    // CREATE PROJECT - PROJECT PHASE TAB ONE
    case type.CREATE_PROJECT_TAB_ONE_REQUESTED:
      return {
        ...state,
        createProjectTabOneLoading: true,
      };
    case type.CREATE_PROJECT_TAB_ONE_SUCCESS:
      return {
        ...state,
        createProjectTabOneLoading: false,
        projectActiveTab: 1,
        edit: true,
      };
    case type.CREATE_PROJECT_TAB_ONE_FAILED:
      return {
        ...state,
        createProjectTabOneLoading: false,
        createProjectTabOneError: action.message,
      };

    // UPDATE EVALUATION REASONS
    case type.UPDATE_EVALUATION_REASONS_REQUESTED:
      return {
        ...state,
        updateEvaluationReasonsLoading: true,
      };
    case type.UPDATE_EVALUATION_REASONS_SUCCESS:
      return {
        ...state,
        updateEvaluationReasonsLoading: false,
        evaluationReason: action.data.evaluationReason,
        projectActiveTab: 2,
      };
    case type.UPDATE_EVALUATION_REASONS_FAILED:
      return {
        ...state,
        updateEvaluationReasonsLoading: false,
        updateEvaluationReasonsError: action.message,
      };

    // UPDATE PROJECT - PROJECT PHASE TAB ONE
    case type.UPDATE_PROJECT_TAB_ONE_REQUESTED:
      return {
        ...state,
        updateProjectTabOneLoading: true,
      };
    case type.UPDATE_PROJECT_TAB_ONE_SUCCESS:
      return {
        ...state,
        updateProjectTabOneLoading: false,
        projectActiveTab: 1,
      };
    case type.UPDATE_PROJECT_TAB_ONE_FAILED:
      return {
        ...state,
        updateProjectTabOneLoading: false,
        updateProjectTabOneError: action.message,
      };

    // UPDATE PROJECT TAB THREE - LOCATION & DEMOGRAPHICS TAB
    case type.UPDATE_PROJECT_TAB_THREE_REQUESTED:
      return {
        ...state,
        updateProjectTabThreeLoading: true,
      };
    case type.UPDATE_PROJECT_TAB_THREE_SUCCESS:
      return {
        ...state,
        updateProjectTabThreeLoading: false,
        projectTabOneData: action.projectTabOneData,
      };
    case type.UPDATE_PROJECT_TAB_THREE_FAILED:
      return {
        ...state,
        updateProjectTabThreeLoading: false,
        updateProjectTabThreeError: action.message,
      };

    // GET ALL EVALUATION MATERIALS
    case type.GET_ALL_EVALUATION_MATERIALS_REQUESTED:
      return {
        ...state,
        allEvaluationMaterialsLoading: true,
      };
    case type.GET_ALL_EVALUATION_MATERIALS_SUCCESS:
      return {
        ...state,
        allEvaluationMaterialsLoading: false,
        allEvaluationMaterials:
          action.allEvaluationMaterials.evaluationMaterials,
          updateEvaluationReasonsError: null
      };
    case type.GET_ALL_EVALUATION_MATERIALS_FAILED:
      return {
        ...state,
        allEvaluationMaterialsLoading: false,
        allEvaluationMaterialsError: action.message,
      };

    // SET PROJECT PERSONA MODAL
    case type.SET_PROJECT_PERSONA_MODAL_REQUESTED:
      return {
        ...state,
        setShowProjectPersonaModal: action.payload.show,
        personaEdit: action.payload.edit,
        personaEditId: action.payload.id,
      };

    // CREATE PROJECT PERSONA
    case type.CREATE_PROJECT_PERSONA_REQUESTED:
      return {
        ...state,
        createProjectPersonaLoading: true,
      };
    case type.CREATE_PROJECT_PERSONA_SUCCESS:
      return {
        ...state,
        createProjectPersonaLoading: false,
        createProjectPersona: action.createProjectPersona,
        setShowProjectPersonaModal: false,
      };
    case type.CREATE_PROJECT_PERSONA_FAILED:
      return {
        ...state,
        createProjectPersonaLoading: false,
        createProjectPersonaError: action.message,
      };

    // GET ALL PROJECT PERSONAS
    case type.GET_ALL_PROJECT_PERSONAS_REQUESTED:
      return {
        ...state,
        allProjectPersonasLoading: true,
      };
    case type.GET_ALL_PROJECT_PERSONAS_SUCCESS:
      return {
        ...state,
        allProjectPersonasLoading: false,
        allProjectPersonas: action.allProjectPersonas.personas,
      };
    case type.GET_ALL_PROJECT_PERSONAS_FAILED:
      return {
        ...state,
        allProjectPersonasLoading: false,
        allProjectPersonasError: action.message,
      };

    // GET PROJECT PERSONA DETAILS
    case type.GET_PROJECT_PERSONA_DETAILS_REQUESTED:
      return {
        ...state,
        projectPersonaDetailsLoading: true,
      };
    case type.GET_PROJECT_PERSONA_DETAILS_SUCCESS:
      return {
        ...state,
        projectPersonaDetailsLoading: false,
        projectPersonaDetails: action.projectPersonaDetails.persona,
      };
    case type.GET_PROJECT_PERSONA_DETAILS_FAILED:
      return {
        ...state,
        projectPersonaDetailsLoading: false,
        projectPersonaDetailsError: action.message,
      };

    // UPDATE PROJECT PERSONA
    case type.UPDATE_PROJECT_PERSONA_REQUESTED:
      return {
        ...state,
        updateProjectPersonaLoading: true,
      };
    case type.UPDATE_PROJECT_PERSONA_SUCCESS:
      return {
        ...state,
        updateProjectPersonaLoading: false,
        setShowProjectPersonaModal: false,
        personaEdit: false,
        personaEditId: "",
      };
    case type.UPDATE_PROJECT_PERSONA_FAILED:
      return {
        ...state,
        updateProjectPersonaLoading: false,
        updateProjectPersonaError: action.message,
      };

    // DELETE PROJECT PERSONA
    case type.DELETE_PROJECT_PERSONA_REQUESTED:
      return {
        ...state,
        deleteProjectPersonaLoading: true,
      };
    case type.DELETE_PROJECT_PERSONA_SUCCESS:
      return {
        ...state,
        deleteProjectPersonaLoading: false,
      };
    case type.DELETE_PROJECT_PERSONA_FAILED:
      return {
        ...state,
        deleteProjectPersonaLoading: false,
        deleteProjectPersonaError: action.message,
      };

    // DELETE PROJECT Draft
    case type.DELETE_DRAFT_PROJECT_REQUESTED:
      return {
        ...state,
        deleteDraftProjectLoading: true,
      };
    case type.DELETE_DRAFT_PROJECT_SUCCESS:

      return {
        ...state,
        deleteDraftProjectLoading: false,
      };
    case type.DELETE_DRAFT_PROJECT_FAILED:
      return {
        ...state,
        deleteDraftProjectLoading: false,
        deleteDraftProjectError: action.message,
      };

    default:
      return state;
  }
}
