import * as type from "./types";

const initialState = {
  expertActiveStep: 0,
  // Create Expert - Step One
  createExpertStepOneLoading: false,
  createExpertStepOneError: null,

  // Update Expert - Step One
  updateExpertStepOneLoading: false,
  updateExpertStepOneError: null,

  // Update Expert - Step TWO
  updateExpertStepTwoLoading: false,
  updateExpertStepTwoError: null,

  // Update Expert - Step Three
  updateExpertStepThreeLoading: false,
  updateExpertStepThreeError: null,

  // GET DATA - Step ONE
  getDataStepOneLoading: false,
  getDataStepOneError: null,
  stepOneData: {},

  // GET DATA - Step TWO
  getDataStepTwoLoading: false,
  getDataStepTwoError: null,
  stepTwoData: {},

  // GET DATA - Step THREE
  getDataStepThreeLoading: false,
  getDataStepThreeError: null,
  stepThreeData: {},
};

export default function expertRegistration(state = initialState, action) {
  switch (action.type) {
    // SET ACTIVE STEP
    case type.SET_ACTIVE_STEP_REQUESTED:
      return {
        ...state,
        expertActiveStep: action.payload,
      };
    // CREATE EXPERT STEP ONE
    case type.CREATE_EXPERT_TAB_ONE_REQUESTED:
      return {
        ...state,
        createExpertStepOneLoading: true,
        createExpertStepOneError: null, // Reset the error when loading
      };
    case type.CREATE_EXPERT_TAB_ONE_SUCCESS:
      return {
        ...state,
        createExpertStepOneLoading: false,
        expertActiveStep: 1,
      };
    case type.CREATE_EXPERT_TAB_ONE_FAILED:
      return {
        ...state,
        createExpertStepOneLoading: false,
        createExpertStepOneError: action.message,
      };

    // UPDATE EXPERT STEP ONE
    case type.UPDATE_EXPERT_TAB_ONE_REQUESTED:
      return {
        ...state,
        updateExpertStepOneLoading: true,
        updateExpertStepOneError: null, // Reset the error when loading
      };
    case type.UPDATE_EXPERT_TAB_ONE_SUCCESS:
      return {
        ...state,
        updateExpertStepOneLoading: false,
        expertActiveStep: 1,
      };
    case type.UPDATE_EXPERT_TAB_ONE_FAILED:
      return {
        ...state,
        updateExpertStepOneLoading: false,
        updateExpertStepOneError: action.message,
      };

    // UPDATE EXPERT STEP two
    case type.UPDATE_EXPERT_TAB_TWO_REQUESTED:
      return {
        ...state,
        updateExpertStepTwoLoading: true,
        updateExpertStepTwoError: null, // Reset the error when loading
      };
    case type.UPDATE_EXPERT_TAB_TWO_SUCCESS:
      return {
        ...state,
        updateExpertStepTwoLoading: false,
        expertActiveStep: 2,
      };
    case type.UPDATE_EXPERT_TAB_TWO_FAILED:
      return {
        ...state,
        updateExpertStepTwoLoading: false,
        updateExpertStepTwoError: action.message,
      };

    // UPDATE EXPERT STEP THREE
    case type.UPDATE_EXPERT_TAB_THREE_REQUESTED:
      return {
        ...state,
        updateExpertStepThreeLoading: true,
        updateExpertStepThreeError: null, // Reset the error when loading
      };
    case type.UPDATE_EXPERT_TAB_THREE_SUCCESS:
      return {
        ...state,
        updateExpertStepThreeLoading: false,
        expertActiveStep: 2,
        updatedSuccess: true
      };
    case type.UPDATE_EXPERT_TAB_THREE_FAILED:
      return {
        ...state,
        updateExpertStepThreeLoading: false,
        updateExpertStepThreeError: action.message,
      };

    // GET STEP ONE DATA
    case type.GET_DETAILS_EXPERT_TAB_ONE_REQUESTED:
      return {
        ...state,
        getDataStepOneLoading: true,
        getDataStepOneError: null, // Reset the error when loading
      };
    case type.GET_DETAILS_EXPERT_TAB_ONE_SUCCESS:
          return {
            ...state,
            getDataStepOneLoading: false,
            stepOneData:action.payload

          };
        case type.GET_DETAILS_EXPERT_TAB_ONE_FAILED:
          return {
            ...state,
            getDataStepOneLoading: false,
            getDataStepOneError: action.message,
      };

    // get STEP TWO DATA
    case type.GET_DETAILS_EXPERT_TAB_TWO_REQUESTED:
      return {
        ...state,
        getDataStepTwoLoading: true,
        getDataStepTwoError: null, // Reset the error when loading
      };
    case type.GET_DETAILS_EXPERT_TAB_TWO_SUCCESS:
      return {
        ...state,
        getDataStepTwoLoading: false,
        stepTwoData: action.payload,
      };
    case type.GET_DETAILS_EXPERT_TAB_TWO_FAILED:
      return {
        ...state,
        getDataStepTwoLoading: false,
        getDataStepTwoError: action.message,
      };

    // get STEP THREE DATA
    case type.GET_DETAILS_EXPERT_TAB_THREE_REQUESTED:
      return {
        ...state,
        getDataStepThreeLoading: true,
        getDataStepThreeError: null, // Reset the error when loading
      };
    case type.GET_DETAILS_EXPERT_TAB_THREE_SUCCESS:
      return {
        ...state,
        getDataStepThreeLoading: false,
        stepThreeData: action.payload,
      };
    case type.GET_DETAILS_EXPERT_TAB_THREE_FAILED:
      return {
        ...state,
        getDataStepThreeLoading: false,
        getDataStepThreeError: action.message,
      };

    default:
      return state;
  }
}
