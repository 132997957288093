import { call, put, takeEvery } from 'redux-saga/effects'
import instance from "../../../../api/api_instance";
import TokenService from '../../../services/tokenServices';

async function getApi(data) {
  try {
    const result = instance({
      url: "v1/expert-details",
      method: "GET",
    }).then((response) => {
      return response;
    })
    return await result;
  } catch (error) {
    throw error;
  }
}

function* fetchProfile(action) {
  try {
    const res = yield call(getApi, action.payload);
    console.log(res,  "res in the saga");
    if (res.status == 200) {
      yield put({ type: "GET_EXPERT_DETAILS_SUCCESS", payload: res.data.data });
    } else {
      yield put({ type: "GET_EXPERT_DETAILS_FAILED", message: "" });
    }
  } catch (e) {
    if (e.response.status === 406 || e.response.status === 404 || e.response.status === 403) {
      yield put({ type: "GET_EXPERT_DETAILS_FAILED", message: e.response.data.error });
    }
  }
}

function* expertRegistrationDetailsSaga() {
  yield takeEvery("GET_EXPERT_DETAILS_REQUESTED", fetchProfile);
}

export default expertRegistrationDetailsSaga;
