import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { Button, Form, FormGroup, Input, Label, Modal, ModalBody } from "reactstrap";
import { createProjectPersona, getProjectPersonaDetails, setProjectPersonaModal, updateProjectPersona } from "../../../../redux/reduxes/customer/projects-new/projectAction";
import { getAllEducationListSelection, getAllProjectLanguages } from "../../../../redux/reduxes/customer/general/actions";


// Target User Type Checkbox
const targets = [
  { id: 'Informed', title: 'Informed', description: 'A straight shooter who knows the audience and needs. Transacts exactly what is in his/her mind' },
  { id: 'Window Shopper', title: 'Window Shopper', description: 'With free time in hand, curiously going around to view products and services. Unsure of needs' },
  { id: 'Boomerang', title: 'Boomerang', description: 'Master of procrastination, knows what he needs, but hesitant to make on the spot decision' },
  { id: 'Dora the Explorer', title: 'Dora the Explorer', description: 'Is informed of the need, watchful of market trends. Knows where to find the perfect deal' },
]

// Age Checkbox
const ages = [
  { id: '5 - 15 years old', title: '5 - 15 years old' },
  { id: '15 - 30 years old', title: '15 - 30 years old' },
  { id: '30 - 45 years old', title: '30 - 45 years old' },
  { id: '45+ Above', title: '45+ Above' },
]

// Gender Checkbox
const gender = [
  { id: 'Male', title: 'Male' },
  { id: 'Female', title: 'Female' },
  { id: 'Non-binary', title: 'Non-binary' },
  { id: 'Not-Applicable', title: 'Not Applicable' },
]


const ProjectPersonaModal = ({ projectActiveTab }) => {
  const dispatch = useDispatch();

  // Fetch Data
  const allProjectLanguages = useSelector(state => state.general.allProjectLanguages);
  const allEducationList = useSelector(state => state.general.allEducationList);
  const details = useSelector(state => state.projectNew.projectPersonaDetails);
  const createProjectPersonaLoading = useSelector(state => state.projectNew.createProjectPersonaLoading);
  const updateProjectPersonaLoading = useSelector(state => state.projectNew.updateProjectPersonaLoading);
  const setShowProjectPersonaModal = useSelector(state => state.projectNew.setShowProjectPersonaModal);
  const update = useSelector(state => state.projectNew.personaEdit);
  const personaId = useSelector(state => state.projectNew.personaEditId);

  const projectdetails = useSelector(state => state.projectNew.projectDetails.project);
  const project = projectdetails && projectdetails._id;

  // Get Languages and Eduction List
  useEffect(() => {
    if (projectActiveTab == 2) {
      dispatch(getAllProjectLanguages());
      const data = {
        personaId: personaId,
        projectId:project
      }
      dispatch(getProjectPersonaDetails(data));
    }
  }, [])

  // States
  const [state, setState] = useState({
    name: "",
    projectAwareness: '',
    age: [],
    gender: [],
    language: [],
    educationLevel: [],

    errors: {
      name: "",
      projectAwareness: "",
      age: "",
      gender: "",
      language: "",
      educationLevel: "",
    }
  });

  // Get Details
  useEffect(() => {
    if (update) {
      details && details.name && setState((prevProps) => ({
        ...prevProps,
        id: details && details._id,
        name: details && details.name,
        projectAwareness: details && details.projectAwareness,
        age: details && details.age,
        gender: details && details.gender,
        language: details && details.language,
        selectedEducations: details && details.educationLevel,
      }));
      let eduecations = [];
      details && details.educationLevel && details.educationLevel.forEach(data => {
        eduecations.push({ id:data._id, label: data.name, value: data.name})
      })
      console.log(details.educationLevel, "details.educationLevel");
      setSelectedEducations(eduecations);
    }
      {details.age && dispatch(getAllEducationListSelection());
      setSearchEducationList(allEducationList)
      setNewList(searchEducationList.map(item => ({
        id: item._id,
        label: item.name,
        value: item.name,
      })))}
  }, [details]);

  const onChangeGenderHandler = (event) =>{
    const { value, name } = event.target;
    let errors = state.errors;
    errors.gender = value == "" ? "Select your Gender" : "";
    console.log(value)

    if(!state.gender.includes(value)){
    setState((prevProps) => ({
      ...prevProps,
      errors: errors,
      gender: [...prevProps.gender, value],
    }))}else{
      setState((prevProps) => ({
        ...prevProps,
        gender: state.gender.filter(item => item != event.target.value),
      }))
    }
  }

  const onChangeLanguageHandler = (event) =>{
    const { value, name } = event.target;
    let errors = state.errors;
    errors.language = value == "" ? "Select Project Language" : "";

    if(!state.language.includes(value)){
    setState((prevProps) => ({
      ...prevProps,
      errors: errors,
      language: [...prevProps.language, value],
    }))}else{
      setState((prevProps) => ({
        ...prevProps,
        language: state.language.filter(item => item != event.target.value),
      }))
    }
  }

  // onChange Handler
  const onChangeHandler = (event) => {
    const { value, name } = event.target;
    let errors = state.errors;
    switch (name) {
      case 'name':
        errors.name = value == "" ? "Enter Persona Name" : "";
        break;
      default:
        break;
    }
    setState((prevProps) => ({
      ...prevProps,
      errors,
      [name]: value,
    }));
  }

  // onChange Project Awareness
  const onChangeProjectAwarenessHandler = (event) => {
    let errors = state.errors;
    if (event.target.checked) {
      setState((prevProps) => ({
        ...prevProps,
        projectAwareness: [...state.projectAwareness, event.target.value],
      }))
    } else {
      setState((prevProps) => ({
        ...prevProps,
        projectAwareness: state.projectAwareness.filter(item => item != event.target.value),
      }))
    }
    errors.projectAwareness = "";
    setState((prevProps) => ({
      ...prevProps,
      errors,
    }));
  }

  const [searchEducationList, setSearchEducationList] = useState([]);
  const [newList, setNewList] = useState([]);

  // onChange Age Handler
  const onChangeAgeHandler = (event) => {
    let errors = state.errors;
    let age = null
    if (event.target.checked) {
      switch(event.target.id){
        case '5 - 15 years old':
          age = '5 - 15 years old'
          break;
        case '15 - 30 years old':
          age = '15 - 30 years old'
          break;
        case '30 - 45 years old':
          age = '30 - 45 years old'
          break;
        case '45+ Above':
          age = '45+ Above'
          break;
        default:
          age='null'
      }
      setState((prevProps) => ({
        ...prevProps,
        age: [...state.age, age],
      }));
    } else {
      setState((prevProps) => ({
        ...prevProps,
        age: state.age.filter(item => item != event.target.value),
      }));
    }
    errors.age = "";
    setState((prevProps) => ({
      ...prevProps,
      errors,
    }))
    dispatch(getAllEducationListSelection(age));
    setSearchEducationList(allEducationList)
    setNewList (searchEducationList.map(item => ({
      id: item._id,
      value:item.value,
      label: item.name,
    })));
  }

  // onChange Education Handler
  const [educationValue, setEducationValue] = useState("");
  const [educationId, setEducationId] = useState(null);
  const [selectedEducations, setSelectedEducations] = useState([]);
  console.log(selectedEducations, "PLPLPLPLPLPLLLPLPLPLPL");


    const onChangeEducationHandler = (obj) => {
      const isFound = selectedEducations.some(o => o.id === obj.id);
    
      if (!isFound) {
        setSelectedEducations([...selectedEducations, { id: obj.id, label: obj.label, value: obj.value }]);
        const filteredEducations = searchEducationList.filter(item => item._id !== obj._id);
        setSearchEducationList(filteredEducations);
      } else {
        console.log('Object already exists in selectedEducations');
      }
    };
    

  // Remove Education Item from the List
  const confirmDeleteEducationItem = (index) => {
    const updatedEducationList = [...selectedEducations];
    updatedEducationList.splice(index, 1);
    setSelectedEducations(updatedEducationList);
  }

  // Validate Form
  const validateForm = () => {
    let valid = true;
    let errors = state.errors;
    if (state.name == "") {
      errors.name = "Enter Persona Name";
      valid = false;
    }
    if (state.projectAwareness.length === 0) {
      errors.projectAwareness = "Select atleast one Target User type";
      valid = false;
    }
    if (state.age.length === 0) {
      errors.age = "Select atleast one Age group";
      valid = false;
    }
    if (state.gender == "") {
      errors.gender = "Select Gender";
      valid = false;
    }
    if (state.language == "") {
      errors.language = "Select atleast one Project Language";
      valid = false;
    }
    // if (state.educationLevel.length === 0) {
    //   errors.educationLevel = "Select atleast one Education Level";
    //   valid = false;
    // }
    setState((prevProps) => ({
      ...prevProps,
      errors: errors,
    }));
    return valid;
  }

  useEffect(()=>{
    dispatch(getAllEducationListSelection());
    setSearchEducationList(allEducationList)
    setNewList(searchEducationList.map(item => ({
      id: item._id,
      label: item.name,
      value: item.name,
    })))
  },[])

  // onSubmit Handler
  const onSubmitHandler = async (event) => {
    event.preventDefault();
    console.log(selectedEducations, "selectedEducations inside the submit handler") //here it is undefined.
    let educationIds = [];
    selectedEducations && selectedEducations.forEach(items => {
      educationIds.push(items.id);
    });

    if (validateForm()) {
      if (update) {
        const data = {
          projectId:project,
          personaId: personaId,
          sendData: {
            name: state.name,
            projectAwareness: state.projectAwareness,
            age: state.age,
            gender: state.gender,
            language: state.language,
            educationLevel: educationIds,
          }
        }
        console.log(data)
        dispatch(updateProjectPersona(data));
      } else {
        const data = {
          projectId:project,
            name: state.name,
            projectAwareness: state.projectAwareness,
            age: state.age,
            gender: state.gender,
            language: state.language,
            educationLevel: educationIds,
        }
        console.log(data)
        dispatch(createProjectPersona(data));
      }
    }
  }

  return (
    <>
      <Modal className="us-modal__dialog" size="xl" isOpen={setShowProjectPersonaModal}>
        <ModalBody className="us-modal__body us-modal__body-overflow">
          <Form>
            <h4 className="us-section__title mb-8">{update ? "Update" : "Add"} Target User Type</h4>

            {/* Persona Name */}
            <FormGroup className="us-form__wrap">
              <Label htmlFor="name" className="us-form__label font-medium">Give your persona a name <span className="text-danger">*</span></Label>
              <Input
                type="text"
                id="name"
                name="name"
                placeholder="Persona Name"
                className={`us-form__input ${state.errors.name ? "us-form__input-error" : ""}`}
                value={state.name}
                onChange={(e) => onChangeHandler(e)
                }
              />
              <div className="us-form__error">{state.errors.name}</div>
            </FormGroup>

            {/* Target User Type */}
            <FormGroup className="us-form__wrap mt-10">
              <Label htmlFor="projectAwareness" className="us-form__label font-medium">Target User Type<span className="text-danger">*</span></Label> <br />
              <div className="w-100 d-flex flex-column flex-md-row align-items-start flex-wrap gap-2 gap-md-3">
                {targets && targets.map((target, index) => {
                  const { id, title, description } = target;
                  return (
                    <>
                      <div className="us-form__wrap position-relative" key={index}>
                        <Input
                          type="checkbox"
                          id={id}
                          name="projectAwareness"
                          className="us-form__custom-checkbox"
                          value={title}
                          onChange={(e) => onChangeProjectAwarenessHandler(e)}
                          checked={state.projectAwareness.includes(title)}
                        />
                        <Label htmlFor={id} className="us-form__custom-label us-form__custom-label--right rounded-min max-w-250">
                          <div className="us-form__custom-label--title mb-4 me-5 me-xl-10">{title}</div>
                          <div className="us-para color-gray3">{description}</div>
                        </Label>
                      </div>
                    </>
                  )
                })}
              </div>
              <div className="us-form__error">{state.errors.projectAwareness}</div>
            </FormGroup>

            {/* Age of Audience */}
            <FormGroup className="us-form__wrap mt-10">
              <div className="head-font text-sm font-medium mb-4">Specify Age of your target audience <span className="text-danger">*</span></div>
              <div className="w-100 d-flex flex-column flex-md-row align-items-start flex-wrap gap-1 gap-md-3">
                {ages && ages.map((age, index) => {
                  const { id, title } = age;
                  return (
                    <>
                      <FormGroup className="us-form__wrap position-relative" key={index}>
                        <Input
                          type="checkbox"
                          id={id}
                          name="age"
                          className="us-form__custom-checkbox"
                          value={id}
                          onChange={(e) => onChangeAgeHandler(e)}
                          checked={state.age.includes(id)}
                        />
                        <Label htmlFor={id} className="us-form__custom-label us-form__custom-label--right rounded-full">
                          <div className="us-form__custom-label--title me-5 me-xl-10">{title}</div>
                        </Label>
                      </FormGroup>
                    </>
                  )
                })}
              </div>
              <div className="us-form__error">{state.errors.age}</div>
            </FormGroup>

            {/* Gender of Audience */}
            <FormGroup className="us-form__wrap mt-10">
              <div className="head-font text-sm font-medium mb-4">Specify Gender <span className="text-danger">*</span></div>
              <div className="w-100 d-flex flex-column flex-md-row align-items-start flex-wrap gap-1 gap-md-3">
                {gender && gender.map((gen, index) => {
                  const { id, title } = gen;
                  return (
                    <>
                      <FormGroup className="us-form__wrap position-relative" key={index}>
                        <Input
                          type="checkbox"
                          id={id}
                          name="gender"
                          className="us-form__custom-checkbox"
                          value={id}
                          onChange={(e) => onChangeGenderHandler(e)}
                          checked={state.gender.includes(id)}
                        />
                        
                        <Label htmlFor={id} className="us-form__custom-label us-form__custom-label--right rounded-full">
                          <div className="us-form__custom-label--title me-10">{title}</div>
                        </Label>
                      </FormGroup>
                    </>
                  )
                })}

              </div>
              <div className="us-form__error">{state.errors.gender}</div>
            </FormGroup>

            {/* Audience Languages */}
            <FormGroup className="us-form__wrap mt-10">
              <div className="head-font text-sm font-medium mb-4">Target Audience Language <span className="text-danger">*</span></div>
              <div className="w-100 d-flex flex-column flex-md-row align-items-start flex-wrap gap-1 gap-md-3">
                {allProjectLanguages && allProjectLanguages.length > 0 && allProjectLanguages.map((lang, index) => {
                  return (
                    <>
                      <FormGroup className="us-form__wrap position-relative" key={index}>
                        <Input
                          type="radio"
                          id={lang}
                          name="language"
                          className="us-form__custom-checkbox"
                          value={lang}
                          onChange={(e) => onChangeLanguageHandler(e)}
                          checked={state.language.includes(lang)}
                        />
                        <Label htmlFor={lang} className="us-form__custom-label us-form__custom-label--radioRightCenter rounded-full">
                          <div className="us-form__custom-label--title me-10 text-capitalize">{lang}</div>
                        </Label>
                      </FormGroup>
                    </>
                  )
                })}
              </div>
              <div className="us-form__error">{state.errors.language}</div>
            </FormGroup>

            {/* Audience Education Level */}
            <FormGroup className="row g-2 us-form__wrap mt-10">
              <Label htmlFor="education" className="us-form__label font-medium mb-0">Audience Education Level</Label>
              <Select
                name="educationLevel"
                placeholder="Select Education Level"
                classNamePrefix="select"
                className="us-form__input-searchSelect w-100"
                options={newList}
                onChange={(e) => onChangeEducationHandler(e)}
              />
              
              {/* Selected Languages */}
              {(selectedEducations && selectedEducations.length > 0 ) &&
                <ul className="us-form__lists d-flex align-items-start flex-wrap w-100 gap-2 mt-5">
                  {selectedEducations.map((education, index) => {
                    return (
                      <li key={index} className="us-form__list w-sm rounded-100 d-flex align-items-center justify-content-between">
                        <div className="us-form__list-title text-xs">{education.label}</div>
                        <Button onClick={() => confirmDeleteEducationItem(index)} type="button" color="link" className="us-button__link p-0 ms-3">
                          <img src="/assets/icons/icon-cancel-red.svg" alt="icon-cancel" width="16" height="16" />
                        </Button>
                      </li>
                    )
                  })}
                </ul>
              }
{/* {(details.educationLevel) &&
                <ul className="us-form__lists d-flex align-items-start flex-wrap w-100 gap-2 mt-2">
                  {details.educationLevel.map((education, index) => {
                    return (
                      <li key={index} className="us-form__list w-sm rounded-100 d-flex align-items-center justify-content-between">
                        <div className="us-form__list-title text-xs">{education.name}</div>
                        <Button onClick={() => confirmDeleteEducationItem(index)} type="button" color="link" className="us-button__link p-0 ms-3">
                          <img src="/assets/icons/icon-cancel-red.svg" alt="icon-cancel" width="16" height="16" />
                        </Button>
                      </li>
                    )
                  })}
                </ul>
              } */}
              <div className="us-form__error">{state.errors.educationLevel}</div>
            </FormGroup>

            <FormGroup className="us-form__wrap d-flex align-items-center justify-content-md-end mt-10 mt-md-20">
              <Button onClick={() => dispatch(setProjectPersonaModal({show:false, edit: false, id:""}))} type="button" color="primary" outline className="us-button us-button__primary-outline px-8 me-5 text-uppercase">Cancel</Button>
              <Button
                type="button"
                onClick={(e) => onSubmitHandler(e)}
                color="primary"
                disabled={createProjectPersonaLoading || updateProjectPersonaLoading}
                className={`us-button us-button__primary px-10 text-uppercase ${(createProjectPersonaLoading || updateProjectPersonaLoading) ? "us-button__disabled" : "us-button__primary"}`}
              >
                {update ? (updateProjectPersonaLoading ? "Updating..." : "Update") : (createProjectPersonaLoading ? "Adding..." : "Add")}
              </Button>
            </FormGroup>
          </Form>
        </ModalBody>
      </Modal>
    </>
  )
}
export default ProjectPersonaModal;