import { call, put, takeEvery } from "redux-saga/effects";
import instance from "../../../../api/api_instance";
import * as type from "./types";

async function getApi(data) {
  console.log(data, "datainsaga");
  try {
    const result = instance({
      url: `v1/expert-phase`,
      method: "POST",
      data: data,
    }).then((response) => {
      return response;
    });
    return await result;
  } catch (error) {
    throw error;
  }
}

function* fetchExpert(action) {
  try {
    const res = yield call(getApi, action.payload);
    const toastrData = {
      content: "Expert Created successfully",
      type: "success",
    };
    const expertData = yield put({
      type: "CREATE_EXPERT_TAB_ONE_SUCCESS",
      expertData: res.data.data,
    });
    yield put({ type: "SET_TOASTR_SUCCESS", data: toastrData });

    // Assuming expertData contains the expertId
    localStorage.setItem("expertId", expertData._id);
  } catch (e) {
    if (
      e.response.status === 400 ||
      e.response.status === 403 ||
      e.response.status === 404 ||
      e.response.status === 409
    ) {
      yield put({
        type: "CREATE_EXPERT_TAB_ONE_FAILED",
        message: e.response.data.message,
      });
    } else {
      yield put({
        type: "CREATE_EXPERT_TAB_ONE_FAILED",
        message: "Some error occurred!",
      });
    }

    if (e.response.status === 500) {
      const toastrFailedData = {
        content: e.response.data.errors
          ? e.response.data.errors
          : "Something went wrong!",
        type: "failed",
      };
      yield put({ type: "SET_TOASTER_SUCCESS", data: toastrFailedData });
    }
  }
}

function* createExpertStepOneSaga() {
  yield takeEvery("CREATE_EXPERT_TAB_ONE_REQUESTED", fetchExpert);
}

export default createExpertStepOneSaga;
