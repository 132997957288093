import { call, put, takeEvery } from 'redux-saga/effects'
import instance from "../../../../api/api_instance";
import Cookies from 'js-cookie';
import TokenService from '../../../services/tokenServices';

async function getApi(data) {
  const registerData = {
    firstName: data.first_name,
    lastName: data.last_name,
    email: data.email,
    mobileNumber: data.mobile_number,
    termsAndCondition: data.terms_conditions,
    linkedInUser: data.linkedInUser,
    ...(!data.linkedInUser && {password: data.password,
    confirmPassword: data.password_confirmation}),
  }

  if (data.linkedInUser) {
    registerData["socialId"] = data.socialId;
    registerData["fullName"] = data.fullName;
    // if (data.emailExistAsNormal == "true") {
      registerData["socialEmail"] = data.socialEmail;
      registerData["provider"] = data.provider;

    // }
  }
  try {
    const result = instance({
      url: "v1/users",
      method: "POST",
      data: registerData
    }).then((response) => {
      return response;
    })
    return await result;
  } catch (error) {
    throw error;
  }
}

function* fetchRegister(action) {
  try {
    const res = yield call(getApi, action.payload);
    if (res.status == 200 || res.status == 201) {
      yield put({ type: "REGISTER_USER_SUCCESS", registerUser: res.data.data });
      TokenService.updateLocalAccessToken(res.data.data.token);
      yield put({ type: "CONFIRM_EMAIL_MOBILE_OTP_REQUESTED", payload: {} });
    } else {
      yield put({ type: "REGISTER_USER_FAILED", message: [] });
    }
  } catch (e) {
    if (e.response.status === 406 || e.response.status === 404 || e.response.status === 403 || e.response.status === 422) {
      yield put({ type: "REGISTER_USER_FAILED", message: e.response.data.errors });
    }
  }
}

function* registerAuthSaga() {
  yield takeEvery("REGISTER_USER_REQUESTED", fetchRegister);
}

export default registerAuthSaga;
