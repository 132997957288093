import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, ModalBody } from "reactstrap";
import { getProjectPersonaDetails } from "../../../redux/reduxes/customer/projects-new/projectAction";


const ProjectTargetUserTypeDetailsModal = ({ viewTargetUserTypeDetailsModal, setViewTargetUserTypeDetailsModal, personaId, setPersonaId, realUserType, projectId }) => {
  const dispatch = useDispatch();

  // Fetch Data
  const details = useSelector(state => state.projectNew.projectPersonaDetails);
  // Get Project Persona Details
  useEffect(() => {
    const data = {
      personaId: personaId,
      projectId: projectId,
    }
    dispatch(getProjectPersonaDetails(data));
  }, [])

  return (
    <>
      <Modal className="us-modal__dialog" size="lg" isOpen={viewTargetUserTypeDetailsModal} toggle={setViewTargetUserTypeDetailsModal}>
        <ModalBody className="us-modal__body">
          <div>
            <h5 className="us-section__title-block text-xl text-lg-3xl mb-6">Target User Type Details</h5>
            {realUserType &&
              <div className="mb-4 bg-light-blue2 rounded p-5">
                <div className="us-section__title-block body-font font-medium text-capitalize">{details && details.name}</div>
              </div>
            }
            <div className=" bg-light-blue rounded p-6">

            <div className="border-bottomm pb-5">
              <div className="head-font text-md font-bold mb-1">Language</div>
              <div className="us-para body-font text-sm font-normal text-capitalize">{details && details.language}</div>
            </div>

            <div className="border-bottomm py-5">
              <div className="head-font text-md font-bold mb-1">Target User Type</div>
              <ol className="us-terms__lists mb-0">
                {details && details.projectAwareness && details.projectAwareness.length > 0 && details.projectAwareness.map((list) => {
                  return (
                    <>
                      <li className="us-terms__list body-font text-sm font-normal mb-0">{list}</li>
                    </>
                  )
                })}
              </ol>
            </div>

            <div className="border-bottomm py-5">
              <div className="head-font text-md font-bold mb-1">Target Audience Age</div>
              <ol className="us-terms__lists mb-0 pb-0">
                  <li className="us-terms__list body-font text-sm font-normal mb-0">{details && details.age && details.age}</li>
              </ol>
            </div>

            <div className="border-bottomm py-5">
              <div className="head-font text-md font-bold mb-1">Gender</div>
              {details.gender && details.gender.map((item,index)=>(
                <div key={index} className="us-para body-font text-sm font-normal">{item}</div>))}
            </div>

            <div className="pt-5">
              <div className="head-font text-md font-bold mb-1">Audience Education Level</div>
              <ol className="us-terms__lists mb-0">
                {details && details.educationLevel && details.educationLevel.length > 0 && details.educationLevel.map((list) => {
                  return (
                    <>
                      <li className="us-terms__list body-font text-sm font-normal">{list.name}</li>
                    </>
                  )
                })}
              </ol>
            </div>
            </div>
            <div className="text-end mt-10">
              <Button onClick={() => setViewTargetUserTypeDetailsModal(false)} type="button" color="primary" outline className="us-button us-button__primary-outline text-uppercase px-10">Close</Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}
export default ProjectTargetUserTypeDetailsModal;