import { call, put, takeEvery } from 'redux-saga/effects'
import instance from "../../../../api/api_instance";

async function getApi(data) {
  try {
    const result = instance({
      url: "v1/profile-update-email-otp",
      method: "POST",
      data: data,
    }).then((response) => {
      return response;
    })
    return await result;
  } catch (error) {
    throw error;
  }
}
function* fetchProfile(action) {
  try {
    const res = yield call(getApi, action.payload);
    const toastrData = {
      content: "Email ID updated successfully",
      type: "success"
    }
    yield put({ type: "UPDATE_EMAIL_SUCCESS", profileDetails: res.data.data });
    yield put({ type: "SET_TOASTR_SUCCESS", data: toastrData });
    // window.location.href = '/customer/account-settings';
  } catch (e) {
    if (e.response.status === 400 || e.response.status === 404 || e.response.status === 409 || e.response.status === 422) {
      yield put({ type: "UPDATE_EMAIL_FAILED", message: e.response.data.message });
    } else {
      yield put({ type: "UPDATE_EMAIL_FAILED", message: "Some error occurred!" });
    }
  }
}

function* updateProfileEmailSaga() {
  yield takeEvery("UPDATE_EMAIL_REQUESTED", fetchProfile);
}

export default updateProfileEmailSaga;
