import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { linkedInLoginToken } from "../../redux/reduxes/global/auth/actions";

const LinkedInLogin = () => {
  const params = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(linkedInLoginToken(params));
  }, [])

  // CSS styles
  const centeredStyles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  };

  return (
    <>
      <p style={centeredStyles}>Loading.....</p>
    </>
  )
}
export default LinkedInLogin;