import Skeleton from "react-loading-skeleton";

import React from 'react'

const SkeletonLoader = () => {
  return (
    <Skeleton 
    width='250px'
    height='60px'
    className="p-6"
    borderRadius='32px'/>
  )
}

export default SkeletonLoader