import './index.css';
import AppRoutes from './routes/Routes';

function App() {
  return (
    <div className="us-app">

      <AppRoutes />

    </div>
  );
}

export default App;
