import { call, put, takeEvery } from 'redux-saga/effects'
import instance from "../../../../api/api_instance";

async function patchApi(data) {
  try {
    const result = instance({
      url: "v1/unlink-linkedin",
      method: "PATCH",
    }).then((response) => {
      return response;
    })
    return await result;
  } catch (error) {
    throw error;
  }
}
function* unlinkLinkedIn(action) {
  try {
    const res = yield call(patchApi, action.payload);
    const toastrData = {
      content: "successfully unlinked Linked In from profile",
      type: "success"
    }
    yield put({ type: "UNLINK_LINKED_IN_SUCCESS", profileDetails: res.data.data });
    // yield put({ type: "GET_PROFILE_DETAILS_REQUESTED", payload: {} });
    yield put({ type: "SET_TOASTR_SUCCESS", data: toastrData });

  } catch (e) {
    const toastrFailedData = {
      content: e.response.data.errors ? e.response.data.errors : "Something went wrong!",
      type: "failed"
    };
    yield put({ type: "UNLINK_LINKED_IN_FAILURE", payload: {} });

    yield put({ type: "SET_TOASTR_SUCCESS", data: toastrFailedData });
    // if (e.response.status === 400 || e.response.status === 404 || e.response.status === 409) {
    //   yield put({ type: "ADD_PROFILE_PICTURE_FAILED", message: e.response.data.message });
    // } else {
    //   yield put({ type: "ADD_PROFILE_PICTURE_FAILED", message: "Some error occurred!" });
    // }

    // if (e.response.status === 500) {
    //   const toastrFailedData = {
    //       content: e.response.data.errors ? e.response.data.errors : "Something went wrong!",
    //       type: "failed"
    //   };
    //   yield put({ type: "SET_TOASTER_SUCCESS", data: toastrFailedData });
    // }
  }
}

function* unlinkLinkedInSaga() {
  yield takeEvery("UNLINK_LINKED_IN_REQUEST", unlinkLinkedIn);
}

export default unlinkLinkedInSaga;
