import React, { useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Row,
} from "reactstrap";
import { useDispatch, useSelector } from 'react-redux';
// import { experimentalEmailSelector, resetLoginErrorMessages } from '../../../redux/reduxes/global/auth/actions';
import { useEffect } from 'react';
import { updateProfileEmail } from "../../../redux/reduxes/customer/profile/actions";



const params = new URLSearchParams(window.location.search);
const linkedInEmail= params.get('linkedInEmail');
const currentEmail = params.get('currentEmail');


const EmailSelector= () => {
  const dispatch = useDispatch();

  // Fetch Data
  // const loginUserLoading = useSelector(state => state.auth.loginUserLoading);
  const loginUserLoading = useSelector(state => state.profile.updateEmailLoading);
  const updateEmailError = useSelector(state => state.profile.updateEmailError);
  // const loginUserError =
  //TEST

  // console.log("Blinding",linkedInEmail)
  const [selectedOption, setSelectedOption] = useState(linkedInEmail);
  const [queryData, setQueryData] = useState({});
  console.log("BASEKEMDK",queryData)
  // const [searchParams, setSearchParams] = useSearchParams();



  // Reset Error Message
  // useEffect(() => {
  //   dispatch(resetLoginErrorMessages())
  // }, [])

  // onChange Handler
  const onChangeHandler = (event) => {
    // event.preventDefault();
    console.log(event.target.value,"Base One")
    setSelectedOption(event.target.value);

  }

  // onSubmit Handler
  const onSubmitHandler = async (event) => {
    event.preventDefault();

    if (linkedInEmail===selectedOption){
      console.log(selectedOption.replace( ' ','+'),"hight")
      dispatch(updateProfileEmail({ email: selectedOption.replace(' ', '+') }))
    } else {
      window.location.href = "/customer/account-settings";
    }
  };

  useEffect(() => {
    // Parse the query parameters from the URL
    const params = new URLSearchParams(window.location.search);

    // Get the value of a specific parameter (e.g., 'id')
    const linkedInEmail = params.get('linkedInEmail');
    const currentEmail = params.get('currentEmail');

    // Set the query data in state
    setQueryData({ linkedInEmail, currentEmail});
  }, []);

  return (
    <>
                <Col md="6" xl="5">
                  <div className="us-auth__right px-6 py-8 px-lg-10 py-lg-10">
                    <Form onSubmit={(e) => onSubmitHandler(e)}>
                      <h1 className="us-section__title mb-6 text-center">  Select Email  </h1>



                      <FormGroup className="us-form__wrap pt-5">
                        <Label htmlFor="email" className="us-form__label"> Which email would you like to continue with ?</Label>
                        <div className="d-flex align-items-center gap-2">
                          <Input
                          type="radio"
                          name="email-select"
                          className="p-3"
                          value={queryData.linkedInEmail}
                          checked={selectedOption === queryData.linkedInEmail}
                          onChange={(e) => onChangeHandler(e)}
                          />
                          <Input
                          type="email"
                          className={`us-form__input`}
                          value={queryData.linkedInEmail&&queryData.linkedInEmail.replace( ' ','+')}
                          onClick={()=>{return setSelectedOption(queryData.linkedInEmail)}}

                          readOnly
                        /></div>
                        <Label htmlFor="email" className="us-form__label"> </Label>

                        <div className="d-flex align-items-center gap-2">
                          <Input
                          type="radio"
                          name="email-select"
                          className="p-3"
                          value={queryData.currentEmail}
                          checked={selectedOption === queryData.currentEmail}
                          // id="email-select-2"
                          onChange={(e) => onChangeHandler(e)}
                          />
                          <Input
                          type="text"
                          className={`us-form__input`}
                          value={queryData.currentEmail&&queryData.currentEmail.replace( ' ','+')}

                          onClick={()=>{return setSelectedOption(queryData.currentEmail)}}
                          readOnly

                        />
                        </div>


                        <div className="us-form__error"> {updateEmailError} </div>
                      </FormGroup>

                      <Button
                        type="submit"
                        color="primary"
                        disabled={loginUserLoading}
                        className={`us-button us-button__primary d-block text-uppercase w-100 mt-8 ${loginUserLoading ? "us-button__disabled" : "us-button__primary"}`}
                      >
                        {loginUserLoading ? "Loading..." : "CONTINUE"}
                      </Button>

                      {/* <Link to="/reset-password" exact className="text-sm mt-5 us-button__link underline p-0">
                        Forgot your password?
                      </Link> */}
                    </Form>
                  </div>
                </Col>
    </>
  );


};
export default EmailSelector;