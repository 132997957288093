import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
const LoadingAnimation = () => {
  const [show, setShow] = useState(true);
  return (
    <div className="overflow-hidden d-flex flex-column vh-100 align-items-center justify-content-center">
      <div className="mb-5" style={{ width: "140px" }}>
        <img
          src="/assets/useralia-text-logo.png"
          alt="useralia-logo"
          className="img-fluid w-100"
        />
      </div>
      <AnimatePresence>
        {show ? (
          <>
            <div className="spin-loading-animation-wrap d-flex justify-content-center flex-column align-item-center">
              <motion.h4
                initial={{ opacity: 0 }}
                animate={{ opacity: 0.5 }}
                transition={{
                  duration: 1.2,
                  // bounce: 0.6,
                }}
                exit={{ opacity: 0, transition: { duration: 0.2 } }}
                className="mb-2"
                style={{opacity: 0.5}}
              >
                Hang on for a few seconds...
              </motion.h4>
              <motion.section
                className="d-flex align-items-center justify-content-center"
                positionTransition
                initial={{ opacity: 0, scale: 0 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{
                  duration: 2,
                  // bounce: 0.6,
                }}
                exit={{ opacity: 0, scale: 0.5, transition: { duration: 0.2 } }}
              >
                <div class="spinner-box">
                  <div class="solar-system">
                    <div class="earth-orbit orbit">
                      <div class="planet earth"></div>
                      <div class="venus-orbit orbit">
                        <div class="planet venus"></div>
                        <div class="mercury-orbit orbit">
                          <div class="planet mercury"></div>
                          <div class="sun"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </motion.section>
            </div>
          </>
        ) : null}
      </AnimatePresence>
    </div>
  );
};
export default LoadingAnimation;
