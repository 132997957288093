import React, { useEffect } from 'react'
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, FormGroup, Input, Label, Row, Col } from 'reactstrap';
import {
  createProjectTabOne,
  // resetProject,
  updateProjectTabOne
} from '../../../redux/reduxes/customer/projects-new/projectAction';
import { useParams } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import SwitchSelector from "react-switch-selector";

const projects = [
  { id: 'PoC (Proof of Concept)', title: 'PoC (Proof of Concept)', img: 'poc', description: 'Product idea test to prove its potential & feasibility in real-world settings.' },
  { id: 'Pilot', title: 'Pilot', img: 'pilot', description: 'Small-scale test of new solution + User feedback + Refinement before full-scale launch.' },
  { id: 'Revamp', title: 'Revamp', img: 'revamp', description: 'Improving existing solution + Significant design changes + Enhance user experience and effectiveness.' },
  { id: 'Market Ready', title: 'Market Ready', img: 'market-ready', description: 'Introducing new solution + Successful introduction and adoption + Launch plan, marketing strategies, and coordination.' },
  { id: 'Continuous Evaluation', title: 'Continuous Evaluation', img: 'evaluate', description: 'Final stage of project + Assess against goals + Analyze data and feedback + Report strengths/weaknesses + Recommendations.' },
]

const ProjectPhaseTab = ({ projectActiveTab }) => {
  const dispatch = useDispatch();
  const { realUserType, mode } = useParams();
  const search = useLocation().search;
  const project = new URLSearchParams(search).get('project');
  // Fetch Data
  const createProjectTabOneLoading = useSelector(state => state.projectNew.createProjectTabOneLoading);
  const details = useSelector(state => state.projectNew.projectDetails);


  // States
  const [state, setState] = useState({
    project_title: "",
    project_phase: "",
    project_type: 'RealUser',

    errors: {
      project_title: "",
      project_phase: "",
    }
  });

  // set the state with the details
  useEffect(() => {
    if(project != null){
      details && details.project && setState((prevProps) => ({
        ...prevProps,
        project_title: details.project.title,
        project_phase: details.project.phase,
        project_type: details.project.projectType,
      }))
    }
  }, [project,details]);

  // Get details on Back Tab
  // useEffect(() => {
  //   title && phase && setState((prevProps) => ({
  //     ...prevProps,
  //     project_title: title,
  //     project_phase: phase,
  //   }))
  // }, []);

  const options = [
    {
        label: 'Real User',
        value: 'RealUser',
        selectedBackgroundColor: "#3B61F4",
        selectedFontColor: "#fff"
    },
    {
        label: 'Expert',
        value: "Expert",
        selectedBackgroundColor: "#3B61F4",
        selectedFontColor: "#fff"
    }
 ];

 const URL = window.location.href.split('/')

 const initialSelectedIndex = URL[6] === 'expert' || state.project_type === 'Expert' ? 1:0;
 const forcedSelectedIndex =  URL[6] === 'expert' || state.project_type === 'Expert' ? 1:0;
 
  const onChange = (newValue) => {
    setState((prevProps) => ({
      ...prevProps,
      project_type: newValue,
    }))
};

  // onChange Handler
  const onChangeHandler = (event) => {
    const { value, name } = event.target;
    let errors = state.errors;
    errors.project_title = "";
    errors.project_phase = "";

    setState((prevProps) => ({
      ...prevProps,
      errors,
      [name]: value,
    }))
  }

  // Validate Form
  const validateForm = () => {
    let valid = true;
    let errors = state.errors;
    if (state.project_title == "") {
      errors.project_title = "Enter Project Title."
      valid = false;
    }
    if (state.project_phase == "") {
      errors.project_phase = "Select atleast one Project Phase."
      valid = false;
    }
    setState((prevProps) => ({
      ...prevProps,
      errors: errors,
    }));
    return valid;
  }

  // onSubmit Handler
  const onSubmitHandler = (event) => {
    event.preventDefault();
    if (validateForm()) {
      if (project) {
        const data = {
          data: {
            title: state.project_title,
            phase: state.project_phase,
            projectType: state.project_type,
          },
          projectID: project
        }
        dispatch(updateProjectTabOne(data));
      } else {
        const data = {
          title: state.project_title,
          phase: state.project_phase,
          projectType: state.project_type
        }
        dispatch(createProjectTabOne(data));
      }
    }
  }

  return (
    <>
      <div className="us-customer__project-objective position-relative">
        <Form onSubmit={(e) => onSubmitHandler(e)}>
          <div>
            <FormGroup className='us-form__wrap mb-8'>
              <Label htmlFor='' className='us-form__label'>Project Title <span className='text-danger'>*</span></Label>
              <Input
                type="text"
                id="project_title"
                name="project_title"
                placeholder="Project Title"
                className={`us-form__input ${state.errors.project_title ? "us-form__input-error" : ""}`}
                value={state.project_title}
                onChange={(e) => onChangeHandler(e)}
              />
              <div className='us-form__error'>{state.errors.project_title}</div>
            </FormGroup>

            <FormGroup className='us-form__wrap mb-8'>
            <Label htmlFor='' className='us-form__label mb-4'>Specify Project Type:</Label>
            <div style={{width: 290, height: 40}}>
            <SwitchSelector
            onChange={onChange}
            options={options}
            initialSelectedIndex={initialSelectedIndex}
            backgroundColor={"#ffffff"}
            fontColor={"#000"}
            border={2}
            forcedSelectedIndex={forcedSelectedIndex}
            />
        </div>
            </FormGroup>

            <div className="us-section__para text-sm mb-5 head-font"><strong>What phase is the project currently in?</strong></div>
            <Row className="g-3 row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-5">
              {projects && projects.map((project, index) => {
                const { id, title, img, description } = project;
                return (
                  <>
                    <Col
                      key={index}>
                      <Input
                        type='radio'
                        id={id}
                        name="project_phase"
                        className="us-form__custom-checkbox"
                        value={id}
                        onChange={(e) => onChangeHandler(e)}
                        checked={id === state.project_phase ? true : false}
                      />
                      <Label htmlFor={id} className='us-form__custom-label   us-form__custom-label--radioTopRight'>
                        <img src={`/assets/images/customer/projects/${img}.svg`} alt={title} className='my-5 img-fluid' />
                        <div className="us-form__custom-label--title head-font font-bold text-sm">{title}</div>
                        <p className='us-para text-sm'>{description}</p>
                      </Label>
                    </Col>
                  </>
                )
              })}
            </Row>
            <div className='us-form__error'>{state.errors.project_phase}</div>
          </div>

          <div className="text-end mt-8 mt-md-14">
            <Button
              type='submit'
              color='primary'
              disabled={createProjectTabOneLoading}
              className={`us-button us-button__primary px-12 text-uppercase ${createProjectTabOneLoading ? "us-button__disabled" : "us-button__primary"}`}
            >
              {createProjectTabOneLoading ? "Saving..." : "Next"}
            </Button>
          </div>

        </Form>
      </div>
    </>
  )
}
export default ProjectPhaseTab;
