// GET PROFILE DETAILS
export const GET_PROFILE_DETAILS_REQUESTED = "GET_PROFILE_DETAILS_REQUESTED";
export const GET_PROFILE_DETAILS_SUCCESS = "GET_PROFILE_DETAILS_SUCCESS";
export const GET_PROFILE_DETAILS_FAILED = "GET_PROFILE_DETAILS_FAILED";


// ADD PROFILE PICTURE
export const ADD_PROFILE_PICTURE_REQUESTED = "ADD_PROFILE_PICTURE_REQUESTED";
export const ADD_PROFILE_PICTURE_SUCCESS = "ADD_PROFILE_PICTURE_SUCCESS";
export const ADD_PROFILE_PICTURE_FAILED = "ADD_PROFILE_PICTURE_FAILED";

// SET UPDATE PROFILE PICTURE MODAL
export const SET_UPDATE_PROFILE_PICTURE_MODAL_REQUESTED = "SET_UPDATE_PROFILE_PICTURE_MODAL_REQUESTED";

// REMOVE PROFILE PICTURE
export const REMOVE_PROFILE_PICTURE_REQUESTED = "REMOVE_PROFILE_PICTURE_REQUESTED";
export const REMOVE_PROFILE_PICTURE_SUCCESS = "REMOVE_PROFILE_PICTURE_SUCCESS";
export const REMOVE_PROFILE_PICTURE_FAILED = "REMOVE_PROFILE_PICTURE_FAILED";


// FULL NAME UPDATE
export const UPDATE_FULL_NAME_REQUESTED = "UPDATE_FULL_NAME_REQUESTED";
export const UPDATE_FULL_NAME_SUCCESS = "UPDATE_FULL_NAME_SUCCESS";
export const UPDATE_FULL_NAME_FAILED = "UPDATE_FULL_NAME_FAILED";

// SET UPDATE FULLNAME MODAL
export const SET_UPDATE_FULLNAME_MODAL_REQUESTED = "SET_UPDATE_FULLNAME_MODAL_REQUESTED";



// PROFILE EMAIL UPDATE
export const UPDATE_EMAIL_REQUESTED = "UPDATE_EMAIL_REQUESTED";
export const UPDATE_EMAIL_SUCCESS = "UPDATE_EMAIL_SUCCESS";
export const UPDATE_EMAIL_FAILED = "UPDATE_EMAIL_FAILED";

// SET UPDATE EMAIL MODAL
export const SET_UPDATE_EMAIL_MODAL_REQUESTED = "SET_UPDATE_EMAIL_MODAL_REQUESTED";

// RESET UPDATE EMAIL ERRORS
export const RESET_UPDATE_EMAIL_ERRORS_REQUESTED = "RESET_UPDATE_EMAIL_ERRORS_REQUESTED";

// UPDATE EMAIL OTP VERIFICATION
export const UPDATE_EMAIL_OTP_VERIFICATION_REQUESTED = "UPDATE_EMAIL_OTP_VERIFICATION_REQUESTED";
export const UPDATE_EMAIL_OTP_VERIFICATION_SUCCESS = "UPDATE_EMAIL_OTP_VERIFICATION_SUCCESS";
export const UPDATE_EMAIL_OTP_VERIFICATION_FAILED = "UPDATE_EMAIL_OTP_VERIFICATION_FAILED";

// RESET UPDATE EMAIL OTP ERRORS
export const RESET_UPDATE_EMAIL_OTP_ERRORS_REQUESTED = "RESET_UPDATE_EMAIL_OTP_ERRORS_REQUESTED";

// RESEND UPDATE  EMAIL OTP
export const RESEND_UPDATE_EMAIL_OTP_REQUESTED = "RESEND_UPDATE_EMAIL_OTP_REQUESTED";
export const RESEND_UPDATE_EMAIL_OTP_SUCCESS = "RESEND_UPDATE_EMAIL_OTP_SUCCESS";
export const RESEND_UPDATE_EMAIL_OTP_FAILED = "RESEND_UPDATE_EMAIL_OTP_FAILED";



// UPDATE  MOBILE NUMBER
export const UPDATE_MOBILE_NUMBER_REQUESTED = "UPDATE_MOBILE_NUMBER_REQUESTED";
export const UPDATE_MOBILE_NUMBER_SUCCESS = "UPDATE_MOBILE_NUMBER_SUCCESS";
export const UPDATE_MOBILE_NUMBER_FAILED = "UPDATE_MOBILE_NUMBER_FAILED";

// SET UPDATE MOBILE NUMBER MODAL
export const SET_UPDATE_MOBILE_NUMBER_MODAL_REQUESTED = "SET_UPDATE_MOBILE_NUMBER_MODAL_REQUESTED";

// RESET UPDATE MOBILE NUMBER ERRORS
export const RESET_UPDATE_MOBILE_NUMBER_ERRORS_REQUESTED = "RESET_UPDATE_MOBILE_NUMBER_ERRORS_REQUESTED";

// UPDATE MOBILE OTP VERIFICATION
export const UPDATE_MOBILE_OTP_VERIFICATION_REQUESTED = "UPDATE_MOBILE_OTP_VERIFICATION_REQUESTED";
export const UPDATE_MOBILE_OTP_VERIFICATION_SUCCESS = "UPDATE_MOBILE_OTP_VERIFICATION_SUCCESS";
export const UPDATE_MOBILE_OTP_VERIFICATION_FAILED = "UPDATE_MOBILE_OTP_VERIFICATION_FAILED";

// RESET UPDATE MOBILE OTP VERIFICATION ERRORS
export const RESET_UPDATE_MOBILE_OTP_VERIFICATION_ERRORS_REQUESTED = "RESET_UPDATE_MOBILE_OTP_VERIFICATION_ERRORS_REQUESTED";

// RESEND UPDATE MOBILE OTP
export const RESEND_UPDATE_MOBILE_OTP_REQUESTED = "RESEND_UPDATE_MOBILE_OTP_REQUESTED";
export const RESEND_UPDATE_MOBILE_OTP_SUCCESS = "RESEND_UPDATE_MOBILE_OTP_SUCCESS";
export const RESEND_UPDATE_MOBILE_OTP_FAILED = "RESEND_UPDATE_MOBILE_OTP_FAILED";



// CHANGE PASSWORD
export const CHANGE_PASSWORD_REQUESTED = "CHANGE_PASSWORD_REQUESTED";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILED = "CHANGE_PASSWORD_FAILED";

// SET CHANGE PASSWORD MODAL
export const SET_CHANGE_PASSWORD_MODAL_REQUESTED = "SET_CHANGE_PASSWORD_MODAL_REQUESTED";

// RESET CHANGE PASSWORD ERROR MESSAGES
export const CHANGE_PASSWORD_RESET_ERROR_MESSAGES_REQUESTED = "CHANGE_PASSWORD_RESET_ERROR_MESSAGES_REQUESTED";

// LINKEDIN UNLINK
export const UNLINK_LINKED_IN_REQUEST = "UNLINK_LINKED_IN_REQUEST"
export const UNLINK_LINKED_IN_SUCCESS = "UNLINK_LINKED_IN_SUCCESS"
export const UNLINK_LINKED_IN_FAILURE = "UNLINK_LINKED_IN_FAILURE"

// CHANGE PASSWORD
export const GET_EXPERT_DETAILS_REQUESTED = "GET_EXPERT_DETAILS_REQUESTED";
export const GET_EXPERT_DETAILS_SUCCESS = "GET_EXPERT_DETAILS_SUCCESS";
export const GET_EXPERT_DETAILS_FAILED = "GET_EXPERT_DETAILS_FAILED";

// LINKEDIN LINK
// export const LINK_LINKED_IN_REQUEST = "LINK_LINKED_IN_REQUEST"
// export const LINK_LINKED_IN_SUCCESS = "LINK_LINKED_IN_SUCCESS"
// export const LINK_LINKED_IN_FAILURE = "LINK_LINKED_IN_FAILURE"

// RESET THE UPDATE THE EMAIL AND MOBILE STEP

export const UPDATE_EMAIL_STEP_RESET = "UPDATE_EMAIL_STEP_RESET"
export const UPDATE_MOBILE_STEP_RESET = "UPDATE_MOBILE_STEP_RESET"

export const CANCEL_LINKEDIN_PASSWORD = "CANCEL_LINKEDIN_PASSWORD"


export const UNLINK_PASSWORD_CREATE_REQUESTED = "UNLINK_PASSWORD_CREATE_REQUESTED"
export const UNLINK_PASSWORD_CREATE_SUCCESS = "UNLINK_PASSWORD_CREATE_SUCCESS"
export const UNLINK_PASSWORD_CREATE_FAILURE = "UNLINK_PASSWORD_CREATE_FAILURE"

export const UNLINK_SET_PASSWORD_MODAL="UNLINK_SET_PASSWORD_MODAL"