import { Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import PageTitle from "../../../components/global/common/pageTitle";
import Layout from "../../../layout";
import { useState } from "react";
import { useParams } from "react-router-dom";
import ProjectStatusCard from "../../../components/customer/projectDetails/projectStatusCard";
import ProjectDeliveriesTab from "../../../components/customer/projectDetails/projectDeliveriesTab";
import ProjectDetailsPreview from "../../../components/customer/projectDetails/projectDetailsPreview";
import { useDispatch, useSelector } from "react-redux";

const ProjectDetails = () => {
  const params = useParams();

  const details = useSelector(state => state.projectNew.projectDetails);

  // Active Tab Element
  const [currentActiveTab, setCurrentActiveTab] = useState("1");
  console.log(details, "the details in thebnbjbnjnjn");
  const toggleCreateProjectTabs = (tab) => {
    if (currentActiveTab != tab) {
      setCurrentActiveTab(tab);
    }
  }

  return (
    <>
      <PageTitle pageTitle="Project Details" />

      <Layout>
        {/* ========= PROJECTS ========= */}
        <section className="us-section us-customer__projects">
          <div className="us-section__inwrap">
            <Container>
              <Row>
                <Col md="6">
                  <h1 className="us-section__title mb-10">{details&&details.project&&details.project.title}</h1>
                </Col>
                <Col md="6">

                </Col>
              </Row>

              <Row>
                <Col md="12">
                  <div className="us-nav">
                    {/*  TABS */}
                    <Nav tabs className="us-nav__tabs">
                      <NavItem className="us-nav__tab-item">
                        <NavLink
                          className={`us-nav__tab-link d-flex align-items-center ${currentActiveTab == 1 ? "active" : ""}`}
                          onClick={() => toggleCreateProjectTabs("1")}
                        >
                          <span className="us-nav__tab-title">Project Requirements</span>
                        </NavLink>
                      </NavItem>

                      <NavItem className="us-nav__tab-item">
                        {/* <NavLink
                          className={`us-nav__tab-link d-flex align-items-center ${currentActiveTab == 2 ? "active" : ""}`}
                          onClick={() => toggleCreateProjectTabs("2")}
                        >
                          <span className="us-nav__tab-title">Project Deliveries</span>
                        </NavLink> */}
                      </NavItem>
                    </Nav>

                    {/* TAB CONTENT */}
                    <TabContent activeTab={currentActiveTab} className="us-nav__tab-content mt-10">
                      {/* Project Requirements Tab */}
                      <TabPane tabId="1" className="us-nav__tab-pane">

                        <ProjectDetailsPreview
                          projectId={params.id}
                        />

                      </TabPane>

                      {/* Project Deliveries Tab */}
                      <TabPane tabId="2" className="us-nav__tab-pane">

                        <ProjectDeliveriesTab />

                      </TabPane>
                    </TabContent>
                  </div>
                </Col>

                {/* <Col md="4" className="mt-20">
                  <ProjectStatusCard />
                </Col> */}
              </Row>
            </Container>
          </div>
        </section>
      </Layout>
    </>
  )
}
export default ProjectDetails;