import { call, put, takeEvery } from "redux-saga/effects";
import instance from "../../../../api/api_instance";
import * as type from "./types";

async function getApi(data) {
  try {
    const result = instance({
      url: `v1/expert-phase-two`,
      method: "GET",
    }).then((response) => {
      return response;
    });
    return await result;
  } catch (error) {
    throw error;
  }
}

function* fetchExpert(action) {
  try {
    const res = yield call(getApi, action.payload);
    // console.log(res.data.data.expertPhaseTwo, "data coming inside thettete");
    yield put({type: "GET_DETAILS_EXPERT_TAB_TWO_SUCCESS", payload: res.data.data.expertPhaseTwo,});
  } catch (e) {
    if (
      e.response.status === 400 ||
      e.response.status === 403 ||
      e.response.status === 404 ||
      e.response.status === 409
    ) {
      yield put({
        type: "GET_DETAILS_EXPERT_TAB_TWO_FAILED",
        message: e.response.data.message,
      });
    } else {
      yield put({
        type: "GET_DETAILS_EXPERT_TAB_TWO_FAILED",
        message: "Some error occurred!",
      });
    }
  }
}
function* getExpertDetailsStepTwoSaga() {
  yield takeEvery("GET_DETAILS_EXPERT_TAB_TWO_REQUESTED", fetchExpert);
}

export default getExpertDetailsStepTwoSaga;
