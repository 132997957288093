import { call, put, takeEvery } from 'redux-saga/effects'
import instance from "../../../../api/api_instance";

async function getApi(data) {
  console.log(data, "kijihijcd");
  try {
    const result = instance({
      url: `v1/project/${data.id}`,
      method: "DELETE",
    }).then((response) => {
      return response;
    })
    return await result;
  } catch (error) {
    throw error;
  }
}

function* deleteDraft(action) {
  try {
    const res = yield call(getApi, action.payload);

    const toastrData = {
      content: `${res.data.name} Project deleted Successfully`,
      type: "success"
    }
    yield put({ type: "DELETE_DRAFT_PROJECT_SUCCESS", data: {} });
    const data = {
      projectId:action.payload.id,
      page:0,
      limit:15,
      type:action.payload.currentTab,
      search: action.payload.search
    }

    yield put({ type: 'GET_ALL_PROJECTS_REQUESTED', payload: data });
    yield put({ type: "SET_TOASTER_SUCCESS", data: toastrData });

  } catch (e) {
    const toastrData = {
      content: "Failed to delete this Project Persona",
      type: "failed"
    }
    if (e.response.status === 406 || e.response.status === 404 || e.response.status === 500) {
      yield put({ type: "DELETE_DRAFT_PROJECT_FAILED", message: e.response.data });
      yield put({ type: "SET_TOASTER_SUCCESS", data: toastrData });
    } else {
      yield put({ type: "DELETE_DRAFT_PROJECT_FAILED", message: "Some error occurred" });
      yield put({ type: "SET_TOASTER_SUCCESS", data: toastrData });
    }

    // if (e.response.status === 500) {
    //   const toastrFailedData = {
    //     content: e.response.data.errors ? e.response.data.errors : "Something went wrong!",
    //     type: "failed"
    //   };
    //   yield put({ type: "SET_TOASTER_SUCCESS", data: toastrFailedData });
    // }
  }
}

function* deleteDraftProjectSaga() {
  yield takeEvery("DELETE_DRAFT_PROJECT_REQUESTED", deleteDraft);
}

export default deleteDraftProjectSaga;