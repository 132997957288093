import { Button, Col, Container, FormGroup, Input, Label, Row } from "reactstrap";
import PageTitle from "../../../components/global/common/pageTitle";
import Layout from "../../../layout";
import ActiveProjects from "../../../components/customer/dashboard/activeProjects";
import { useHistory } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { setProjectActiveTab } from "../../../redux/reduxes/customer/projects-new/projectAction";


const Dashboard = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  // Get Expert Report
  const handleExpertReportEvent = () => {
    history.push("/customer/projects/create-project/false/create");
    dispatch(setProjectActiveTab(0));
  }

  // Get Real User Report
  const handleRealUserReportEvent = () => {
    history.push("/customer/projects/create-project/true/create");
    dispatch(setProjectActiveTab(0));
  }

  return (
    <>
      <PageTitle pageTitle="Customer Dashboard" />

      <Layout>
        {/* ============= DASHBOARD ================ */}
        <section className="us-section us-customer__dashboard">
          <div className="us-section__inwrap">
            <Container>
              <Row>
                <Col lg="9">
                  <div className="us-customer__dashboard-left overflow-hidden">
                    {/* Get Started */}

                    <div className="us-customer__dashboard-reports d-lg-flex align-items-center bg-white mb-14">
                      <div className="p-5 p-md-10">
                        <h6 className="us-section__title-mini mb-2">Get started</h6>
                        <p className="us-para">Complete your profile first so we can connect you more <br className="d-none d-md-block" /> with personalized projects for you.</p>
                        <div className="d-md-inline-flex mt-6">
                          <Button onClick={(e) => handleExpertReportEvent(e)} type="button" color="primary" className="us-button us-button__primary text-uppercase mt-4">Get Expert Report</Button>
                          <Button onClick={(e) => handleRealUserReportEvent(e)} type="button" color="primary" outline className="us-button us-button__primary-outline text-uppercase mt-4 ms-md-4">Get Real User Report</Button>
                        </div>
                      </div>
                      <div className="d-none d-lg-block ms-auto">
                        <img src="/assets/images/customer/dashboard/dashboard-reports.png" alt="get-reports" className="img-fluid d-block" width="300px" height="100px" />
                      </div>
                    </div>

                    {/* Projects Showcase */}
                    <ActiveProjects />

                    {/* Switch between Profile Roles */}
                    {/* <div className="us-customer__dashboard-switch">
                      <div className="d-flex align-items-center">
                        <div className="us-section__title-mini mb-4">Switch Profile</div>
                        <div></div>
                      </div>
                      <div className="us-form__wrap">
                        <FormGroup switch className="d-flex align-items-center flex-nowrap p-0">
                          <div className="us-form__switch-text us-form__switch-text--left">Customer</div>
                          <Label htmlFor="switch_profile" className="us-form__switch-label position-relative d-inline-block mb-0 mx-2">
                            <Input
                              type="checkbox"
                              id="switch_profile"
                              name="switch_profile"
                              className="us-form__switch-input"
                            />
                            <span className="us-form__switch-slider position-absolute start-0 top-0 end-0 bottom-0"></span>
                          </Label>
                          <div className="us-form__switch-text us-form__switch-text--right">Expert</div>
                        </FormGroup>
                      </div>
                    </div> */}
                  </div>
                </Col>

                <Col lg="3">

                </Col>
              </Row>
            </Container>
          </div>
        </section>
      </Layout>
    </>
  )
}
export default Dashboard;