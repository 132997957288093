import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, ModalBody } from "reactstrap";


const DeleteModal = ({id, headText, bodyText, modalValue, deleteAction, modalAction, deleteLoading, personaId, evaluationId ,search,currentTab}) => {
  const dispatch = useDispatch();

  const details = useSelector(state => state.projectNew.projectDetails.project);
  const projectId = details && details._id;
  const data = { id, personaId, projectId, evaluationId,search,currentTab }

  // Delete Handler
  const dispatchTheFunction = () => {
    dispatch(deleteAction(data));
    modalAction(false);
  }

  return (
    <>
      <Modal size="md" className="us-modal__dialog" isOpen={modalValue}>
        <ModalBody className="us-modal__body text-center">
          <div>
            <h6 className="us-section__title-mini mb-1">{headText}</h6>
            <p className="us-para">{bodyText}</p>

            <div className="mt-14">
              <Button onClick={() => modalAction(false)} color="danger" type="button" className="us-button us-button__danger-outline text-uppercase px-8">Cancel</Button>
              <Button
                color="primary"
                type="button"
                className={`us-button us-button__primary text-uppercase px-10 ms-5 ${deleteLoading ? "us-button__disabled" : "us-button__primary"}`}
                disabled={deleteLoading}
                onClick={() => dispatchTheFunction()}
              >
                {deleteLoading ? "Deleting..." : "Delete"}
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}
export default DeleteModal;