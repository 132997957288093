import * as type from './types';

// GET USER DETAILS
export function getProfileDetails(data) {
  return {
    type: type.GET_PROFILE_DETAILS_REQUESTED,
    payload: data,
  }
}


// ADD PROFILE PICTURE
export function addProfilePicture(data) {
  return {
    type: type.ADD_PROFILE_PICTURE_REQUESTED,
    payload: data,
  }
}

// SET UPDATE PROFILE PICTURE MODAL
export function setUpdateProfilePictureModal(data) {
  return {
    type: type.SET_UPDATE_PROFILE_PICTURE_MODAL_REQUESTED,
    payload: data,
  }
}

// REMOVE PROFILE PICTURE
export function removeProfilePicture(data) {
  return {
    type: type.REMOVE_PROFILE_PICTURE_REQUESTED,
    payload: data,
  }
}


// UPDATE FULL NAME
export function updateProfileFullName(data) {
  return {
    type: type.UPDATE_FULL_NAME_REQUESTED,
    payload: data,
  }
}

// SET UPDATE FULLNAME MODAL
export function setUpdateFullNameModal(data) {
  return {
    type: type.SET_UPDATE_FULLNAME_MODAL_REQUESTED,
    payload: data,
  }
}



// UPDATE PROFILE EMAIL
export function updateProfileEmail(data) {
  return {
    type: type.UPDATE_EMAIL_REQUESTED,
    payload: data,
  }
}

// SET UPDATE PROFILE EMAIL MODAL
export function setUpdateEmailModal(data) {
  return {
    type: type.SET_UPDATE_EMAIL_MODAL_REQUESTED,
    payload: data,
  }
}

// SET UPDATE EMAIL ERRORS
export function resetUpdateEmailErrors(data) {
  return {
    type: type.RESET_UPDATE_EMAIL_ERRORS_REQUESTED,
    payload: data,
  }
}

// UPDATE PROFILE EMAIL OTP VERIFICATION
export function updateEmailOTPVerification(data) {
  return {
    type: type.UPDATE_EMAIL_OTP_VERIFICATION_REQUESTED,
    payload: data,
  }
}

// SET UPDATE EMAIL OTP ERRORS
export function resetUpdateEmailOTPVerificationErrors(data) {
  return {
    type: type.RESET_UPDATE_EMAIL_OTP_ERRORS_REQUESTED,
    payload: data,
  }
}

// RESEND PROFILE EMAIL OTP
export function resendUpdateEmailOTP(data) {
  return {
    type: type.RESEND_UPDATE_EMAIL_OTP_REQUESTED,
    payload: data,
  }
}



// UPDATE PROFILE MOBILE NUMBER
export function updateProfileMobileNumber(data) {
  return {
    type: type.UPDATE_MOBILE_NUMBER_REQUESTED,
    payload: data,
  }
}

// SET UPDATE MOBILE NUMBER MODAL
export function setUpdateMobileNumberModal(data) {
  return {
    type: type.SET_UPDATE_MOBILE_NUMBER_MODAL_REQUESTED,
    payload: data,
  }
}

// RESET UPDATE MOBILE NUMBER ERRORS
export function resetUpdateMobileNumberErrors(data) {
  return {
    type: type.RESET_UPDATE_MOBILE_NUMBER_ERRORS_REQUESTED,
    payload: data,
  }
}

// UPDATE MOBILE NUMBER OTP VERIFICATION
export function updateMobileOTPVerification(data) {
  return {
    type: type.UPDATE_MOBILE_OTP_VERIFICATION_REQUESTED,
    payload: data,
  }
}

// RESET UPDATE MOBILE NUMBER OTP VERIFICATION ERRORSresetUpdateRegisterMobileNumberErrors
export function resetUpdateMobileOTPVerificationErrors(data) {
  return {
    type: type.RESET_UPDATE_MOBILE_OTP_VERIFICATION_ERRORS_REQUESTED,
    payload: data,
  }
}

// RESEND UPDATE MOBILE OTP
export function resendUpdateMobileOTP(data) {
  return {
    type: type.RESEND_UPDATE_MOBILE_OTP_REQUESTED,
    payload: data,
  }
}


// CHANGE PASSWORD
export function changePassword(data) {
  return {
    type: type.CHANGE_PASSWORD_REQUESTED,
    payload: data,
  }
}

// UNLINK LinkedIn, PASSWORD CREATE
export function unlinkPasswordCreate(data) {
  console.log(data,"OK!!")
  return {
    type: type.UNLINK_PASSWORD_CREATE_REQUESTED,
    payload: data,
  }
}

// SET CHANGE PASSWORD MODAL
export function setChangePasswordModal(data) {
  return {
    type: type.SET_CHANGE_PASSWORD_MODAL_REQUESTED,
    payload: data,
  }
}
// TEST ACTION
export function unlinkLinkedInPassword(data) {
  return {
    type: type.CANCEL_LINKEDIN_PASSWORD,
    payload: data,
  }
}

// CHANGE PASSWORD RESET ERROR MESSAGES
export function resetChangePasswordErrorMessages(data) {
  return {
    type: type.CHANGE_PASSWORD_RESET_ERROR_MESSAGES_REQUESTED,
    payload: data,
  }
}

// GET EXPERT DETAILS
export function getExpertDetails(data) {
  return {
    type: type.GET_EXPERT_DETAILS_REQUESTED,
    payload: data,
  }
}

export function unlinkLinkedInAction(){
  return {
    type: type.UNLINK_LINKED_IN_REQUEST
  }
}

// RESET THE UPDATE THE EMAIL AND MOBILE STEP
export function resetTheEmailUpdateStep(){
  return {
    type: type.UPDATE_EMAIL_STEP_RESET
  }
}
export function resetMobileNumber(){
  return {
    type: type.GET_PROFILE_DETAILS_REQUESTED
  }
}

export function resetTheMobileUpdateStep(){
  return {
    type: type.UPDATE_MOBILE_STEP_RESET
  }
}
export function unlinkPasswordModalShow(data){
  return {
    type: type.UNLINK_SET_PASSWORD_MODAL,
    payload: data
  }
}
