import { all } from 'redux-saga/effects';
import registerAuthSaga from '../reduxes/global/auth/registerAuthSaga';
import loginAuthSaga from '../reduxes/global/auth/loginAuthSaga';
import updateFullNameSaga from '../reduxes/customer/profile/updateFullNameSaga';
import profileDetailsSaga from '../reduxes/customer/profile/profileDetailsSaga';
import confirmEmailMobileOTPSaga from '../reduxes/global/auth/confirmEmailMobileOTPSaga';
import createProjectMobileOTPSuccessSaga from '../reduxes/global/auth/createProjectMobileOTPSuccessSaga';
import screenShotOfProject from '../reduxes/global/auth/getScreenShotOfProjectSaga';
import verifyEmailMobileOTPSaga from '../reduxes/global/auth/verifyEmailMobileOTPSaga';
import updateRegisterEmailSaga from '../reduxes/global/auth/updateRegisterEmailSaga';
import updateRegisterMobileNumberSaga from '../reduxes/global/auth/updateRegisterMobileNumberSaga';
import resendEmailOTPConfirmationSaga from '../reduxes/global/auth/resendEmailOTPConfirmationSaga';
import resendMobileOTPConfirmationSaga from "../reduxes/global/auth/resendMobileOTPConfirmationSaga";
import updateProfileEmailSaga from '../reduxes/customer/profile/updateProfileEmailSaga';
import updateEmailOTPVerificationSaga from '../reduxes/customer/profile/updateEmailOTPVerificationSaga';
import resendUpdateEmailOTPSaga from '../reduxes/customer/profile/resendUpdateEmailOTPSaga';
import updateMobileNumberSaga from '../reduxes/customer/profile/updateMobileNumberSaga';
import addProfilePictureSaga from '../reduxes/customer/profile/addProfilePictureSaga';
import removeProfilePictureSaga from '../reduxes/customer/profile/removeProfilePictureSaga';
import changePasswordSaga from '../reduxes/customer/profile/changePasswordSaga';
import triggerForgotPasswordEmailOtpSaga from '../reduxes/global/resetPassword/triggerForgotPasswordEmailOtpSaga';
import verifyForgotPasswordEmailOtpSaga from '../reduxes/global/resetPassword/verifyForgotPasswordEmailOtpSaga';
import resetForgotPasswordSaga from '../reduxes/global/resetPassword/resetForgotPasswordSaga';
import updateMobileOTPVerificationSaga from '../reduxes/customer/profile/updateMobileOTPVerificationSaga';
import resendUpdateMobileOTPSaga from '../reduxes/customer/profile/resendUpdateMobileOTPSaga';
import unlinkLinkedinChangePasswordSaga from "../reduxes/customer/profile/unlinkLinkedinChangePasswordSaga";
  // import createEvaluationStepTwoSaga from '../reduxes/customer/projects/createEvaluationStepTwoSaga';
  // import createEvaluationStepThreeSaga from '../reduxes/customer/projects/createEvaluationStepThreeSaga';


import updateProjectTabThreeSaga from "../reduxes/customer/projects-new/updateProjectTabThreeSaga";


import linkedInLoginTokeSaga from '../reduxes/global/auth/linkedInLoginTokenSaga';
import unlinkLinkedInSaga from "../reduxes/customer/profile/unlinkLinkedInSaga"

import updateEvaluationMAterialStepOneSaga from "../reduxes/customer/evalutionMaterial/updateEvaluationMeterialStepOne";
import updateEvaluationMAterialStepTwoSaga from "..//reduxes/customer/evalutionMaterial/updateEvaluationMeterialStepTwo";
import updateEvaluationMAterialStepThreeSaga from "../reduxes/customer/evalutionMaterial/updateEvaluationMeterialStepThree";
import deleteEvaluationMaterialsFilesSaga from "../reduxes/customer/evalutionMaterial/deleteEvaluationMaterialsFile";
import getAllEvaluationDetailsSaga from '../reduxes/customer/evalutionMaterial/getAllEvaluationDetailsSaga';
import createEvaluationStepOneSaga from '../reduxes/customer/evalutionMaterial/createEvaluationStepOneSaga';
import deleteEvaluationMaterialsSaga from "../reduxes/customer/evalutionMaterial/deleteEvaluationMaterialsSaga";
import evaluationStepOneDetailsSaga from '../reduxes/customer/evalutionMaterial/evaluationStepOneDetailsSaga';
import evaluationStepTwoDetailsSaga from '../reduxes/customer/evalutionMaterial/evaluationStepTwoDetailsSaga';
import evaluationStepThreeDetailsSaga from '../reduxes/customer/evalutionMaterial/evaluationStepThreeDetailsSaga';
import deleteEvaluationMaterialPrototypeSaga from "../reduxes/customer/evalutionMaterial/deleteEvaluationMaterialsPrototypeSaga";

import createProjectTabOneSaga from '../reduxes/customer/projects-new/createProjectTabOneSaga';
import updateProjectTabOneSaga from '../reduxes/customer/projects-new/updateProjectTabOneSaga';
import getAllProjectsSaga from "../reduxes/customer/projects-new/getAllProjectsSaga";
import getProjectDetailsSaga from "../reduxes/customer/projects-new/getProjectDetailsSaga";
import getAllEvaluationMaterialsSaga from '../reduxes/customer/projects-new/getAllEvaluationMaterialsSaga';
import deleteProjectPersonaSaga from "../reduxes/customer/projects-new/deleteProjectPersonaSaga";
import getProjectPersonaDetailsSaga from "../reduxes/customer/projects-new/getProjectPersonaDetailsSaga";
import updateProjectPersonaSaga from "../reduxes/customer/projects-new/updateProjectPersonaSaga";
import createProjectPersonaSaga from "../reduxes/customer/projects-new/createProjectPersonaSaga";
import getAllProjectPersonasSaga from "../reduxes/customer/projects-new/getAllProjectPersonasSaga";
import updateEvaluationReasonsSaga from "../reduxes/customer/projects-new/updateEvaluationReasonsSaga";

import getAllProjectCategoriesSaga from '../reduxes/customer/general/getAllProjectCategoriesSaga';
import getAllProjectMultilingualSaga from '../reduxes/customer/general/getAllProjectMultilingualSaga';
import getAllCountriesListSaga from "../reduxes/customer/general/getAllCountriesListSaga";
import getAllStatesListSaga from "../reduxes/customer/general/getAllStatesListSaga";
import getAllCitiesListSaga from "../reduxes/customer/general/getAllCitiesListSaga";
import getAllEducationListSaga from "../reduxes/customer/general/getAllEducationListSaga";
import getAllProjectLanguagesSaga from "../reduxes/customer/general/getAllProjectLanguagesSaga";
import deleteDraftProjectSaga from "../reduxes/customer/projects-new/deleteDraftProjectSaga";

//expert registration
import createExpertStepOneSaga from "../reduxes/customer/expertRegistration/createExpertStepOneSaga";
import updateExpertStepOneSaga from "../reduxes/customer/expertRegistration/updateExpertStepOneSaga";
import getExpertDetailsStepOneSaga from "../reduxes/customer/expertRegistration/getExpertDetailsTabOneSaga";
import updateExpertSteptwoSaga from "../reduxes/customer/expertRegistration/updateExpertSteptwoSaga";
import updateExpertStepThreeSaga from "../reduxes/customer/expertRegistration/updateExpertStepThreeSaga";
import getExpertDetailsStepTwoeSaga from "../reduxes/customer/expertRegistration/getExpertDetailsTabTwoSaga";
import getExpertDetailsStepThreeSaga from "../reduxes/customer/expertRegistration/getExpertDetailsTabThreeSaga";
import expertRegistrationDetailsSaga from "../reduxes/customer/profile/expertRegistrationDetailsSaga";


export default function* rootSaga() {
  yield all([

    // Auth
    registerAuthSaga(),
    loginAuthSaga(),
    confirmEmailMobileOTPSaga(),
    verifyEmailMobileOTPSaga(),
    updateRegisterEmailSaga(),
    updateRegisterMobileNumberSaga(),
    resendEmailOTPConfirmationSaga(),
    resendMobileOTPConfirmationSaga(),
    linkedInLoginTokeSaga(),

    // Forgot Password
    triggerForgotPasswordEmailOtpSaga(),
    verifyForgotPasswordEmailOtpSaga(),
    resetForgotPasswordSaga(),

    // Profile
    profileDetailsSaga(),
    addProfilePictureSaga(),
    removeProfilePictureSaga(),
    updateFullNameSaga(),
    updateProfileEmailSaga(),
    updateEmailOTPVerificationSaga(),
    resendUpdateEmailOTPSaga(),
    updateMobileNumberSaga(),
    updateMobileOTPVerificationSaga(),
    resendUpdateMobileOTPSaga(),
    changePasswordSaga(),
    unlinkLinkedinChangePasswordSaga(),

    //prjects new
    getAllProjectsSaga(),
    getProjectDetailsSaga(),
    createProjectTabOneSaga(),
    updateProjectTabOneSaga(),
    getAllEvaluationMaterialsSaga(),
    updateEvaluationReasonsSaga(),
    updateProjectTabThreeSaga(),
    deleteDraftProjectSaga(),

    // evelution material
    getAllEvaluationDetailsSaga(),
    createEvaluationStepOneSaga(),
    updateEvaluationMAterialStepOneSaga(),
    updateEvaluationMAterialStepTwoSaga(),
    updateEvaluationMAterialStepThreeSaga(),
    deleteEvaluationMaterialsFilesSaga(),
    deleteEvaluationMaterialsSaga(),
    evaluationStepOneDetailsSaga(),
    evaluationStepTwoDetailsSaga(),
    evaluationStepThreeDetailsSaga(),
    deleteEvaluationMaterialPrototypeSaga(),

    //general
    getAllProjectCategoriesSaga(),
    getAllProjectMultilingualSaga(),
    getAllCountriesListSaga(),
    getAllStatesListSaga(),
    getAllCitiesListSaga(),
    getAllEducationListSaga(),
    getAllProjectLanguagesSaga(),

    createProjectPersonaSaga(),
    getAllProjectPersonasSaga(),
    getProjectPersonaDetailsSaga(),
    updateProjectPersonaSaga(),
    deleteProjectPersonaSaga(),

    unlinkLinkedInSaga(),

    // expert registration
    createExpertStepOneSaga(),
    getExpertDetailsStepOneSaga(),
    updateExpertStepOneSaga(),
    updateExpertSteptwoSaga(),
    updateExpertStepThreeSaga(),
    getExpertDetailsStepTwoeSaga(),
    getExpertDetailsStepThreeSaga(),
    expertRegistrationDetailsSaga(),

    //Get screenshot of project
    screenShotOfProject(),

    // Create project after registration
    createProjectMobileOTPSuccessSaga(),


  ])
};