import { Col, Container, Row } from "reactstrap";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return(
    <section className="border-top">
      <Container>
        <Row>
          <Col className="text-center py-3 text-xs">
          Copyrights © Useralia Private Limited | {currentYear} (alpha version)
          </Col>
        </Row>
      </Container>
    </section>
  )
}
export default Footer;