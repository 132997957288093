import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  Row,
} from "reactstrap";
import {
  changePassword,
  resetChangePasswordErrorMessages,
  unlinkLinkedInPassword,
  unlinkPasswordCreate,
  unlinkPasswordModalShow
} from "../../../redux/reduxes/customer/profile/actions";


const UnlinkLinkedIn = ({ setShowUnlinkLinkedIn}) => {
  const dispatch = useDispatch();

  // console.log("loading second time KOKOOKOK");

  // Fetch Data
  const changePasswordLoading = useSelector(
    (state) => state.profile.changePasswordLoading
  );
  const changePasswordError = useSelector(
    (state) => state.profile.changePasswordError
  );
  const profileDetails = useSelector(state => state.profile.profileDetails);
  console.log(
    "this is the error message coming after submit",
    // profileDetails.email
  );

  // States
  const [state, setState] = useState({
    // current_password: "",
    new_password: "",
    new_password_confirmation: "",

    errors: {
      // current_password: "",
      new_password: "",
      passwordUpperCase: "",
      passwordDigit: "",
      passwordSpecialCharacters: "",
      new_password_confirmation: "",
    },
  });

  // Reset Error Messages
  useEffect(() => {
    dispatch(resetChangePasswordErrorMessages());
  }, []);

  // Reset Error Messages
  useEffect(() => {
    let errors = state.errors;
    // console.log(changePasswordError, "changePasswordError OKOKOKOKOKOKOK");
    changePasswordError &&
      changePasswordError.length > 0 &&
      changePasswordError.forEach((error) => {
        switch (error.context.key) {
          // case "oldPassword":
          //   errors.current_password = error.message;
          //   break;
          case "newPassword":
            errors.new_password = error.message;
            break;
          case "new_password_confirmation":
            errors.new_password_confirmation = error.message;
            break;
          default:
            break;
        }
      });
    if (changePasswordError.length == 0) {
      // errors.current_password = "";
      errors.new_password = "";
      errors.new_password_confirmation = "";
    }
    setState((prevProps) => ({
      ...prevProps,
      errors,
    }));
  }, [changePasswordError]);

  // Validate Form
  const validatePasswordForm = (errors) => {
    let valid = true;
    Object.values(errors).forEach((val) => val.length > 0 && (val = false));
    // console.log(
    //   state.new_password_confirmation,
    //   state.new_password,
    //   "LOKOMOTIVE JJJJJJ",
    //   errors
    // );
    if (
      // state.current_password == "" ||
      state.new_password == "" ||
      state.new_password_confirmation == "" ||
      state.new_password_confirmation != state.new_password ||
      state.errors.passwordUpperCase != "" ||
      state.errors.passwordDigit != "" ||
      state.errors.passwordSpecialCharacters != "" ||
      state.new_password.length < 8
      // state.new_password_confirmation != state.password
    ) {
      valid = false;
    }
    // console.log("FLYER",valid)
    return valid;
  };

  // Change Handler
  const onChangeHandler = (event) => {
    event.preventDefault();
    const upperCaseLetters = RegExp(/[A-Z]/g);
    const digits = RegExp(/[0-9]/g);
    const specialCharacters = RegExp(
      /^(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]).*$/
    );

    const { value, name } = event.target;
    let errors = state.errors;
    switch (name) {
      // case 'current_password':
      //   if (value == "") {
      //     errors.current_password = "Enter Current Password"
      //   }
      //   break;
      case "new_password":
        if (!upperCaseLetters.test(value)) {
          errors.passwordUpperCase =
            "Password should have atleast one Uppercase letter.";
        } else {
          errors.passwordUpperCase = "";
        }
        if (!digits.test(value)) {
          errors.passwordDigit = "Password should have atleast one Digit.";
        } else {
          errors.passwordDigit = "";
        }
        if (!specialCharacters.test(value)) {
          errors.passwordSpecialCharacters =
            "Password should have atleast one Special character.";
        } else {
          errors.passwordSpecialCharacters = "";
        }
        if (value.length < 8) {
          errors.new_password =
            "Password must be minimum of 8 characters long.";
        } else {
          errors.new_password = "";
        }
        // if (value == state.current_password) {
        //   errors.new_password = "New password shouldn't match with the old password."
        // } else {
        //   errors.new_password = "";
        // }
        break;
      case "new_password_confirmation":
        if (value == "") {
          errors.new_password_confirmation = "Enter Confirm Password.";
        } else {
          errors.new_password_confirmation = "";
        }
        if (value != state.new_password) {
          errors.new_password_confirmation = "Password doesn't match.";
        } else {
          errors.new_password_confirmation = "";
        }
        break;
      default:
        break;
    }
    setState((prevProps) => ({
      ...prevProps,
      errors,
      [name]: value,
    }));
    dispatch(resetChangePasswordErrorMessages());
  };

  // Form Submit
  const onSubmitHandler = async (event) => {
    event.preventDefault();
    if (validatePasswordForm(state.errors)) {
      // console.log(
      //   state.new_password_confirmation,
      //   "Data is coming here2!!",
      //   state.new_password,
      //   validatePasswordForm(state.errors)
      // );
      const data = {
        // oldPassword: state.current_password,
        // newPassword: state.new_password,
        password: state.new_password,
        confirmPassword: state.new_password_confirmation
      };
      dispatch(unlinkPasswordCreate(data));
    } else {
      console.log(
        state.new_password_confirmation,
        "Data is coming here!!",
        state.new_password,
        validatePasswordForm(state.errors)
      );
      let errors = state.errors;
      // if (state.current_password == "") {
      //   errors.current_password = "Current Password is required."
      // }
      if (
        state.new_password == "" &&
        state.errors.upperCaseLetters != "" &&
        state.errors.digits != "" &&
        state.errors.specialCharacters != "" &&
        state.new_password.length < 8
      ) {
        errors.new_password = "Password is required.";
      }
      if (state.new_password_confirmation == "") {
        errors.new_password_confirmation = "Confirm Password is required.";
      } else if (state.new_password_confirmation != state.password) {
        errors.new_password_confirmation = "Confirm Password doesn't match.";
      } else {
        errors.new_password_confirmation = "";
      }

      setState((prevProps) => ({
        ...prevProps,
        errors: errors,
      }));
    }
  };

  // Reset the form values on Closing Modal
  const onCancelTheEdit = () => {
    // const data = {
    //   new_password: "",
    //   new_password_confirmation: "",
    // };
    let errors = state.errors;
    console.log(errors);
    errors.current_password = "";
    errors.new_password = "";
    errors.passwordUpperCase = "";
    errors.passwordDigit = "";
    errors.passwordSpecialCharacters = "";
    errors.new_password_confirmation = "";

    setState((prevProps) => ({
      ...prevProps,
      new_password: "",
      new_password_confirmation: "",
      current_password: "",
      errors: errors,
    }));
    // dispatch(changePassword(data));
    dispatch(unlinkPasswordModalShow(false));
  };

  // Show/Hide Current Password
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const toggleCurrentPassword = () => {
    setShowCurrentPassword(!showCurrentPassword);
  };

  // Show/Hide New Password
  const [showNewPassword, setShowNewPassword] = useState(false);
  const toggleNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  // Show/Hide Confirm New Password
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
  const toggleConfirmNewPassword = () => {
    setShowConfirmNewPassword(!showConfirmNewPassword);
  };

  console.log(state.errors, "the errors coming inside LPOKO");
  return (
    <>
      <Modal
        className="us-modal__dialog"
        size="md"
        isOpen={setShowUnlinkLinkedIn}
      >
        <ModalBody className="us-modal__body">
          <h1 className="us-section__title-block mb-8 mb-md-12 text-center">
            Unlink Change Password
          </h1>
          <Form onSubmit={(e) => onSubmitHandler(e)}>
            <Row>
              <Col sm="12">
                <FormGroup className="us-form__wrap mb-6">
                  <Label htmlFor="current_password" className="us-form__label">
                    Email address
                  `</Label>
                  <div className="us-form__input-group position-relative d-flex align-items-center">
                    <Input
                      type="text"
                      value={profileDetails && profileDetails.email}
                      className={`us-form__input`}
                      readOnly
                    />
                  </div>
                </FormGroup>

                <FormGroup className="us-form__wrap mb-6">
                  <Label htmlFor="new_password" className="us-form__label">
                    Password <span className="text-danger">*</span>
                  </Label>
                  <div className="us-form__input-group position-relative d-flex align-items-center">
                    <Input
                      type={showNewPassword ? "text" : "password"}
                      name="new_password"
                      id="new_password"
                      placeholder="New Password"
                      className={`us-form__input ${
                        state.errors.new_password ? "us-form__input-error" : ""
                      }`}
                      value={state.new_password}
                      onChange={(e) => onChangeHandler(e)}
                      autoComplete="new_password"
                      maxLength={50}
                    />
                    <Button
                      type="button"
                      onClick={() => toggleNewPassword()}
                      className="us-form__input-button position-absolute"
                    >
                      {showNewPassword ? (
                        <img
                          src="/assets/icons/icon-eye-show.svg"
                          alt="icon-eye-show"
                          className="img-fluid"
                        />
                      ) : (
                        <img
                          src="/assets/icons/icon-eye-hide.svg"
                          alt="icon-eye-hide"
                          className="img-fluid"
                        />
                      )}
                    </Button>
                  </div>
                  <div className="us-form__error">
                    {state.errors.new_password}
                  </div>
                  <div className="us-form__error">
                    {" "}
                    {state.errors.passwordUpperCase}{" "}
                  </div>
                  <div className="us-form__error">
                    {state.errors.passwordDigit}
                  </div>
                  <div className="us-form__error">
                    {state.errors.passwordSpecialCharacters}
                  </div>
                </FormGroup>

                <FormGroup className="us-form__wrap mb-6">
                  <Label
                    htmlFor="new_password_confirmation"
                    className="us-form__label"
                  >
                    Confirm Password <span className="text-danger">*</span>
                  </Label>
                  <div className="us-form__input-group position-relative d-flex align-items-center">
                    <Input
                      type={showConfirmNewPassword ? "text" : "password"}
                      name="new_password_confirmation"
                      id="new_password_confirmation"
                      placeholder="Confirm New Password"
                      className={`us-form__input ${
                        state.errors.new_password_confirmation
                          ? "us-form__input-error"
                          : ""
                      }`}
                      value={state.new_password_confirmation}
                      onChange={(e) => onChangeHandler(e)}
                      autoComplete="new_password"
                      maxLength={50}
                    />
                    <Button
                      type="button"
                      onClick={() => toggleConfirmNewPassword()}
                      className="us-form__input-button position-absolute"
                    >
                      {showConfirmNewPassword ? (
                        <img
                          src="/assets/icons/icon-eye-show.svg"
                          alt="icon-eye-show"
                          className="img-fluid"
                        />
                      ) : (
                        <img
                          src="/assets/icons/icon-eye-hide.svg"
                          alt="icon-eye-hide"
                          className="img-fluid"
                        />
                      )}
                    </Button>
                  </div>
                  <div className="us-form__error">
                    {state.errors.new_password_confirmation}
                  </div>
                </FormGroup>

                <div className="d-flex align-items-center justify-content-center mt-12">
                  <Button
                    onClick={(e) => onCancelTheEdit(e)}
                    type="button"
                    color="danger"
                    outline
                    className="us-button us-button__danger-outline text-uppercase px-10 me-6"
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    color="primary"
                    disabled={changePasswordLoading}
                    className={`us-button us-button__primary text-uppercase px-10 ${
                      changePasswordLoading
                        ? "us-button__disabled"
                        : "us-button__primary"
                    }`}
                  >
                    {changePasswordLoading ? "Submitting..." : "Submit"}
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};
export default UnlinkLinkedIn;
