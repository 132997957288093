import { call, put, takeEvery } from 'redux-saga/effects'
import instance from "../../../../api/api_instance";

async function getApi(data) {
  try {
    const result = instance({
      url: "v1/project/",
      method: "POST",
      data: data,
    }).then((response) => {
      return response;
    })
    return await result;
  } catch (error) {
    throw error;
  }
}
function* fetchAuth(action) {
  try {
    const res = yield call(getApi, action.payload);
    console.log('BLADE TAIL',action.payload)
    if (res.status == 200) {
      yield put({ type: "PROJECT_CREATED_OTP_SUCCESS", authData: res.data.data });
      
    } else {
      yield put({ type: "PROJECT_CREATED_OTP_FAILED", message: "" });
    }
  } catch (e) {
    if (e.response.status === 400 || e.response.status === 404 || e.response.status === 409) {
      yield put({ type: "PROJECT_CREATED_OTP_FAILED", message: e.response.data });
    } else {
      yield put({ type: "PROJECT_CREATED_OTP_FAILED", message: "Some error occurred!" });
    }
  }
}


function* createProjectMobileOTPSuccessSaga() {
  yield takeEvery("CREATE_PROJECT_AFTER_OTP_REQUEST", fetchAuth);
}

export default createProjectMobileOTPSuccessSaga;
