import { useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import Stepper from "react-stepper-horizontal/lib/Stepper";
import EvaluationMaterialStepOne from "./evaluationMaterialStepOne";
import EvaluationMaterialStepTwo from "./evaluationMaterialStepTwo";
import EvaluationMaterialStepThree from "./evaluationMaterialStepThree";
import { useSelector } from "react-redux";

const EvaluationMaterialModal = ({ projectActiveTab, setShowEvaluationMaterialModal, update, setUpdate }) => {

  // Fetch Data
  const evaluationMaterialActiveStep = useSelector(state => state.evelutionMaterials.evaluationMaterialActiveStep);
  const evaluationId = useSelector(state => state.evelutionMaterials.openedEvelutionMaterialId);
  const details = useSelector(state => state.projectNew.projectDetails.project);
  console.log(details, "okokokooK");
  const projectId = details && details._id;
console.log(evaluationMaterialActiveStep, "evaluationMaterialActiveStep");

  // States
  // const [activeStep, setActiveStep] = useState(0);
  const [state, setState] = useState({
    steps: [
      {
        // icon: "/assets/icons/icon-personal.svg",
        // title: "Personal Info",
        onClick: (event) => {
          event.preventDefault();
        },
      },
      {
        // icon: "/assets/icons/icon-professional.svg",
        // title: "Professional",
        onClick: (event) => {
          event.preventDefault();
        },
      },
      {
        // icon: "/assets/icons/icon-expertise.svg",
        // title: "Industry Expertise",
        onClick: (event) => {
          event.preventDefault();
        },
      },
    ],
  })

  return (
    <>
      <Modal size="xl" className="us-modal__dialog" isOpen={setShowEvaluationMaterialModal}>
        <ModalBody className="us-modal__body us-modal__body-overflow">
          <div className="position-relative">
            <h5 className="us-section__title">Adding Evaluation Material</h5>
            <div className="us-modal__stepper-top">
              <div className="us-modal__stepper-info">
                <Stepper
                  steps={state.steps}
                  lineMarginOffset={0}
                  activeStep={evaluationMaterialActiveStep}
                  barStyle="solid"
                  activeColor="#3B61F4"
                  completeColor="#3B61F4"
                  defaultBarColor="#ddd"
                  completeBarColor="#3B61F4"
                  circleFontSize="14px"
                  activeOpacity={1}
                />
              </div>
            </div>

            <div className="us-modal__stepper-bottom mt-14">
              {evaluationMaterialActiveStep === 0 ?
                <EvaluationMaterialStepOne
                  projectActiveTab={projectActiveTab}
                  evaluationMaterialActiveStep={evaluationMaterialActiveStep}
                  setShowEvaluationMaterialModal={setShowEvaluationMaterialModal}
                  update={update} 
                  setUpdate={setUpdate}
                  evaluationId={evaluationId}
                  projectId={projectId}
                />
                :
                evaluationMaterialActiveStep === 1 ?
                  <EvaluationMaterialStepTwo
                    projectActiveTab={projectActiveTab}
                    evaluationMaterialActiveStep={evaluationMaterialActiveStep}
                    setShowEvaluationMaterialModal={setShowEvaluationMaterialModal}
                    projectId={projectId}
                    update={update}
                    evaluationId={evaluationId}
                  />
                  :
                  evaluationMaterialActiveStep === 2 ?
                    <EvaluationMaterialStepThree
                      projectActiveTab={projectActiveTab}
                      evaluationMaterialActiveStep={evaluationMaterialActiveStep}
                      setShowEvaluationMaterialModal={setShowEvaluationMaterialModal}
                      projectId={projectId}
                      update={update}
                      evaluationId={evaluationId}
                    />
                    :
                    null
              }
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}
export default EvaluationMaterialModal;