import {  Col, Container, Row } from "reactstrap";
import PageTitle from "../../../components/global/common/pageTitle";
import Layout from "../../../layout";
import PaymentCard from "../../../components/customer/projectDetails/paymentCard";
import ProjectDetailsPreview from "../../../components/customer/projectDetails/projectDetailsPreview";
import { useParams } from "react-router-dom";

const ProjectPreview = () => {
  const params = useParams();

  return (
    <>
      <PageTitle pageTitle="Project Preview" />

      <Layout>

        {/* PROJECT DETAILS SECTION : START */}
        <section className="us-section us-customer__project-details">
          <div className="us-section__inwrap">
            <Container>
              <Row className="mb-10">
                <Col md="6">
                  <h1 className="us-section__title">Project Preview</h1>
                </Col>
                <Col md="6">
                </Col>
              </Row>

              <Row>
                <Col md="12">
                  {/* Project Preview */}
                  <ProjectDetailsPreview
                    projectId={params.id}
                  />
                </Col>

                {/* <Col md="4"> */}
                  {/* Payment Details */}
                  {/* <PaymentCard /> */}

                  {/* temp remove after ..... */}
                  {/* <div className="us-form__list-bgprimary2 shadow-sm d-flex align-items-center px-4 px-md-8 py-6 mb-8">
                    <div className="d-flex align-items-center me-auto">
                       <h6 className="text-sm head-font font-semibold mb-0 ms-2">Thanks for submitting the project. The Useralia support team will contact you within 48 hours</h6>
                    </div>
                  </div> */}
                  {/* temp end */}
                {/* </Col> */}
              </Row>
            </Container>
          </div>
        </section>
      </Layout>
    </>
  )
}
export default ProjectPreview;