import createSagaMiddleware from 'redux-saga';
import { configureStore } from '@reduxjs/toolkit'
import { persistStore, persistReducer } from 'redux-persist'
import rootReducer from '../rootReducer';
import rootSaga from '../rootSaga/index';
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

// const sagaMiddleware = createSagaMiddleware();
// const store = compose(
//     applyMiddleware(sagaMiddleware, logger),
//     window.devToolsExtension && window.devToolsExtension(),
// )(createStore)(rootReducer);

// sagaMiddleware.run(rootSaga);

// const persistor = persistStore(store)


const sagaMiddleware = createSagaMiddleware();

const preloadedState = {
    // auth: {},
}

const persistConfig = {
    key: 'root',
    storage,
    whitelist: [
        // "auth"
    ],
    blacklist: ['auth']
}
const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore(
    {
        reducer: persistedReducer,
        middleware: (getDefaultMiddleware) => getDefaultMiddleware({
            serializableCheck: false
          }).concat(sagaMiddleware),
        devTools: process.env.NODE_ENV !== 'production',
        preloadedState
    }
);
sagaMiddleware.run(rootSaga);

const persistor = persistStore(store)

export { store, persistor };