
import { Button, FormGroup, Input, Label } from "reactstrap";

const AddResearchExpectation = ({allResearchExpectations, key, indexNumber, value, handleResearchExpectationsChange, handleRemoveResearchExpectations, titleError}) => {
  return (
    <>
      <div key={key} className="d-flex align-items-center mb-4">
        <div className="w-100">
          <Input
            type="text"
            id="testScenarios"
            name="testScenarios"
            placeholder="Expectations from this research..."
            className="us-form__input"
            value={value.title}
            onChange={(e) => handleResearchExpectationsChange(e.target.value, indexNumber)}
          />
          <div className="us-form__error">{titleError}</div>
        </div>

        {allResearchExpectations && allResearchExpectations.length > 1 &&
          <Button onClick={() => handleRemoveResearchExpectations(indexNumber)} type="button" color="link" className="us-button__link py-0">
            <img src="/assets/icons/icon-delete-red.svg" alt="icon-delete" />
          </Button>
        }
      </div>
    </>
  )
}
export default AddResearchExpectation;