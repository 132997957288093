import { call, put, takeEvery } from "redux-saga/effects";
import instance from "../../../../api/api_instance";

async function getApi(data) {
  try {
    const result = instance({
      url:`v1/expert-phase-three`,
      method: "PATCH",
      data: data,
    }).then((respose) => {
      return respose;
    });
    return await result;
  } catch (error) {
    throw error;
  }
  
  
}
function* fetchExpert(action) {
  try {
    const res = yield call(getApi, action.payload);
    const toastrData = {
      content: "Expert Registration Completed Successfully",
      type: "success",
    };
    yield put({ type: "UPDATE_EXPERT_TAB_THREE_SUCCESS", data: res.data });
    yield put ({type: "SET_TOASTER_SUCCESS", data: toastrData});
  } catch (e) {

    if(e.response.status === 400 || e.response.status === 404 || e.response.status === 409){
      yield put({type: "UPDATE_EXPERT_TAB_THREE_FAILED", message: e.response.data.message});
  }else{
      yield put({type: "UPDATE_EXPERT_TAB_THREE_FAILED", message: "Some error occurred!"});
  }

    if(e.respose.status === 500){
        const toastrFailedData = {
            content: e.respose.data.errors ? e.respose.data.errors: "Something went wrong!",
            type: "failed"
        };
        yield put({type: "SET_TOASTER_SUCCESS", data: toastrFailedData});
    }
    // console.log(e,"jsksdjkjsdkjk")
  }
}

function* updateExpertStepThreeSaga(){
    yield takeEvery("UPDATE_EXPERT_TAB_THREE_REQUESTED", fetchExpert);
}

export default updateExpertStepThreeSaga;
