import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, FormGroup, Input, Label, Modal, ModalBody } from "reactstrap";
import { resetUpdateRegisterEmailErrors, setRegisterUpdateEmailModal, updateRegisterEmail } from "../../../redux/reduxes/global/auth/actions";


const UpdateEmailModal = ({  setShowRegisterUpdateEmailModal }) => {
  const dispatch = useDispatch();

  // Fetch Data
  const email = useSelector(state => state.auth.email);
  const updateRegisterEmailLoading = useSelector(state => state.auth.updateRegisterEmailLoading);
  const updateRegisterEmailError = useSelector(state => state.auth.updateRegisterEmailError);

  // States
  const [state, setState] = useState({
    email: "",

    errors: {
      email: "",
    }
  });

  // Get Email ID
  useEffect(() => {
    email && email && setState((prevProps) => ({
      ...prevProps,
      email: email,
    }))
  }, [email]);

  // Reset Update Email Errors
  useEffect(() => {
    let errors = {...state.errors};
    if (updateRegisterEmailError && updateRegisterEmailError != "") {
      // updateRegisterEmailError.forEach(error => {
      //   switch (error.context.key) {
      //     case "email":
      //       errors.email = error.message;
      //       break;
      //     default:
      //       break;
      //   }
      // })
      errors.email = updateRegisterEmailError;
    } else {
      errors.email = "";
    }
    setState((prevProps) => ({
      ...prevProps,
      errors,
    }));
  }, [updateRegisterEmailError])

  // onChange Handler
  const onChangeHandler = (event) => {
    event.preventDefault();
    const validEmailRegex = RegExp(
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    );
    const { name, value } = event.target;
    let errors = state.errors;
    switch (name) {
      case "email":
        errors.email = validEmailRegex.test(value) ? "" : "Enter a valid email ID!";
        break;
      default:
        break;
    }
    setState((prevProps) => ({
      ...prevProps,
      errors,
      [name]: value,
    }));
    dispatch(resetUpdateRegisterEmailErrors());
  }

  // Validate Form
  const validateForm = () => {
    let valid = true;
    let errors = state.errors;
    if (state.email == "") {
      errors.email = "Email ID is required."
      valid = false;
    }
    setState((prevProps) => ({
      ...prevProps,
      errors: errors,
    }))
    return valid;
  }

  // onSubmit Handler
  const onSubmitHandler = async (event) => {
    event.preventDefault();
    if (validateForm()) {
      const data = {
        email: state.email,
      }
      dispatch(updateRegisterEmail(data));
    }
  }

  // Reset the Form on Closing
  const onCancelSubmit = () => {
    setState((prevProps) => ({
      ...prevProps,
      email: "",

      errors: {
        email: "",
      }
    }));
    dispatch(updateRegisterEmail());
    dispatch(setRegisterUpdateEmailModal(false));
  }

  return (
    <>
      <Modal size="md" className="us-modal__dialog" isOpen={setShowRegisterUpdateEmailModal}>
        <ModalBody className="us-modal__body">
          <h5 className="us-section__title-block text-center mb-10">Update Email</h5>
          <Form onSubmit={(e) => onSubmitHandler(e)}>
            <FormGroup className="us-form__wrap">
              <Label htmlFor="email" className="us-form__label">Email ID <span className="text-danger">*</span></Label>
              <Input
                type="email"
                id="email"
                name="email"
                placeholder="Enter Email ID"
                className="us-form__input"
                value={state.email}
                onChange={(e) => onChangeHandler(e)}
              />
              <div className="us-form__error">{state.errors.email}</div>
            </FormGroup>

            <div className="d-flex align-items-center justify-content-center mt-10">
              <Button onClick={(e) => onCancelSubmit(e)} type="button" color="danger" outline className="us-button us-button__danger-outline text-uppercase px-8">Cancel</Button>
              <Button
                type="submit"
                color="primary"
                disabled={updateRegisterEmailLoading}
                className={`us-button us-button__primary text-uppercase px-10 ms-4 ${updateRegisterEmailLoading ? "us-button__disabled" : ""}`}
              >
                {updateRegisterEmailLoading ? "Updating..." : "Update"}
              </Button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </>
  )
}
export default UpdateEmailModal;