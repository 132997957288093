import React, { useState } from "react";
import PageTitle from "../../components/global/common/pageTitle";
import Layout from "../../layout";
import { linkedInAuth } from "../../utils/socialLogin";
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Row,
} from "reactstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { loginUser, resetLoginErrorMessages, registerUserStep } from '../../redux/reduxes/global/auth/actions';
import { useEffect } from 'react';

const Login = () => {
  const dispatch = useDispatch();

  // Fetch Data
  const loginUserLoading = useSelector(state => state.auth.loginUserLoading);
  const loginUserError = useSelector(state => state.auth.loginUserError);

  // States
  const [state, setState] = useState({
    email: "",
    password: "",

    errors: {
      email: "",
      password: "",
    },
  });

  // Reset Error Message
  useEffect(() => {
    dispatch(resetLoginErrorMessages())
  }, [])

  // onChange Handler
  const onChangeHandler = (event) => {
    event.preventDefault();
    const validEmailRegex = RegExp(
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    );
    const { name, value } = event.target;
    let errors = state.errors;
    switch (name) {
      case "email":
        errors.email = validEmailRegex.test(value) ? "" : "Enter a valid email ID!";
        break;
      case "password":
        errors.password = value.length < 8  ? "Password must be at least 8 characters long!" : "";
        break;
      default:
        break;
    }
    setState((prevProps) => ({
      ...prevProps,
      errors,
      [name]: value,
    }));
    dispatch(resetLoginErrorMessages())
  }

  // Validate Form
  const validateForm = (errors) => {
    let valid = true;
    Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
    if (state.email == "" || state.password == "") valid = false;
    return valid;
  };

  // onSubmit Handler
  const onSubmitHandler = async (event) => {
    event.preventDefault();
    if (validateForm(state.errors)) {
      const data = {
        email: state.email,
        password: state.password,
      }
      dispatch(loginUser(data));
    } else {
      let errors = state.errors;
      if (state.email == "") {
        errors.email = "Enter an Email ID";
      }
      if (state.password == "") {
        errors.password = "Enter Password";
      }
      setState((prevProps) => ({
        ...prevProps,
        errors: errors,
      }));
    }
  };

  // Show/Hide Password
  const [showPassword, setShowPassword] = useState(false);
  const passwordToggler = () => {
    setShowPassword(!showPassword);
  };
  // Set to Register Step On Register Link
  const onRegisterClick = () => {
    dispatch(registerUserStep(0));
    window.location.href = "/register";
  }


  return (
    <>
      <PageTitle pageTitle="Login" />

      <Layout>
        {/* Register Section */}
        <section className="us-section us-section__auth"  style={{ background: "url('/assets/images/auth/auth-bg.png') no-repeat 80% 10%" }}>
          <div className="us-section__inwrap pt-md-20">
            <Container>
              <Row>
                <Col md="6" xl="6" className="my-auto">
                  <div className="us-auth__left d-none d-md-block">
                    <div className="us-auth__left-imgwrap">
                      <img  src="/assets/images/auth/auth-banner.png" alt="auth-banner" className="img-fluid"/>
                    </div>
                  </div>
                </Col>

                <Col md="6" xl="5">
                  <div className="us-auth__right px-6 py-8 px-lg-10 py-lg-10">
                    <Form onSubmit={(e) => onSubmitHandler(e)}>
                      <h1 className="us-section__title mb-6 text-center">  Sign in  </h1>

                      <FormGroup className="us-form__wrap pb-2">
                        <Button
                          color="primary"
                          className="us-button us-button__primary us-button__primary-linkedin d-flex algn-items-center justify-content-center w-100"
                          onClick={linkedInAuth}
                        >
                          <img  src="/assets/icons/icon-linkedin.svg" alt="icon-linkedin" className="me-3"/>
                          <span className="text-uppercase mt-1"> Sign In with LinkedIn</span>
                        </Button>
                      </FormGroup>

                      <div className="us-auth__other d-flex justify-content-center position-relative"> or</div>

                      <FormGroup className="us-form__wrap pt-5">
                        <Label htmlFor="email" className="us-form__label"> Email</Label>
                        <Input
                          type="email"
                          name="email"
                          id="email"
                          placeholder="Email ID"
                          className={`us-form__input ${state.errors.email ? "us-form__input-error" : ""}`}
                          value={state.email}
                          onChange={(e) => onChangeHandler(e)}
                        />
                        <div className="us-form__error"> {state.errors.email} </div>
                      </FormGroup>

                      <FormGroup className="us-form__wrap">
                        <Label htmlFor="password" className="us-form__label"> Password</Label>
                        <div className="us-form__input-group position-relative d-flex align-items-center">
                          <Input
                            type={showPassword ? "text" : "password"}
                            name="password"
                            id="password"
                            placeholder="Password"
                            className={`us-form__input ${state.errors.password ? "us-form__input-error" : "" }`}
                            value={state.password}
                            onChange={(e) => onChangeHandler(e)}
                          />
                          <Button type="button" onClick={(e) => passwordToggler(e)} className="us-form__input-button position-absolute">
                            {showPassword ?
                              <img src="/assets/icons/icon-eye-show.svg" alt="icon-eye-show" className="img-fluid" />
                            :
                              <img src="/assets/icons/icon-eye-hide.svg" alt="icon-eye-hide" className="img-fluid"/>
                            }
                          </Button>
                        </div>
                        <div className="us-form__error">{state.errors.password}</div>
                        {loginUserError && <div className="us-form__error">{loginUserError}</div>}
                      </FormGroup>

                      {/* <FormGroup className='us-form__wrap'>
                        <Label htmlFor='remember_me' className='us-form__label us-form__custom-register--label d-flex align-items-center'>
                        <div className="us-form__error">
                          {state.errors.password}
                        </div>
                      </FormGroup>

                      <FormGroup className="us-form__wrap">
                        <Label
                          htmlFor="remember_me"
                          className="us-form__label us-form__custom-register--label d-flex align-items-center"
                        >
                          <Input
                            type="checkbox"
                            id="remember_me"
                            name="remember_me"
                            className="us-form__custom-register--checkbox"
                          />
                          <span className="ps-2 mt-2 text-xs font-normal">
                            Remember me
                          </span>
                        </Label>
                      </FormGroup> */}

                      <Button
                        type="submit"
                        color="primary"
                        disabled={loginUserLoading}
                        className={`us-button us-button__primary d-block text-uppercase w-100 mt-8 ${loginUserLoading ? "us-button__disabled" : "us-button__primary"}`}
                      >
                        {loginUserLoading ? "Loading..." : "SIGN IN"}
                      </Button>

                      <Link to="/reset-password" exact className="text-sm mt-5 us-button__link underline p-0">
                        Forgot your password?
                      </Link>
                    </Form>
                  </div>
                  <div className="us-para head-font d-flex  mt-7  justify-content-center">
                    <span className="d-none d-lg-block">Don't have an account?</span>
                    {/* <Link to="/register" exact className="us-button__link text-primary text-sm font-semibold ms-3">Create an account</Link> */}
                    <Button onClick={() => onRegisterClick()} type="button" color="link" className="us-button__link text-primary text-sm font-semibold ms-3 p-0" style={{ textDecoration: "none" }}>Create an account</Button>
                  </div>
                </Col>

              </Row>
            </Container>

          </div>
        </section>
      </Layout>
    </>
  );
};
export default Login;
