import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import AddTestScenerio from "./addTestScenerio";
import AddResearchExpectation from "./addResearchExpectation";
import { updateEvelutionMaterialStepThree, getEvaluationStepThreeDetails, setEvaluationMaterialActiveStep, setEvaluationMaterialModal } from "../../../../redux/reduxes/customer/evalutionMaterial/projectAction";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const EvaluationMaterialStepThree = ({ projectId, update }) => {
  const dispatch = useDispatch();
  // const search = useLocation().search;
  // const project = new URLSearchParams(search).get('project');

  // Fetch Data
  const createEvaluationStepThreeLoading = useSelector(state => state.evelutionMaterials.updateEvaluationStepThreeLoading);
  const details = useSelector(state => state.evelutionMaterials.evaluationStepThreeDetails);
 const evaluationId = useSelector(state => state.evelutionMaterials.evaluationId);

  // States
  const [state, setState] = useState({
    testScenarios: [],
    testScenarioNeedHelp: false,
    researchExpectations: [],
    researchExpectationNeedHelp: false,

    errors: {
      testScenarios: "",
      researchExpectations: "",
    }
  });
  // =============== TEST SCENERIOS ======================= //
  // Add Multiple Test Scenerios Inputs
  const [allTestScenarios, setAllTestScenarios] = useState([{ title: "", titleError: "" }]);
  const handleAddTestScenerios = () => {
    const newTestScenario = { title: "" };
    setAllTestScenarios([...allTestScenarios, newTestScenario]);
  }

  // ==================== RESEARCH EXPECTATIONS ========================== //
  // Add Multiple Research Expectations Inputs
  const [allResearchExpectations, setAllResearchExpectations] = useState([{ title: "", titleError: "" }]);
  const handleAddResearchExpectations = () => {
    const newResearchExpectations = { title: "" };
    setAllResearchExpectations([...allResearchExpectations, newResearchExpectations]);
  };

  // Get Details
  useEffect(() => {
    details && details.evaluationMaterial && details.evaluationMaterial.testScenarios && setState((prevProps) => ({
      ...prevProps,
      testScenarios: details.evaluationMaterial.testScenarios,
      testScenarioNeedHelp: details.evaluationMaterial.testScenarioNeedHelp,
      researchExpectations: details.evaluationMaterial.researchExpectations,
      researchExpectationNeedHelp: details.evaluationMaterial.researchExpectationNeedHelp,
    }))
    let researchExpectations = [];
    details && details.evaluationMaterial && details.evaluationMaterial.researchExpectations.forEach(data => {
      researchExpectations.push({ title: data, titleError: "" })
    })
    setAllResearchExpectations([...allTestScenarios, ...researchExpectations])

    let testScenarios = [];
    details && details.evaluationMaterial && details.evaluationMaterial.testScenarios.forEach(data => {
      testScenarios.push({ title: data, titleError: "" })
    })
    setAllTestScenarios([...allTestScenarios, ...testScenarios])
  }, [details]);

  // Dispatch Details
  useEffect(() => {
    const data = {
      evaluationId: evaluationId,
      projectId: projectId
    }
    dispatch(getEvaluationStepThreeDetails(data));
  }, [])




  // onChange Test Scenrios
  const handleTestScenarioChange = (value, indexNumber) => {
    const updatingAllTestScenarios = allTestScenarios.map((items, index) => {
      if (indexNumber == index) {
        return { ...items, "title": value, titleError: value === "" ? "Specify Test Scenario" : "" };
      }
      return items;
    });
    setAllTestScenarios(updatingAllTestScenarios);
  }

  // Remove the Test Scenerio Input
  const handleRemoveTestScenario = (index) => {
    const filteredTestScenarios = [...allTestScenarios];
    filteredTestScenarios.splice(index, 1);
    setAllTestScenarios(filteredTestScenarios);
  }

  // onChange Test Scenario Need Help Handler
  const onTestScenarioHelpHandler = (event) => {
    setState((prevProps) => ({
      ...prevProps,
      testScenarioNeedHelp: event.target.checked,
    }));
  }


  // onChange Research Expectations
  const handleResearchExpectationsChange = (value, indexNumber) => {
    const updatingResearchExpectations = allResearchExpectations.map((items, index) => {
      if (indexNumber == index) {
        return { ...items, "title": value, titleError: value === "" ? "Specify Expectations from this Research" : "" };
      }
      return items;
    });
    setAllResearchExpectations(updatingResearchExpectations);
  }

  // Remove the Research Expectation Input
  const handleRemoveResearchExpectations = (index) => {
    const filteredResearchExpectations = [...allResearchExpectations];
    filteredResearchExpectations.splice(index, 1);
    setAllResearchExpectations(filteredResearchExpectations);
  }

  // onChange Research Expectation Need Help Handler
  const onResearchExpectationsHelpHandler = (event) => {
    console.log("trucker Expectations",event.target.checked)
    setState((prevProps) => ({
      ...prevProps,
      researchExpectationNeedHelp: event.target.checked,
    }));
  }


  // Validate Form
  const validateForm = () => {
    let valid = true;
    let errors = state.errors;
    // if (state.testScenarios == "") {
    //   errors.testScenarios = "Specify atleast one Test Scenario.";
    //   valid = false;
    // }
    // if (state.researchExpectations == "") {
    //   errors.researchExpectations = "Specify atleast one Research Expectations.";
    //   valid = false;
    // }

    const testScenariosErrors = allTestScenarios;
    testScenariosErrors.forEach(item => {
      // if (item.title === ""&&state.testScenarioNeedHelp!==true) {
      //   item.titleError = "Test Scenario description is required";
      //   valid = false;
      // }
    });
    setAllTestScenarios([...testScenariosErrors]);

    const researchErrors = allResearchExpectations;
    researchErrors.forEach(item => {
      // if (item.title === ""&&state.researchExpectationNeedHelp!==true) {
      //   item.titleError = "Research Expectation description is required";
      //   valid = false;
      // }
    });
    setAllResearchExpectations([...researchErrors]);

    setState((prevProps) => ({
      ...prevProps,
      errors,
    }))
    return valid;
  }

  // onSubmit Handler
  const onSubmitHandler = async (event) => {
    event.preventDefault();
    // Remove errors states from the list
    const filterTestScenarios = [];
    allTestScenarios && allTestScenarios.forEach((items) => {
      items.title !== "" && filterTestScenarios.push(items.title);
    });

    // Remove errors states from the list
    const filterResearchExpectations = [];
    allResearchExpectations && allResearchExpectations.forEach((items) => {
      items.title !== "" && filterResearchExpectations.push(items.title);
    });

    if (validateForm()) {
      const data = {
        projectId: projectId,
        evaluationId: evaluationId,
        data: {
          testScenarios: state.testScenarioNeedHelp?[]:filterTestScenarios,
          testScenarioNeedHelp: state.testScenarioNeedHelp,
          researchExpectations: state.researchExpectationNeedHelp ? [] :filterResearchExpectations,
          researchExpectationNeedHelp: state.researchExpectationNeedHelp,
        }
      }
      dispatch(updateEvelutionMaterialStepThree(data));
      // dispatch(setEvaluationMaterialModal(false));
    }
  }
  return (
    <>
      <Form onSubmit={(e) => onSubmitHandler(e)}>
        {/* =========== SPECIFY TEST SCENARIO (IF ANY) ============= */}
        <div className="mb-12">
          <FormGroup className="us-form__wrap mb-4">
            <Label htmlFor="testScenarios" className="us-form__label mb-1">Specify test scenario (if any)</Label>
            <p className="us-para color-gray3 mb-4">Based on your product type and requirement, we implement the research methods</p>
            {!state.testScenarioNeedHelp &&
              <>
            <div className={`${allTestScenarios && allTestScenarios.length > 3 && "us-form__input-lists"}`}>
              {allTestScenarios && allTestScenarios.length > 0 && allTestScenarios.map((scenario, index) => {
                return (
                  <AddTestScenerio
                    allTestScenarios={allTestScenarios}
                    key={index}
                    indexNumber={index}
                    value={scenario}
                    handleTestScenarioChange={handleTestScenarioChange}
                    handleRemoveTestScenario={handleRemoveTestScenario}
                    titleError={scenario.titleError}
                  />
                )
              })}
            </div>
              <div className="us-form__error">{state.errors.testScenarios}</div>
              </>
            }
          </FormGroup>

          <div className="d-flex flex-column flex-md-row align-items-start justify-content-between mt-6">
            <FormGroup className="mb-0">
              <Label htmlFor="testScenarioNeedHelp" className="d-flex align-items-center">
                <span className="me-3">Not sure, need help</span>
                <Input
                  type="checkbox"
                  id="testScenarioNeedHelp"
                  name="testScenarioNeedHelp"
                  className="us-form__input px-3"
                  value={state.testScenarioNeedHelp}
                  onChange={(e) => onTestScenarioHelpHandler(e)}
                  checked={state.testScenarioNeedHelp}
                />
              </Label>
            </FormGroup>
            {!state.testScenarioNeedHelp && <Button onClick={(e) => handleAddTestScenerios(e)} type="button" color="link" className=" us-button__link text-sm text-primary body-font font-medium p-0 ms-auto">Add multiple scenarios +</Button>}
          </div>
        </div>

        {/* ========== EXPECTATIONS FROM THIS RESEARCH (IF ANY) =========== */}
        <div>
          <FormGroup className="us-form__wrap">
            <Label htmlFor="research_expectations" className="us-form__label mb-1">Expectations from this research (if any)</Label>
            <p className="us-para color-gray3 mb-4">Based on your product type and requirement, we implement the research methods</p>
            {!state.researchExpectationNeedHelp &&
              <>
                <div className={`${allResearchExpectations && allResearchExpectations.length > 3 && "us-form__input-lists"}`}>
                  {allResearchExpectations && allResearchExpectations.length > 0 && allResearchExpectations.map((research, index) => {
                    return (
                      <AddResearchExpectation
                        allResearchExpectations={allResearchExpectations}
                        key={index}
                        indexNumber={index}
                        value={research}
                        handleResearchExpectationsChange={handleResearchExpectationsChange}
                        handleRemoveResearchExpectations={handleRemoveResearchExpectations}
                        titleError={research.titleError}
                      />
                    )
                  })}
                  <div className="us-form__error">{state.errors.researchExpectations}</div>
                </div>
              </>
            }
          </FormGroup>

          <div className="d-flex flex-column flex-md-row align-items-start justify-content-between mt-6">
            <FormGroup className="mb-0">
              <Label htmlFor="researchExpectationNeedHelp" className="d-flex align-items-center">
                <span className="me-3">Not sure, need help</span>
                <Input
                  type="checkbox"
                  id="researchExpectationNeedHelp"
                  name="researchExpectationNeedHelp"
                  className="us-form__input px-3"
                  value={state.researchExpectationNeedHelp}
                  onChange={(e) => onResearchExpectationsHelpHandler(e)}
                  checked={state.researchExpectationNeedHelp}
                />
              </Label>

            </FormGroup>
            {!state.researchExpectationNeedHelp && <Button onClick={(e) => handleAddResearchExpectations(e)} type="button" color="link" className=" us-button__link text-sm text-primary body-font font-medium p-0 ms-auto">Add multiple expectations +</Button>}
          </div>
        </div>


        <div className="d-flex align-items-center justify-content-md-end mt-10 mt-md-20">
          <Button onClick={() => dispatch(setEvaluationMaterialActiveStep(1))} type="button" color="primary" outline className="us-button us-button__primary-outline px-8 me-5 text-uppercase">Previous</Button>
          <Button
            type="submit"
            color="primary"
            disabled={createEvaluationStepThreeLoading}
            className={`us-button us-button__primary text-uppercase ${createEvaluationStepThreeLoading ? "us-button__disabled px-10" : "us-button__primary px-12"}`}
          >
            {createEvaluationStepThreeLoading ? "Saving..." : "Done"}
          </Button>
        </div>
      </Form>
    </>
  )
}
export default EvaluationMaterialStepThree;