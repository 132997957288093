import React from 'react'
import { Helmet } from 'react-helmet';

const PageTitle = ({pageTitle}) => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{pageTitle}</title>
      </Helmet>
    </>
  )
}
export default PageTitle;
