import React, { useEffect, useParams } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
// import { getEvaluationStepOneDetails } from "../../../../redux/reduxes/customer/projects/projectActions";
import { createEvaluationStepOne, updateEvelutionMaterialStepOne, setEvelutionMaterialModal , getEvaluationStepOneDetails } from "../../../../redux/reduxes/customer/evalutionMaterial/projectAction";
import { getAllProjectCategories, getAllProjectMultilingual } from "../../../../redux/reduxes/customer/general/actions";
import { getProjectDetails } from "../../../../redux/reduxes/customer/projects-new/projectAction";
import { useLocation } from "react-router-dom";
import Select from "react-select";

// Evaluation Material Checkbox
const apps = [
  { id: 'Websites', title: 'Websites' },
  { id: 'Web Applications', title: 'Web Applications' },
  { id: 'Mobile', title: 'Mobile' },
  { id: 'Wearables', title: 'Wearables' },
  { id: 'Desktop Applications', title: 'Desktop Applications' },
  { id: 'IOT', title: 'IOT' },
  { id: 'Smart Device Applications', title: 'Smart Device Applications' },
  { id: 'Virtual Reality (VR)', title: 'Virtual Reality (VR)' },
  { id: 'Augmented Reality (AR)', title: 'Augmented Reality (AR)' },
  { id: 'Dashboard and Analytics', title: 'Dashboard and Analytics' },
  { id: 'Point of Sale (POS)', title: 'Point of Sales (POS)' },
]

// App Testing
const testing = [
  { id: 'visual_impairments', title: 'Visual Impairments' },
  { id: 'hearing_impairments', title: 'Hearing Impairments' },
  { id: 'motor_or_mobility_impairments', title: 'Motor or Mobility Impairments' },
  { id: 'cognitive_disabilities', title: 'Cognitive Disabilities' },
  { id: 'speech_disorders', title: 'Speech Disorders' },
  { id: 'neurological_disorders', title: 'Neurological Disorders' },
  { id: 'age_related_disabilities', title: 'Age-Related Disabilities' },
]


const EvaluationMaterialStepOne = ({ projectActiveTab, evaluationMaterialActiveStep, update, setUpdate, evaluationId }) => {
  const dispatch = useDispatch();
  // const { realUserType } = useParams();

  const search = useLocation().search;
  // const project = new URLSearchParams(search).get('project');
  // console.log(project, "huhhdhuhuhuhduhuhuhduhuhuhd");

  // Fetch Data
  const createEvaluationStepOneLoading = useSelector(
    (state) => state.evelutionMaterials.createEvaluationStepOneLoading
  );
  const allProjectCategories = useSelector(
    (state) => state.general.allProjectCategories
  );
  const allProjectMultilingual = useSelector(
    (state) => state.general.allProjectMultilingual
  );
  const details = useSelector(
    (state) => state.projectNew.projectDetails.project
  );
  const projectId = details._id;
  const evaluationStepOneDetails = useSelector(
    (state) => state.evelutionMaterials.evaluationStepOneDetails
  );
  const stetEvaluationId = useSelector(
    (state) => state.evelutionMaterials.openedEvelutionMaterialId
  );
  // console.log(evaluationStepOneDetails, "evaluationStepOneDetails");
  const mode = stetEvaluationId != "" ? true : false;
  // Get categories & multilingual
  useEffect(() => {
    // if (projectActiveTab == 1) {
    const multyLingualData = {
      paginate: false,
    };
    const categoriesData = {
      paginate: false,
    };
    dispatch(getAllProjectCategories(categoriesData));
    dispatch(getAllProjectMultilingual(multyLingualData));
    const data = {
      evaluationId: evaluationId,
      projectId: projectId,
    };
    dispatch(getEvaluationStepOneDetails(data));
    // }
  }, []);

  // States
  const [state, setState] = useState({
    appName: "",
    appType: "",
    categoryIds: [],
    multilingualIds: [],
    accessibilityTesting: [],

    errors: {
      appName: "",
      appType: "",
      categoryIds: "",
      multilingualIds: "",
    },
  });

  // Get Details
  useEffect(() => {
    // let categoriesList = [];
    // let multilingualList = [];
    // details && details.categories && details.categories.forEach((items) => {
    //   categoriesList.push(items);
    //   console.log(items, "ehvekjer");
    // });
    // details && details.multilinguals && details.multilinguals.forEach((items) => {
    //   multilingualList.push(items);
    // });
    console.log(details, "the details", evaluationStepOneDetails);
    let categoryIds = [];
    let languageIds = [];
    evaluationStepOneDetails &&
      evaluationStepOneDetails.categories &&
      evaluationStepOneDetails.categories.length > 0 &&
      evaluationStepOneDetails.categories.forEach((data) => {
        categoryIds.push(data._id);
      });
    evaluationStepOneDetails &&
      evaluationStepOneDetails.multilinguals &&
      evaluationStepOneDetails.multilinguals.length > 0 &&
      evaluationStepOneDetails.multilinguals.forEach((data) => {
        languageIds.push(data._id);
      });

    evaluationStepOneDetails &&
      evaluationStepOneDetails.appName &&
      setState((prevProps) => ({
        ...prevProps,
        id: evaluationStepOneDetails && evaluationStepOneDetails._id,
        appName: evaluationStepOneDetails && evaluationStepOneDetails.appName,
        appType: evaluationStepOneDetails && evaluationStepOneDetails.appType,
        // categoryIds: categoryIds,
        // selectedCategoryOptions: categoryIds,
        selectedLanguageOptions:
          evaluationStepOneDetails && evaluationStepOneDetails.multilinguals,
        accessibilityTesting:
          evaluationStepOneDetails &&
          evaluationStepOneDetails.accessibilityTesting,
      }));
    setSelectedCategoryOptions(categoryIds);
    setSelectedLanguageOptions(languageIds);
  }, [details, evaluationStepOneDetails]);

  // console.log(state.categoryIds, "hjhhjkjk");
  // console.log(state.selectedCategoryOptions, "uhushdtdkszixbhdbusnuj");

  // On Change Handler
  const onChangeHandler = (event) => {
    const { value, name } = event.target;
    let errors = state.errors;
    errors.appName = "";
    errors.appType = "";

    setState((prevProps) => ({
      ...prevProps,
      errors,
      [name]: value,
    }));
  };

  // Store All Categories Locally
  const [storeAllCategories, setStoreAllCategories] = useState([]);
  useEffect(() => {
    if (allProjectCategories && allProjectCategories.length != 0) {
      setStoreAllCategories(allProjectCategories);
    }
  }, [allProjectCategories]);

  // On Category Change Handler & Add to List
  const [selectedCategoryOptions, setSelectedCategoryOptions] = useState([]);
  const onCategoryChangeHandler = (event) => {
    // console.log("text : ", event.target.options[event.target.selectedIndex].text, "id :" + event.target.value,"verjverkvre");
    const { value, name } = event.target;
    let errors = state.errors;
    errors.categoryIds = value == "" ? "Select a Industry Category" : "";
    // Get the selected value

    if (!selectedCategoryOptions.includes(value)) {
      setSelectedCategoryOptions([...selectedCategoryOptions, value]);
      const filteredCategories = storeAllCategories.filter(
        (item) => item._id != value
      );
      setStoreAllCategories(filteredCategories);
    }

    setState((prevProps) => ({
      ...prevProps,
      [name]: value,
    }));
  };

  // Get the Category Title
  function getTheCategoryTitle(id) {
    const currentCategory =
      allProjectCategories &&
      allProjectCategories.filter((item) => item._id == id);
    return currentCategory[0].title;
  }

  // Delete the Category from List
  const removeSelectedCategoryOption = (id) => {
    setSelectedCategoryOptions(
      selectedCategoryOptions.filter((option) => option != id)
    );
    const filteredCategories =
      allProjectCategories &&
      allProjectCategories.filter((item) => item._id == id);
    setStoreAllCategories([...storeAllCategories, filteredCategories[0]]);
  };

  // Store All Multilingual Locally
  const [storeAllMultilingual, setStoreAllMultilingual] = useState([]);
  useEffect(() => {
    if (allProjectMultilingual && allProjectMultilingual.length != 0) {
      setStoreAllMultilingual(allProjectMultilingual);
    }
  }, [allProjectMultilingual]);

  // On Multlingual Change Handler & Add to List
  const [selectedLanguageOptions, setSelectedLanguageOptions] = useState([]);
  const onMultilingualChangeHandler = (event) => {
    const { value, name } = event.target;
    let errors = state.errors;
    errors.multilingualIds = value == "" ? "Select a Product Language" : "";

    // Get the Selected value
    if (!selectedLanguageOptions.includes(value)) {
      setSelectedLanguageOptions([...selectedLanguageOptions, value]);
      const filteredMultilingual = storeAllMultilingual.filter(
        (item) => item._id != value
      );
      setStoreAllMultilingual(filteredMultilingual);
    }

    setState((prevProps) => ({
      ...prevProps,
      [name]: value,
    }));
  };

  // Get the Language Title
  function getTheLanguageTitle(id) {
    const currentLanguage =
      allProjectMultilingual &&
      allProjectMultilingual.filter((item) => item._id == id);
    return currentLanguage[0].language;
  }

  // Delete the Language from List
  const removeSelectedLanguageOption = (id) => {
    setSelectedLanguageOptions(
      selectedLanguageOptions.filter((option) => option != id)
    );
    const filteredMultilingual =
      allProjectMultilingual &&
      allProjectMultilingual.filter((item) => item._id == id);
    setStoreAllMultilingual([...storeAllMultilingual, filteredMultilingual[0]]);
  };

  // show/Hide Accessibility Testing
  const [showAccessibilityTesting, setShowAccessibilityTesting] =
    useState(false);
  const toggleAccessibilityTesting = (event) => {
    if (event.target.checked) {
      setShowAccessibilityTesting(true);
    } else {
      setShowAccessibilityTesting(false);
    }
  };

  // On Accessiblity Change Handler
  const onAccessibilityChangeHandler = (event) => {
    let errors = state.errors;
    if (event.target.checked) {
      setState((prevProps) => ({
        ...prevProps,
        accessibilityTesting: [
          ...state.accessibilityTesting,
          event.target.value,
        ],
      }));
    } else {
      setState((prevProps) => ({
        ...prevProps,
        accessibilityTesting: state.accessibilityTesting.filter(
          (item) => item != event.target.value
        ),
      }));
    }
    errors.accessibilityTesting = "";
    setState((prevProps) => ({
      ...prevProps,
      errors,
    }));
  };

  // Validate Form
  const validateForm = () => {
    let valid = true;
    let errors = state.errors;
    if (state.appName == "") {
      errors.appName = "Enter App Name";
      valid = false;
    }
    if (state.appType == "") {
      errors.appType = "Select atleast One App Type";
      valid = false;
    }
    if (state.categoryIds.length + selectedCategoryOptions.length <= 0) {
      errors.categoryIds = "Select a Product Category";
      valid = false;
    }
    if (state.multilingualIds.length + selectedLanguageOptions.length <= 0) {
      errors.multilingualIds = "Select a Product Language";
      valid = false;
    }
    setState((prevProps) => ({
      ...prevProps,
      errors: errors,
    }));
    return valid;
  };

  // onSubmit Handler
  const onSubmitHandler = (event) => {
    event.preventDefault();
    if (validateForm()) {
      console.log(projectId, "projectId");
      let data = {
        projectId: projectId,
        evaluationId: evaluationId,
        data: {
          appName: state.appName,
          appType: state.appType,
          categories: selectedCategoryOptions,
          multilinguals: selectedLanguageOptions,
          accessibilityTesting: state.accessibilityTesting,
        },
      };
      if (showAccessibilityTesting) {
        data["accessibilityTesting"] = state.accessibilityTesting;
      }
      // accessibilityTesting: showAccessibilityTesting && state.accessibilityTesting,
      console.log(mode, "the update coming inside the submit");
      if (mode) {
        dispatch(updateEvelutionMaterialStepOne(data));
      } else {
        dispatch(createEvaluationStepOne(data));
      }
    }
  };
  const closePopupHandler = () => {
    dispatch(
      setEvelutionMaterialModal({ show: false, isUpdate: false, id: "" })
    );
    const data = {
      projectId: projectId,
    };
    dispatch(getProjectDetails(data));
  };

  // FIX FOR KEYBOARD INPUT SELECTION

  const handleKeyDown = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <div className="us-custproject__evaluation-stepone position-relative">
        <Form onSubmit={(e) => onSubmitHandler(e)}>
          {/* App Name */}
          <FormGroup className="us-form__wrap mb-12">
            <Label htmlFor="appName" className="us-form__label">
              Give your product/service a name
            </Label>
            <Input
              type="text"
              id="appName"
              name="appName"
              placeholder="Enter App Name"
              className={`us-form__input ${
                state.errors.appName ? "us-form__input-error" : ""
              }`}
              value={state.appName}
              onChange={(e) => onChangeHandler(e)}
            />
            <div className="us-form__error">{state.errors.appName}</div>
          </FormGroup>

          {/* What type of app it is? */}
          <FormGroup className="us-form__wrap mb-12">
            <div className="text-sm font-semibold mb-5 head-font">
              Evaluation material
            </div>
            <div className="w-100 d-flex align-items-start flex-wrap gap-2 gap-md-3">
              {apps &&
                apps.map((app, index) => {
                  const { id, title } = app;
                  return (
                    <>
                      <div
                        className="us-form__wrap position-relative mb-0"
                        key={index}
                      >
                        <Input
                          type="radio"
                          id={id}
                          name="appType"
                          className="us-form__custom-checkbox"
                          value={id}
                          onChange={(e) => onChangeHandler(e)}
                          checked={id === state.appType ? true : false}
                        />
                        <Label
                          htmlFor={id}
                          className="us-form__custom-label us-form__custom-label--radioRightCenter rounded-full"
                        >
                          <div className="us-form__custom-label--title me-7 me-md-10">
                            {title}
                          </div>
                        </Label>
                      </div>
                    </>
                  );
                })}
            </div>
            <div className="us-form__error">{state.errors.appType}</div>
          </FormGroup>

          {/* What industry category does your product belong to? */}
          <FormGroup className="us-form__wrap mb-12">
            <div>
              <Label htmlFor="categoryIds" className="us-form__label">
                What industry category does your product belong to?
              </Label>
              <Input
                type="select"
                id="categoryIds"
                name="categoryIds"
                placeholder="What industry category does your product belong to?"
                className={`us-form__input ${
                  state.errors.categoryIds ? "us-form__input-error" : ""
                }`}
                value={state.categoryIds}
                onChange={(e) => onCategoryChangeHandler(e)}
                onKeyDown={handleKeyDown}
              >
                <option value={state.categoryIds} selected defaultValue>
                  Select Industry Category
                </option>
                {storeAllCategories &&
                  storeAllCategories.length > 0 &&
                  storeAllCategories.map((category) => (
                    <option value={category._id}>{category.title}</option>
                  ))}
              </Input>
              <div className="us-form__error">{state.errors.categoryIds}</div>
            </div>

            {/* Selected Industry Category */}
            {selectedCategoryOptions && selectedCategoryOptions.length > 0 && (
              <ul className="us-form__lists d-flex align-items-start flex-wrap w-100 gap-2 mt-5">
                {selectedCategoryOptions.map((list, index) => {
                  return (
                    <>
                      <li
                        key={index}
                        className="us-form__list w-sm rounded-100 d-flex align-items-center justify-content-between"
                      >
                        <div className="us-form__list-title text-xs">
                          {getTheCategoryTitle(list)}
                        </div>
                        <Button
                          onClick={() => removeSelectedCategoryOption(list)}
                          type="button"
                          color="link"
                          className="us-button__link p-0 ms-3"
                        >
                          <img
                            src="/assets/icons/icon-cancel-red.svg"
                            alt="icon-cancel"
                            width="16"
                            height="16"
                          />
                        </Button>
                      </li>
                    </>
                  );
                })}
              </ul>
            )}
          </FormGroup>

          {/* Is your product multilingual */}
          <FormGroup className="us-form__wrap mb-12">
            <div>
              <Label htmlFor="multilingualIds" className="us-form__label">
                Is your product multilingual? If yes, please select the
                languages your product is available in.
              </Label>
              {/* <Input
                type="checkbox"
                id="is_multilingual"
                name="is_multilingual"
                className="us-form__input ms-3 -mt-1 px-3"
              /> */}
            </div>

            <div>
              <Input
                type="select"
                id="multilingualIds"
                name="multilingualIds"
                placeholder="Is your product multilingual?"
                className={`us-form__input ${
                  state.errors.multilingualIds ? "us-form__input-error" : ""
                }`}
                value={state.multilingualIds}
                onChange={(e) => onMultilingualChangeHandler(e)}
                onKeyDown={handleKeyDown}
              >
                <option value={state.multilingualIds} selected defaultValue>
                  Select Language
                </option>
                {storeAllMultilingual &&
                  storeAllMultilingual.length > 0 &&
                  storeAllMultilingual.map((lang) => (
                    <option className="text-capitalize" value={lang._id}>{lang.language}</option>
                  ))}
              </Input>
              <div className="us-form__error">
                {state.errors.multilingualIds}
              </div>
            </div>

            {/* Selected Product Multilingual */}
            {selectedLanguageOptions && selectedLanguageOptions.length > 0 && (
              <ul className="us-form__lists d-flex align-items-start flex-wrap w-100 gap-2 mt-5">
                {selectedLanguageOptions.map((list, index) => {
                  return (
                    <>
                      <li
                        key={index}
                        className="us-form__list w-sm rounded-100 d-flex align-items-center justify-content-between"
                      >
                        <div className="us-form__list-title text-xs text-capitalize">
                          {getTheLanguageTitle(list)}
                        </div>
                        <Button
                          onClick={() => removeSelectedLanguageOption(list)}
                          type="button"
                          color="link"
                          className="us-button__link p-0 ms-3"
                        >
                          <img
                            src="/assets/icons/icon-cancel-red.svg"
                            alt="icon-cancel"
                            width="16"
                            height="16"
                          />
                        </Button>
                      </li>
                    </>
                  );
                })}
              </ul>
            )}
          </FormGroup>

          {/* Does it require accessibility testing? */}
          <FormGroup className="us-form__wrap mb-12">
            <div className="mb-3">
              <Label
                htmlFor="isAccessibilityTesting"
                className="us-form__label"
              >
                Does it require accessibility testing?
              </Label>
              <Input
                type="checkbox"
                id="isAccessibilityTesting"
                name="isAccessibilityTesting"
                className="us-form__input ms-3 -mt-1 px-3"
                value={showAccessibilityTesting}
                onChange={toggleAccessibilityTesting}
                checked={showAccessibilityTesting ? true : false}
              />
            </div>

            {showAccessibilityTesting && (
              <div className="w-100 d-flex align-items-start flex-wrap gap-2 gap-md-3">
                {testing &&
                  testing.map((test, index) => {
                    const { id, title } = test;
                    return (
                      <>
                        <div
                          className="us-form__wrap position-relative mb-0"
                          key={index}
                        >
                          <Input
                            type="checkbox"
                            id={id}
                            name="project_evaluation"
                            className="us-form__custom-checkbox"
                            value={title}
                            onChange={(e) => onAccessibilityChangeHandler(e)}
                            checked={state.accessibilityTesting.includes(title)}
                          />
                          <Label
                            htmlFor={id}
                            className="us-form__custom-label us-form__custom-label--right rounded-full"
                          >
                            <div className="us-form__custom-label--title me-8">
                              {title}
                            </div>
                          </Label>
                        </div>
                      </>
                    );
                  })}
              </div>
            )}
          </FormGroup>

          <div className="d-flex align-items-center justify-content-md-end mt-10 mt-md-20">
            <Button
              onClick={() => closePopupHandler()}
              type="button"
              color="primary"
              outline
              className="us-button us-button__primary-outline px-8 me-5 text-uppercase"
            >
              Cancel
            </Button>
            <Button
              type="submit"
              color="primary"
              // disabled={createEvaluationStepOneLoading}
              className={`us-button us-button__primary text-uppercase  ${
                createEvaluationStepOneLoading
                  ? "us-button__disabled px-10"
                  : "us-button__primary px-12"
              }`}
            >
              {createEvaluationStepOneLoading ? "Saving..." : "Next"}
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
}
export default EvaluationMaterialStepOne;