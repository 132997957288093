import   { useState, useEffect } from "react";
import OtpInput from "react-otp-input";
import PhoneInput from "react-phone-input-2";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { resendUpdateMobileOTP, resetUpdateMobileNumberErrors, resetUpdateMobileOTPVerificationErrors, setUpdateMobileNumberModal, updateMobileOTPVerification, updateProfileMobileNumber, resetTheMobileUpdateStep,resetMobileNumber} from "../../../redux/reduxes/customer/profile/actions";
import { resetMobileNumberOnCancel, resetEmailOnCancel } from "../../../redux/reduxes/global/auth/actions";
import { isValidPhoneNumber } from 'react-phone-number-input';

const UpdateMobileNumberModal = ({ setShowUpdateMobileNumberModal}) => {
  const dispatch = useDispatch();

  // Fetch Data
  const mobileNumber = useSelector(state => state.profile.mobileNumber);
  const mobileOtpToken = useSelector(state => state.profile.mobileOtpToken);
  const updateMobileNumberLoading = useSelector(state => state.profile.updateMobileNumberLoading);
  const updateMobileNumberError = useSelector(state => state.profile.updateMobileNumberError);
  const updateMobileStep = useSelector(state => state.profile.updateMobileStep);
  const updateMobileOTPVerificationLoading = useSelector(state => state.profile.updateMobileOTPVerificationLoading);
  const updateMobileOTPVerificationError = useSelector(state => state.profile.updateMobileOTPVerificationError);
  const profileDetails = useSelector(state => state.profile.profileDetails);

  // States
  const [state, setState] = useState({
    mobile_number: "",
    mobile_otp: "",

    errors: {
      mobile_number: "",
      mobile_otp: "",
    }
  });

  // Get Mobile Number
  useEffect(() => {
    profileDetails && profileDetails.mobileNumber && setState((prevProps) => ({
      ...prevProps,
      mobile_number: profileDetails.mobileNumber,
    }))
    dispatch(resetUpdateMobileOTPVerificationErrors());
  }, [profileDetails]);

  // Reset Errors
  useEffect(() => {
    let errors = state.errors;
    if (updateMobileNumberError && updateMobileNumberError.length > 0) {
      updateMobileNumberError.forEach(error => {
        switch (error.context.key) {
          case 'mobileNumber':
            errors.mobile_number = error.message;
            break;
          default:
            break;
        }
      })
    } else {
      errors.mobile_number = ""
    }
    setState((prevProps) => ({
      ...prevProps,
      errors,
    }));
  }, [updateMobileNumberError])

  // Reset Mobile OTP Verify Errors
  useEffect(() => {
    dispatch(resetUpdateMobileOTPVerificationErrors());
  }, [])

  // onChangeHandler
  const onUpdateMobileChangeHandler = (value) => {
    let errors = state.errors;
    if (!value) {
      errors.mobile_number = "Mobile Number is Required";
    } else if (!isValidPhoneNumber(`+${value}`)) {
      errors.mobile_number = "Enter a valid mobile number, must be 10 digits";
    } else {
      errors.mobile_number = "";
    }
    setState((prevProps) => ({
      ...prevProps,
      errors,
      mobile_number: value,
    }));
    dispatch(resetUpdateMobileNumberErrors());
  }

  // On Update Mobile OTP
  const onUpdateMobileOTPHandler = (event) => {
    let errors = state.errors;
    errors.mobile_number = "";
    setState((prevProps) => ({
      ...prevProps,
      errors,
      mobile_otp: event,
    }));
    dispatch(resetUpdateMobileOTPVerificationErrors());
  }

  // Validate Form
  const validateUpdateMobileForm = () => {
    let valid = true;
    let errors = state.errors;
    if (!state.mobile_number) {
      errors.mobile_number = "Mobile Number is required.";
      valid = false;
    } else if ((state.mobile_number || "").replace(/\ /g, "").length < 12) {
      errors.mobile_number = "Enter a valid mobile number, must be 10 digits";
      valid = false;
    } else {
      errors.mobile_number = "";
    }
    setState((prevProps) => ({
      ...prevProps,
      errors: errors,
    }));
    return valid;
  }

  // Validate Update Mobile OTP
  const validateUpdateMobileOTPForm = () => {
    let valid = true;
    let errors = state.errors;
    if (state.mobile_otp.length < 6) {
      errors.mobile_otp = "Invalid OTP";
      valid = false;
    }
    setState((prevProps) => ({
      ...prevProps,
      errors: errors,
    }));
    return valid;
  }

  // onSubmit Handler
  const onMobileSubmitHandler = async (event) => {
    event.preventDefault();
    if (validateUpdateMobileForm()) {
      const data = {
        mobileNumber: state.mobile_number,
      }
      dispatch(updateProfileMobileNumber(data));
    }
  }

  // OTP Expiration Time ====== MOBILE NUMBER
  const [timeRemaining, setTimeRemaining] = useState(120); // Time remaining in seconds
  const [isRunning, setIsRunning] = useState(true) // Whether the countdown is running or paused

  // Decrement Time remaining by every 1 second  ====== MOBILE NUMBER
  useEffect(() => {
    let interval;
    if (isRunning && timeRemaining > 0) {
      interval = setInterval(() => {
        setTimeRemaining(prevTime => prevTime - 1)
      }, 1000)
    }
    return () => clearInterval(interval);
  }, [isRunning, timeRemaining]);

  // Reset the Timer to 2 Minutes ====== MOBILE NUMBER
  const handleMobileResetTime = () => {
    setIsRunning(true);
    setTimeRemaining(120);
  }

  // Format the Time remaining as MM:SS ====== MOBILE NUMBER
  const minutes = Math.floor(timeRemaining / 60).toString().padStart(2, '0');
  const seconds = (timeRemaining % 60).toString().padStart(2, '0');
  const timeString = `${minutes}:${seconds}`;

  // Resend Email OTP Confirmation
  const confirmResendMobileOTP = (event) => {
    event.preventDefault();
    const data = {
      email: state.email,
    }
    dispatch(resendUpdateMobileOTP(data)); 
    handleMobileResetTime();
  }


  //  on Verify OTP Submit Handler
  const onVerifyOTPSubmitHandler = async (event) => {
    event.preventDefault();
    if (validateUpdateMobileOTPForm()) {
      const data = {
        mobileNumber: state.mobile_number,
        mobileOtp: state.mobile_otp,
        mobileOtpToken: mobileOtpToken,
        mobileUpdate:true,
      }
      dispatch(updateMobileOTPVerification(data));
    }
  }

  // onCancelEditing
  const onMobileCancelEditing = () => {
    setState((prevProps) => ({
      ...prevProps,
      mobile_number: "",

      errors: {
        mobile_number: "",
      }
    }));
    clearState();
    // dispatch(updateProfileMobileNumber());
    // profileDetails && profileDetails.mobileNumber && dispatch(resetMobileNumberOnCancel(profileDetails.mobileNumber));
    dispatch(setUpdateMobileNumberModal(false));
  }

  // On OTP Cancel Editing
  const onMobileOTPVerifyCancelEditing = () => {
    setState((prevProps) => ({
      ...prevProps,
      mobile_otp: "",

      errors: {
        mobile_otp:"",
      }
    }));
    clearState();
    // dispatch(updateMobileOTPVerification());
    // profileDetails && profileDetails.mobileNumber && dispatch(resetMobileNumberOnCancel(profileDetails.mobileNumber));
    dispatch(setUpdateMobileNumberModal(false));
    dispatch(resetTheMobileUpdateStep());
    dispatch(resetMobileNumber());
  }

  const clearState = () => {
    setState((prevProps) => ({
      ...prevProps,
      mobile_number: "",
      mobile_otp: "",

      errors: {
        mobile_number: "",
        mobile_otp: "",
      }
    }));
  }
  return (
    <>
      <Modal size="md" isOpen={setShowUpdateMobileNumberModal} className="us-modal__dialog">
        <ModalBody className="us-modal__body">
          <h4 className="us-section__title-mini text-center mb-8 mb-md-12">Update Mobile Number</h4>
          {updateMobileStep === 0 &&
             <Form onSubmit={(e) => onMobileSubmitHandler(e)}>
             <Row>
               <Col sm="12">
                 <FormGroup className="us-form__wrap">
                   <Label htmlFor="email" className="us-form__label">Mobile Number <span className="text-danger">*</span></Label>
                   <PhoneInput
                     id="mobile_number"
                     name="mobile_number"
                     country={"in"}
                     value={state.mobile_number}
                     specialLabel={""}
                     onChange={(e) => onUpdateMobileChangeHandler(e)}
                     className={`us-form__input us-form__input--country overflow-unset d-flex align-items-center w-100 ${state.errors.mobile_number ? "us-form__input-error" : ""   }`}
                   />
                   <div className="us-form__error">{state.errors.mobile_number}</div>
                 </FormGroup>
               </Col>
             </Row>

             <div className="d-flex align-items-center justify-content-center mt-10">
               <Button onClick={(e) => onMobileCancelEditing(e)} type="button" color="danger" outline className="us-button us-button__danger-outline py-3 px-8 me-4">Cancel</Button>
                <Button
                  type="submit"
                  color="primary"
                  disabled={updateMobileNumberLoading || state.mobile_number == mobileNumber}
                  className={`us-button us-button__primary py-3 px-8 ${updateMobileNumberLoading ? "us-button__disabled" : "us-button__primary"}`}
                >
                  {updateMobileNumberLoading ? "Updating..." : "Update"}
                </Button>
             </div>
           </Form>

            // <Form onSubmit={(e) => onVerifyOTPSubmitHandler(e)}>
            //   <Row>
            //     <Col sm="12">
            //       <FormGroup className="us-form__wrap">
            //         <Label htmlFor="verify_mobile_top" className="us-form__label mb-0">Enter OTP received on Mobile Number</Label>
            //         <div className="us-para mb-3">{mobileNumber}</div>
            //         <OtpInput
            //           id="verify_mobile_top"
            //           name="verify_mobile_top"
            //           numInputs={6}
            //           isInputNum={true}
            //           className="me-2"
            //           inputStyle="us-form__input-otp"
            //           value={state.mobile_otp}
            //           onChange={(e) => onUpdateMobileOTPHandler(e)}
            //         />
            //         <div className="us-form__error">{state.errors.mobile_otp}</div>
            //         {updateMobileOTPVerificationError && <div className="us-form__error">{updateMobileOTPVerificationError}</div>}
            //       </FormGroup>
            //       <div className='flex items-center mt-3'>
            //         {(timeString == "00:00" || state.errors.mobile_otp == "OTP expired") &&
            //           <Button type="button" color="link" onClick={(e) => confirmResendMobileOTP(e)} className="us-button__link text-primary text-sm font-semibold p-0">Resend OTP</Button>
            //         }
            //         {timeString != "00:00" &&
            //           <div className="color-gray3 text-md font-medium">
            //             <div>{timeString}</div>
            //           </div>
            //         }
            //       </div>
            //     </Col>
            //   </Row>

            //   <div className="d-flex align-items-center justify-content-center mt-10">
            //     <Button onClick={() => onMobileOTPVerifyCancelEditing(false)} type="button" color="danger" outline className="us-button us-button__danger-outline py-3 px-8 me-4">Cancel</Button>
            //     <Button
            //       type="submit"
            //       color="primary"
            //       disabled={updateMobileOTPVerificationLoading}
            //       className={`us-button us-button__primary py-3 px-8 ${updateMobileOTPVerificationLoading ? "us-button__disabled" : "us-button__primary"}`}
            //     >
            //       {updateMobileOTPVerificationLoading ? "Verifying..." : "Verify"}
            //     </Button>
            // </div>
            // </Form>
          }
        </ModalBody>
      </Modal>
    </>
  )
}
export default UpdateMobileNumberModal;